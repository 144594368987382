import { ErrorHandler, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import _ from "lodash";

import { EidosErrorDialogComponent } from "@common/components/dialogs/eidos-error-dialog/eidos-error-dialog.component";
import { EidosLogSeverity } from "@common/models/core-constant.model";
import { EidosLogService } from "./services/eidos-log.service";
import { EidosUtilityService } from "./services/eidos-utility.service";
import { EidosSecurityService } from "./services/eidos-security.service";

/**
 * Main application error handler
 *
 * @export
 * @class CoreErrorHanlder
 * @implements {ErrorHandler}
 */
@Injectable()
export class CoreErrorHandler implements ErrorHandler {

  constructor(
    private dialog: MatDialog,
    private coreUtilityService: EidosUtilityService,
    private coreSecurityService: EidosSecurityService,
    private coreLogService: EidosLogService
  ) {
  }
  /**
   * Handles a client side error
   *
   * @param {Error} clientError
   * @memberof CoreErrorHandler
   */
  public handleError<T extends Error>(clientError: T) {

    const skipLog = this.skipLog(clientError);

    if (!skipLog) {

      // Log the error on debug mode
      this.coreLogService.logDebug(EidosLogSeverity.Error, clientError);

      if (this.coreUtilityService.skipNextError) return;

      this.coreUtilityService.inDebugMode.subscribe(debug => {
        if (this.coreSecurityService.isDeveloperUser() && debug) {
          this.dialog.open(EidosErrorDialogComponent, {
            width: '50%',
            data: clientError
          });
        }
      });

      // Don't send log to DB at the moment
      // this.coreApiService.logClientSideError(clientError).subscribe({
      //   next: (response: boolean) => {
      //     if (response) {
      //       // Log the error on debug mode
      //       this.coreLogService.logDebug(EidosLogSeverity.Error, clientError);

      //       this.coreUtilityService.inDebugMode.subscribe(debug => {
      //         if (!!debug) {
      //           this.dialog.open(EidosErrorDialogComponent, {
      //             width: '50%',
      //             data: clientError
      //           });
      //         }
      //       });
      //     }
      //   },
      //   error: error => {
      //     this.coreLogService.logDebug(EidosLogSeverity.Error, error);

      //     this.coreUtilityService.inDebugMode.subscribe(debug => {
      //       if (!!debug) {
      //         this.dialog.open(EidosErrorDialogComponent, {
      //           width: '50%',
      //           data: clientError
      //         });
      //       }
      //     });
      //   },
      //   complete: () => {
      //   }
      // });
    }
  }

  /**
   * Skip some verboses logs
   *
   * @private
   * @param {*} clientError
   * @return {*}  {boolean}
   * @memberof CoreErrorHandler
   */
  private skipLog(clientError: any): boolean {

    if(clientError.name == 'SchemaError' )return true;

    // Skip https://angular.io/errors/NG0100
    if (clientError.code === -100) {
      return true;
    }

    if (_.isEqual(this.coreLogService.lastLoggedError, clientError)) {
      return true;
    }

    this.coreLogService.lastLoggedError = clientError;

    return false;
  }
}
