import { Injectable, isDevMode } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IEidosEnvironment, IEidosLinksConfiguration as IEidosLinkConfiguration } from './environment.interface';

import { EidosIconType } from '@common/models/core-constant.model';
import { IEidosExternalApp } from '@common/models/eidos-external-app.interface';
import { merge, assign } from 'lodash';
import _ from 'lodash';
declare const eidosConfiguration: IEidosEnvironment;

@Injectable({
  providedIn: 'root'
})
export class EidosConfigService {
  /**
   * Default config
   *
   * @type {IEidosEnvironment}
   * @memberof EidosConfigService
   */
  public readonly DEFAULT_CONFIG: IEidosEnvironment = {
    production: false,
    baseUrl: window.location.origin,
    urlAPI: window.location.origin + '/mb',
    reportAPIUrl: window.location.origin + '/mb',
    reportInvokeAction: "/EidosWebDocumentViewer/Invoke",
    urlMessagingService: '',
    ExtendedFieldHeight: 59,
    ExtendedFieldPadding: 5,
    ExtendedFieldMargin: 5,

    microsoftAuth: { enabled: false, authorizationUrl: 'general' },

    myBizLegacyConfiguration: {
      id: '-1',
      objectId: -1,
      source: 'mybiz',
      url: '/mb/webui/mybiz.html#',
      pattern: 'mybiz.html#',
      useToken: true,
      action: 'object',
      useLoader: false,
      actionInUrl: true,
      label: "MyBiz",
    },

    embeddedUrl: {
      id: '-2',
      objectId: 0,
      source: 'url',
      url: '',
      pattern: '',
      useToken: false,
      action: 'euri',
      useLoader: false,
      actionInUrl: true,
      label: "exturl"
    },

    loginConfiguration: {
      assetsPath: 'configurations/images/',
      logoName: 'logo.png',
      wallpaperNames: 'background_1.jpg'
    },

    applicationTitle: 'Eidos',
    tokenOnHeader: false,

    notificationConfiguration: {
      enabled: false,
      pollingInterval: 60
    },

    logo: {
      iconType: EidosIconType.SVG,
      iconCode: 'logo_wide_1c',
      iconSize: 4,
      inverseColor: true
    },

    defaultProfileBadge: {
      defaultBadgeColorPrimary: 'red',
      defaultBadgeColorSecondary: 'white'
    },

    externalAppsRoot: 'app',

    externalIcons: [],

    exchangeRateEmail: {
      body: '', 
      cc: [], 
      object: '', 
      to: []    
    },

    modules: [],

    links: [],

    formats: {
      defaultCurrency: 'USD',
      dateAndTime: 'dd/MM/yyyy HH:mm',
      dateFmt: 'dd/MM/yyyy',
      dateAndMonth: 'dd MMM',
      dateAndMonthAndYear: 'dd MMM yyyy',
      dateAndMonthAndYearAndTime: 'dd MMM yyyy HH:mm',
      dateQueryParams: 'yyyy-MM-dd', // Format used for query date params

      dateStandardLocaleOpts: { locale: 'en'},

      currencies: {
        USD: "$",
        EUR: "€",
        GBP: "£",
        AUD: "A$",
        CAD: "C$",
      },
    },

    cache: {
      enabled: true,
      version: 0,
      cacheValidityMinutes: 1440 // 1 day
    }
  };
  /**
   * Current configuration
   *
   * @type {BehaviorSubject<IEidosEnvironment>}
   * @memberof EidosConfigService
   */
  public currentConfig: BehaviorSubject<IEidosEnvironment>;
  /**
   * External apps from current local configuration
   *
   * @type {BehaviorSubject<Array<IEidosExternalApp>>}
   * @memberof EidosConfigService
   */
  public currentlocalConfigExternalApps: BehaviorSubject<Array<IEidosExternalApp>>;
  /**
   * Current modules configuration
   *
   * @type {BehaviorSubject<Array<any>>}
   * @memberof EidosConfigService
   */
  public currentModulesConfig: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  /**
   * Current links configuration
   *
   * @type {BehaviorSubject<Array<any>>}
   * @memberof EidosConfigService
   */
  public currentLinksConfig: BehaviorSubject<Array<IEidosLinkConfiguration>> = new BehaviorSubject<Array<IEidosLinkConfiguration>>([]);
  public getLinks(key:string):IEidosLinkConfiguration | undefined {
    return this.currentLinksConfig.getValue().find(l=>l.key.toLocaleLowerCase() === key.toLocaleLowerCase());
  }
  constructor(
    private titleService: Title
  ) {

    const defaultConfig = Object.assign({}, this.DEFAULT_CONFIG);

    // Update values from config values
//    let config = Object.assign(defaultConfig, environment, this.getCustomConfiguration());
    let config = assign(merge(defaultConfig, environment, this.getCustomConfiguration()), this.getCustomConfiguration());

    let localConfigExternalApps: Array<IEidosExternalApp> = config?.externalApps ?? [];

    if (isDevMode()) {
      try {

        // Read local custom configuration
        const eidosLocalConfiguration = require('../../../configurations/eidos-config.local.js').eidosConfiguration;
        if (typeof eidosLocalConfiguration !== 'undefined') {
          console.log('Local configuration override', eidosLocalConfiguration);

          // Read local external apps configuration
          const localExternalApps = eidosLocalConfiguration.externalApps;
          if (typeof localExternalApps !== 'undefined') {
            console.log('External apps local override', localExternalApps);
            localConfigExternalApps = Object.assign(localConfigExternalApps, localExternalApps);
          }

          // Update config and ext apps from local configuration
          //config = Object.assign(config, eidosLocalConfiguration);
          config = merge(defaultConfig, eidosLocalConfiguration);
          this.currentlocalConfigExternalApps = new BehaviorSubject<Array<IEidosExternalApp>>(localConfigExternalApps);
        }
      } catch (ex) {
        console.log('No local configuration found. No config property will be overwritten.');
      }

      console.log("Current config", config);
      console.log("Go to /eidos/readme to check current configuration");
    }

    this.currentConfig = new BehaviorSubject<IEidosEnvironment>(config);
    this.currentModulesConfig.next(config.modules);
    if(_.isArray(config.links)) {
      this.currentLinksConfig.next((config.links as Array<any>).filter(l=>l.key && l.template).map(l=>l as IEidosLinkConfiguration));
    }
    this.currentlocalConfigExternalApps = new BehaviorSubject<Array<IEidosExternalApp>>(localConfigExternalApps);
    this.titleService.setTitle(config.applicationTitle);
  }

  /**
   * Get configuration from file
   *
   * @private
   * @return {*}  {*}
   * @memberof EidosConfigService
   */
  private getCustomConfiguration(): any {
    if (typeof eidosConfiguration !== 'undefined') {
      // Resolve URLs
      eidosConfiguration.urlAPI = eidosConfiguration.urlAPI.toLocaleLowerCase().replace("~", eidosConfiguration.baseUrl);
      if (eidosConfiguration.windowsAuth) {
        eidosConfiguration.windowsAuth.authorizationUrl = eidosConfiguration.windowsAuth.authorizationUrl.toLocaleLowerCase().replace("~", eidosConfiguration.baseUrl);
      }
      return eidosConfiguration;
    } else {
      console.log('Configuration missing');
      return {};
    }
  }
}
