import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResFinancialSplit } from '@app/reservation/models/res-booking.model';
import { ReservationApiService } from '@app/reservation/services/reservation-api.service';

@Component({
  selector: 'app-res-financial-split-dialog',
  templateUrl: './res-financial-split-dialog.component.html',
  styleUrls: ['./res-financial-split-dialog.component.scss']
})
export class ResFinancialSplitDialogComponent {
  constructor(
    private reservationApiService: ReservationApiService,
    private dialogRef: MatDialogRef<ResFinancialSplitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  financialSplit: ResFinancialSplit[] = []

  ngOnInit(): void {
    this.reservationApiService.getFinancialSplit(this.data.bkgID, this.data.section).subscribe({
      next: (response: ResFinancialSplit[]) => {
        this.financialSplit = response;
      }
    })
  }
  
  /**
   * Close the dialog
   *
   * @memberof ResFinancialSplitDialogComponent
   */
  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }
}
