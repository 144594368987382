import { EventEmitter, Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { EidosLogSeverity, LocalStorageKeys, SecurityCheckRequestType } from '@common/models/core-constant.model';
import { IMyBizUserInfo } from '@common/models/user-info.interface';
import { EidosUser } from '@common/models/user-info.model';
import { EidosLogService } from './eidos-log.service';

@Injectable({
  providedIn: 'root'
})
export class EidosSecurityService {
  /**
   * Security check request
   *
   * @memberof SecurityService
   */
  public checkRequest = new EventEmitter<SecurityCheckRequestType>();
  /**
   * Current authentication state
   *
   * @memberof EidosSecurityService
   */
  public authenticated = new BehaviorSubject<boolean | undefined>(undefined);

  /**
   * Last token check moment
   *
   * @private
   * @type {number}
   * @memberof SecurityService
   */
  private lastTokenCheck: number = Date.now();
  /**
  * Token check interval (in ms)
  *
  * @private
  * @memberof SecurityService
  */
  private checkInterval = 10000 * 60;
  /**
   * Current logged user
   *
   * @type {EidosUser}
   * @memberof SecurityService
   */
  private _currentUser: EidosUser;
  /**
   * Current logged user subject
   *
   * @type {BehaviorSubject<EidosUser>}
   * @memberof SecurityService
   */
  public currentLoggedUser: BehaviorSubject<EidosUser>;

  /**
  * Returns the current logged user
  *
  * @deprecated Use currentUser behaviour subject instead
  * @type {EidosUser}
  * @memberof SecurityService
  */
  get currentUser(): EidosUser {
    return this._currentUser;
  }
  /**
   * Set the current logged user
   *
   * @deprecated Use setUser method instead
   * @memberof SecurityService
   */
  set currentUser(user: EidosUser) {
    this._currentUser = user;
    localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(user));
    this.currentLoggedUser.next(user);
  }

  constructor(
    private eidosLogService: EidosLogService
  ) {
    // Set and emit fake user as current user
    this._currentUser = new EidosUser();
    this.currentLoggedUser = new BehaviorSubject<EidosUser>(this._currentUser);
  }
  /**
   * Returns if current user is admin
   *
   * @return {*}  {boolean}
   * @memberof EidosSecurityService
   */
  isAdminUser(): boolean {
    return this._currentUser.isAdmin;
  }
  /**
   * Returns if current user is admin
   *
   * @return {*}  {boolean}
   * @memberof EidosSecurityService
   */
  isDeveloperUser(): boolean {
    return this._currentUser.isDeveloper;
  }  

  private checkTokenValidity(): void {
    //TODO
    let milliseconds = Date.now() - this.lastTokenCheck;
    if (milliseconds > this.checkInterval) {
      this.lastTokenCheck = Date.now();
      this.checkRequest.emit(SecurityCheckRequestType.Authentication);
    }
  }

  public revalidateToken(): void {
    this.lastTokenCheck = 0;
    this.checkTokenValidity();
  }

  public isAuthenticated(): boolean {
    const hasToken = (this.getToken()?.length || 0) > 0;
    if (hasToken) {
      this.checkTokenValidity();
    }
    return hasToken;
  }

  public clearAuthentication(): void {
    this.resetToken();
    this.resetUser();
    sessionStorage.clear();
  }

  public amIAuthenticated(): boolean | Promise<boolean> {
    if (!this.isAuthenticated()) return false;

    if (!this._currentUser.isValid) {
      // Try to set the user based on raw one cached, reload otherwise
      var user = this.getRawUser();
      if (user) {
        this.setUser(new EidosUser(user));
        this.eidosLogService.logDebug(EidosLogSeverity.Log, "User", user);
      } else {
        this.checkRequest.emit(SecurityCheckRequestType.User);
      }
    }
    return true;
  }
  /**
   * Get the current cached token
   *
   * @return {*}  {(string | null)}
   * @memberof EidosSecurityService
   */
  public getToken(): string | null {
    return localStorage.getItem(LocalStorageKeys.TOKEN);
  }
  /**
   * Set a token in cache
   *
   * @param {string} token
   * @memberof EidosSecurityService
   */
  public setToken(token: string) {
    localStorage.setItem(LocalStorageKeys.TOKEN, token);
    this.authenticated.next(true);
  }
  /**
   * Erase cached token
   *
   * @memberof EidosSecurityService
   */
  public resetToken() {
    localStorage.removeItem(LocalStorageKeys.TOKEN);
    this.authenticated.next(false);
  }

  /**
   * Update a raw user property in cache
   *
   * @param {*} propertyToUpdate
   * @memberof EidosSecurityService
   */
  public updateUserProperty(propertyToUpdate: any): void {
    const u = Object.assign(this.getRawUser(), propertyToUpdate);
    this.setRawUser(u);
    // return this.currentLoggedUser;
  }
  /**
   * Get user raw from cache
   *
   * @return {*}  {(any | null)}
   * @memberof SecurityService
   */
  public getRawUser(): any | null {
    const user = localStorage.getItem(LocalStorageKeys.RAW_USER);
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  }
  /**
   * Set raw user (also in cache) after login
   *
   * @param {*} user
   * @memberof SecurityService
   */
  public setRawUser(user: IMyBizUserInfo) {
    localStorage.setItem(LocalStorageKeys.RAW_USER, JSON.stringify(user));
    this.setUser(new EidosUser(user));
  }

  /**
   * Get current logged user from cache
   *
   * @return {*}  {(any | null)}
   * @memberof SecurityService
   */
  public getUser(): any | null {
    const user = localStorage.getItem(LocalStorageKeys.USER);
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  }
  /**
   * Set the current logged user
   *
   * @private
   * @param {EidosUser} user
   * @memberof SecurityService
   */
  private setUser(user: EidosUser) {
    this._currentUser = user;
    localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(user));
    this.currentLoggedUser.next(this._currentUser);
  }
  /**
   * Reset cached logged user
   *
   * @memberof SecurityService
   */
  private resetUser() {
    localStorage.removeItem(LocalStorageKeys.DEBUG_MODE);
    localStorage.removeItem(LocalStorageKeys.PINNED_MENU);
    localStorage.removeItem(LocalStorageKeys.USER);
    localStorage.removeItem(LocalStorageKeys.RAW_USER);
    sessionStorage.clear();
    this._currentUser = new EidosUser();
    this.currentLoggedUser.next(this._currentUser);
  }
  /**
   * Return the route request while not logged in
   *
   * @return {*}  {(string | null)}
   * @memberof SecurityService
   */
  public getRequestedRoute(): string | null {
    return localStorage.getItem(LocalStorageKeys.REQUESTED_ROUTE);
  }
  /**
   * Return the route request navigation params while not logged in
   *
   * @return {*}  {[string, NavigationExtras]}
   * @memberof EidosSecurityService
   */
  public getRequestedRouteForNavigation(): [string, NavigationExtras] {
    const requestedRoute = this.getRequestedRoute()?.split('?') ?? [];
    if (Array.isArray(requestedRoute) && requestedRoute.length > 0) {
      if (requestedRoute.length > 1) {
        // There are query parameters
        const qp = requestedRoute[1].split('&').reduce((acc, param) => {
          const paramEntries = param.split('=');
          if (Array.isArray(paramEntries) && paramEntries.length > 1) {
            acc[paramEntries[0]] = paramEntries[1];
          }
          return acc;
        }, {} as any);
        return [requestedRoute[0], { queryParams: qp }];
      } else {
        // no query parameters
        return [requestedRoute[0], {}]
      }
    } else {
      return ['', {}]
    }
  }
  /**
   * Set the route requested while not logged in
   *
   * @param {string} route
   * @memberof EidosSecurityService
   */
  public setRequestedRoute(route: string): void {
    localStorage.setItem(LocalStorageKeys.REQUESTED_ROUTE, route);
  }
  /**
   * Clear the route requested while not logged in
   *
   * @param {UrlSegment[]} urlSegments
   * @memberof SecurityService
   */
  public clearRequestedRoute(): void {
    localStorage.removeItem(LocalStorageKeys.REQUESTED_ROUTE);
  }
}
