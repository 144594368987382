import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { filter, take } from 'rxjs';

import { EidosApiService } from '@common/services/eidos-api.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { SpinnerOverlayService } from '@common/services/spinner-overlay.service';
import { EidosTokenGuard } from './eidos-token.guard';
import { CoreRoute } from '@common/models/core-constant.model';
import { EidosExternalAppService } from '@common/eidos-external-app/eidos-external-app.service';

@Injectable({
  providedIn: 'root'
})
export class EidosExternalAppGuard extends EidosTokenGuard implements CanActivate {

  constructor(
    public router: Router
    , public eidosSecurityService: EidosSecurityService
    , public externalAppService: EidosExternalAppService
    , public spinnerOverlayService: SpinnerOverlayService
    , public eidosApiService: EidosApiService) {
    super(router, eidosSecurityService, externalAppService, spinnerOverlayService, eidosApiService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>(resolve => {
      super.canActivate(route).then((value: boolean | UrlTree) => {
        if (value instanceof UrlTree) {
          // Not authenticated, must resolve login path
          resolve(value);
        } else {
          // Logic of this guard

          this.spinnerOverlayService.show();

          this.externalAppService.apps
            .pipe(
              filter(apps => !!apps),
              take(1)
            )
            .subscribe(() => {
              this.spinnerOverlayService.hide();
              const action = route?.url[1]?.path ?? this.router.routerState.snapshot.url.split('/')[1];
              if (this.externalAppService.isValidAction(action)) {
                const app = this.externalAppService.getAppReferenceByAction(action);
                if (app) {
                  app.isVisible = true;
                }
                return resolve(!!app);
              } else {
                return resolve(this.router.parseUrl(CoreRoute.Home));
              }
            });

          // if (this.externalAppEventService.isValidAction(action)) {
          //   this.spinnerOverlayService.hide();
          //   const app = this.externalAppEventService.getAppReferenceByAction(action);
          //   if (app) app.isVisible = true;
          //   return resolve(!!app);
          // } else {
          //   this.externalAppEventService.apps
          //     .pipe(
          //       filter(apps => !!apps),
          //       take(1)
          //     )
          //     .subscribe(() => {
          //       this.spinnerOverlayService.hide();
          //       const app = this.externalAppEventService.getAppReferenceByAction(action);
          //       if (app) app.isVisible = true;
          //       return resolve(!!app);
          //     });
          // }
        }
      });
    });
  }
}
