import {
  IDynamicApiErrorResponse,
  IDynamicApiResponse,
} from '@app/core/models/dynamic-api-response.model';
import { SafeBaseObject } from '@common/models/base-object.models';
import { DateTime, Interval } from 'luxon';
import _ from 'lodash';
import { lastValueFrom, map } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { AppModule } from '@app/app.module';
import { EidosUtilityService } from '@app/core/services/eidos-utility.service';
import { IEidosGridCallableMethod } from '@app/core/models/eidos-grid.models';
import { EidosConfigService } from '@app/core/config/eidos-config.service';
import { IResDynConfiguration } from '../components/res-dyn-grid/res-dyn-grid.component';

import { IReservationSuiteSelectionDialogComponentConfigSuites } from '../components/res-suite-selection-dialog/res-suite-selection-dialog.component';
import {
  IReservationErrorApi,
  ReservationApiService,
} from '../services/reservation-api.service';
import {
  IReservationApiCruise,
  ReservationCruise,
  ReservationCruiseItinerary,
  ReservationSuite,
} from './res-cruise.models';
import {
  IReservationApiCustomRequestDetail,
  IReservationApiCustomRequestDetailGuest,
  ReservationCustomRequestDetail,
  ReservationCustomRequestDetailGuest,
} from './res-custom-request.models';
import { ReservationIndividual } from './res-owner.model';
import {
  ReservationApiBoolean,
  ReservationRoute,
  RESERVATION_ROOT,
} from './res-constant.model';
import { ResSearchPopupConfig } from '../components/res-search-popup/res-search-popup.component';
import { ReservationActionBooking } from './reservation-permission.model';
import {
  ReservationBasePackage,
  ReservationPackageTravelDateDetail,
  ReservationProductItineraryBaseData,
  ReservationProductServiceImages,
  ReservationProductServiceOptions,
  ReservationProductSuggestedItems,
} from './res-package.model';
import {
  IReservationApiPackageInfo,
  ReservationPackageEcmData,
} from './res-package-detail.model';
import {
  IReservationLinkedBooking,
  IReservationLinkedBookingOfBooking,
} from '../components/res-booking/res-booking-link/res-booking-link.component';
import { ReservationService } from '../services/reservation.service';
import { IResApiBookingCancelReason } from './res-cached-data.models';
import { ReservationAccomodation } from './res-service.models';

export enum BookingActionType {
  BkgCopy = 'BKGCOPY',
  BkgModify = 'BKGEDIT',
  BkgNote = 'BKGNOTE',
  BkgDelete = 'BKGDELETE',
  BkgRecalculate = 'BKGRECALC',
  BkgConfirm = 'BKGCONFIRM',
  BkgLink = 'BKGLINK',
  BkgVersionPromote = 'BKGVERSIONPROMOTE',
  BkgVersionClone = 'BKGVERSIONCLONE',
  BkgVersionGuest = 'BKGVERSIONPGUEST',
  BkgVersionCustomPackage = 'BKGVERSIONCUSTOMPKG',
  BkgVersionAddOptions = 'BKGVERSIONADDOPTIONS',
  BkgVersionDeleteSupplements = 'BKGVERSIONDELETESUPPLEMENTS',
  BkgVersionCloneBooking = 'BKGVERSIONCLONEBOOKING',
  BkgVersionPkgUpgrade = 'BKGVERSIONPKGUPGRADE',
  BkgVersioneWishlist = 'BKGVERSIONWISHLIST',
  BkgVersionChangeVoyage = 'BKGVERSIONCHANGEvoyage',
  BkgVersionChangeSuite = 'BKGVERSIONCHANGESUITE',
  BkgVersionCommission = 'BKGVERSIONCOMMISSION',
  BkgVersionDocument = 'BKGVERSIONDOCUMENT',
  BkgVersionCertificates = 'BKGVERSIONCERTIFICATES',
  BkgCostAnalysis = 'BKGCOSTANALYSIS',
  Payment = 'BKGPAYMENT',
  BkgReferralDiscounts = 'BKGREFERRALDISCOUNTS',
  BkgGenerateDynamicMap = 'BKGGENERATEDYNAMICMAP',
}

export interface IResBookingCreateApiResponse {
  BkgID?: number;
  ErrorMessage?: string;
}

export interface IResBookingAction {
  id: string;
  icon?: string;
  caption: string;
  disabled: boolean;
  order: number;
  tooltip: string;
}

type ResPaymentProvider = 'NUVEI' | 'PAYEN';

export interface IReservationApiBookingRequestParameters {
  BkgID?: number;
}

export interface IReservationApiBookingVersionRequestParameters
  extends IReservationApiBookingRequestParameters {
  VersionID?: number;
}
export interface IReservationApiBookingDelete
  extends IReservationApiBookingVersionRequestParameters {
  CancelReasonID: number;
  CanceledNote?: string;
}
export interface IReservationApiBookingDelete
  extends IReservationApiBookingVersionRequestParameters {
  CancelReasonID: number;
  CanceledNote?: string;
}

export interface IReservationApiBookingGetMyActivitiesUrlParameters
  extends IReservationApiBookingRequestParameters {
  Agent: string;
}

export interface IReservationApiBookingAddPackageParameters
  extends IReservationApiBookingRequestParameters {
  SectionID?: number;
  Package: Array<IReservationApiAddPackage>;
}

export interface IReservationApiAddPackage {
  PackageID: number;
  PackageTravelDateID: number;
  GuestCod: number;
  RoomID?: number;
  RetailPrice: string;
}
export interface IReservationApiBookingActionCheckParameters
  extends IReservationApiBookingVersionRequestParameters {
  Action: ReservationActionBooking;
}

export interface IReservationApiBookingActionParameters
  extends IReservationApiBookingActionCheckParameters {
  NewSuite?: Array<IReservationSuiteSelectionDialogComponentConfigSuites>;
  KeepPrice?: ReservationApiBoolean;
}

export interface IReservationApiBookingGetMyActivitiesUrlParameters
  extends IReservationApiBookingRequestParameters {
  Agent: string;
}

export interface IReservationApiEditBookingParameters
  extends IReservationApiBookingRequestParameters {
  CreationDate?: DateTime;
  ConfirmationDate?: DateTime;
  DepositDate?: DateTime;
  FinalPaymentDate?: DateTime;
  AgencyID?: number;
  GrpID?: number;
  Bkg_Agent_Owner?: string;
  ResAgentEmail?: string;
  IndividualID?: number;
  PriceTypeID: number;
  //Currency: string; // TODO: uncomment to edit booking currency
  BkgID: number;
  CommissionPaymentType: string;
  Market: string;
  CreatedBy?: string;
}

export interface IReservationApiBooking {
  BkgID: number;
  BkgName: string;
  BkgType: string;
  BkgStatus: string;
  BkgCurrency: string;
  PriceTypeID: number;
  PriceTypeName: string;
  OrganizationID: number;
  OrganizationName: string;
  CompanyCode: string;
  CompanyID: number;
  CompanyName: string;
  IsOpenDeposit: boolean;
  ExtendVoyage: ReservationApiBoolean;
  IsFloatingDeposit: boolean; // temporary: remove after rename on API side
  PaidInFull: string;
  Note: string;
  TravelDateStart?: DateTime;
  TravelDateEnd?: DateTime;
  CreationDate?: DateTime;
  DepositDate?: DateTime;
  ConfirmationDate?: DateTime;
  CancelDate?: DateTime;
  GrpID?: number;
  GrpName?: string;
  HideGuest: boolean;
  EditCreationDate: ReservationApiBoolean;
  PaymentProvider?: string;
  PaymentProviderType: string;
  HidePayNow: ReservationApiBoolean;
  HideRepayFlag: ReservationApiBoolean;
  GifComplete: number;
  CreatedBy?: string;
  UpdatedBy?: string;
  Updated?: DateTime;
  IsNetFare: boolean;
  LinkedNext: number;
  LinkedPrevious: number;
  CanImportAirs: ReservationApiBoolean;

  Owner: IReservationApiBookingOwner;
  FinacialRecap: Array<IReservationApiBookingFinancialRecap>;
  Voyages: Array<IReservationApiCruise>;
  Supplements: Array<IReservationApiBookingSupplement>;
  Pax: Array<IReservationApiBookingGuest>;
  Packages: Array<IReservationApiBookingPackage>;

  Deposit: Array<IReservationApiBookingDeposit>;
  Penalties: Array<IReservationApiBookingPenalties>;
  CustomPackages: Array<IReservationApiBookingCustomPackageGuest>;

  PriceJourney: Array<IReservationApiBookingPriceJourney>;
  JourneySupplements: Array<IReservationApiBookingPriceSupplementJourney>;

  Links: IReservationLinkedBookingOfBooking[];
  EmbarkDebarkInfo: IEmbarkDebarkInfo;
  EmbarkDebarkDetails: IEmbarkDebarkDetails[];
  DisplayCommissionButton: ReservationApiBoolean;
  DayByDayItineraryDisplay: ReservationApiBoolean;
  RightRezSearch: boolean;
  GMTSearch: boolean;
  RoomsConfig:IApiRoomConfig[]
}

interface IReservationApiBookingOwner {
  BkgID: number;
  Address: string;
  AddressToDisplay: string;
  Address2: string;
  Agency: string;
  AgencyCod: string;
  AgencyContacts: string;
  OwnerType: string;
  AgencyID: number;
  AgencyIataCod: string;
  AgentContacts: string;
  AgentID: number;
  City: string;
  CommunicationEmail: string;
  Email: string;
  FirstName: string;
  IndividualID: number;
  LastName: string;
  MainContact: string;
  MiddleName: string;
  Phone: string;
  CruiseCommission: number;
  AirCommission: number;
  OptionCommission: number;
  EtaAccountingEmail: string;
  Note: string;
  Country: string;
  CountryCod: string;
  StateCod: string;
  Zip1: string;
  Zip2: string;
  Guest: string;
  DisplayGuest: string;
  IsWebSiteAgent: string;
}
export interface IReservationApiBookingFinancialRecap {
  BkgID: number;
  SectionID: number;
  TotalDue: number;
  TotalPriceTax: number;
  TotalCharge: number;
  TotalPayment: number;
  Commission: number | null;
  CommissionPaid: number | null;
  VatCommission: number | null;
  NetCommission: number | null;
  Cost: number;
  TotalCostTax: number;
  Tax: number;
  PortCharge: number;
  NCF: number;
  GuestNum: number;
  BookingBalance: number;
  PrePaid: boolean;
}

interface IReservationApiBookingPackage {
  BkgID: number;
  SectionID: number;
  PackageID: number;
  PackageTravelDateID: number;
  PackageName: string;
  IsTM: string;
  PackageInclusion: string;
  PackageExclusion: string;
  TravelStartDate: Date;
  TravelEndDate: Date;
  MinPax: number;
  MaxPax: number;
  EscortInc: boolean;
  DriverInc: boolean;
  GuideInc: boolean;
  BrandID: number;
  Occupancy: number;
  Duration: number;
  ChildReduction: number;
  SellingPrice: number;
  Currency: string;
  PriceSetUp: string;
  Adult: number;
  Child: number;
}
interface IReservationApiBookingSupplement {
  BkgID?: number;
  SectionID?: number;
  ItemID?: number;
  PackageID?: number;
  PackageTravelDateID?: number;
  VoyageID?: number;
  OptionID?: number;
  MinPax?: number;
  MaxPax?: number;
  OptionName?: string;
  OptionShortName?: string;
  OptionDesc?: string;
  ItineraryDate?: Date;
  ServiceID?: number;
  SellingPrice?: number;
  SellingPriceCurrency?: string;
  Message?: string;
  RemarkAir?: string;
  Cost: number;
  CostTax: number;
  CostCurrency: string;
  Commission: number;
  Commissionable: string;
  SortSequence?: number;
  GuestCod?: string;
  PromoID?: number;
  Promo?: IResevationApiBookingPromo;
  Note: string;
  IsSBC: string;
  CanEdit: string;
  CanDelete: string;
  FlightDetails: string;
  PrePost: string;
  IsCertificateItem: string;
  IsPackageUpgrade: string;
  CanEditSupplier: ReservationApiBoolean;
  ForcedSupplierID?: number;
  SupplierName?: string;
  HideInBkg: string;
  PackageUpgradeAvailable: ReservationApiBoolean;
  FilePath?: string
}
export interface IReservationApiBookingGuest {
  BkgID: number;
  SectionID: number;
  VoyageID: number;
  PackageID?: number;
  PackageTravelDateID?: number;
  GuestID: number;
  IndividualID: number;
  RoomID: number;
  IsPlaceHolder: string;
  InsuranceStatus: string;
  InsuranceDeclineUser: string;
  InsuranceDeclineDate: string;
  GuestCod: number;
  LastName: string;
  FirstName: string;
  Title: string;
  GuestStatusCod: string;
  GuestType?: string;
  CountryCod: string;
  Country: string;
  StateCod: string;
  County: string;
  City: string;
  Zip1: string;
  Zip2: string;
  Address1: string;
  Address2: string;
  Address3: string;
  Address4: string;
  NationalityCod: string;
  BirthDate: Date;
  BirthPlace: string;
  BirthNation: string;
  Phone: string;
  PhonePrefix_ISO: string;
  PassportNumber: string;
  PassportIssuePlace: string;
  PassportIssueDate: Date;
  PassportExpireDate: Date;
  PassportIssueCountryCod: string;
  PassportIssueCountryCod_ISO2: string;
  Gender: string;
  SellingPrice: number;
  PriceTax: number;
  Commission: number;
  CommissionTax: number;
  FixedPrice: string;
  UpSelling: string;
  UpSellingAmount: number;
  OriginalSuiteCategoryID: number;
  household_id: number;
  HasPNR: number;
  DefaultCurrency: string;
  CheckBooking: string;
  StringBkgId: string;
  IndividualPreference: boolean;
  BookingPreference: boolean;
  GuestOwnArrangments: IReservationApiBookingGuestOwnArrangement[];
  GuestCharge?: number;
  HasFutureBkg: ReservationApiBoolean;
  HasGuestReferral: ReservationApiBoolean;
  KeySuiteID:number;
}

interface IReservationApiBookingGuestOwnArrangement {
  BkgID: number;
  ItineraryDate: string;
  Description: string;
  GuestCod: number;
  GuestID: number;
  Type: string;
  ServiceType: string;
}

interface IReservationApiBookingPriceJourney {
  SectionID: number;
  ServiceTypeID: number;
  ServiceTypeName: string;
  VoyageID: number;
  PakageID: number;
  SellingPrice: number;
  SellingPriceTax: number;
  Commission: number;
  GuestNum: number;
  Adult: number;
  Child: number;
  Quantity: number;
  NightNum: number;
  BkgCost: number;
  BkgCostTax: number;
}
interface IReservationApiBookingPriceSupplementJourney {
  SectionID: number;
  ServiceTypeID: number;
  ServiceTypeName: string;
  ServiceID: number;
  PackageID?: number;
  PackageTravelDateID?: number;
  OptionID: number;
  OptionName: string;
  SellingPrice: number;
  SellingPriceTax: number;
  Commission: number;
  BkgCost: number;
  BkgCostTax: number;
  CostCurrency: string;
  GuestNum: number;
  Quantity: number;
  NightNum: number;
  ItineraryDate: Date;
  Included: boolean;
  HideInBkg: string;
  PackageUpgradeAvailable: ReservationApiBoolean;
  FilePath?: string;
}
class ResBookingError {
  static UNKNOWN_COD = 'unknown';
  static UNKNOWN_DES = 'no info availables for this error';

  code: string;
  description: string;
  constructor(code?: string, description?: string) {
    this.code = code ?? ResBookingError.UNKNOWN_COD;
    this.description = description ?? ResBookingError.UNKNOWN_DES;
  }
  static getUnknowError(): IReservationErrorApi {
    return {
      errorCod: this.UNKNOWN_COD,
      errorDescription: this.UNKNOWN_DES,
    } as IReservationErrorApi;
  }
}
export enum ResBookingGifFlag {
  NotCompleted = 0,
  Completed = 1,
  Hidden = 2,
}
export class ResBooking extends SafeBaseObject {
  hasUpdateData() {
    return (
      this.updated &&
      (this.creationDate?.toFormat('yyyyMMdd') !==
        this.updated?.toFormat('yyyyMMdd') ||
        this.createdBy !== this.updatedBy)
    );
  }
  static INVALID_BOOKING: number = 0;
  static PHILANTROPY = 970;
  static ErrorResponse(
    bkgID: number,
    errors: Array<IDynamicApiErrorResponse>,
    response: IDynamicApiResponse
  ): ResBooking {
    const res = new ResBooking(response.Body);
    res.bkgID = bkgID;
    errors.forEach((e) =>
      res.errors?.push(new ResBookingError(e.ErrorCod, e.ErrorDesc))
    );
    return res;
  }
  public bkgID: number = 0;
  public errors: Array<ResBookingError> = [];
  public bkgName: string = '';
  public bkgType: string = '';
  public bkgStatus: string = '';
  public bkgCurrency: string = '';
  public note: string = '';
  public priceTypeID?: number = 0;
  public priceTypeName: string = '';
  public grpID?: number;
  public grpName: string = '';
  public bkgAgentOwner: string = '';
  public organizationID: number = 0;
  public organizationName: string = '';

  public companyCode: string = '';
  public companyID: number = 0;
  public companyName: string = '';
  public canExtendVoyage: boolean = false;
  public isOpenDeposit: boolean = false;
  public isWCWL: boolean = false;

  get isFloatingDeposit(): boolean {
    // The booking is a floating deposit if it is an OD created after 01/08/2023
    return (
      this.isOpenDeposit &&
      !!this.creationDate?.isValid &&
      this.creationDate.toMillis() >
        DateTime.fromObject({ year: 2023, month: 8, day: 1 }).toMillis()
    );
  }
  public owner: BookingOwner = new BookingOwner();

  public travelStartDate?: DateTime;
  public travelEndDate?: DateTime;
  public disabledOptionsDates: string[] = [];
  public creationDate?: DateTime;
  public createdBy: string = '';
  public updatedBy: string = '';
  public updated?: DateTime;
  public depositDate?: DateTime;
  public confirmationDate?: DateTime;
  public cancelDate?: DateTime;
  public hideGuest: boolean = false;
  public editCreationDate: boolean = true;
  public changeSuite?: boolean = false;
  public embarkDebarkInfo = new ResEmbarkDebarkInfo();
  public embarkDebarkDetails: ResEmbarkDebarkDetails[] = []
  roomsConfig: ResRoomConfig[] = []
  travelGroups: ResBookingTravelGroup[] = []

  public financialRecap: ResBookingFinancialRecap[] = [];
  public penalties: Array<BookingPenalties> = [];
  public deposit: Array<BookingDeposit> = [];
  paidInFull: boolean = false;
  paymentProvider: ResPaymentProvider = 'NUVEI';
  paymentProviderType?: string;
  hidePayNow: boolean = false;
  hideRepayFlag: boolean = false;
  gifComplete: number = 2;
  isNetFare = false;
  linkedNext?: number;
  linkedPrevious?: number;
  multiSuite = false
  public defaultSearchStart: DateTime;
  public defaultSearchEnd: DateTime;
  canImportAirs: boolean = false;
  public certificateNeedPermission = false;
  public commissionPaymentType: string = '';
  public canEditCommissionPaymentType: boolean = false;

  private _sections: Array<BookingSection> = [];
  public get sections(): Array<BookingSection> {
    return this._sections.sort((a, b) => a.sectionID - b.sectionID);
  }
  public set sections(value: Array<BookingSection>) {
    this._sections = value;
  }

  public priceJourney: Array<BookingPriceJourney> = [];
  public journeySupplements: Array<BookingJourneySupplement> = [];

  prevBkg?: number;
  nextBkg?: number;
  links: IReservationLinkedBooking[] = [];
  market: string = '';
  pax: Array<IReservationApiBookingGuest> = [];
  voyages: Array<IReservationApiCruise> = [];

  selectedSectionId = 0;
  hasReferral = false;
  isReferral = false;
  displayCommissionButton = false;
  dayByDayItineraryDisplay = false;
  layoutWithTabs = false
  defaultTab = 0

  rightRezSearch = false;
  GMTSearch = false;

  canAddPackage = false
  canAddVoyage = false
  canAddSanctuary = false
  canAddService = false
  canAddLand = false

  // public lockStatus = false;
  constructor(data?: IReservationApiBooking) {
    super();
    if (data) {
      super.updateData(data)
      this.updateData(data)
    };
    this.owner = new BookingOwner(data?.Owner);
    this.embarkDebarkInfo = new ResEmbarkDebarkInfo(data?.EmbarkDebarkInfo);
    this.embarkDebarkDetails =
      data?.EmbarkDebarkDetails?.map((d) => new ResEmbarkDebarkDetails(d)) ??
      [];
    this.errors = [];
    this.defaultSearchStart = (this.travelStartDate ?? DateTime.now()).plus({
      days: -10,
    });
    this.defaultSearchEnd = (this.travelEndDate ?? DateTime.now()).plus({
      days: +10,
    });

    const hastMoreTravelGroup = this.roomsConfig.length>1
    this.roomsConfig.forEach(rCfg=>{
      let tg = this.travelGroups.find(r=>r.roomConfHdrID===rCfg.roomConfHdrID)
      if(!tg) {
        tg = new ResBookingTravelGroup(rCfg.roomConfHdrID,this.travelGroups.length+1)
        this.travelGroups.push(tg)
      }
      const g = this.getAllGuests().find(g=>g.guestID===rCfg.guestID)
      if(g) {
        g.hasTravelGroup = hastMoreTravelGroup
        g.travelGroupID = tg.travelGroupID
        g.roomID = tg.travelGroupID
        tg.addGuest(g)
      }
    })
  }

  get genericDescription(): string {
    return this.isOpenDeposit ? 'Open Deposit' : 'Booking';
  }
  get noteAsHtml(): string {
    return this.note.split(' | ').join('<br>');
  }
  isAlreadyPayed(): boolean {
    return this.financialRecap.length > 0
      ? (this.financialRecap[0].totalPayment ?? 0) > 0
      : false;
  }
  isLastSection(sectionID: number): boolean {
    return (
      this.isValid() &&
      !this.isEmpty() &&
      sectionID === this.sections[this.sections.length - 1].sectionID
    );
  }
  isValid(): boolean {
    return this.bkgID !== 0;
  }
  hasErrors(): boolean {
    return this.isValid() && (this.errors?.length ?? 0) > 0;
  }
  hasAllGuestDefined(): boolean {
    return (
      this.sections.filter(
        (s) => s.allSessionGuests().filter((g) => g.isPlaceHolder).length > 0
      ).length === 0
    );
  }
  isPhilantrory() {
    return this.companyID === ResBooking.PHILANTROPY;
  }
  philantropyName() {
    return this.owner.guest ?? 'ANONYMOUS';
  }
  philantropyEmail() {
    return this.owner.email ?? '**********';
  }
  isEmpty(): boolean {
    return !this.isValid() || (this.sections?.length ?? 0) === 0;
  }
  isWorldCruiseWaitList(): boolean {
    return this.isWCWL;
  }
  isMultySection(): boolean {
    return (
      this.isValid() && (this.isQuote() || (this.sections?.length ?? 0) > 1)
    );
  }
  // isLocked() {
  //   return this.lockStatus;
  // }
  // isReadOnly() {
  //   return this.isDeleted() || !this.lockStatus;
  // }
  isDeleted() {
    return (
      this.isValid() &&
      ((this.bkgStatus ?? '').trim().toLowerCase() === 'cancelled' ||
        this.isDeletedWithPenalty())
    );
  }
  isDeletedWithPenalty() {
    return (
      this.isValid() &&
      (this.bkgStatus ?? '').trim().toLowerCase() === 'cancelled with penalty'
    );
  }
  isQuote() {
    return (
      this.isValid() && (this.bkgStatus ?? '').trim().toLowerCase() === 'quote'
    );
  }
  isOption() {
    return (
      this.isValid() && (this.bkgStatus ?? '').trim().toLowerCase() === 'option'
    );
  }
  isBooked() {
    return (
      this.isValid() && (this.bkgStatus ?? '').trim().toLowerCase() === 'booked'
    );
  }
  getPaymentName(): string {
    switch (this.paymentProvider) {
      case 'NUVEI':
        return 'Crystal Cruises';
      case 'PAYEN':
        return 'Abercrombie & Kent Limited';
    }
  }
  getAllVoyages(): Array<ReservationCruise> {
    let voyages = new Array<ReservationCruise>();
    this.sections?.forEach(
      (s) => (voyages = voyages.concat(s.allSessionVoyages()))
    );
    return voyages;
  }
  getAllGuests(): Array<BookingGuest> {
    const guests = new Array<BookingGuest>();
    this.sections?.forEach((s) =>
      s.allSessionGuests().forEach((g) => guests.push(g))
    );
    return guests;
  }
  getAccomodations() {
    const bookingGuests = this.getAllGuests()
    const accomodations:ReservationAccomodation[]=[]
    const groups= new Set<number>()
    bookingGuests.forEach(bg=>groups.add(bg.roomID))
    groups.forEach(gn=>{
      const a = ReservationAccomodation.newRoom(gn)
      a.Adult = bookingGuests.filter(bg => bg.roomID===gn && bg.guestType === 'Adult').length
      a.Child = bookingGuests.filter(bg => bg.roomID===gn && bg.guestType === 'Child').length
      a.Infant = bookingGuests.filter(bg => bg.roomID===gn && bg.guestType === 'Infant').length
      a.Guests = bookingGuests.filter(bg => bg.roomID===gn)
      accomodations.push(a)
    })
    if(accomodations.length===0) return [ReservationAccomodation.newRoom(1)]

    return accomodations
  }
  get hasPNRs(): boolean {
    return this.getAllGuests().some((g) => g.hasPNR);
  }

  addSectionPackage(
    item: IReservationApiBookingPackage
  ): BookingPackage | null {
    if (item.SectionID === undefined) return null;
    if (item.PackageID === undefined) return null;
    if (item.PackageTravelDateID === undefined) return null;

    const s = this.sections.filter((s) => s.sectionID === item.SectionID);
    if (s.length === 0) {
      const section = new BookingSection(this, item.SectionID);
      this.sections.push(section);
      s.push(section);
    }

    const pkg = s[0].bkgPackages.filter(
      (pkg) =>
        pkg.packageID === item.PackageID &&
        pkg.packageTravelDateID === item.PackageTravelDateID
    );
    if (pkg.length === 0) {
      const bkgPackage = new BookingPackage(
        this.bkgID ?? 0,
        item.PackageID,
        item.PackageTravelDateID,
        item
      );
      s[0].bkgPackages.push(bkgPackage);
      return bkgPackage;
    }

    pkg[0].updateData(item);
    return pkg[0];
  }
  addSectionJourneySupplement(item: BookingJourneySupplement) {
    if (item.sectionID === undefined) return null;

    const s = this.sections.filter((s) => s.sectionID === item.sectionID);
    if (s.length === 0) {
      const section = new BookingSection(this, item.sectionID);
      this.sections.push(section);
      s.push(section);
    }

    s[0].journeySupplements.push(item);
    return;
  }
  getSection(sectionID: number): BookingSection {
    const s: Array<BookingSection> =
      this.sections?.filter((s) => s.sectionID === sectionID) || [];
    if (s.length === 0) {
      s.push(new BookingSection(this, sectionID));
      if (!this.sections) this.sections = [];
      this.sections.push(s[0]);
    }

    return s[0];
  }
  addSectionCruise(item: ReservationCruise) {
    if (!item.sectionID) return;

    const s = this.getSection(item.sectionID);
    s.addVoyage(item);
  }
  addSectionSupplement(item: BookingSupplement) {
    if (!item.sectionID) return;

    const s = this.getSection(item.sectionID);
    s.addSupplement(item);
  }
  addSectionGuest(item: BookingGuest) {
    if (!item.sectionID) return;

    const s = this.getSection(item.sectionID);
    s.addGuest(item);
  }
  addSectionCustomPackages(item: BookingCustomPackages) {
    if (!item.sectionID) return;

    const s = this.getSection(item.sectionID);
    s.addCustomPackage(item);
  }

  override updateData(data: IReservationApiBooking): void {

    //this.addMangledProperty(data);
    this.addProperty('BkgAgentOwner', data, 'Bkg_Agent_Owner')
    this.addBooleanProperty('canExtendVoyage', data, 'ExtendVoyage');
    this.addBooleanProperty('GMTSearch',data,'GMTSearch')

    //redefine property DateTime becouse automatic property not is set for optional property
    this.addDateTimeProperty('travelStartDate', data, 'TravelStartDate');
    this.addDateTimeProperty('travelEndDate', data, 'TravelEndDate');
    this.addDateTimeProperty('creationDate', data, 'CreationDate');
    this.addDateTimeProperty('depositDate', data, 'DepositDate');
    this.addDateTimeProperty('confirmationDate', data, 'ConfirmationDate');
    this.addDateTimeProperty('cancelDate', data, 'CancelDate');
    this.addDateTimeProperty('updated', data, 'Updated');

    if ((this.bkgName ?? '').trim() === '')
      this.bkgName = `BkgId ${this.bkgID}`;

    //child
    this.owner = new BookingOwner(data.Owner);
    if (data.FinacialRecap) {
      this.financialRecap = data.FinacialRecap?.map(
        (f) => new ResBookingFinancialRecap(f)
      );
    }

    //childs
    this.sections = [];
    this.deposit = [];
    this.penalties = [];
    this.priceJourney = [];
    this.journeySupplements = [];

    const allSupplments: Array<BookingSupplement> = [];
    const allVoyages: Array<ReservationCruise> = [];
    const allCustomPackages: Array<BookingCustomPackages> = [];
    const allJourneySupplements: Array<BookingJourneySupplement> = [];

    //dispatch supplements on section and collet all in array for post elaboration
    if (data.Supplements) {
      data.Supplements.sort((s) => s.SortSequence ?? 0).forEach((item) => {
        const suplement = new BookingSupplement(item);
        allSupplments.push(suplement);
        this.addSectionSupplement(suplement);
      });
    }

    //dispatch voyages on section and collet all in array for post elaboration
    if (data.Voyages) {
      data.Voyages.forEach((item) => {
        const voy = new ReservationCruise(item);

        //add journeyPrice
        if (data.PriceJourney) {
          data.PriceJourney.filter(
            (pg) =>
              pg.SectionID === voy.sectionID && pg.VoyageID === voy.voyageID
          ).forEach((pg) => {
            voy.bkgPriceJourney = new BookingPriceJourney(pg);
          });
        }

        allVoyages.push(voy);
        this.addSectionCruise(voy);
      });
    }

    //dispatch custom package on section and collet all in array for post elaboration
    if (data.CustomPackages) {
      data.CustomPackages.forEach((item) => {
        const customPkg = new BookingCustomPackages(item);
        allCustomPackages.push(customPkg);
        this.addSectionCustomPackages(customPkg);
      });
    }

    //dispatch journay supplement on section and collet all in array for post elaboration
    if (data.JourneySupplements) {
      data.JourneySupplements.forEach((item) => {
        const js = new BookingJourneySupplement(item);
        allJourneySupplements.push(js);
        this.addSectionJourneySupplement(js);
      });
    }
    //function to collet supplement by guest and organize it in timeline
    //variant: totally context or Package context
    const rooms:{key:number,roomID:number}[] = []
    const prepareGuestTimeline = function (
      item: IReservationApiBookingGuest,
      packageID?: number,
      packageTravelDateID?: number
    ): BookingGuest {
      const guest = new BookingGuest(item);
      guest.roomID = rooms.find(r=>r.key===guest.keySuiteID)?.roomID ?? 1
      guest.packageID = packageID;
      guest.packageTravelDateID = packageTravelDateID;
      guest.guestTimeLine = [];

      //add all context supplements
      allSupplments
        .filter((s) => s.sectionID === item.SectionID)
        .filter((s) => s.guestCod === guest.guestCod)
        .filter(
          (s) =>
            (packageID ?? 0) === 0 ||
            ((s.packageID ?? 0) === (packageID ?? 0) &&
              (s.packageTravelDateID ?? 0) === (packageTravelDateID ?? 0))
        )
        .filter((s) => s.itineraryDate?.isValid)
        .sort(
          (a, b) => a.itineraryDate!.toMillis() - b.itineraryDate!.toMillis()
        )
        .forEach(function (s) {
          const pt = guest.guestTimeLine.filter((pt) =>
            pt.date.equals(s.itineraryDate!)
          );

          if (pt.length === 0) {
            pt.push(new BookingTimeLine(item.SectionID, s.itineraryDate!));
            guest.guestTimeLine.push(pt[0]);
          }
          pt[0].addSupplement(s);
        });

      //insert voyage on guest
      //insert voyage at right timeline position
      allVoyages
        .filter((v) => (v.sectionID ?? 0) === (item.SectionID ?? 0))
        .filter(
          (v) =>
            (packageID ?? 0) === 0 ||
            ((v.packageID ?? 0) === (packageID ?? 0) &&
              (v.packageTravelDateID ?? 0) === (packageTravelDateID ?? 0))
        )
        .filter(
          (v) => v.keySuiteID === guest.keySuiteID
              || v.keySuiteID === 0 || guest.keySuiteID === 0
        )
        .forEach((v) => {
          if(v.keySuiteID === guest.keySuiteID) v.roomID = guest.roomID
          const pt = guest.guestTimeLine.filter((pt) => pt.date.equals(v.embarkDate!));

          if (pt.length === 0) {
            pt.push(new BookingTimeLine(item.SectionID, v.embarkDate!));
            guest.guestTimeLine.push(pt[0]);
          }
          pt[0].voyage = v;

        });

      //insert voyage at right timeline position
      allCustomPackages
        .filter((v) => (v.sectionID ?? 0) === (item.SectionID ?? 0))
        .filter(
          (cp) =>
            (packageID ?? 0) === 0 ||
            ((cp.packageID ?? 0) === (packageID ?? 0) &&
              (cp.packageTravelDateID ?? 0) === (packageTravelDateID ?? 0))
        )
        .filter((cp) => cp.guestCod === +guest.guestCod)
        .filter((cp) => item.KeySuiteID < 1 || cp.keySuiteID < 1 || cp.keySuiteID===item.KeySuiteID)
        .forEach((cp) => {
          cp.details!.forEach((crd) => {
            if(crd.keySuiteID === guest.keySuiteID) crd.roomID = guest.roomID
            const pt = guest.guestTimeLine.filter((pt) => pt.date.equals(crd.date!));

            if (pt.length === 0) {
              pt.push(new BookingTimeLine(item.SectionID, crd.date!));
              guest.guestTimeLine.push(pt[0]);
            }

            pt[0].customRequestDetails.push(crd);
          });
        });

      guest.guestOwnArrangments
        .filter((oa) => oa.guestCod === +guest.guestCod)
        .forEach((oa) => {
          const pt = guest.guestTimeLine.filter(
            (pt) => !!oa.itineraryDate && pt.date.equals(oa.itineraryDate)
          );

          if (pt.length === 0) {
            pt.push(new BookingTimeLine(item.SectionID, oa.itineraryDate!));
            guest.guestTimeLine.push(pt[0]);
          }

          pt[0].ownArrangments.push(oa);
        });

      //sort for date
      guest.guestTimeLine = guest.guestTimeLine.sort((a, b) =>
        a.date && b.date
          ? a.date.toMillis() - b.date.toMillis()
          : a.date
          ? -1
          : 1
      );

      return guest;
    };

    //dispatch pax on section with yours timeline
    if (data.Pax) {
      data.Pax.forEach((item) => {
        if(!rooms.find(r=>r.key===item.KeySuiteID)) rooms.push({key:item.KeySuiteID,roomID:rooms.length+1})
        this.addSectionGuest(prepareGuestTimeline(item))
      });
    }

    //dispatch pax on section with yours pax and yours timeline
    if (data.Packages) {
      data.Packages.forEach((pkg) => {
        if (data.Pax) {
          this.addSectionPackage(pkg);

          data.Pax.filter((item) => item.SectionID === pkg.SectionID)
            .filter(
              (item, i, array) =>
                array.findIndex((x) => x.GuestID === item.GuestID) === i
            )
            .forEach((item) => {
              this.addSectionGuest(
                prepareGuestTimeline(
                  item,
                  pkg.PackageID,
                  pkg.PackageTravelDateID
                )
              );
            });
        }
      });
    }

    //dispatch custom request (special pax) on section
    this.sections.forEach((s) => {
      s.bkgPackages.forEach((pkg) => {
        if (pkg.travelStartDate) {
          const interval = Interval.fromDateTimes(
            pkg.travelStartDate,
            pkg.travelEndDate!.plus({ days: 1 })
          );
          s.allSessionGuests()
            .filter((g) => g.guestID && pkg.hasGuest(g.guestID))
            .forEach((g) => {
              const x = g.guestTimeLine.filter((t) =>
                t.date.equals(pkg.travelStartDate!)
              );
              if (x.length > 0) x[0].package = pkg;
              g.guestTimeLine
                .filter(
                  (t) =>
                    interval.contains(t.date) ||
                    t.voyage.packageID === pkg.packageID
                )
                .forEach((t) => {
                  t.package.packageShortName = pkg.packageShortName;
                  t.packageBadgeName = pkg.packageTypeName;
                });
            });
        }
      });
      s.initalizeSpecialPackage();

      const voyages = s.allSessionVoyages();
      if (voyages.length > 1) {
        voyages[0].isDeletable = true;
        voyages[voyages.length - 1].isDeletable = true;
      }

      voyages.forEach((v, idx) => {
        if (v.sellingPrice) {
          //calculate sellingprice
          const priceDiff = allSupplments
            .filter(
              (sup) =>
                sup.sectionID === s.sectionID &&
                (sup.voyageID === v.voyageID ||
                  (idx === 0 && sup.voyageID === null))
            )
            .map((sup) => sup.sellingPrice)
            .reduce((p, c) => p + c, 0);

          v.sellingPrice += priceDiff;
        }
        //add voyage supplements
        s.bkgAllSupplements.supplements.filter((s) => s.voyageID === v.voyageID).forEach((s) => v.supplements?.push(s));

        if(v.packageID>0) {
          const p = s.bkgPackages.find(p=>p.packageID===v.packageID)
          if(p) p.addSuite(v)
        }
      });
    });

    //load deposit data
    if (data.Deposit) {
      data.Deposit.forEach((item) =>
        this.deposit.push(new BookingDeposit(item))
      );
    }

    //load penalries data
    if (data.Penalties) {
      data.Penalties.forEach((item) =>
        this.penalties.push(new BookingPenalties(item))
      );
    }

    //load price guest data
    if (data.PriceJourney) {
      data.PriceJourney.forEach((item) =>
        this.priceJourney.push(new BookingPriceJourney(item))
      );
    }
    //load price journey data
    if (data.JourneySupplements) {
      data.JourneySupplements.forEach((item) =>
        this.journeySupplements.push(new BookingJourneySupplement(item))
      );
    }

    //create diabledOptiondate array
    const dod = new Set<string>()
    this.getAllVoyages().forEach(v=>{
      if(!v.embarkDate || !v.debarkDate) return
      Interval.fromDateTimes(v.embarkDate,v.debarkDate).splitBy({day:1}).forEach(dt=>{
        dod.add(dt.start.toFormat('yyyyLLdd'))
      })
    })
    this.disabledOptionsDates = Array.from(dod)
    if (data.Links) {
      this.prevBkg = data.Links.find(
        (l) => l.IsSameVyg === 'N' && l.BkgID_To === this.bkgID
      )?.BkgID_From;
      this.links = data.Links.map((l) => ({
        LinkedBkgID: l.LinkedBkgID,
        BkgID: l.BkgID_From,
        BkgIDLinked: l.BkgID_To,
        BkgStatus: l.BkgStatus,
        VoyageNumber: l.VoyageNumber,
        StartDate: l.StartDate,
        IsTravelWith: l.IsTravelWith,
        Status: 'A',
        IsSameVyg: l.IsSameVyg,
      }));
      this.nextBkg = data.Links.find(
        (l) => l.IsSameVyg === 'N' && l.BkgID_From === this.bkgID
      )?.BkgID_To;
    }

    this.embarkDebarkInfo = new ResEmbarkDebarkInfo(data?.EmbarkDebarkInfo);
    this.embarkDebarkDetails = data?.EmbarkDebarkDetails?.map((d) => new ResEmbarkDebarkDetails(d)) ?? [];
    this.roomsConfig = data?.RoomsConfig?.map((d) => new ResRoomConfig(d)) ?? [];

  }

  reset() {
    this.bkgID = 0;

    this.owner = new BookingOwner();
    this.financialRecap = [];

    this.travelStartDate = undefined;
    this.travelEndDate = undefined;
    this.creationDate = undefined;
    this.createdBy = '';
    this.updatedBy = '';
    this.updated = undefined;
    this.depositDate = undefined;
    this.confirmationDate = undefined;
    this.cancelDate = undefined;

    this.penalties = [];
    this.deposit = [];

    this.sections = [];
  }
}

export class BookingSection {
  private bkg: ResBooking;
  public bkgID: number;
  public sectionID: number;
  public bkgPackages: Array<BookingPackage>;
  public bkgNoPackage: BookingPackage;
  public bkgAllSupplements: BookingPackage;
  public bkgPaxSpecialRequest: Array<BookingSpecialRequest>;
  public bkgAllUncategorized: Array<BookingSupplement>;
  private bkgAllUncategorizedLoaded: boolean;
  public bkgAllUncategorizedGrouped: Array<BookingSupplement>;
  public journeySupplements: Array<BookingJourneySupplement>;
  private bkgAllUncategorizedLoadedGrouped: boolean;
  public costAnalysisActive: boolean;
  public displayGuest: boolean;
  public hideGuest: boolean;
  public guestNum: number;
  public multiSuite:boolean
  isReferral: boolean;
  constructor(bkg: ResBooking, id: number) {
    this.bkg = bkg;
    this.bkgID = bkg.bkgID;
    this.multiSuite = bkg.multiSuite
    this.isReferral = bkg.isReferral;
    this.displayGuest = bkg.owner.displayGuest ?? true;
    this.hideGuest = bkg.hideGuest ?? false;
    this.sectionID = id;
    this.bkgPackages = [];
    this.bkgNoPackage = new BookingPackage(this.bkgID, 0, 0);
    this.bkgAllSupplements = new BookingPackage(this.bkgID, 0, 0);
    this.bkgPaxSpecialRequest = [];
    this.bkgAllUncategorized = [];
    this.bkgAllUncategorizedLoaded = false;
    this.bkgAllUncategorizedGrouped = [];
    this.journeySupplements = [];
    this.bkgAllUncategorizedLoadedGrouped = false;
    this.costAnalysisActive = false;
    this.guestNum =
      bkg.financialRecap.find((f) => f.sectionID === id)?.guestNum ?? 0;
  }
  get layoutWithTabs() {
    return this.bkg.layoutWithTabs
  }
  get defaultTab() {
    return this.bkg.defaultTab
  }
  hasGmtAirFacility() {
    return this.bkg.GMTSearch;
  }
  hasRightRezAirFacility() {
    return this.bkg.rightRezSearch;
  }
  isQuote(): boolean {
    return this.bkg.isQuote();
  }
  isCommissionButtonVisible(): boolean {
    return this.bkg.displayCommissionButton;
  }
  isDayByDayButtonVisible(): boolean {
    return this.bkg.dayByDayItineraryDisplay;
  }
  isEmpty(): boolean {
    return this.bkg.isEmpty();
  }
  isMultySection(): boolean {
    return this.bkg.isMultySection();
  }
  // isReadOnly() {
  //   return this.bkg.isReadOnly();
  // }
  isDeleted() {
    return this.bkg.isDeleted();
  }
  isOption() {
    return this.bkg.isOption();
  }
  isBooking() {
    return this.bkg.isBooked();
  }

  public getOwner(): BookingOwner {
    return this.bkg.owner;
  }

  public getBkgCurrency(): string {
    return this.bkg.bkgCurrency;
  }

  public getJourneySupplements() {
    if (this.costAnalysisActive) return this.journeySupplements;
    return this.journeySupplements.filter((js) => !js.hideInBkg);
  }
  public allSessionGuests(): Array<BookingGuest> {
    return this.bkgAllSupplements.guests;
  }
  public get hasPNRs(): boolean {
    return this.allSessionGuests().some((g) => g.hasPNR);
  }
  public allSessionVoyages(): Array<ReservationCruise> {
    return this.bkgAllSupplements.voyages;
  }
  allSessionCustomPackage(): Array<BookingCustomPackages> {
    return this.bkgAllSupplements.customPackages;
  }
  public getAllOrderPackagesOrVoyages(): Array<
    ReservationCruise | BookingPackage
  > {
    const allOrdered: Array<ReservationCruise | BookingPackage> = [];
    this.bkgPackages.forEach((pkg) => allOrdered.push(pkg));
    this.bkgNoPackage.voyages.forEach((v) => allOrdered.push(v));
    return allOrdered.sort((a, b) => {
      const dta =
        a instanceof ReservationCruise
          ? (a as ReservationCruise).embarkDate!
          : (a as BookingPackage).travelStartDate!;
      const dtb =
        b instanceof ReservationCruise
          ? (b as ReservationCruise).embarkDate!
          : (b as BookingPackage).travelStartDate!;
      return dta?.toMillis() - dtb?.toMillis();
    });
  }
  // costOfVoyage() {
  //   let cv=0
  //   //this.getJourneySupplements().filter(s=>s.optionName==='Cruise Charge').forEach(s=>cv+=s.sellingPrice)
  //   return cv;
  // }
  public getAllUncategorizedSupplements(): Array<BookingSupplement> {
    if (this.bkgAllUncategorizedLoaded) return this.bkgAllUncategorized;
    this.bkgNoPackage.supplements
      .filter((s) => s.voyageID == null)
      .forEach((v) => this.bkgAllUncategorized.push(v));
    this.bkgAllUncategorizedLoaded = true;
    return this.bkgAllUncategorized.sort((a, b) => {
      if (!a.itineraryDate) return -1;
      if (!b.itineraryDate) return 1;
      return a.itineraryDate!.toMillis() - b.itineraryDate!.toMillis();
    });
  }
  public getAllUncategorizedSupplementsGrouped(): Array<BookingSupplement> {
    if (this.bkgAllUncategorizedLoadedGrouped)
      return this.bkgAllUncategorizedGrouped;
    this.bkgNoPackage.supplements
      .filter((s) => s.voyageID == null)
      .forEach((s) => {
        var grouped = this.bkgAllUncategorizedGrouped.filter(
          (sg) =>
            sg.serviceType === s.serviceType &&
            Math.abs(sg.optionID) === Math.abs(s.optionID)
        );
        if (grouped.length === 0) {
          grouped.push(s.clone());
          grouped[0].isGrouped = true;
          grouped[0].groupedAmount = 0;
          grouped[0].groupedCost = 0;
          grouped[0].groupedCostTax = 0;
          grouped[0].groupedQuantity = 0;
          this.bkgAllUncategorizedGrouped.push(grouped[0] as BookingSupplement);
        }
        grouped[0].groupedAmount += s.sellingPrice;
        grouped[0].groupedCost += s.cost * s.ROE;
        grouped[0].groupedCostTax += s.costTax * s.ROE;
        if (s.optionID > 0) grouped[0].groupedQuantity += 1; //skip discount
        grouped[0].included = grouped[0].included && s.included;
        if (grouped[0].optionID < 1) {
          grouped[0].optionDesc = s.optionDesc;
          grouped[0].optionName = s.optionName;
          grouped[0].optionShortName = s.optionShortName;
        }
      });
    this.bkgAllUncategorizedGrouped.sort(
      (a, b) => a.sortSequence - b.sortSequence
    );
    this.bkgAllUncategorizedLoadedGrouped = true;
    return this.bkgAllUncategorizedGrouped;
  }
  initalizeSpecialPackage(): void {
    this.bkgPaxSpecialRequest = this.getAllSpecialRequest();
  }
  private getAllSpecialRequest(): Array<BookingSpecialRequest> {
    const cs = new Array<BookingSpecialRequest>();
    this.allSessionGuests().forEach((g) =>
      cs.push(
        new BookingSpecialRequest(
          g,
          this.allSessionCustomPackage().filter(
            (cp) => cp.guestCod === +(g.guestCod ?? '0')
          )
        )
      )
    );
    return cs;
  }
  public addPackage(bkgPackage: BookingPackage) {
    this.bkgPackages.push(bkgPackage);
  }
  public addVoyage(voy: ReservationCruise) {
    if (voy.packageID ?? 0 > 0) {
      const p = this.bkgPackages.filter(
        (p) =>
          p.packageID === voy.packageID &&
          p.packageTravelDateID === voy.packageTravelDateID
      );
      if (p.length === 0) {
        const bkgPackage = new BookingPackage(this.bkgID, voy.packageID!, voy.packageTravelDateID);
        bkgPackage.roomID = voy.roomID
        this.addPackage(bkgPackage);
        p.push(bkgPackage);
      }
      p[0].voyages.push(voy);
    } else {
      this.bkgNoPackage.voyages.push(voy);
    }

    this.bkgAllSupplements.voyages.push(voy);
  }
  public addGuest(guest: BookingGuest) {
    if (guest.packageID ?? 0 > 0) {
      const p = this.bkgPackages.filter(
        (p) =>
          p.packageID === guest.packageID &&
          p.packageTravelDateID === guest.packageTravelDateID
      );
      if (p.length === 0) {
        const bkgPackage = new BookingPackage(
          this.bkgID,
          guest.packageID!,
          guest.packageTravelDateID ?? 0
        );
        this.addPackage(bkgPackage);
        p.push(bkgPackage);
      }
      p[0].guests.push(guest);
    } else {
      this.bkgNoPackage.guests.push(guest);
    }

    const g = this.bkgAllSupplements.guests.filter(
      (g) => g.guestID === guest.guestID
    );
    if (g.length === 0) this.bkgAllSupplements.guests.push(guest);
    else
      g[0].voyagePrices.push(
        new BookingGuestVoyagePrice(
          guest.voyageID,
          guest.sellingPrice,
          guest.fixedPrice,
          guest.upSelling,
          guest.upSellingValue,
          guest.originalSuiteCategoryID
        )
      );
  }
  public addSupplement(supplement: BookingSupplement) {
    if (supplement.packageID ?? 0 > 0) {
      const p = this.bkgPackages.filter(
        (p) =>
          p.packageID === supplement.packageID &&
          p.packageTravelDateID === supplement.packageTravelDateID
      );
      if (p.length === 0) {
        const bkgPackage = new BookingPackage(
          this.bkgID,
          supplement.packageID!,
          supplement.packageTravelDateID ?? 0
        );
        this.addPackage(bkgPackage);
        if(supplement.voyageID>0) {
          this.allSessionVoyages().filter(v=>v.voyageID===supplement.voyageID).forEach(v=>{
            bkgPackage.voyages.push(v)
          })
        }
        p.push(bkgPackage);
      }
      p[0].supplements.push(supplement);
    } else {
      this.bkgNoPackage.supplements.push(supplement);
    }

    this.bkgAllSupplements.supplements.push(supplement);
  }

  public addCustomPackage(customPackage: BookingCustomPackages) {
    this.bkgNoPackage.customPackages.push(customPackage);
    this.bkgAllSupplements.customPackages.push(customPackage);
  }

  public get sailDate() {
    if (!this.allSessionVoyages().length) return;
    return this.bkgAllSupplements.voyages[0].sailDate;
  }

  public get sailDateTime() {
    if (!this.allSessionVoyages().length) return;
    return this.bkgAllSupplements.voyages[0].sailDateTime;
  }

  public get startDate() {
    if (!this.allSessionVoyages().length) return this.bkg.travelStartDate;
    return this.bkgAllSupplements.voyages[0].embarkDate;
  }
  public get endDate() {
    if (!this.allSessionVoyages().length) return this.bkg.travelEndDate;
    return this.bkgAllSupplements.voyages[
      this.bkgAllSupplements.voyages.length - 1
    ].debarkDate;
  }

  public get disabledOptionsDates() {
    return this.bkg.disabledOptionsDates;
  }
  public get travelStartDate() {
    return this.bkg.travelStartDate;
  }
  public get travelEndDate() {
    return this.bkg.travelEndDate;
  }

  public get embarkDate() {
    if (!this.allSessionVoyages().length) return;
    return this.bkgAllSupplements.voyages[0].embarkDate;
  }

  public get embarkFromDateTime() {
    if (!this.allSessionVoyages().length) return;
    return this.bkgAllSupplements.voyages[0].embarkFromDateTime;
  }

  public get embarkToDateTime() {
    if (!this.allSessionVoyages().length) return;
    return this.bkgAllSupplements.voyages[0].embarkToDateTime;
  }

  public get embarkPort() {
    if (!this.allSessionVoyages().length) return;
    let voyage = this.bkgAllSupplements.voyages[0];
    return { cityID: voyage.embarkPortID, city: voyage.embarkPort };
  }

  public get embarkAirportIataCod() {
    if (!this.allSessionVoyages().length) return '';
    return this.bkgAllSupplements.voyages.at(0)?.embarkIataCod ?? '';
  }

  public get debarkDate() {
    if (!this.allSessionVoyages().length) return;
    return this.bkgAllSupplements.voyages[
      this.bkgAllSupplements.voyages.length - 1
    ].debarkDate;
  }

  public get debarkDateTime() {
    if (!this.allSessionVoyages().length) return;
    return this.bkgAllSupplements.voyages[
      this.bkgAllSupplements.voyages.length - 1
    ].debarkDateTime;
  }

  public get debarkPort() {
    if (!this.allSessionVoyages().length) return;
    let voyage =
      this.bkgAllSupplements.voyages[this.bkgAllSupplements.voyages.length - 1];
    return { cityID: voyage.debarkPortID, city: voyage.debarkPort };
  }

  public get debarkAirportIataCod() {
    if (!this.allSessionVoyages().length) return '';
    return this.bkgAllSupplements.voyages.at(-1)?.debarkIataCod ?? '';
  }

  public get isOverNight() {
    if (!this.allSessionVoyages().length) return false;
    return this.bkgAllSupplements.voyages[0].isOverNight;
  }

  public get priceTypeID(): number | undefined {
    return this.bkg.priceTypeID;
  }

  public get bkgCurrency(): string {
    return this.bkg.bkgCurrency;
  }
}

export class BookingPackage extends ReservationBasePackage {
  public bkgID: number = 0;
  public sectionID: number = 0;
  public travelStartDate?: DateTime;
  public travelEndDate?: DateTime;
  public brandID: number = 0;
  public childReduction: number = 0;
  public sellingPrice: number = 0;
  public adult: number = 0;
  public child: number = 0;
  public bkgPriceJourney?: BookingPriceJourney;
  public priceSetUp: string = '';
  public cityFrom: string = '';
  public cityTo: string = '';

  public voyages: Array<ReservationCruise>;
  suites:ReservationSuite[]
  public guests: Array<BookingGuest>;
  public supplements: Array<BookingSupplement>;
  public customPackages: Array<BookingCustomPackages>;
  public itineraries: Array<ReservationCruiseItinerary>;

  public internalType = 'package';

  private reservationApiService: ReservationApiService;

  constructor(
    bkgId: number,
    id: number,
    travelDateId: number,
    data?: IReservationApiBookingPackage
  ) {
    super();
    this.reservationApiService = AppModule.injector.get(ReservationApiService);
    if (data) this.updateData(data);

    this.packageID = id;
    this.packageTravelDateID = travelDateId;
    this.bkgID = bkgId;
    this.voyages = [];
    this.suites = []
    this.guests = [];
    this.supplements = [];
    this.customPackages = [];
    this.itineraries = [];
  }
  override updateData(data: IReservationApiBookingPackage): void {
    if (data) {
      this.addMangledProperty(data);
      this.addDateTimeProperty('travelStartDate', data, 'TravelStartDate');
      this.addDateTimeProperty('travelEndDate', data, 'TravelEndDate');
    }
  }
  addSuite(cruise:ReservationCruise) {
    this.suites.push(new ReservationSuite(cruise))
  }
  isValid(): boolean {
    if (this.bkgID == 0) return false;
    if (this.packageID == 0) return false;
    if (this.packageTravelDateID == 0) return false;
    return true;
  }
  isVoyageOfLec() {
    return this.voyages.length > 0;
  }
  hasGuest(guestID: number): boolean {
    const g = this.guests.filter((g) => g.guestID === guestID);
    return g.length > 0;
  }
  isGrandVoyage(grandVoyagePriceType: number | undefined): boolean {
    return (
      !!this.voyages?.length &&
      this.voyages.every((v) => v.belongsToGrandVoyage(grandVoyagePriceType))
    );
  }
  displayName(): string {
    return (
      this.packageName ?? `${this.packageID}-${this.packageTravelDateID}` ?? ''
    );
  }
  displayBrand(): string {
    return `${this.brandID}` ?? '';
  }
  getStartDate(): DateTime | undefined {
    return this.travelStartDate;
  }
  getEndDate(): DateTime | undefined {
    return this.travelEndDate;
  }
  loadEcmData(): Promise<ReservationPackageEcmData> {
    if (this.ecmData.loaded) return Promise.resolve(this.ecmData);

    return lastValueFrom(
      this.reservationApiService
        .getPackageInfo({
          PackageID: this.packageID,
          PackageTravelDateID: this.packageTravelDateID,
        })
        .pipe(
          map<IReservationApiPackageInfo, ReservationPackageEcmData>((data) => {
            this.ecmData = ReservationPackageEcmData.mapEcmData(data.EcmData);
            if (data.EcmData)
              this.ecmData = ReservationPackageEcmData.mapEcmData(data.EcmData);
            if (data.CalendarDate)
              this.calendarDate = data.CalendarDate.map(
                (c) => new ReservationPackageTravelDateDetail(c, 0)
              );
            //@TODO remove form calendarDates these just booked
            if (data.Itineraries)
              this.itinerariesSimple = data.Itineraries.map(
                (i) => new ReservationProductItineraryBaseData(i)
              );

            if (data.ServiceOptions)
              this.serviceOptions = data.ServiceOptions.map(
                (i) => new ReservationProductServiceOptions(i)
              );
            if (data.ServiceImages)
              this.serviceImages = data.ServiceImages.map(
                (i) => new ReservationProductServiceImages(i)
              );
            if (data.SuggestedItems)
              this.suggestedItems = data.SuggestedItems.map(
                (i) => new ReservationProductSuggestedItems(i)
              );

            if (data.Categories)
              this.categories =
                data.Categories?.map((b) => {
                  return {
                    categoryCod: b.CategoryCod,
                    category: b.Category,
                  };
                }) ?? [];
            if (data.Companies)
              this.companies =
                data.Companies?.map((b) => {
                  return {
                    companyID: b.CompanyID,
                    companyName: b.CompanyName,
                  };
                }) ?? [];

            this.serviceOptions.forEach(
              (so) =>
                (so.images =
                  this.serviceImages.find(
                    (si) => si.serviceID === so.serviceID
                  ) ?? new ReservationProductServiceImages())
            );
            this.itinerariesSimple.forEach(
              (is) =>
                (is.serviceOptions = this.serviceOptions.filter(
                  (so) => so.day === is.day
                ))
            );
            const reservationService =
              AppModule.injector.get(ReservationService);
            reservationService.bookingUtility.ecmDataChanged$.next({});
            return this.ecmData;
          })
        )
    );
  }
  getItineraryServiceOptions(
    itinerary: ReservationProductItineraryBaseData
  ): Array<ReservationProductServiceOptions> {
    return this.serviceOptions.filter((so) => so.day === itinerary.day);
  }
}

export class ResTailorMadeParameters extends SafeBaseObject {
  serviceID = 0
  optionID = 0
  priceTypeID = 0
  companyID = 0

  constructor(data: any) {
    super();
    if (data) {
      super.updateData(data)
      this.updateData(data);
    }
  }
}
export class ResBookingCancelReason extends SafeBaseObject {
  cancelReasonID = 0;
  cancelReason = '';
  hasNote = false;
  constructor(data: IResApiBookingCancelReason) {
    super();
    if (data) super.updateData(data);
  }
}
export interface IResBookingCancelReasonResponse {
  cancelReason: string;
  cancelReasonID: number;
  note?: string;
}

export interface IEmbarkDebarkInfo {
  BkgID: number;
  DebarkCity: string;
  DebarkDate: Date;
  EmbarkCity: string;
  EmbarkDate: Date;
}

export interface IEmbarkDebarkDetails {
  BkgID: number;
  DebarkCity: string;
  DebarkDate: DateTime;
  EmbarkCity: string;
  EmbarkDate: DateTime;
  SuiteCategory: string;
  SuiteNo: string;
  VoyageNumber: string;
}
export interface IApiRoomConfig {
  BkgID:number
  RoomConfHdrID:number
  GuestID:number
}
class BookingTimeLine {
  static packagesKeyCallections: Array<string> = [];
  public date: DateTime;
  public sectionID: number;
  public supplements: Array<BookingSupplement>;
  public voyage: ReservationCruise;
  public package: BookingPackage;

  public customRequestDetails: Array<ReservationCustomRequestDetail>;
  ownArrangments: Array<ReservationBookingGuestOwnArrangement>;
  public packageBadgeNumber: number = 0;
  public packageBadgeName: string = '';

  constructor(sectionID: number, date: DateTime) {
    this.sectionID = sectionID;
    this.date = date;
    this.supplements = [];
    this.customRequestDetails = [];
    this.ownArrangments = [];
    this.voyage = new ReservationCruise({} as IReservationApiCruise);
    this.package = new BookingPackage(0, 0, 0);
  }

  addSupplement(supplement: BookingSupplement) {
    this.supplements.push(supplement);
  }
  hasVoyage(): boolean {
    return this.voyage.isValid();
  }
  hasPackage(): boolean {
    return this.package.isValid();
  }
  getGroupBadgeClass(supplement?: BookingSupplement): object {
    const b: any = {};
    if (!this.package.isValid()) return b;
    const key = `${this.package.packageID}-${this.package.packageTravelDateID}`;
    const idx = BookingTimeLine.packagesKeyCallections.findIndex(
      (s) => s === key
    );
    this.packageBadgeNumber =
      (idx === -1 ? BookingTimeLine.packagesKeyCallections.push(key) : idx) + 1;
    this.packageBadgeName = this.package.packageTypeName;

    b['er-guest-timeline-package-group-' + this.packageBadgeNumber] =
      !supplement ||
      (supplement.packageID === this.package.packageID &&
        supplement.packageTravelDateID === this.package.packageTravelDateID);
    return b;
  }
  getSupplementsPre(costAnalisys: boolean) {
    return this.supplements
      .filter((s) => s.prePost === 'PRE' && (costAnalisys || !s.hideInBkg))
      .sort((a, b) => a.sortSequence - b.sortSequence);
  }
  getSupplementsPost(costAnalisys: boolean) {
    return this.supplements
      .filter((s) => s.prePost !== 'PRE' && (costAnalisys || !s.hideInBkg))
      .sort((a, b) => a.sortSequence - b.sortSequence);
  }
  getOwnArrangmentsPre() {
    return this.ownArrangments.filter((s) => s.isPre);
  }
  getOwnArrangmentsPost() {
    return this.ownArrangments.filter((s) => s.isPost);
  }
  isStartType(): boolean {
    return this.voyage.isValid() || this.package.isValid();
    /* voyage and package is present only at the start day
       check for date is redundant
    */
    // return (this.voyage.isValid() && this.date.equals(this.voyage.embarkDate!))
    //        ||
    //        (this.package.isValid() && this.date.equals(this.package.travelStartDate!));
  }
  hasType(): boolean {
    return this.isCruise() || this.isPackage();
  }
  getType(): string {
    if (this.isStartType()) {
      if (this.isPackage()) return 'package';
      if (this.isCruise()) return 'cruise';
    }

    return '';
  }
  isCruise(): boolean {
    const v = this.supplements.filter((s) => s.isVoyage());
    return v.length > 0;
  }
  isPackage(): boolean {
    const p = this.supplements.filter((s) => s.isPackage());
    return p.length > 0;
  }
}
export enum BookingSupplementContextType {
  Nothing = '',
  Package = 'pkg',
  Voyage = 'voy',
}
export class BookingSupplement extends SafeBaseObject {
  public bkgID: number = 0;
  public sectionID: number = 0;
  public itemID: number = 0;
  public packageID: number = 0;
  public packageTravelDateID: number = 0;
  public voyageID: number = 0;
  public optionID: number = 0;
  public minPax: number = 0;
  public maxPax: number = 0;
  public optionName: string = '';
  public optionShortName: string = '';
  public optionDesc: string = '';
  public itineraryDate?: DateTime;
  public serviceID: number = 0;
  public serviceType: string = '';
  public sellingPrice: number = 0;
  public sellingPriceCurrency: string = '';
  public message: string = '';
  public remarkAir: string = '';
  public cost: number = 0;
  public costTax: number = 0;
  public costCurrency: string = '';
  public commission: number = 0;
  public commissionable: boolean = false;
  public quantityPP: number = 0;
  public canEdit: boolean = false;
  public canDelete: boolean = false;
  public flightDetails: string = '';
  public prePost: string = '';
  public sortSequence: number = 0;
  public guestCod: string = '';
  public promoID: number = 0;
  public promo: ResevationSupplementPromo = new ResevationSupplementPromo();
  public note: string = '';
  public ROE: number = 0;
  public included: boolean = false;
  public isSBC: boolean = false;
  public selected: boolean | undefined | null = false;
  public enableSelection: boolean = false;
  public isCertificateItem: boolean = false;
  public isPackageUpgrade: boolean = false;

  public isGrouped: boolean = false;
  public groupedQuantity: number = 0;
  public groupedAmount: number = 0;
  public groupedCost: number = 0;
  public groupedCostTax: number = 0;
  filePath = ''

  canEditSupplier: boolean = false;
  forcedSupplierID?: number;
  supplierName?: string;
  serviceTypeName?: string;
  hideInBkg = false
  onRequest = false

  country = ''
  city = ''
  supplier = ''
  packageUpgradeAvailable = false

  constructor(data: any) {
    super();
    if (data) this.updateData(data);
  }
  public clone(): BookingSupplement {
    const s = new BookingSupplement(this);
    s.itineraryDate = this.itineraryDate;
    s.isSBC = this.isSBC;
    s.included = this.included;
    s.canEditSupplier = this.canEditSupplier;
    //Promo?
    return s;
  }
  override updateData(data: IReservationApiBookingSupplement): void {
    this.addMangledProperty(data);
    this.addProperty('ROE', data, 'ROE');
    this.addDateTimeProperty('itineraryDate', data, 'ItineraryDate');
    this.addBooleanProperty('isSBC', data, 'IsSBC');
    this.addBooleanProperty('included', data, 'Included');
    this.addBooleanProperty('canEditSupplier', data, 'CanEditSupplier');
    this.addBooleanProperty('commissionable', data, 'Commissionable');
    this.addBooleanProperty('isCertificateItem', data, 'IsCertificateItem');
    this.addBooleanProperty('onRequest', data, 'OnRequest');
    this.addBooleanProperty('packageUpgradeAvailable', data, 'PackageUpgradeAvailable');
    this.promo = new ResevationSupplementPromo(data.Promo);
  }
  hasExtraInfo() {
    return this.country+this.city+this.supplier !== ''
  }
  extraInfo() {
    return [this.country,this.city,this.supplier].filter(s=>s!=='').join(' - ')
  }
  hasPromo(): boolean {
    return this.promo.promoID > 0;
  }
  isValid() {
    return this.bkgID !== 0;
  }
  getContext(): BookingSupplementContextType {
    if (this.isPackage()) return BookingSupplementContextType.Package;
    if (this.isVoyage()) return BookingSupplementContextType.Voyage;
    return BookingSupplementContextType.Nothing;
  }
  isPackage(): boolean {
    return (this.packageID ?? 0) > 0;
  }
  isVoyage(): boolean {
    return (this.voyageID ?? 0) > 0;
  }
  isFlight(): boolean {
    return this.serviceType?.trim().toLowerCase() === 'flights';
  }
  remarkHasPNR(): boolean {
    return this.message?.toLowerCase().includes('pnr');
  }
}

class BookingGuestVoyagePrice {
  voyageID: number;
  sellingPrice: number;
  fixedPrice: boolean;
  upSelling: boolean;
  upSellingValue: number;
  originalSuiteCategoryValue: number;
  constructor(
    voyageID: number,
    sellingPrice: number,
    fixedPrice: boolean,
    upSelling: boolean,
    upSellingValue: number,
    originalSuiteCategoryValue: number
  ) {
    this.voyageID = voyageID;
    this.sellingPrice = sellingPrice;
    this.fixedPrice = fixedPrice;
    this.upSelling = upSelling;
    this.upSellingValue = upSellingValue;
    this.originalSuiteCategoryValue = originalSuiteCategoryValue;
  }
}
export class BookingGuest extends SafeBaseObject {
  static fromDirect(direct: ReservationIndividual): BookingGuest {
    return new BookingGuest({
      IndividualID: direct.individualID,
      Title: direct.title,
      LastName: direct.lastName,
      FirstName: direct.firstName,
      MiddleName: direct.middleName,
      Gender: direct.gender,
      GuestType: direct.guestType,
      IndividualType: direct.individualType,
      Country: direct.country,
      household_id: direct.householdId,
    } as unknown as IReservationApiBookingGuest);
  }
  static newPlaceholder(guestCod: number, roomID=1): BookingGuest {
    return new BookingGuest({
      GuestCod: guestCod,
      LastName: 'QUOTE',
      FirstName: '1',
      IsPlaceHolder: 'Y',
      RoomID: roomID
    } as unknown as IReservationApiBookingGuest);
  }
  public firstName: string = '';
  public middleName: string = '';
  public lastName: string = '';
  public title: string = '';
  public sectionID: number = 0;
  public individualID?: number;
  public individualType: string = '';
  public isPlaceHolder: boolean = false;
  public insuranceStatus: string = '';
  public insuranceDeclineUser: string = '';
  public insuranceDeclineDate?: DateTime;
  public guestID?: number;
  public roomID = 1

  public guestCod: string = '';
  public guestStatusCod: string = '';
  public guestType: string = '';
  public countryCod: string = '';
  public country: string = '';
  public stateCod: string = '';
  public county: string = '';
  public city: string = '';
  public zip1: string = '';
  public Zzip2: string = '';
  public Zip2: string = '';
  public address1: string = '';
  public address2: string = '';
  public address3: string = '';
  public address4: string = '';
  public nationalityCod: string = '';
  public birthDate: DateTime = DateTime.invalid('empty');
  public birthPlace: string = '';
  public birthNation: string = '';
  public email: string = '';
  public phone: string = '';
  public phonePrefix_ISO2: string = '';
  public passportNumber: string = '';
  public passportIssuePlace: string = '';
  public passportIssueDate?: Date;
  public passportExpireDate?: Date;
  public passportIssueCountryCod: string = '';
  public passportIssueCountryCodISO2: string = '';
  public gender: string = '';
  public sellingPrice: number = 0;
  public priceTax: number = 0;
  public commission: number = 0;
  public commissionTax: number = 0;
  public guestTimeLine: Array<BookingTimeLine> = [];
  public supplements: Array<BookingSupplement> = [];
  public voyagePrices: Array<BookingGuestVoyagePrice> = [];
  public isVip = false;
  public bkgID: number = 0;
  public packageID?: number;
  public packageTravelDateID?: number;
  public voyageID: number = 0;
  public loyaltyID?: number;
  public fixedPrice: boolean = false;
  public upSelling: boolean = false;
  public upSellingValue: number = 0;
  public originalSuiteCategoryID: number = 0;
  public householdId?: number;
  public displayPhone: string = '';
  public hasPNR: number = 0;
  public defaultCurrency: string = '';
  public checkBooking: boolean = false;
  public individualPreference: boolean = false;
  public bookingPreference: boolean = false;
  public bookingSameVoyage: Array<{ bkgID: number; bkgUrl: SafeUrl }> = [];
  public stringBkgId: string = '';
  public static insurance2States = ['CA', 'FL', 'OR', 'PA', 'RI', 'NY', 'WA'];

  public linkCRM: string = '';
  public hasLinkCRM: boolean = false;
  public customGrids: IResDynConfiguration[] = [];
  public callbacks: Array<IEidosGridCallableMethod> = [];
  guestOwnArrangments: ReservationBookingGuestOwnArrangement[] = [];
  guestCharge: number = 0;
  hasFutureBkg: boolean = false;
  hasGuestReferral: boolean = false;
  keySuiteID = 0;
  hasTravelGroup = false
  travelGroupID = 0
  isSelected?: boolean | null = false

  constructor(data?: IReservationApiBookingGuest) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
  isValid() {
    return this.guestID ?? 0 != 0
  }
  cloneForAccomodation() {
    return new BookingGuest({
      IndividualID: this.individualID,
      Title: this.title,
      LastName: this.lastName,
      FirstName: this.firstName,
      MiddleName: this.middleName,
      Gender: this.gender,
      GuestType: this.guestType,
      IndividualType: this.individualType,
      Country: this.country,
      household_id: this.householdId,
    } as unknown as IReservationApiBookingGuest);
  }
  override updateData(data: IReservationApiBookingGuest): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('passportIssueDate', data, 'PassportIssueDate');
    this.addDateTimeProperty('passportExpireDate', data, 'PassportExpireDate');
    this.addDateTimeProperty(
      'insuranceDeclineDate',
      data,
      'InsuranceDeclineDate'
    );

    // i did this because the addMangledProperty remove underscore from the property name
    delete (this as any).phonePrefixISO2;
    this.addProperty('phonePrefix_ISO2', data, 'PhonePrefix_ISO2');
    const sanitizer = AppModule.injector.get(DomSanitizer);
    const utility = AppModule.injector.get(EidosUtilityService);
    const UserName = utility.getCurrentUser()?.username ?? '';
    if (data.StringBkgId) {
      this.bookingSameVoyage = data.StringBkgId.split(',').map((id) => {
        return {
          bkgID: +id.trim(),
          bkgUrl: sanitizer.bypassSecurityTrustResourceUrl(
            [RESERVATION_ROOT, ReservationRoute.Booking, id].join('/')
          ),
        };
      });
    }
    this.customGrids = [
      {
        visible: false,
        key: 'CustomerPreference',
        parameters: {
          UserName: UserName,
          EntityType: 'customer',
          Id: this.individualID,
          Take: '1000',
          Skip: 0,
          OrderBy: '',
        },
      },
    ];
    if (this.individualID) {
      const config = AppModule.injector.get(EidosConfigService);
      const cfg = config.currentModulesConfig
        .getValue()
        .find((m) => m.moduleName === 'reservation');
      this.linkCRM = `${cfg.crossReferenceURL.entityMaster}/entity-master?entity=customer&id=${this.individualID}`;
      this.hasLinkCRM = true;
    }

    this.guestOwnArrangments = (data.GuestOwnArrangments ?? []).map(
      (g) => new ReservationBookingGuestOwnArrangement(g)
    );
  }
  displayNameFull() {
    let guestCod = +this.guestCod
    const grpName = `(G${this.roomID} - ${guestCod})`
    if(guestCod>1000) guestCod /= 1000
    if (this.lastName === 'Quote') return `${this.lastName} ${grpName}`;
    return `${this.firstName ?? ''} ${this.middleName ?? ''} ${this.lastName ?? ''} ${grpName}`.replace('  ', ' ').trim();
  }
  displayNameWithGroup(withGroup = false,index?: number): string {
    const grpName = withGroup ? `(G${this.roomID})` : ''
    let guestCod = +this.guestCod
    if(guestCod>1000) guestCod /= 1000
    if (this.lastName === 'Quote') return `${this.lastName} ${index ?? guestCod}`;
    return `${this.firstName ?? ''} ${this.middleName ?? ''} ${this.lastName ?? ''} ${grpName}`.replace('  ', ' ').trim();
  }

  displayName(index?: number): string {
    if (this.lastName === 'Quote')
      return `${this.lastName} ${index ?? this.guestCod}`;

    return `${this.firstName ?? ''} ${this.middleName ?? ''} ${
      this.lastName ?? ''
    }`
      .replace('  ', ' ')
      .trim();
  }

  get getDisplayName(): string {
    return this.displayName();
  }

  hasAvailableInsurance(): boolean {
    return this.insuranceStatus?.toLowerCase() === 'available';
  }
  public isInsuranceDetermined(): boolean {
    return !!this.stateCod;
  }
  hasNotAvailableInsurance(): boolean {
    return this.insuranceStatus?.toLowerCase() === 'not available';
  }
  hasInsurancePdf2(): boolean {
    return BookingGuest.insurance2States.includes(this.stateCod);
  }
  getInsurance1Rule(): string {
    return `Insurance valid for ${BookingGuest.insurance2States.join(
      ','
    )} states`;
  }
  getInsurance2Rule(): string {
    return `Insurance valid for states differnt from ${BookingGuest.insurance2States.join(
      ','
    )}`;
  }
  hasInsurancePdf1(): boolean {
    return !BookingGuest.insurance2States.includes(this.stateCod);
  }
  hasBookedInsurance(): boolean {
    return this.insuranceStatus?.toLowerCase() === 'already booked';
  }

  hasDeclinedInsurance(): boolean {
    return this.insuranceStatus?.toLowerCase() === 'declined';
  }

  hasPreferences(): boolean {
    return this.individualPreference || this.bookingPreference;
  }

  hasVoyageFixedPrice(voyageID: number) {
    if (voyageID !== this.voyageID) {
      const vp = this.voyagePrices.find((vp) => vp.voyageID === voyageID);
      if (vp) return vp.fixedPrice;
    }
    return this.fixedPrice;
  }

  getVoyageSellingPrice(voyageID: number) {
    if (voyageID !== this.voyageID) {
      const vp = this.voyagePrices.find((vp) => vp.voyageID === voyageID);
      if (vp) return vp.sellingPrice;
    }
    return this.sellingPrice;
  }

  getVoyageUpSellingData(voyageID: number, key: string) {
    if (voyageID == this.voyageID) return this[key as keyof BookingGuest];
    return this.voyagePrices.find((vp) => vp.voyageID === voyageID)?.[
      key as keyof BookingGuestVoyagePrice
    ];
  }

  get isAdult(): boolean {
    return this.guestType?.toLowerCase() === 'adult';
  }

  get isChild(): boolean {
    return this.guestType?.toLowerCase() === 'child';
  }

  get missingGMTMandatoryFields(): string[] {
    const missingFields: string[] = [];

    if (!this.firstName) missingFields.push('First Name');
    if (!this.lastName) missingFields.push('Last Name');
    if (!this.birthDate.isValid) missingFields.push('Birth Date');
    if (!this.gender) missingFields.push('Gender');
    /* if (!this.passportNumber) missingFields.push('Passport Number')
    if(!this.passportIssueCountryCod) missingFields.push('Passport Issue Country')
    if(!(this.passportIssueDate as unknown as DateTime)?.isValid) missingFields.push('Passport Issue Date') */

    return missingFields;
  }

  displayMissingGMTMandatoryFields(displayName: boolean = true): string {
    if (!this.missingGMTMandatoryFields.length) return '';
    const name = displayName ? `${this.getDisplayName} is` : 'Guest is';
    return `${name} missing the following mandatory fields: ${this.missingGMTMandatoryFields.join(
      ', '
    )}`;
  }
}

export class ResSelectableItem<T> {
  private _item: T;
  get item(): T {
    return this._item;
  }
  set item(value: T) {
    this._item = value;
  }
  private _selected: boolean | null | undefined = false;
  get selected(): boolean | null | undefined {
    return this._selected === true;
  }
  set selected(value: boolean | null | undefined) {
    this._selected = value;
  }
  constructor(item: T) {
    this._item = item;
  }
}

export enum ResEditGuestAction {
  PersonalData = 'personalData',
  AirData = 'airData',
}

export class BookingPriceJourney extends SafeBaseObject {
  public sectionID: number = 0;
  public serviceTypeID: number = 0;
  public serviceTypeName: string = '';
  public voyageID: number = 0;
  public packageID: number = 0;
  public sellingPrice: number = 0;
  public sellingPriceTax: number = 0;
  public commission: number = 0;
  public guestNum: number = 0;
  public adult: number = 0;
  public child: number = 0;
  public quantity: number = 0;
  public nightNum: number = 0;
  public bkgCost: number = 0;
  public bkgCostTax: number = 0;

  constructor(data?: IReservationApiBookingPriceJourney) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationApiBookingPriceJourney): void {
    this.addMangledProperty(data);
  }
}

export class BookingJourneySupplement extends SafeBaseObject {
  public sectionID: number = 0;
  public serviceTypeID: number = 0;
  public serviceTypeName: string = '';
  public serviceID: number = 0;
  public optionID: number = 0;
  public optionName: string = '';
  public packageID: number = 0;
  public packageTravelDateID: number = 0;
  public sellingPrice: number = 0;
  public sellingPriceTax: number = 0;
  public commission: number = 0;
  public bkgCost: number = 0;
  public bkgCostTax: number = 0;
  public costCurrency?: string;
  public guestNum: number = 0;
  public quantity: number = 0;
  public nightNum: number = 0;
  public itineraryDate?: DateTime;
  public sellingPriceCurrency?: string;
  public included: boolean = true;
  public hideInBkg = false;
  public onRequest = false;
  public packageUpgradeAvailable = false;
  filePath=''

  constructor(data?: IReservationApiBookingPriceSupplementJourney) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(
    data: IReservationApiBookingPriceSupplementJourney
  ): void {
    super.updateData(data);
    this.addDateTimeProperty('itineraryDate', data, 'ItineraryDate');
    this.addBooleanProperty('onRequest', data, 'OnRequest');
    this.addBooleanProperty('packageUpgradeAvailable', data, 'PackageUpgradeAvailable');
  }
}

export class ResEmbarkDebarkInfo extends SafeBaseObject {
  public bkgID = 0;
  public debarkCity = '';
  public debarkDate?: DateTime;
  public embarkCity = '';
  public embarkDate?: DateTime;

  constructor(data?: IEmbarkDebarkInfo) {
    super();
    if (data) {
      this.updateData(data);
      this.addDateTimeProperty('embarkDate', data, 'EmbarkDate');
      this.addDateTimeProperty('debarkDate', data, 'DebarkDate');
    }
  }

  getFormattedEmbarkDate(): string {
    return this.embarkDate?.toFormat('dd LLL yyyy') ?? '';
  }

  getFormattedDebarkDate(): string {
    return this.debarkDate?.toFormat('dd LLL yyyy') ?? '';
  }
}

export class ResEmbarkDebarkDetails extends SafeBaseObject {
  bkgID = 0;
  realBkgID = 0;
  debarkCity = '';
  debarkDate?: DateTime;
  embarkCity = '';
  embarkDate?: DateTime;
  suiteCategory = '';
  suiteNo = '';
  voyageNumber = '';
  status = '';

  constructor(data?: IEmbarkDebarkDetails) {
    super();
    if (data) {
      this.updateData(data);
      this.addDateTimeProperty('embarkDate', data, 'EmbarkDate');
      this.addDateTimeProperty('debarkDate', data, 'DebarkDate');
    }
  }
  getFormattedEmbarkDate(): string {
    return this.embarkDate?.toFormat('dd LLL yyyy') ?? '';
  }

  getFormattedDebarkDate(): string {
    return this.debarkDate?.toFormat('dd LLL yyyy') ?? '';
  }
}

export class ResRoomConfig extends SafeBaseObject {
  bkgID = 0
  roomConfHdrID = 0
  guestID = 0

  constructor(data?: IApiRoomConfig) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
  override updateData(_data: IApiRoomConfig): void {

  }
}
export class ResBookingTravelGroup {
  travelGroupID = 0
  guests:BookingGuest[] = []
  roomConfHdrID = 0

  constructor(roomConfHdrID:number,travelGroupID:number) {
    this.roomConfHdrID = roomConfHdrID
    this.travelGroupID = travelGroupID
  }

  addGuest(guest:BookingGuest) {
    this.guests.push(guest)
  }
}

export class BookingOwner extends SafeBaseObject {
  public ReservationAgent?: string;
  public AgencyCommissionApplied?: number;
  public TotalCommission?: number;

  public agency?: string;
  public address?: string;
  public addressToDisplay?: string;
  public address2?: string;
  public agencyCod?: string;
  public agencyContacts?: string;
  public ownerType?: string;
  public agencyID?: number;
  public agencyIataCod?: string;
  public agentContacts?: string;
  public agentID?: number;
  public city?: string;
  public communicationEmail?: string;
  public email?: string;
  public firstName?: string;
  public individualID?: number;
  public lastName?: string;
  public mainContact?: string;
  public middleName?: string;
  public phone?: string;
  public cruiseCommission?: number;
  public airCommission?: number;
  public optionCommission?: number;
  public etaAccountingEmail?: string;
  public note?: string;
  public countryCod?: string;
  public country?: string;
  public stateCod?: string;
  public zip1?: string;
  public zip2?: string;
  public guest?: string;
  public displayGuest?: boolean;
  public isWebSiteAgent?: boolean;

  constructor(data?: IReservationApiBookingOwner) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
  override updateData(data: IReservationApiBookingOwner): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('displayGuest', data, 'DisplayGuest');
    this.addBooleanProperty('isWebSiteAgent', data, 'IsWebSiteAgent');
  }
  isDirect(): boolean {
    return this.ownerType?.toUpperCase() === 'DIRECT';
  }
  isAgent(): boolean {
    return this.ownerType?.toUpperCase() !== 'DIRECT';
  }
  displayName(): string {
    if (this.isDirect()) return this.displayContactName();
    return this.agency ?? '';
  }
  displayContactName(): string {
    return `${this.firstName ?? ''} ${this.middleName ?? ''} ${
      this.lastName ?? ''
    }`.replace('  ', ' ');
  }
}
export class ResBookingFinancialRecap extends SafeBaseObject {
  public sectionID: number;
  public totalDue: number;
  public totalPriceTax: number;
  public totalCharge: number;
  public totalPayment: number;
  commission: number | null = null;
  commissionPaid: number | null = null;
  vatCommission: number | null = null;
  netCommission: number | null = null;
  public cost: number;
  public totalCostTax: number;
  public tax: number;
  public portCharge: number;
  public NFC: number;
  public guestNum: number;
  bookingBalance: number | null = null;
  prePaid: boolean = false;

  constructor(data?: IReservationApiBookingFinancialRecap) {
    super();
    this.sectionID = 0;
    this.totalDue = 0;
    this.totalPriceTax = 0;
    this.totalCharge = 0;
    this.totalPayment = 0;
    this.cost = 0;
    this.totalCostTax = 0;
    this.tax = 0;
    this.portCharge = 0;
    this.NFC = 0;
    this.guestNum = 0;
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiBookingFinancialRecap): void {
    this.addMangledProperty(data);
    this.addProperty('NFC', data, 'NFC');
  }
}
interface IReservationApiBookingPenalties {
  BkgID?: number;
  SectionID?: number;
  PackageID?: number;
  PackageName?: string;
  ServiceID?: number;
  ServiceLongName?: string;
  OptionID?: number;
  OptionName?: string;
  VoyageID?: number;
  VoyageNumber?: string;
  VoyageName?: string;
  VoyageDesc?: string;
  DaysFrom?: number;
  DaysTo?: number;
  DateFrom?: string;
  DateTo?: string;
  AmountPerc?: number;
  FixedAmount?: number;
  AmountValue?: number;
  ProtectCommission?: boolean;
}
class BookingPenalties extends SafeBaseObject {
  public bkgID: number = 0;
  public sectionID: number = 0;
  public packageID: number = 0;
  public packageName: string = '';
  public serviceID: number = 0;
  public serviceLongName: string = '';
  public optionID: number = 0;
  public optionName: string = '';
  public voyageID: number = 0;
  public voyageNumber: string = '';
  public voyageName: string = '';
  public voyageDesc: string = '';
  public daysFrom: number = 0;
  public daysTo: number = 0;
  public dateFrom?: DateTime;
  public dateTo?: DateTime;
  public amountPerc: number = 0;
  public amountValue: number = 0;
  public fixedAmount: number = 0;
  public protectCommission: boolean = false;
  constructor(data: any) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationApiBookingPenalties): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('dateFrom', data, 'DateFrom');
    this.addDateTimeProperty('dateTo', data, 'DateTo');
  }
}

interface IReservationApiBookingDeposit {
  SectionID?: number;
  PackageID?: number;
  PackageName?: string;
  ServiceID?: number;
  OptionID?: number;
  VoyageID?: number;
  VoyageName?: string;
  PaymentDueDate?: DateTime;
  FullBookingSchedulingFlag?: boolean;
  Amount?: number;
  Currency?: number;
  IsFinalPayment?: boolean;
  DisplayText?: string;
}
export class BookingDeposit extends SafeBaseObject {
  public sectionID: number = 0;
  public packageID: number = 0;
  public packageName: string = '';
  public serviceID: number = 0;
  public optionID: number = 0;
  public voyageID: number = 0;
  public voyageName: string = '';
  public paymentDueDate?: DateTime;
  public fullBookingSchedulingFlag: boolean = false;
  public amount: number = 0;
  public currency: string = '';
  public isFinalPayment: boolean = false;
  public displayText: string = '';

  constructor(data: IReservationApiBookingDeposit) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationApiBookingDeposit): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('paymentDueDate', data, 'PaymentDueDate');
  }
}

export interface IReservationApiParametersRoomConfHdr {
  RoomID: number
  AdultNum: number
  ChildNum: number
  Child2Num: number
  InfantNum: number
  SuiteCapacityCod?:string
  ServiceTypeID?: number
  VoyageID?: number
  PackageID?: number
  CampID?: number
  accomodationIdx:number
}
export interface IReservationApiParametersBookCamp {
  SuiteCategoryID: number
  CapacityID: number
  TravelStartDate: Date
  TravelEndDate: Date
  Adult: number
  Child: number
  SuiteID?: number
  PropertyID?:number
  ServiceID?:number
  OptionID?:number
  RoomID: number
  OnRequest: boolean
  SellingPrice: number
  ROE: number
  Markup: number
}
export interface IResApiParametersQuoteCreate {
  BkgID?: number;
  BkgName?: string;
  BkgType?: string;
  BkgCurrency?: string;
  VoyageID?: number;
  PriceTypeID?: number;
  OrganizationID?: number;
  CreationDate?: Date;
  DepositDate?: Date;
  ConfirmationDate?: Date;
  CancelDate?: Date;
  TravelDate?: Date;
  AgencyID?: number;
  AgencyContacts?: string;
  OwnerType?: string;
  AgentID?: number;
  AgentContacts?: string;
  CommunicationEmail?: string;
  UserName?: string;
  Action?: string;
  Unchecked?: string,
  GuestConstranumber?: string;
  CompanyID?: number;
  GuestNum?: number;
  PackageOptions: Array<IReservationApiParametersPackage>;
  GrpId?: number;
  Guests: Array<IReservationApiParametersGuest>;
  NewSuite?: Array<IReservationApiParametersSuite>;
  LoyaltyDiscountId?: number;
  AirPortFrom?: string;
  AirClassFrom?: string;
  AirPortTo?: string;
  AirClassTo?: string;
  PromoID?: string;
  Package?: IReservationApiParametersAddPackage[]
  BookCamp?: IReservationApiParametersBookCamp[]
  RoomConfHdr: IReservationApiParametersRoomConfHdr[]

  WC?: string;
  CategoryType?: string;
  Note?: string;
  WLVoyageID?: number;
}
export interface IReservationApiParametersSuite {
  VoyageID: number,
  SuiteCategoryID?: number,
  SuiteCategoryCod?: string,
  SuiteCapacityID?: number,
  SuiteNumber?: number,
  IsGty: 'Y' | 'N' | 'W' | 'R',
  RoomID: number,
}
export interface IReservationApiParametersPackage {
  PackageID?: number;
  BkgCurrency?: string;
  ServiceID?: number;
  OptionID?: number;
  ItineraryDate?: Date;
  SortSequence?: number;
  Alternative?: string;
  StartDate?: Date;
  EndDate?: Date;
  TravelStartDate?: Date;
  TravelEndDate?: Date;
  GuestFrom?: number;
  GuestTo?: number;
  CostCurrency?: string;
  CostTax?: number;
  ROE?: number;
  Markup?: number;
  PriceCurrency?: string;
  PriceTax?: number;
  Cost?: number;
  Price?: number;
  VoyageID?: number;
  PriceTypeID?: number;
  PriceCategoryID?: number;
  SuiteCategoryID?: number;
  SuiteCapacityID?:number;
  SuiteID?: number;
  Adult?: number;
  Child?: number;
  PriceSingle?: number;
  PriceDouble?: number;
  PriceExtraGuest?: number;
  PriceChild?: number;
  Commission?: number;
}
export interface IReservationApiParametersAddPackage {
  PackageID: number;
  PackageTravelDateID: number;
  GuestCod: number;
  RetailPrice: string;
}
export interface IReservationApiParametersGuest {
  DirectID?: number;
  LastName?: string;
  FirstName?: string;
  CountryCod?: string;
  StateCod?: string;
  CountyCod?: string;
  City?: string;
  Zip1?: string;
  Zip2?: string;
  Address1?: string;
  Address2?: string;
  Address3?: string;
  Address4?: string;
  NationalityCod?: string;
  BirthDate?: Date;
  Phone?: string;
  Gender?: string;
  PassportNumber?: string;
  PassportIssuePlace?: string;
  PassportIssueDate?: Date;
  PassportExpireDate?: Date;
  Price?: number;
  PriceTax?: number;
  Commission?: number;
  CommissionTax?: number;
  GuestType?: ResGuestType;
  RoomID: number;
  TempGuestCod: number;
}
export enum ResGuestType {
  Adult = 'Adult',
  Child = 'Child',
  Child2 = 'Child2',
  Infant = 'Infant',
}

interface IResevationApiBookingPromo {
  PromoID?: number;
  BkgID?: number;
  SectionID?: number;
  PromoName?: string;
  PromoShortName?: string;
  PromoDesc?: string;
  SellingPrice?: number;
  SellingPriceCurrency?: string;
}
class ResevationSupplementPromo extends SafeBaseObject {
  public promoID: number = 0;
  public bkgID: number = 0;
  public sectionID: number = 0;
  public promoName: string = '';
  public promoShortName: string = '';
  public promoDesc: string = '';
  public sellingPrice: number = 0;
  public sellingPriceCurrency: string = '';
  constructor(data?: IResevationApiBookingPromo) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data?: IResevationApiBookingPromo): void {
    this.addMangledProperty(data);
  }
}

interface IReservationApiBookingCustomPackageGuest
  extends IReservationApiCustomRequestDetailGuest {
  CustomPackagesDetail: IReservationApiCustomRequestDetail[];
}

class BookingCustomPackages extends ReservationCustomRequestDetailGuest {
  public sectionID?: number;
  public packageID?: number;
  public packageTravelDateID?: number;
  public requestName?: string;
  public note?: string;
  public details?: Array<ReservationCustomRequestDetail>;

  constructor(data?: IReservationApiBookingCustomPackageGuest) {
    super(data);
  }
  override updateData(data?: IReservationApiBookingCustomPackageGuest): void {
    super.updateData(data);
    this.details = [];
    if (data?.CustomPackagesDetail && data?.CustomPackagesDetail.length > 0) {
      data.CustomPackagesDetail.forEach((crd) =>
        this.details!.push(new ReservationCustomRequestDetail(crd))
      );
    }
  }
}

export class BookingSpecialRequest {
  public guest: BookingGuest;
  public requests: Array<BookingCustomPackages>;

  constructor(guest: BookingGuest, requests: Array<BookingCustomPackages>) {
    this.guest = guest;
    this.requests = requests;
  }
  hasRequests(): boolean {
    return this.requests.length > 0;
  }
  getRequestName(): string {
    if (this.requests.length === 0) return '';
    return this.requests[0].requestName ?? '';
  }
  getRequestNote(): string {
    if (this.requests.length === 0) return '';
    return this.requests[0].note ?? '';
  }
}

export enum BookingModals {
  Guests = 'guests-crud',
  Options = 'add-options',
  Commissions = 'commissions',
  Documents = 'documents',
  Insurances = 'insurances',
}

/**
 * Interface of an existing check request
 *
 * @export
 * @interface IResValueExistCheckParams
 */
export interface IResValueExistCheckParams {
  Type: 'BkgID' | 'GroupCod';
  Value: string | number;
  BkgIDFrom?: number;
}

export interface IResBookingGroup {
  GrpID: number;
  GroupName: string;
}
/**
 * New suite params in a booking change voyage
 *
 * @export
 * @interface ResBookingChangeVoyageNewSuiteParams
 */
export interface ResBookingChangeVoyageNewSuiteParams {
  VoyageID: number;
  SuiteNumber?: number;
  SuiteCategoryID?: number;
  SuiteCategoryCod?: string;
  SuiteCapacityID: number;
  IsGty: string;
  RoomID: number;
}
/**
 * Voyage 2 Voyage params
 *
 * @export
 * @interface ResBookingV2VParams
 */
export interface ResBookingV2VParams {
  BkgID: number;
  SectionID: number;
  NewSuite: ResBookingChangeVoyageNewSuiteParams[];
  PriceTypeID?: number;
}

/**
 * V2V = Voyage 2 Voyage
 * OD = OD conversion
 * CB = Copy booking
 */
export type ResBookingSearchVoyagePopupAction = 'V2V' | 'OD' | 'CB';

/**
 * Configuration of a search popup in bkg
 * Used by:
 * V2V
 * OD conversion
 * Copy booking
 *
 * @export
 * @interface ResBookingSearchVoyagePopupConfig
 * @extends {ResSearchPopupConfig}
 */
export interface ResBookingSearchVoyagePopupConfig
  extends ResSearchPopupConfig {
  action: ResBookingSearchVoyagePopupAction;
}

export class ReservationBookingGuestOwnArrangement extends SafeBaseObject {
  bkgID: number = ResBooking.INVALID_BOOKING;
  itineraryDate: DateTime = DateTime.now();
  description?: string;
  guestCod: number = -1;
  guestID: number = -1;
  type: string = '';
  serviceType: string = 'Own Arrangement';

  get isPre(): boolean {
    return this.type === 'Arrival';
  }

  get isPost(): boolean {
    return this.type === 'Departure';
  }

  get isValid(): boolean {
    return (
      !_.isUndefined(this.itineraryDate) &&
      this.itineraryDate !== null &&
      this.itineraryDate.isValid
    );
  }

  constructor(data?: IReservationApiBookingGuestOwnArrangement) {
    super();
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiBookingGuestOwnArrangement): void {
    this.addMangledProperty(data);

    this.addDateTimeProperty('itineraryDate', data, 'ItineraryDate');
  }
}

export class ResBkgWishlist extends SafeBaseObject {
  wishListID: number = 0;
  voyageID: number = 0;
  suiteCategoryID: number = 0;
  deckID: number = 0;
  suiteID: number = 0;
  suiteNo: number = 0;
  bkgID: number = 0;

  constructor(data?: IReservationApiBkgWishlist) {
    super();
    data && this.updateData(data);
  }
}

export interface IReservationApiBkgWishlist {
  WishListID?: number;
  VoyageID: number;
  SuiteCategoryID: number;
  DeckID: number;
  SuiteID: number;
  SuiteNo?: number;
  BkgID?: number;
  Status?: string;
}

export interface IReservationApiManageBkgWishlistParams {
  BkgID: number;
  Wishlist: IReservationApiBkgWishlist[];
}

export interface IReservationBookingMarginality {
  BkgID?: number;
  Cost?: number;
  CostTax?: number;
  CostCurrency?: string;
  ItemListID?: number;
  ItineraryDate?: DateTime;
  OptionID?: number;
  OptionName?: string;
  PackageID?: number;
  PackageName?: string;
  Price?: number;
  PriceTax?: number;
  PriceCurrency?: string;
  ServiceID?: number;
  ServiceName?: string;
  ServiceType?: string;
  ServiceTypeID?: number;
  SupplierID?: number;
  SupplierName?: string;
}

export class ReservationBookingMarginality extends SafeBaseObject {
  bkgID = 0;
  cost = 0;
  costActual = 0;
  costTax = 0;
  costTaxActual = 0;
  costCurrency = '';
  itemListID = 0;
  itineraryDate = DateTime.invalid('init');
  margin = 0;
  marginActual = 0;
  optionID = 0;
  optionName = '';
  packageID = 0;
  packageName = '';
  price = 0;
  priceTax = 0;
  priceCurrency = '';
  serviceID = 0;
  serviceName = '';
  serviceType = '';
  serviceTypeID = 0;
  supplierID = 0;
  supplierName = '';


  constructor(data?: IReservationBookingMarginality) {
    super();
    data && this.updateData(data);
  }
  override updateData(data: IReservationBookingMarginality): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('itineraryDate', data, 'ItineraryDate');
  }
}

export class Market extends SafeBaseObject {
  market: string = '';

  constructor(data?: IMarket) {
    super();
    data && this.updateData(data);
  }
}

export interface IMarket {
  Market: string;
}

export class ReservationBookingReferralGuests extends SafeBaseObject {
  amount = 0;
  amountUsed = 0;
  bkgID = 0;
  currency = '';
  discountType = '';
  firstName = '';
  guestPortfolioID = 0;
  guestReferralId = 0;
  lastName = '';
  oldDirectID = 0;
  status = '';
  maxDiscount = 0;

  isSelected = false;

  constructor(data?: any) {
    super();
    data && this.updateData(data);
  }
  override updateData(data: any): void {
    this.addMangledProperty(data);
  }
}

export interface IReservationApiManageReferralDiscounts {
  GuestPortfolioID?: number;
  GuestReferralID?: number;
  Status?: string;
  Amount?: number;
  AmountUsed?: number;
  DiscountType?: string;
  BkgID?: number;
}

export class BookingToRequest extends SafeBaseObject {
  requestId = 0;
  type = '';
  typeDesc = '';
  bkgId = 0;
  individuals = 0;
  bkgStatus = '';
  requestType = '';
  voyageDetail = '';
  embarkDate = DateTime.invalid('empty');
  debarkDate = DateTime.invalid('empty');
  priority = '';
  priorityDesc = '';
  priorityOperator = '';
  priorityOperatorDesc = '';
  assignTo = '';
  WFStatus = '';
  link = '';
  constructor(data?: any) {
    super();
    if (data) {
      super.addMangledProperty(data);
      this.updateData(data);
    }
  }
  override updateData(data?: any): void {
    this.addProperty('WFStatus', data, 'WFStatus');
    switch (this.type) {
      case 'HTL':
        this.typeDesc = 'HOTEL';
        break;
      case 'AIR':
        this.typeDesc = 'AIR';
        break;
      case 'TRF':
        this.typeDesc = 'TRANSFER';
        break;
      default:
        this.typeDesc = this.type;
        break;
    }
  }
}

export class ResBkgJourneyItinerary extends SafeBaseObject{
  bookingItineraryDateID: number = 0;
  bkgID: number = 0;
  sequence: number = 0;
  itineraryDate: Date = new Date();
  mealID: string[] = [];
  itineraryDayTitle: string = '';
  dayItinerary: ResBkgJourneyItineraryDetail[] = [];
  status: string = 'A'
  canDelete = true
  companyID: number = 0
  isDMCChild: string = 'N'

  // get dmcID() {
  //   return this.geoTreeID
  // }
  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
      if (data.MealID && data.MealID !== "") this.mealID = data.MealID.split(',');
      if (data.ItineraryDate && data.ItineraryDate !== '') this.itineraryDate = new Date(data.ItineraryDate)
      if (data.DayItinerary && data.DayItinerary.length > 0) this.dayItinerary = data.DayItinerary.map((d: any) => new ResBkgJourneyItineraryDetail(d));
    }
  }
}

export class ResBkgJourneyItineraryDetail extends SafeBaseObject{
  bookingItineraryDateCityID: number = 0;
  bookingItineraryDateID: number = 0;
  bkgID: number = 0;
  cityID: number = 0;
  cityIDTo: number = 0;
  movingTypeID: number = 0;
  description: string = '';
  timeFrom: Date = new Date();
  timeTo: Date = new Date();
  sequence: number = 0;
  status: string = 'A'

  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
      let dateFrom = new Date();
      let [hoursFrom, minutesFrom, secondsFrom] = data.TimeFrom?.split(':').map(Number) ?? [0,0,0];
      dateFrom.setHours(hoursFrom);
      dateFrom.setMinutes(minutesFrom);
      dateFrom.setSeconds(secondsFrom);
      this.timeFrom = dateFrom

      let dateTo = new Date();
      let [hoursTo, minutesTo, secondsTo] = data.TimeTo?.split(':').map(Number) ?? [0,0,0];
      dateTo.setHours(hoursTo);
      dateTo.setMinutes(minutesTo);
      dateTo.setSeconds(secondsTo);
      this.timeTo = dateTo
    }
  }
}

export interface IResBkgJourneyItinerary {
  BookingItineraryDateID: number;
  BkgID: number;
  Sequence: number;
  ItineraryDate: Date
  MealID: string;
  ItineraryDayTitle: string;
  Status: string;
  CompanyID: number;
  IsDMCChild: string;
}

export interface IResBkgJourneyItineraryDetail {
  BookingItineraryDateCityID: number;
  BookingItineraryDateID: number;
  BkgID: number;
  CityID: number;
  CityIDTo: number;
  MovingTypeID: number;
  Description: string;
  TimeFrom: string;
  TimeTo: string;
  Sequence: number;
  Status: string;
}


export class ResBkgDmc extends SafeBaseObject{
  key = ''
  value = ''
  companyID = 0
  company	= ''
  geoTreeID	= 0
  geoTreeName = ''

  constructor(data?: any) {
    super();
    if(!data) return

    super.updateData(data);
    this.updateData(data);
  }

  override updateData(data: any) {
    if(!data) return

    this.key = `${this.companyID}-${this.geoTreeID}`
    this.value = `${this.geoTreeName} (${this.company})`
  }
}

export class ResFinancialSplit extends SafeBaseObject{
  payer = ''
  totalCharge = 0

  constructor(data?: any) {
    super();
    if(!data) return

    super.updateData(data);
    this.updateData(data);
  }
}

