/**
 * Check if a the string value is included in CachedData
 * NB: Remember to add a new CachedData in CachedData type and
 * ALSO in the array used in this const function.
 *
 * @param {string} value
 * @return {boolean} T if value is included CachedData, F otherwise
 */
export const isValidCachedDataType = function (value: string): value is CachedData {
  return [
    'PriceTypes',
    'ProductTypes',
    'GeoTree',
    'Destinations',
    'CruiseLines',
    'Ships',
    'CustomRequestTypes',
    'Sites',
    'Companies',
    'ExperienceTypes',
    'Occupations',
    'ReceiptTypes',
    'Countries',
    'PhonePrefixes',
    'Languages',
    'Salutations',
    'TransferTypes',
    'PriceSetups',
    'PkgItineraryTypes',
    'PackageTypes',
    'ProductCategoryTypes',
    'Brands',
    'LoyaltyDiscounts',
    'Currencies',
    'NoteTypes',
    'SuiteCategories',
    'Deckplans',
    'SuiteStatus',
    'SuiteCapacities',
    'SuiteAmenities',
    'LoyaltyRedemptionTiers',
    'CertificateTypes',
    'GuestTypes',
    'ManifestTypes',
    'PackageStatus',
    'PackageMeals',
    'PackageCategories',
    'PackagePhysicalLevels',
    'PackageKidFriendlies',
    'PackageKidFriendliesTo',
    'PackageMealTypes',
    'CommissionPayoutTypes',
    'CommissionPayoutBookingStatus',
    'PromoCombinabilities',
    'PromoTypes',
    'PromoActionTypes',
    'PromoStatus',
    'CommissionPayoutBatchStatus',
    'SearchableSuiteCategories',
    'TSSites',
    'CancelReasons',
    'GeoTreeTypes',
    'Departments',
    'BestTimeToGo',
    'TimeZone',
    'MovingTypes',
  ].includes(value);
};
export type CachedData =
  | 'PriceTypes'
  | 'ProductTypes'
  | 'GeoTree'
  | 'Destinations'
  | 'CruiseLines'
  | 'Ships'
  | 'CustomRequestTypes'
  | 'Sites'
  | 'Companies'
  | 'ExperienceTypes'
  | 'Occupations'
  | 'ReceiptTypes'
  | 'Countries'
  | 'PhonePrefixes'
  | 'Languages'
  | 'Salutations'
  | 'TransferTypes'
  | 'PriceSetups'
  | 'PkgItineraryTypes'
  | 'PackageTypes'
  | 'ProductCategoryTypes'
  | 'Brands'
  | 'LoyaltyDiscounts'
  | 'Currencies'
  | 'NoteTypes'
  | 'SuiteCategories'
  | 'Deckplans'
  | 'SuiteStatus'
  | 'SuiteCapacities'
  | 'SuiteAmenities'
  | 'LoyaltyRedemptionTiers'
  | 'CertificateTypes'
  | 'GuestTypes'
  | 'ManifestTypes'
  | 'PackageStatus'
  | 'PackageMeals'
  | 'PackageCategories'
  | 'PackagePhysicalLevels'
  | 'PackageKidFriendlies'
  | 'PackageKidFriendliesTo'
  | 'PackageMealTypes'
  | 'CommissionPayoutTypes'
  | 'CommissionPayoutBookingStatus'
  | 'ServiceTypes'
  | 'PromoCombinabilities'
  | 'PromoTypes'
  | 'PromoActionTypes'
  | 'PromoStatus'
  | 'CommissionPayoutBatchStatus'
  | 'ServiceTypes'
  | 'SearchableSuiteCategories'
  | 'TSSites'
  | 'CancelReasons'
  | 'GeoTreeTypes'
  | 'Departments'
  | 'BestTimeToGo'
  | 'TimeZone'
  | 'MovingTypes'
  ;

export interface IReservationDestination {
  DestinationID: number;
  DestinationShortName: string;
  DestinationName: string;
}

export interface IReservationCruiseLine {
  CruiseLineID: number;
  CruiseLine: string;
  CruiseLineCod: string;
}

export interface IReservationShip {
  ShipID: number;
  Ship: string;
  ShipCod: string;
  CruiseLineID: number;
  PaxNum: number;
  Suite: number;
  CompanyID: number;
  IsShip: string;
}

export interface IReservationGeoTree {
  DestinationID: number;
  DestinationTypeName: string;
  DestinationShortName: string;
  DestinationName: string;
  CountryID: number;
  Country: string;
  CityID: number;
  CityCod: string;
  PortCod: string;
  City: string;
  Latitude: number;
  Longitude: number;
  GeoTreeID: number;
  GeoTreeName: string;
}

export interface IReservationProductType {
  ProductCod: string;
  ProductDesc: string;
  ProductOrder: number;
}

export interface IReservationPriceType {
  PriceTypeID: number;
  PriceTypeCod?: string;
  PriceTypeName: string;
  BrandID: number
  CompanyID:number
  IsAgencyMandatory:number
  IsDefaultFilter:string
  PermissionCod:string
}

export interface IReservationCustomRequestType {
  TypeID: number;
  TypeName: string;
}

export interface IReservationSite {
  AgentPortalSiteName: string;
  BusinessHeld: string;
  CRM_Site_Name: string;
  CompanyID: number;
  SiteID: number;
  SiteName: string;
  Status: string;
  SystemCurrencyISOCode: string;
}

export interface IResApiBookingCancelReason {
  CancelReasonID:number;
  CancelReason:string;
  HasNote:string;
}

export interface ITravelStudioSite {
  SiteID: number;
  SiteCode: string;
  Site: string;
}

export interface IReservationCompany {
  AgentPortalCompanyName: string;
  AgentPortalCompany_Country: string;
  CRM_Reporting_Company_Name: string;
  CompanyCode: string;
  CompanyID: number;
  CompanyType: string;
  IsActive: boolean;
  IsManualSite: boolean;
  ParentCompanyName: string;
  Region: string;
  ReportingCompanyName: string;
  Siteid: number;
  Status: string;
  SystemCompanyName: string;
  CompanyName: string;
  TradingCompany: string;
  TradingCompany_Country: string;
  TradingCompany_Region: string;
}

export interface IReservationExperienceType {
  TagID: number;
  Tag: string;
  ECMLinkID: string;
  SortPriority: number;
}

export interface IReservationOccupation {
  Occupation: string;
}

export interface IReservationApiGeotreeType {
  DestinationTypeID?: number;
  DestinationTypeName?: string;
}
export interface IReservationApiDepartment {
  DepartmentID:number
  DepartmentName:string
}

export interface IReservationApiBestTimeToGo {
  BestTimeToGoID:number
  BestTimeToGo:string
}

export interface IReservationApiTimeZone {
  City:string
  TimeZoneID:number
  TimeZone:string
}

export interface IReservationReceiptType {
  ISCreditCard?: string;
  ReceiptTypeID?: number;
  ReceiptTypeName?: string;
}

export interface IReservationCountry {
  Country: string;
  CountryCod: string;
  CountryCodISO2: string;
  CountryID: number;
  Status: string;
}

export interface IReservationPhonePrefix {
  Desc: string;
  Cod: string;
  Prefix: number;
}

export interface IReservationLanguage {
  LanguageId: number;
  LanguageISO3: string;
  LanguageISO2: string;
  Language: string;
}

export interface IReservationSalutation {
  SalutationId: number;
  SalutationCod: string;
  Salutation: string;
}

export interface IReservationTransferType {
  TransferTypeID: number;
  TransferTypeName: string;
}

export interface IReservationAirport {
  AirportID: number;
  IataCod: string;
  MetroAreaCod: string;
  CountryCod: string;
  ContinentCod: string;
  CountryCodISO2: string;
  ID: string;
  IsoRegion: string;
  LatitudeDeg: string;
  LongitudeDeg: string;
  Municipality: string;
  Name: string;
  Type: string;
}

export interface IReservationPriceSetup {
  PriceSetUpID: number;
  PriceSetUp: string;
  ValueType: string;
  DiscountOn: string;
  Message: string;
  Status: string;
}

export interface IReservationPkgItineraryType {
  ItineraryTypeID: number;
  ItineraryTypeName: string;
}

export interface IReservationPackageType {
  PackageTypeID: number;
  PackageTypeName: string;
  PackageTypeIsDefault: boolean;
  NeedSuite: string;
}

export interface IReservationProductCategoryType {
  IsDefault: boolean;
  ProductTypeID: number;
  ProductTypeName: string;
}

export interface IReservationPackageStatus {
  Cod: string;
  Desc: string;
  Position: number;
}

export interface IReservationPackageMeal {
  Cod: string;
  Desc: string;
  Position: number;
}

export interface IReservationPackageMealType {
  MealID: number;
  Meal: string;
}

export interface IReservationPackageCategory {
  Cod: string;
  Desc: string;
  Position: number;
}

export interface IReservationPackagePhysicalLevel {
  Cod: string;
  Desc: string;
  Position: number;
}

export interface IReservationPackageKidFriendly {
  Cod: string;
  Desc: string;
}
export interface IReservationPackageKidFriendlyTo extends IReservationPackageKidFriendly{
}
export interface IReservationBrand {
  BrandID: number;
  BrandName: string;
  Position: number;
}

export interface IReservationLoyaltyDiscount {
  CompanyID: number;
  LoyaltyDiscountID: number;
  OptionCod: string;
  OptionDesc: string;
  DiscountValue: number;
  DiscountType: string;
  GuestType: string;
}

export interface IReservationCurrency {
  CurrencyCod: string;
  Symbol: string;
}

export interface IReservationNoteType {
  NoteTypeID?: number;
  NoteTypeName?: string;
  NoteTypeCod?: string;
  PickList?: string;
}

export interface IReservationSuiteCategory {
  ShipCod: string;
  Ship: string;
  ShipID: number;
  SuiteCategoryID: number;
  SuiteCategoryCod: string;
  SuiteCategory: string;
  SuiteCategoryDesc: string;
  Name: string;
  Image1: string;
  Image2: string;
  Image3: string;
  Image4: string;
  Amenities: string;
  Services: string;
  StateroomConfiguration: string;
  DisplaySequence: number;
}

export interface IReservationSuiteStatus {
  SuiteStatusCod: string;
  SuiteStatusDesc: string;
  OrderID: number;
}

export interface IReservationSuiteCapacity {
  SuiteCapacityID: number;
  SuiteCapacity: string;
  SuiteCapacityNum: number;
}

export interface IReservationSuiteAmenity {
  AmenitiesID: number;
  Amenity: string;
  Icon: string;
}

export interface IReservationLoyaltyRedemptionTier {
  LoyaltyRedemptionID: number;
  TierID: number;
  TierCod: string;
  TierDesc: string;
}

export interface IReservationCertificateType {
  CertificateTypeID: number;
  CertificateTypeCod: string;
  CertificateTypeDesc: string;
  ExpirationDate: string
}

export interface IReservationManifestType {
  ManifestType: string;
}

export interface IReservationCommissionPayoutType {
  CommissionTypeCod: string;
  CommissionTypeDesc: string;
}

export interface IReservationCommissionPayoutBkgStatus {
  BkgStatus: string;
}
export interface IReservationCommissionPayoutBatchStatus {
  BatchStatusID: number;
  BatchStatus: string;
}
export interface IReservationServiceType {
  ServiceTypeID?: number;
  ServiceTypeName?: string;
  ServiceTypeDescription?: string;
  ProductCod?: string;
  GuestConstraint?: string;
  TabsLocation?: string;
  OnBoardUse?: string;
  HasCruise?: string;
}

export interface IReservationSearchableSuiteCategory {
  DisplayValue: string
  Value: string
  CompanyID: number
}

export interface IReservationMovingType {
  MovingTypeID: number;
  MovingType: string;
}
