import { SafeBaseObject } from "@app/core/models/base-object.models";
import { ReservationApiBoolean } from "./res-constant.model";
import { DateTime } from "luxon";
import { v4 as uuid } from 'uuid';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppModule } from "@app/app.module";
import { ResPackageTravelDate } from "./res-package.model";

/**
 * Interface of Extend your Journey request
 *
 * @export
 * @interface IResExtendJourneyRequest
 */
export interface IResExtendJourneyRequest {
  Id:number
  Type:string;
  ProductCod?: string;
  BkgId?: number;
  SectionID?: number;
  PortsOfCallCities?: number[];
  PortsOfCall?: boolean;
  DestinationIdList?: string;
  CityTABLEID?: string;
  MonthList?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  Adult?: number;
  Child?: number;
  PackageName?: string;
  Currency?: string;
  PriceTypeID?: number;
  IsRetail?: ReservationApiBoolean;
  PackageTypeID?: string;
  AgencyID?: number;
}

export interface IResExtendJourneyPackage {
  PackageID:number
  TravelDates:[]
  IsAK:string
  HasBundle:string
  IsBundle:string
  PackageImage:string
}
export interface IResExtendJourneyVoyage {
  VoyageID:number
  IsAK:string
  HasBundle?:string
}
export interface IResExtendJourneyDivisor {
  title:string
}
export interface IResExtendJourney {
  Packages:Array<IResExtendJourneyPackage>;
  Voyages:Array<IResExtendJourneyVoyage>;
}
export class ResExtendJourneyType extends SafeBaseObject {
  internalType=''
}
export enum ResExtendJourneyEnumType {
  Package = 'package',
  Voyage = 'voyage',
  Divisor = 'divisor',
}
export type ResExtendJourneyClassType = ResExtendJourneyEnumType.Package|ResExtendJourneyEnumType.Voyage|ResExtendJourneyEnumType.Divisor;
export class ResExtendJourneyDivisor extends ResExtendJourneyType {
  title = '';
  static internalType:ResExtendJourneyClassType = ResExtendJourneyEnumType.Divisor
  static emptyDivisor():ResExtendJourney {
    return new ResExtendJourney(ResExtendJourneyDivisor.internalType,{title:''})
  }
  constructor(data:IResExtendJourneyDivisor) {
    super()
    this.internalType = ResExtendJourneyDivisor.internalType;
    if(data) this.updateData(data)
  }
}
export class ResExtendJourneyPackage extends ResExtendJourneyType {
  static internalType:ResExtendJourneyClassType = ResExtendJourneyEnumType.Package;

  packageID = 0
  packageTypeID=0;
  packageTypeName='';
  packageShortName='';
  packageName='';
  isTM = false;
  packageLongName='';
  packageDescription='';
  escortInc = false;
  guideInc = false;
  driverInc = false;
  occupancy = 100;
  cityID = 0;
  cityFrom = '';
  cityIDTo = 0;
  cityTo	= '';
  duration = 0;
  durationUDM	= '';
  minPax = 0;
  maxPax = 0;
  packageInclusion	= ''
  packageExclusion	= ''
  packageImage	= ''
  packageImageUrl:SafeUrl;
  ecmPDF	= ''
  ecmPDFUrl:SafeUrl;
  ecmMap	= ''
  ecmMapUrl:SafeUrl;
  packageImportantNotes	= ''
  InternalNotes = ''
  currency		= ''
  priceTypeID	 = 0;
  priceTypeName		= ''
  guestFrom	 = 0;
  guestTo	 = 0;
  sellingPrice	 = 0;
  category		= ''
  meal		= ''
  kidFriendly		= ''
  kidFriendlyTo		= ''
  physicalLevel	= ''
  isAK = false;
  hasBundle = false;
  isBundle = false;

  travelDates: ResPackageTravelDate[] = []

  get hasSpecialOptions(): boolean {
    return this.escortInc || this.guideInc || this.driverInc || this.isAK;
  }
  get formattedDuration(): string {
    return this.duration + (this.durationUDM ? ' ' + this.durationUDM : '');
  }

  constructor(data?:IResExtendJourneyPackage) {
    super()
    const sanitizer = AppModule.injector.get(DomSanitizer);
    this.internalType = ResExtendJourneyPackage.internalType;
    if(data) {
      this.updateData(data)
      if(data.TravelDates) this.travelDates = data.TravelDates.map((travelDate) => new ResPackageTravelDate(travelDate))
    }
    if(!this.packageImage || this.packageImage==='') this.packageImage = '/assets/svg/extend-journey-logo-AK-gray.svg'
    this.packageImageUrl = sanitizer.bypassSecurityTrustResourceUrl(`url("${this.packageImage}")`)
    this.ecmMapUrl= sanitizer.bypassSecurityTrustResourceUrl(this.ecmMap)
    this.ecmPDFUrl= sanitizer.bypassSecurityTrustResourceUrl(this.ecmPDF)
  }
}
export class ResExtendJourneyVoyage extends ResExtendJourneyType {
  static internalType:ResExtendJourneyClassType = ResExtendJourneyEnumType.Voyage
  voyageID = 0;
  voyageName = ''
  voyageNumber = ''
  voyageImage = ''
  shipID = 0;
  ship = ''
  embarkDate?: DateTime
  debarkDate?: DateTime
  duration = ''
  embarkCityID = 0;
  cityFrom = ''
  debarkCityID = 0;
  cityTo = ''
  destination = ''
  currency = ''
  priceTypeID = 0;
  priceTypeName = ''
  sellingPrice = 0;
  portCharge = 0
  isBundle = false;

  constructor(data?:IResExtendJourneyVoyage) {
    super()
    this.internalType = ResExtendJourneyVoyage.internalType;
    if(data) this.updateData(data)
  }
}
export class ResExtendJourney {
  key:string='';
  type:ResExtendJourneyClassType=ResExtendJourneyEnumType.Divisor;
  item:ResExtendJourneyPackage|ResExtendJourneyVoyage|ResExtendJourneyDivisor = new ResExtendJourneyDivisor({title:'No Data'});

  get itemPkg():ResExtendJourneyPackage {
    return this.item as ResExtendJourneyPackage
  }
  get itemVoy():ResExtendJourneyVoyage {
    return this.item as ResExtendJourneyVoyage
  }
  get itemDiv():ResExtendJourneyDivisor {
    return this.item as ResExtendJourneyDivisor
  }

  constructor(type:ResExtendJourneyClassType,data:IResExtendJourneyPackage|IResExtendJourneyVoyage|IResExtendJourneyDivisor) {
    this.type = type;
    if(data) {
      switch(type) {
        case 'package':
          const datap = data as IResExtendJourneyPackage
          this.key = `${datap.PackageID}`;
          this.item = new ResExtendJourneyPackage(datap);
          break;
        case 'voyage':
          const datav = data as IResExtendJourneyVoyage
          this.key = `${datav.VoyageID}`;
          this.item = new ResExtendJourneyVoyage(datav as IResExtendJourneyVoyage);
          break;
        default:
          const datad = data as IResExtendJourneyDivisor
          this.key = uuid();
          this.item = new ResExtendJourneyDivisor(datad);
          break;
      }
    }
  }
}
