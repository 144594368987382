import { SafeBaseObject } from "@app/core/models/base-object.models";
import { DateTime } from "luxon";

export interface IResExchangeTypeCurrencyRate {
    FXTableTypeID?: number;
    TypeDescription?: string;
}
export class ResExchangeTypeCurrencyRate extends SafeBaseObject {
    FXTableTypeID = 0
    typeDescription = ''

    constructor(data?: IResExchangeTypeCurrencyRate) {
        super();
        if (data) {
            super.updateData(data);
            this.addProperty('FXTableTypeID', data, 'FXTableTypeID')
        }
    }
}

export interface IResApiGetExchangeRateParams {
    TypeID?: number;
    Year?: string;
    Currency?: string
    DateFrom?: Date;
    DateTo?: Date;
}

export interface IResExchangeRates {
    Currency?: string;
    FCtoUSD?: number;
    USDtoFC?: number;
    StartDate?: DateTime;
    EndDate?: DateTime;
    Year?: number;
    Type?: string;
}

export class ResExchangeRates extends SafeBaseObject {
    currency = ''
    FCtoUSD = 0
    USDtoFC = 0
    startDate = DateTime.invalid('empty')
    endDate = DateTime.invalid('empty')
    year = 0
    type = ''

    constructor(data?: IResExchangeRates) {
        super();
        if (data) {
            super.updateData(data);
            this.addDateTimeProperty("startDate", data, "StartDate");
            this.addDateTimeProperty("endDate", data, "EndDate");
            this.addProperty('FCtoUSD', data, 'FCtoUSD');
            this.addProperty('USDtoFC', data, 'USDtoFC');
        }
    }
}

export interface IResApiManageExchangeRateTableParams {
    DateFrom?: Date;
    DateTo?: Date;
    CurrencyTable?: IResApiManageCurrencyTable[]
    TypeID?: number;
}
export interface IResApiManageCurrencyTable {
    Currency?: string;
    FCtoUSD?: string;
    USDtoFC?: string;
}
