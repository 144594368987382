import { SafeBaseObject } from "@app/core/models/base-object.models";
import { DateTime } from "luxon";
import { IReservationCertificateType } from "./res-cached-data.models";

export class ResBookingCertificate extends SafeBaseObject {
  certificateID: number;
  individualID: number;
  bkgID: number;
  externalBkgID: string;
  insertBkgID: number;
  serviceTypeID: number;
  serviceTypeName: string;
  serviceID: number;
  serviceName: string;
  optionID: number;
  optionName: string;
  issueDate: DateTime
  expirationDate: DateTime
  notes: string;
  certificateTypeCod: string;
  certificateTypeDesc: string;
  currencyCod: string;
  attachedFile: string;
  canEdit: boolean;
  guest: string // name of the certificate owner
  guestCod: string; // guests that can use this certificate
  highlight: boolean;

  certificateTotalAmount: number;
  certificateTotalUsed: number;
  certificateTotalAmountLeft: number;

  bookingTotalAvailable: number;
  bookingTotalUsed: number;
  bookingAvailable: number;

  formattedCertificateTotalAmount: string = "";
  formattedCertificateTotalUsed: string = "";
  formattedCertificateTotalAmountLeft: string = "";

  formattedBookingTotalAvailable: string = "";
  formattedBookingTotalUsed: string = "";
  formattedBookingAvailable: string = "";

  formattedIssueDate: string = "";
  formattedExpirationDate: string = "";

  constructor(data?: IResApiBookingCertificate) {
    super();

    this.certificateID = 0;
    this.individualID = 0;
    this.bkgID = 0;
    this.externalBkgID = "";
    this.insertBkgID = 0;
    this.serviceTypeID = 0;
    this.serviceTypeName = "";
    this.serviceID = 0;
    this.serviceName = "";
    this.optionID = 0;
    this.optionName = "";
    this.issueDate = DateTime.local();
    this.expirationDate = DateTime.local();
    this.notes = "";
    this.certificateTypeCod = "";
    this.certificateTypeDesc = "";
    this.currencyCod = "";
    this.attachedFile = "";
    this.canEdit = false;
    this.guest = "";
    this.guestCod = "";
    this.highlight = false;

    this.certificateTotalAmount = 0;
    this.certificateTotalUsed = 0;
    this.certificateTotalAmountLeft = 0;

    this.bookingTotalAvailable = 0;
    this.bookingTotalUsed = 0;
    this.bookingAvailable = 0;

    if (data) this.updateData(data);
  }
  override updateData(data: IResApiBookingCertificate): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('canEdit', data, 'CanEdit')
    this.addBooleanProperty('highlight', data, 'Highlight')
    this.addDateTimeProperty('issueDate', data, 'IssueDate')
    this.addDateTimeProperty('expirationDate', data, 'ExpirationDate')
  }

  /* canBeUsedByGuest(guestCod: string): boolean {
    if (!this.guestCod) return false;
    return this.guestCod.toLowerCase() == 'all' || this.guestCod.split(',').map(c => c.trim()).includes(guestCod.toString())
  } */

}

export interface IResApiBookingCertificate {
  CertificateID: number;
  IndividualID: number;
  BkgID: number;
  ExternalBkgID: string;
  InsertBkgID: number;
  ServiceTypeID: number;
  ServiceTypeName: string;
  ServiceID: number;
  ServiceName: string;
  OptionID: number;
  OptionName: string;
  IssueDate: string;
  ExpirationDate: string;
  Notes: string;
  CertificateTypeCod: string;
  CertificateTypeDesc: string;
  MaxAmountAvailable: number;
  TotalAmount: number;
  TotalAmountLeft: number;
  CurrencyCod: string;
  AttachedFile: string;
  CanEdit: string;
  Guest: string;
  GuestCod: string;
  Highlight: string;
}

export class ResBookingCertificateAvailableAmount extends SafeBaseObject {
  guestCod: string;
  certificateTypeCod: string;
  maxAmount: number;

  constructor(data?: IResApiBookingCertificateAvailableAmount) {
    super();

    this.guestCod = "";
    this.certificateTypeCod = "";
    this.maxAmount = 0;

    if (data) this.updateData(data);
  }
  override updateData(data: IResApiBookingCertificateAvailableAmount): void {
    this.addMangledProperty(data);
  }
}

export interface IResApiBookingCertificateAvailableAmount {
  GuestCod: string;
  CertificateTypeCod: string;
  MaxAmount: number;
}

export class ResBookingCertificateDetail extends SafeBaseObject {
  amount: number;
  bkgID: number;
  certificateID: number;
  conversionRate: number;
  created: DateTime;
  createdBy: string;
  currencyCod: string;
  guestCod: string;
  guest: string;

  formattedAmount: string = "";
  formattedCreated: string = "";

  constructor(data?: IResApiBookingCertificateDetail) {
    super();

    this.amount = 0;
    this.bkgID = 0;
    this.certificateID = 0;
    this.conversionRate = 0;
    this.created = DateTime.local();
    this.createdBy = "";
    this.currencyCod = "";
    this.guestCod = "";
    this.guest = "";

    if (data) this.updateData(data);
  }
  override updateData(data: IResApiBookingCertificateDetail): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('created', data, 'Created')
  }
}
export class ResCertificateType extends SafeBaseObject {
  certificateTypeID: number;
  certificateTypeCod: string;
  certificateTypeDesc: string;
  expirationDate: DateTime

  constructor(data?: IReservationCertificateType) {
    super();

    this.certificateTypeID = 0;
    this.certificateTypeCod = "";
    this.certificateTypeDesc = "";
    this.expirationDate = DateTime.local();

    if (data) this.updateData(data);
  }
  override updateData(data: IReservationCertificateType): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('expirationDate', data, 'ExpirationDate')
  }
}

export interface IResApiBookingCertificateDetail {
  Amount: number;
  BkgID: number;
  CertificateID: number;
  ConversionRate: number;
  Created: string;
  CreatedBy: string;
  CurrencyCod: string;
  GuestCod: string;
  Guest: string;
}

export interface IResApiCreateBookingCertificate {
  IndividualId?: number;
  BkgID?: number;
  ExternalBkgID?: string;
  InsertBkgID?: number;
  IssueDate?: string;
  ExpirationDate?: string;
  Notes?: string;
  CertificateTypeCod: string;
  Amount?: number;
  CurrencyCod?: string;
  AttachedFile?: string;
}

export interface IResApiUpdateBookingCertificate extends IResApiCreateBookingCertificate {
  CertificateID: number;
}

export interface IResApiGetBookingCertificateDetail {
  BkgID?: number;
  GuestCod?: string;
  OptionID?: number;
  CertificateID?: number;
}

export interface IResApiSearchCertificate {
  CertificateID?: number;
  BkgID?: number;
  FirstName?: string;
  LastName?: string;
  ExternalBkgID?: string;
}

export interface IResApiImportCertificate {
  Import: { CertificateID: number, BkgID: number }[]
}