import { NgModule } from '@angular/core';

import {
  DxListModule,
  DxButtonModule,
  DxTagBoxModule,
  DxRangeSliderModule,
  DxTextBoxModule,
  DxDateBoxModule,
  DxCalendarModule,
  DxSliderModule,
  DxSwitchModule,
  DxDataGridModule,
  DxSortableModule,
  DxTabPanelModule,
  DxTemplateModule,
  DxTabsModule,
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxLoadIndicatorModule,
  DxScrollViewModule,
  DxAccordionModule,
  DxTreeViewModule,
  DxPopupModule,
  DxNumberBoxModule,
  DxTextAreaModule,
  DxDropDownButtonModule,
  DxTooltipModule,
  DxToolbarModule,
  DxDrawerModule,
  DxValidatorModule,
  DxPopoverModule,
  DxValidationGroupModule,
  DxRadioGroupModule
} from 'devextreme-angular';

@NgModule({
  exports: [
    DxListModule,
    DxButtonModule,
    DxRangeSliderModule,
    DxTagBoxModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxCalendarModule,
    DxSliderModule,
    DxSwitchModule,
    DxDataGridModule,
    DxSortableModule,
    DxTabPanelModule,
    DxTemplateModule,
    DxTabsModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxLoadIndicatorModule,
    DxTreeViewModule,
    DxAccordionModule,
    DxScrollViewModule,
    DxPopupModule,
    DxNumberBoxModule,
    DxTextAreaModule,
    DxDropDownButtonModule,
    DxTooltipModule,
    DxToolbarModule,
    DxDrawerModule,
    DxValidatorModule,
    DxPopoverModule,
    DxValidationGroupModule,
    DxRadioGroupModule
  ],
  providers: [
  ],
})
export class DevExpressModule { }
