import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { IEidosIcon } from '@app/core/config/environment.interface';
import { EidosIconType } from '@app/core/models/core-constant.model';
import { EidosCommandTypes, IEidosCommandPanelItem, IEidosCommandStatus, IEidosCommandTooltip } from './eidos-command-panel.model';
import { EidosCommandPanelService } from './eidos-command-panel.service';

@Component({
  selector: 'eidos-command-panel',
  templateUrl: './eidos-command-panel.component.html',
  styleUrls: ['./eidos-command-panel.component.scss']
})
export class EidosCommandPanelComponent implements OnInit {

  EidosCommandTypes = EidosCommandTypes;

  public title: SafeHtml;
  public titlePosition: 'Left' | 'Middle' | 'Right' = 'Left';
  public leftActions$: BehaviorSubject<Array<IEidosCommandPanelItem>>;
  public rightActions$: BehaviorSubject<Array<IEidosCommandPanelItem>>;

  public status$: BehaviorSubject<IEidosCommandStatus>;

  public get historyBackDisabled() {
    return !this.eidosCommandPanelService.validHistoryBack;
  }
  public get historyForwardDisabled() {
    return !this.eidosCommandPanelService.validHistoryForward;
  }
  public defaultIcon = {
    iconType: EidosIconType.Awesome,
    iconCode: 'fa-exclamation',
    iconSize: 1,
  } as IEidosIcon;
  constructor(
    private eidosCommandPanelService: EidosCommandPanelService,
    private domSanitizer: DomSanitizer,
  ) {

    this.leftActions$ = this.eidosCommandPanelService.leftActions;
    this.rightActions$ = this.eidosCommandPanelService.rightActions;

    this.status$ = this.eidosCommandPanelService.status;
    this.title = '';

    this.eidosCommandPanelService.title.subscribe(t => this.title = this.domSanitizer.bypassSecurityTrustHtml(t));
    this.eidosCommandPanelService.titlePosition.subscribe(tp => this.titlePosition = tp);
  }

  ngOnInit(): void {
  }
  getClasses(action: IEidosCommandPanelItem,dropDownItem?:boolean): object {
    if (!action.class) return { disabled: action.isDisabled ? action.isDisabled() : false, 'dropdown-item':dropDownItem ?? false };

    if (_.isString(action.class)) {
      let o: any = {};
      o[action.class] = true;
      o['dropdown-item']=dropDownItem ?? false;
      o.disabled = action.isDisabled ? action.isDisabled() : false;
      return o;
    }

    return Object.assign({}, action.class, { disabled: action.isDisabled ? action.isDisabled() : false });
  }
  getTooltipMessage(tooltip: IEidosCommandTooltip) {
    if (_.isFunction(tooltip.message)) return tooltip.message();
    return tooltip.message;
  }
  execBack() {
    this.eidosCommandPanelService.goBack();
  }
  execForward() {
    this.eidosCommandPanelService.goForward();
  }
  execCallback(event: any, self: IEidosCommandPanelItem) {

    let isPromise: void | Promise<boolean> = void (0);
    try {
      this.eidosCommandPanelService.statusStartWorking(self);
      isPromise = self.callbackAddParameters ? self.callback(event, self) : self.callback();
      if (isPromise) {
        //promise
        isPromise.then(() => { })
          .catch(error => {
            this.eidosCommandPanelService.notifyerror(error, self);
          })
          .finally(() => {
            this.eidosCommandPanelService.statusStopWorking(self);
          })
      }
    }
    catch (error) {
      this.eidosCommandPanelService.notifyerror(error, self);
    }
    finally {
      if (!isPromise) this.eidosCommandPanelService.statusStopWorking(self);
    }
  }
}
