import { SafeBaseObject } from "@app/core/models/base-object.models";
import { DateTime } from "luxon";
import { IReservationApiGeotreeType } from "./res-cached-data.models";
import { BookingGuest, IReservationApiBookingGuest, ResGuestType } from "./res-booking.model";

export class ResGroups {
  id = 0
  description = ''
  code = ''
  attributes:any[]=[]
}
export class ResGuestGroup extends SafeBaseObject  {
  groupGuestID = 0
  bkgID = 0
  paxGroupID = 0
  guestID = 0
  title = ''
  lastName = ''
  firstName = ''
  individualID = 0
  guestType = ''
  payerGroupID = 0

  middleName = ''
  gender = ''
  individualType = ''
  country = ''
  householdId = 0

  constructor(data?: any) {
    super();

    if (data) {
      super.updateData(data)
      this.updateData(data);
    }
  }

  override updateData(_: any) {
  }
}
export class ResPayerGroup extends SafeBaseObject  {
  payerGroupID = 0
  payerGroupName = ''
  payerGroupType = ''

  get description() {
    let groupNameExpanded=''
    if(this.payerGroupID===-1) {
      groupNameExpanded=`[BKG OWNER]`
    } else {
      groupNameExpanded=`[${this.payerGroupType} ${this.payerGroupID}]`
    }
    return `${this.payerGroupName} ${groupNameExpanded}`
  }

  constructor(data?: any) {
    super();

    if (data) {
      super.updateData(data)
      this.updateData(data);
    }
  }

  override updateData(_: any) {
  }
}
export class ResPayerDepartment extends SafeBaseObject {
  paymentDepartmentID	= 0
  companyID	= 0
  paymentDepartment = ''
  costCenter = ''
  GLAccount = ''

  get description() {
    return `${this.paymentDepartment} [${this.costCenter} - ${this.paymentDepartmentID}]`
  }

  constructor(data?: any) {
    super();

    if (data) {
      super.updateData(data)
      this.updateData(data);
    }
  }

  asResPayerGroup() {
    const payer = new ResPayerGroup()

    payer.payerGroupID = this.paymentDepartmentID
    payer.payerGroupName = this.paymentDepartment
    payer.payerGroupType = this.costCenter

    return payer
  }

  override updateData(_: any) {
  }
}
export class ReservationAccomodation  {
  Adult = 2
  Child = 0
  Child2 = 0
  Infant = 0
  RoomID = 1
  SuiteNumber?: number;
  ServiceTypeID?:number;
  Guests:BookingGuest[]=[]
  PayerID = 0
  PropertyID = 0
  ServiceID = 0
  OptionID = 0

  SellingPrice = 0
  Cost = 0
  ROE = 0
  OnRequest = false
  Markup = 0
  Selected = false


  get filledGuests() {
    return this.Guests.filter(g=>!g.isPlaceHolder).length
  }
  static newRoom(roomID:number,serviceTypeID?:number) {
    const n = new ReservationAccomodation()
    n.RoomID=roomID
    n.ServiceTypeID=serviceTypeID
    return n
  }
  get minOccupancy() {
    return this.Adult+this.Child+this.Child2
  }
  get groupSize() {
    return this.Adult+this.Child+this.Child2+this.Infant
  }

  clone() {
    const n = new ReservationAccomodation()
    n.Adult=this.Adult
    n.Child=this.Child
    n.Infant=this.Infant
    n.ServiceTypeID = this.ServiceTypeID
    this.Guests.forEach( g=> n.Guests.push(g.cloneForAccomodation()))
    return n
  }
  static syncGuestPlaceholder(accomodation: ReservationAccomodation,startGuestCod = 0) {
    let tempGuestCod=startGuestCod
    for(let i=accomodation.Guests.filter(g=>g.guestType===ResGuestType.Adult).length+1; i<=accomodation.Adult; i++) {
      tempGuestCod++
      const guest = BookingGuest.newPlaceholder(tempGuestCod)
      guest.firstName = i.toString()
      guest.guestType = ResGuestType.Adult
      guest.roomID = accomodation.RoomID
      accomodation.Guests.push(guest)
    }
    for(let i=accomodation.Guests.filter(g=>g.guestType===ResGuestType.Child).length+1; i<=accomodation.Child; i++) {
      tempGuestCod++
      const guest = BookingGuest.newPlaceholder(tempGuestCod)
      guest.firstName = i.toString()
      guest.guestType = ResGuestType.Child
      guest.roomID = accomodation.RoomID
      accomodation.Guests.push(guest)
    }
    for(let i=accomodation.Guests.filter(g=>g.guestType===ResGuestType.Child2).length+1; i<=accomodation.Child2; i++) {
      tempGuestCod++
      const guest = BookingGuest.newPlaceholder(tempGuestCod)
      guest.firstName = i.toString()
      guest.guestType = ResGuestType.Child2
      guest.roomID = accomodation.RoomID
      accomodation.Guests.push(guest)
    }
    for(let i=accomodation.Guests.filter(g=>g.guestType===ResGuestType.Infant).length+1; i<=accomodation.Infant; i++) {
      tempGuestCod++
      const guest = BookingGuest.newPlaceholder(tempGuestCod)
      guest.firstName = i.toString()
      guest.guestType = ResGuestType.Infant
      guest.roomID = accomodation.RoomID
      accomodation.Guests.push(guest)
    }
  }
  static createFromGroupGuests(data:ResGuestGroup[]) {
    const accomodations:ReservationAccomodation[] = []
    const groups = new Set<number>()
    data.forEach(item=> groups.add(item.paxGroupID))

    groups.forEach(g=>{
      const room = ReservationAccomodation.newRoom(g)
      room.Adult = data.filter(item=>item.paxGroupID===g && item.guestType==='Adult').length
      room.Child = data.filter(item=>item.paxGroupID===g && item.guestType==='Child').length
      room.Child2 = data.filter(item=>item.paxGroupID===g && item.guestType==='Child2').length
      room.Infant = data.filter(item=>item.paxGroupID===g && item.guestType==='Infant').length
      room.PayerID = data.find(item=>item.paxGroupID===g)?.payerGroupID ?? -1
      data.filter(item=>item.paxGroupID===g).forEach(g=>{
        // const skip = room.Guests.find(item=>item.individualID===g.individualID || (item.isPlaceHolder && item.firstName === g.firstName && item.lastName === g.lastName && g.paxGroupID && g.paxGroupID))
        // if(skip) return
        room.Guests.push(new BookingGuest({
          IndividualID: g.individualID,
          GuestID: g.guestID,
          Title: g.title,
          LastName: g.lastName,
          FirstName: g.firstName,
          MiddleName: g.middleName,
          Gender: g.gender,
          GuestType: g.guestType,
          IndividualType: g.individualType,
          Country: g.country,
          household_id: g.householdId,
          IsPlaceHolder: g.lastName === 'QUOTE' ? 'Y' : 'N'
        } as unknown as IReservationApiBookingGuest))
      })
      accomodations.push(room)
    })
    return accomodations
  }

}

export class ReservationSetupService extends SafeBaseObject {
  address?: string;
  brandID?: number;
  city?: string;
  cityID?: number;
  companyID?: number;
  country?: string;
  countryCod?: string;
  countyCod?: string;
  currency?: string;
  discontinueDays?: number;
  discontinueDaysCru?: number;
  hasIncluded?: boolean;
  serviceDescription?: string;
  priceSetupID?: number;
  serviceID?: number;
  serviceLongName?: string;
  serviceLedGerCode?: string;
  serviceShortName?: string;
  serviceTermsInclusions?: string;
  serviceTermsExclusions?: string;
  serviceTypeID?: number;
  serviceTypeName?: string;
  siteID?: number;
  stateCod?: string;
  status?: string;
  supplierID?: number;
  supplierName?: string;
  zip?: string;
  transferIDFrom?: number;
  transferIDTo?: number;
  transferTypeIDFrom?: number;
  transferTypeIDTo?: number;
  pierFrom?: string;
  pierTo?: string;
  hotelUniqueID?: number;
  hotelName?: string;
  geoTreeID?: number;
  latitude?: number;
  longitude?: number;
  nearestAirportID?: number;
  longDescription?: string;

  serviceIDTS?: number;
  companyIDTS?: number;

  get formattedStatus(): string {
    switch (this.status) {
      case 'A': return 'Active';
      case 'D': return 'Deleted';
      default: return '';
    }
  }

  constructor(data?: IReservationApiSetupService) {
    super();

    this.hasIncluded = false;
    this.status = 'A';

    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiSetupService): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('hasIncluded', data, 'HasIncluded');
  }

  // get allCompaniesAsString(): string {
  //   return this.companies.map((c) => c.companyName).join(', ');
  // }
}

export interface IReservationApiSetupService {
  Address?: string;
  City?: string;
  CityID?: number;
  Companies?: IReservationSetupServiceCompanies[];
  Country?: string;
  CountryCod?: string;
  CountyCod?: string;
  Currency?: string;
  DiscontinueDays?: string;
  DiscontinueDaysCru?: string;
  HasIncluded?: string;
  PriceSetupID?: number;
  ServiceDescription?: string;
  ServiceID?: number;
  ServiceLongName?: string;
  ServiceLedGerCode?: string;
  ServiceShortName?: string;
  ServiceTermsInclusions?: string;
  ServiceTermsExclusions?: string;
  ServiceTypeID?: number;
  ServiceTypeName?: string;
  SiteID?: number;
  StateCod?: string;
  Status?: string;
  SupplierID?: number;
  SupplierName?: string;
  Zip?: string;
  TransferIDFrom?: number;
  TransferIDTo?: number;
  TransferTypeIDFrom?: number;
  TransferTypeIDTo?: number;
  PierFrom?: string;
  PierTo?: string;
  HotelUniqueID?: number;
  HotelName?: string;
  GeoTreeID?: number;
  Latitude?: number;
  Longitude?: number;
  NearestAirportID?: number;
  LongDescription?: string;
}

export interface IReservationSetupServiceCompanies {
  CompanyID: number;
  CompanyName: string;
  ServiceID?: number;
}
export class ReservationSetupOption extends SafeBaseObject {
  brandID?: number;
  commissionable?: boolean;
  isSBC?: boolean;
  maxPax?: number;
  minPax?: number;
  optionDesc?: string;
  optionID?: number;
  optionName?: string;
  optionShortName?: string;
  optionTypeID?: number;
  optionType?: string;
  refundable?: boolean;
  serviceID?: number;
  serviceName?: string;
  serviceTypeName?: string;
  status?: string;
  taxTypeID?: number;
  taxValue?: number;
  Flag?: string;
  priceSetupID?: number;

  get formattedStatus(): string {
    switch (this.status) {
      case 'A': return 'Active';
      case 'D': return 'Deleted';
      default: return '';
    }
  }

  constructor(data?: IReservationApiSetupOption) {
    super();

    this.commissionable = false;
    this.isSBC = false;
    this.refundable = false;

    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiSetupOption): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('commissionable', data, 'Commissionable');
    this.addBooleanProperty('isSBC', data, 'IsSBC');
    this.addBooleanProperty('refundable', data, 'Refundable');
  }
}

export class ReservationSetupSequenceDiscount extends SafeBaseObject {
  brandID?: number;
  commissionable?: boolean;
  isSBC?: boolean;
  maxPax?: number;
  minPax?: number;
  optionDesc?: string;
  optionID?: number;
  optionName?: string;
  optionShortName?: string;
  optionTypeID?: number;
  optionType?: string;
  refundable?: boolean;
  serviceID?: number;
  serviceName?: string;
  serviceTypeName?: string;
  status?: string;
  taxTypeID?: number;
  taxValue?: number;
  Flag?: string;
  priceSetupID?: number;
  optionCod?: number;
  sequenceDiscount?: number
  placedAfter?: string;

  get formattedStatus(): string {
    switch (this.status) {
      case 'A': return 'Active';
      case 'D': return 'Deleted';
      default: return '';
    }
  }

  constructor(data?: IReservationApiSetupSequenceDiscount) {
    super();

    this.commissionable = false;
    this.isSBC = false;
    this.refundable = false;

    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiSetupSequenceDiscount): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('commissionable', data, 'Commissionable');
    this.addBooleanProperty('isSBC', data, 'IsSBC');
    this.addBooleanProperty('refundable', data, 'Refundable');
  }
}

export interface IReservationApiSetupSequenceDiscount {
  BrandID?: number;
  Commissionable?: string;
  IsSBC?: string;
  MaxPax?: number;
  MinPax?: number;
  OptionDesc?: string;
  OptionID?: number;
  OptionName?: string;
  OptionShortName?: string;
  OptionTypeID?: number;
  OptionType?: string
  Refundable?: string;
  ServiceID?: number;
  ServiceName?: string;
  ServiceTypeName?: string;
  Status?: string;
  TaxTypeID?: number;
  TaxValue?: number;
  PriceSetupID?: number;
  OptionCod?: number;
  SequenceDiscount?: number;
  PlacedAfter?: string;
}

export interface IReservationApiSetupOption {
  BrandID?: number;
  Commissionable?: string;
  IsSBC?: string;
  MaxPax?: number;
  MinPax?: number;
  OptionDesc?: string;
  OptionID?: number;
  OptionName?: string;
  OptionShortName?: string;
  OptionTypeID?: number;
  OptionType?: string
  Refundable?: string;
  ServiceID?: number;
  ServiceName?: string;
  ServiceTypeName?: string;
  Status?: string;
  TaxTypeID?: number;
  TaxValue?: number;
  PriceSetupID?: number;
}

export class ReservationSetupServiceOption extends SafeBaseObject {
  brandID?: number;
  commissionableSO: boolean;
  commissionable: boolean;
  cityID?: number;
  city?: string;
  isSBC: boolean;
  maxPax: number;
  minPax: number;
  optionDesc: string;
  optionID?: number;
  optionName: string;
  optionShortName: string;
  refundable: boolean;
  isIncluded: string;
  status: string;
  taxTypeID?: number;
  taxValue?: number;
  currency: string;
  endDate?: DateTime;
  message: string;
  priceSetupID?: number;
  priceSetup?: string;
  serviceID?: number;
  serviceName: string;
  startDate?: DateTime;
  discount?: number;
  discountType?: string;
  selfIncluded?: boolean;
  /**
   * Service2Option not inclusive ID
   *
   * @type {number}
   * @memberof ReservationSetupServiceOption
   */
  serviceOptionID?: number;
  /**
   * Service2Option inclusive ID
   *
   * @type {number}
   * @memberof ReservationSetupServiceOption
   */
  serviceToServiceOptionID?: number;
  /**
   * Service type of the Service2Option Service
   *
   * @type {number}
   * @memberof ReservationSetupServiceOption
   */
  serviceTypeIDSO?: number;

  get formattedStartDate(): string {
    if (DateTime.isDateTime(this.startDate) && this.startDate.isValid) {
      return this.startDate?.toFormat("dd MMM yyyy");
    }
    return "";
  }

  get formattedEndDate(): string {
    if (DateTime.isDateTime(this.endDate) && this.endDate.isValid) {
      return this.endDate.toFormat("dd MMM yyyy");
    }
    return "";
  }

  get formattedStatus(): string {
    switch (this.status) {
      case 'A': return 'Active';
      case 'D': return 'Deleted';
      default: return '';
    }
  }

  get seats(): string {
    return (this.maxPax ?? 0) + ' seats';
  }

  constructor(data?: IReservationApiSetupServiceOption) {
    super();

    this.commissionableSO = false;
    this.commissionable = false;
    this.isSBC = false;
    this.isIncluded = 'N';
    this.maxPax = 0;
    this.minPax = 0;
    this.optionDesc = '';
    this.optionName = '';
    this.optionShortName = '';
    this.refundable = false;
    this.status = '';
    this.currency = '';
    this.message = '';
    this.serviceName = '';

    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiSetupServiceOption): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('commissionableSO', data, 'CommissionableSO');
    this.addBooleanProperty('commissionable', data, 'Commissionable');
    this.addBooleanProperty('isSBC', data, 'IsSBC');
    this.addBooleanProperty('refundable', data, 'Refundable');
    this.addBooleanProperty('isIncluded', data, 'IsIncluded');
    this.addBooleanProperty('selfIncluded', data, 'SelfIncluded');
    this.addDateTimeProperty('endDate', data, 'EndDate');
    this.addDateTimeProperty('startDate', data, 'StartDate');

  }

  get available(): boolean {
    return this.status?.toLowerCase() === 'a';
  }

  get deleted(): boolean {
    return this.status?.toLowerCase() === 'd';
  }
}

export interface IReservationApiSetupServiceOption extends IReservationApiSetupOption {
  Currency?: string;
  CommissionableSO?: string;
  Commissionable?: string;
  CityID?: number;
  City?: string;
  EndDate?: string;
  Message?: string;
  PriceSetupID?: number;
  PriceSetup?: string;
  ServiceID?: number;
  ServiceName?: string;
  StartDate?: string;
  SelfIncluded?: string;
  /**
   * Y for Service2Option inclusive
   * N for Service2Option not inclusive
   *
   * @type {string}
   * @memberof IReservationApiSetupServiceOption
   */
  IsIncluded?: string;
  /**
   * Service2Option not inclusive ID
   *
   * @type {number}
   * @memberof IReservationApiSetupServiceOption
   */
  ServiceOptionID?: number;
  /**
   * Service2Option inclusive ID
   *
   * @type {number}
   * @memberof IReservationApiSetupServiceOption
   */
  ServiceToServiceOptionID?: number;
  /**
   * Service type of the Service2Option Service
   *
   * @type {number}
   * @memberof IReservationApiSetupServiceOption
   */
  ServiceTypeIDSO?: number;
}

export class ReservationSetupServiceOptionInclusiveDetail extends SafeBaseObject {
  currencyCod?: string;
  discount?: number;
  discountType?: string;
  endDate?: DateTime;
  itineraryDate?: DateTime;
  priceSetupID?: number;
  priceTypeID?: number;
  serviceToServiceOptionID?: number;
  startDate?: DateTime;
  status: string;
  prePostFlag?: string;

  // when isPercentage is true, this will contain all ReservationSetupServiceOptionInclusiveDetail given from the API, with the same priceTypeID
  allCurrenciesPercentageDetails: { serviceToServiceOptionID?: number, currencyCod?: string }[] = [];

  get formattedStatus(): string {
    switch (this.status) {
      case 'A': return 'Active';
      case 'D': return 'Deleted';
      default: return '';
    }
  }

  get isSaved(): boolean {
    return this.serviceToServiceOptionID != null
  }

  get isPercentage(): boolean {
    return this.discountType?.toLocaleLowerCase() === 'percentage';
  }

  get isAvailable(): boolean {
    return this.status?.toLowerCase() === 'a';
  }

  get isDeleted(): boolean {
    return this.status?.toLowerCase() === 'd';
  }

  constructor(data?: IReservationApiSetupServiceOptionInclusiveDetail) {
    super();
    this.status = 'A';

    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiSetupServiceOptionInclusiveDetail): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('itineraryDate', data, 'ItineraryDate');
    this.addDateTimeProperty('endDate', data, 'EndDate');
    this.addDateTimeProperty('startDate', data, 'StartDate');
  }
}

export interface IReservationApiSetupServiceOptionInclusiveDetail {
  CurrencyCod?: string;
  Discount?: number;
  DiscountType?: string;
  EndDate?: string;
  ItineraryDate?: string;
  PriceSetupID?: number;
  PriceTypeID?: number;
  ServiceToServiceOptionID?: number;
  StartDate?: string;
  Status?: string;
  PrePostFlag?: string;
}

export interface IReservationApiManageServiceOptionInclusive {
  ServiceID?: number,
  OptionID?: number,
  ServiceIDOption?: number,
  isUpd?: string
  Status?: string,
  Setup: IReservationApiManageServiceOptionInclusiveDetail[]
}

interface IReservationApiManageServiceOptionInclusiveDetail {
  ServiceToServiceOptionID?: number | null,
  ItineraryDate?: DateTime,
  DiscountType?: string,
  Discount?: number,
  CurrencyCod?: string,
  PriceTypeID?: number,
  PriceSetUpID?: number,
  StartDate?: string,
  EndDate?: string,
  PrePostFlag?: string,
  Status: string,
}

//Geotree
export interface IReservationGeotreeCitySelect {
  City?: string;
  CityID?: number;
  CitySearchable?: boolean;
  Country?: string;
  CountryID?: number;
  GeoTreeID?: number;
  GeoTreeName?: string;
  IsCity?: boolean;
  IsPOI?: boolean;
  IsPort?: number;
  PortCod?: string
}

export interface IReservationApiGeotreeCitySelectParams {
  City?: string;
  CountryID?: number;
  PageSize?: number;
  PageNum?: number;
}

export class ReservationGeotreeCitySelect extends SafeBaseObject {
  city: string = '';
  cityID: number = 0;
  citySearchable: boolean = true;
  country: string = '';
  countryID: number = 0;
  geoTreeID: number = 0;
  geoTreeName: string = '';
  isCity: boolean = true;
  isPOI = false
  isPort = false;
  portCod = ''

  constructor(data?: IReservationGeotreeCitySelect) {
    super();
    if (data) {
      this.updateData(data);
      this.addBooleanProperty('isPort', data, 'IsPort');
    }
  }
}

export interface IReservationGeotreeCity extends SafeBaseObject {
  City?: string;
  CityID?: number;
  CitySearchable?: boolean;
  Country?: string;
  CountryID?: number;
  IsCity?: boolean;
  CityCity?: string;
  CityRgn?: string;
  CityCruise?: string;
  CityCod?: string;
  PortCod?: string;
  CityStatusID?: number;
  CityTypeID?: number;
  Latitude?: number;
  Longitude?: number;
  CircleLatitude?: number;
  CircleLongitude?: number;
  MainIataCod?: string;
  // DestinationID?: number;
  // DestinationName?: string;
  GeoTree: any[];
  IsPOI?: boolean;
}

export class ReservationCityLikedGeoTree extends SafeBaseObject {
  cityID = 0
  departmentID = 0
  geoTreeID = 0
  geoTreeName = ''

  constructor(data?: IReservationGeotreeCity) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}
export class ReservationGeotreeCity extends SafeBaseObject {
  city: string | null = '';
  cityID: number | null = 0;
  citySearchable: boolean = true;
  country: string = '';
  countryID: number | null = 0;
  isCity: boolean = true;
  cityCity: string = '';
  cityRgn: string = '';
  cityCruise: string = '';
  cityCod: string = '';
  portCod: string = '';
  cityStatusID: number = 0;
  cityTypeID: number = 0;
  latitude: number = 0;
  longitude: number = 0;
  circleLatitude: number = 0;
  circleLongitude: number = 0;
  mainIataCod: string = '';
  isPOI: boolean = false;
  geoTree: ReservationCityLikedGeoTree[] = []


  constructor(data?: IReservationGeotreeCity) {
    super();
    if (data) {
      this.updateData(data);
      if (data.GeoTree) this.geoTree = data.GeoTree.map(g => new ReservationCityLikedGeoTree(g))
    }
  }
}

export interface IReservationGeotreeCityPicklist {
  DestinationTypeID: number
  DestinationTypeName: string
  ExternalCityID: number | null
  ExternalCity: string | null
  GeoTreeID: number
  GeoTreeName: string
}

export interface IReservationApiGeotree {
  GeoTreeID?: number;
  GeoTreeName?: string;
  GeoTreeTypeID?: number;
  DestinationTypeID?: number;
  DepartmentID?: number;
  DepartmentName?: string;
  LEVEL_1_ID?: number;
  LEVEL_2_ID?: number;
  LEVEL_3_ID?: number;
  LEVEL_4_ID?: number;
  LEVEL_5_ID?: number;
  PortCod?: string;
  CityCod?: string;
  MainIataCod?: string;
  Latitude?: number;
  Longitude?: number;
  ExternalCityID?: number;
  ExternalCountryID?: number;
  CompanyID?: number;
  SalesPriority?: number;
  DestinationPriority?: number;
  Status?: string;
  Images?: IReservationApiGeotreeImage[];
  Title?: string;
  Description?: string;
  isVisible?: boolean;
  BestTimeToGo?: string;
  TimeZone?: string;
  Breadcrumbs?: string;
}
export interface IReservationApiGeotreeImage {
  GeoTreeIMGID?: number;
  GeoTreeID?: number;
  FileID?: number;
  FileGUID?: string;
  FileName?: string;
  Status?: string;
  GeoTreeIMGType?: string;
  GeoTreeIMGTypeID?: number;
  CategoryName?: string;
  DocCatID?: number;
  ImgNote?: string;
}

export class ReservationApiGeotree extends SafeBaseObject {
  geoTreeID = 0
  geoTreeName?: string;
  geoTreeTypeID = 0
  destinationTypeID?: number;
  departmentID?: number;
  departmentName?: string;
  level1ID?: number;
  level2ID?: number;
  level3ID?: number;
  level4ID?: number;
  level5ID?: number;
  portCod?: string;
  cityCod?: string;
  mainIataCod?: string;
  latitude?: number;
  longitude?: number;
  externalCityID?: number;
  externalCountryID?: number;
  companyID?: number;
  salesPriority?: number;
  destinationPriority?: number;
  status?: string;
  images: ReservationApiGeotreeImage[] = [];
  title?: string;
  description?: string;
  isValid = false;
  isVisibleDest = false;
  isVisibleSales = false;
  bestTimeToGo?: number[] = [];
  timeZone?: number[] = [];
  breadcrumbs?: string = '';
  weatherInfo = ''
  weatherInfoParsed:any = {}

  constructor(data?: IReservationApiGeotree) {
    super();
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data?: IReservationApiGeotree): void {
    if (data) {
      super.updateData(data);
      this.addProperty('level1ID', data, 'LEVEL_1_ID');
      this.addProperty('level2ID', data, 'LEVEL_2_ID');
      this.addProperty('level3ID', data, 'LEVEL_3_ID');
      this.addProperty('level4ID', data, 'LEVEL_4_ID');
      this.addProperty('level5ID', data, 'LEVEL_5_ID');
      this.bestTimeToGo = data.BestTimeToGo ? data.BestTimeToGo.split(',').map(Number) : [];
      this.timeZone = data.TimeZone ? data.TimeZone.split(',').map(Number) : [];

      this.isValid = this.geoTreeID > 0;
      if (data.Images) {
        this.images = data.Images.map(i => new ReservationApiGeotreeImage(i));
      }
      this.weatherInfoParsed = JSON.parse(this.weatherInfo)
    }
  }
}
export class ReservationApiGeotreeImage extends SafeBaseObject {
  geoTreeIMGID?: number;
  geoTreeID?: number;
  fileID?: number;
  fileGUID?: string;
  FileGUID?: string;
  fileName?: string;
  status?: string = 'A'
  geoTreeIMGType?: string;
  geoTreeIMGTypeID?: number = 1;
  categoryName?: string;
  docCatID?: number;
  imgNote?: string;

  constructor(data?: IReservationApiGeotreeImage) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}


export class ReservationApiGeotreeType extends SafeBaseObject {
  destinationTypeID?: number;
  destinationTypeName?: string;

  constructor(data?: IReservationApiGeotreeType) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export interface IReservationApiGeotreeMovement {
  TypeOrder?: number;
  Source?: number;
  Target?: number;
  Option?: number;
}

export interface IReservationApiCreateNewServiceOrOption {
  PackageID?: number
  PackageDay?: number
  Quantity?: number
  HideInBkg?: boolean

  ServiceID?: number
  ServiceName?: string
  ServiceShortName?: string
  ServicePriceSetupID?: number
  ServiceTypeID?: number
  ServiceCityID?: number
  ServiceSupplierID?: number

  OptionID?: number
  OptionName?: string
  OptionShortName?: string
  OptionMinPax?: number
  OptionMaxPax?: number
  OptionCommissionable?: boolean
  BrandID?: number

  CompanyID?: number

}

export interface CreateServiceOptionFormValues {
  packageDay?: number;
  quantity?: number;
  serviceShortName?: string;
  serviceLongName?: string;
  priceSetupID?: number;
  companyID?: number;
  serviceTypeID?: number;
  cityID?: number;
  supplierID?: number;
  optionShortName?: string;
  optionName?: string;
  minPax?: number;
  maxPax?: number;
  serviceBrandID?: number;
  commissionable?: boolean;
  packageID?: number;
  hideInBkg?: boolean;
}

export class ReservationCreateNewServiceOrOption extends SafeBaseObject {
  packageID?: number;
  packageDay?: number;
  quantity?: number;
  hideInBkg?: boolean;

  serviceID?: number;
  serviceName?: string;
  servicePriceSetupID?: number;
  serviceTypeID?: number;
  serviceCityID?: number;
  serviceSupplierID?: number;

  optionID?: number;
  optionName?: string;
  optionShortName?: string;
  optionMinPax?: number;
  optionMaxPax?: number;
  optionCommissionable?: boolean;
  brandID?: number;

  companyID?: number;

  constructor(data?: IReservationApiCreateNewServiceOrOption) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export interface IReservationHotelBrand {
  HotelBrandID?: number;
  HotelBrandName?: string;
}

export class ReservationHotelBrand extends SafeBaseObject {
  hotelBrandID = 0;
  hotelBrandName = '';
  status?: string = 'A'

  constructor(data?: IReservationHotelBrand) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export interface IReservationHotelChain {
  HotelChainID?: number;
  HotelChainName?: string;
}

export class ReservationHotelChain extends SafeBaseObject {
  hotelChainID = 0;
  hotelChainName = '';
  status?: string = 'A'

  constructor(data?: IReservationHotelChain) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export interface IReservationAPIGetHotelBrand {
  HotelBrandID?: number;
  HotelBrandName?: string;
}

export interface IReservationAPIManageHotelBrand {
  HotelBrandID?: number;
  HotelBrandName?: string;
  Status?: string;
}

export interface IReservationAPIGetHotelChain {
  HotelChainID?: number;
  HotelChainName?: string;
}
export interface IReservationAPIManageHotelChain {
  HotelChainID?: number;
  HotelChainName?: string;
  Status?: string;
}

export class ReservationHotelUnique extends SafeBaseObject {
  hotelUniqueID = 0
  hotelBrandID = 0
  hotelBrandName = ''
  hotelChainID = 0
  hotelChainName = ''
  hotelDisplayName = ''
  hotelCod = ''
  hotelName = ''
  countryCod = ''
  country = ''
  cityID = 0
  city = ''
  geoTreeID = 0
  geoTreeName = ''
  address = ''
  address2 = ''
  zip = ''
  zip2 = ''
  mail = ''
  phonePrefix = ''
  phone = ''
  latitude = 0
  longitude = 0
  mainServiceID = 0
  serviceLongName = ''
  description = ''
  longDescription = ''
  hotelLevel = 3
  status?: string = 'A'
  services: ReservationhotelUniqueService[] = []

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
      if (data.Services) this.services = data.Services.map((s: any) => new ReservationhotelUniqueService(s))
    }
  }
}

export class ReservationhotelUniqueService extends SafeBaseObject {
  hotelUniqueID: number = 0
  serviceID: number = 0
  serviceLongName: string = ''
  serviceShortName: string = ''
  serviceDescription: string = ''
  supplierName: string = ''
  tradingCompany: string = ''

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}


export interface IReservationAPIGetHotelUnique {
  HotelUniqueID?: number
  HotelCod?: string
  HotelName?: string
  HotelBrand?: string
  HotelChain?: string
  CountryCod?: string
  Country?: string
  CityID?: number
  City?: string
  GeoTreeID?: number
  Address?: string
  Latitude?: number
  Longitude?: number
  PackageID?: number
}

export interface IReservationAPIManageHotelUnique {
  HotelUniqueID?: number
  HotelCod?: string
  HotelName?: string
  HotelBrandID?: number
  HotelChainID?: number
  CountryCod?: string
  CityID?: number
  GeoTreeID?: number
  Address?: string
  Address2?: string
  Zip?: string
  Zip2?: string
  Mail?: string
  PhonePrefix?: string
  Phone?: string
  Latitude?: number
  Longitude?: number
  MainServiceID?: number
  HotelLevel?: number
  Description?: string
  LongDescription?: string
  Status?: string;
}

export interface IReservationTSHotelSetup {
  HotelUniqueSourceID?: number
  CompanyID?: number
  CompanyName?: string
  SiteID?: number
  Currency?: string
  Source?: string
  SourceID?: number
  ServiceID?: number
  SupplierName?: string
  SupplierID_Link?: number
  SupplierID?: number
  ServiceName?: string
  Zip?: string
  Address?: string
  City?: string
  CityID?: number
  ServiceID_Link?: number
  ServiceName_Link?: string
}

export class ReservationTSHotelSetup extends SafeBaseObject {
  hotelUniqueSourceID = 0
  companyID = 0
  companyName = ''
  currency = ''
  siteID = 0
  source = ''
  sourceID = 0
  supplierName = ''
  supplierIDLink = 0
  supplierID = 0
  serviceID = 0
  serviceName = ''
  zip = ''
  address = ''
  city = ''
  cityID = 0
  serviceIDLink = 0
  serviceNameLink = ''

  constructor(data?: IReservationTSHotelSetup) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export interface IReservationAPIGetHotelUniqueSource {
  HotelUniqueSourceID?: number;
  CompanyName?: number;
  Source?: number;
  SourceID?: number;
  SupplierName?: number;
  ServiceName?: number;
  City?: number;
  Address?: number;
  Zip?: number;
  PackageID?: number;
}
export interface IReservationAPIManageHotelUniqueSource {
  HotelUniqueSourceID?: number
  Status?: string
}
