import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosEnvironment, IEidosIcon, IEidosLoginConfiguration } from '@common/config/environment.interface';
import { EidosApiService } from '@common/services/eidos-api.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosAuthType, CoreRoute } from '@common/models/core-constant.model';
import { SpinnerOverlayService } from '@common/services/spinner-overlay.service';
import { MyBizApiResponse } from '@common/models/mybiz.model';
import { LoginResponse } from '@common/models/login-response.model';
import { EidosResetCredentialsDialogComponent } from '@common/components/eidos-dialogs/eidos-reset-credentials-dialog/eidos-reset-credentials-dialog.component';

@Component({
  selector: 'eidos-login',
  templateUrl: './eidos-login.component.html',
  styleUrls: ['./eidos-login.component.scss']
})
export class EidosLoginComponent {

  EidosAuthType = EidosAuthType;
  /**
   * Loading for auto redirection flag
   *
   * @type {boolean}
   * @memberof EidosLoginComponent
   */
  public loadingForGrantAccess: boolean = false;
  /**
   * Logging in type
   *
   * @type {(EidosAuthType | undefined)}
   * @memberof EidosLoginComponent
   */
  public loggingIn: EidosAuthType | undefined;
  /**
   * Caps lock active flag
   *
   * @type {boolean}
   * @memberof EidosLoginComponent
   */
  public capsLockActive: boolean = false;
  /**
   * Login configuration
   *
   * @private
   * @type {IEidosLoginConfiguration}
   * @memberof EidosLoginComponent
   */
  private loginConfiguration: IEidosLoginConfiguration;
  /**
   * Logo icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosTopbarComponent
   */
  public logo: IEidosIcon;
  /**
  * Path to login logo image
  *
  * @type {string}
  * @memberof EidosLoginComponent
  */
  public logoImagePath: string = '';
  /**
   * Path to login wallpaper image
   *
   * @type {string}
   * @memberof EidosLoginComponent
   */
  public backgroundImagePath: string = '';
  /**
   * Login form for form authentication
   *
   * @memberof EidosLoginComponent
   */
  public loginForm = new UntypedFormGroup({
    username: new UntypedFormControl(''),
    password: new UntypedFormControl('')
  });
  /**
   * Windows auth enabled
   *
   * @type {boolean}
   * @memberof EidosLoginComponent
   */
  public windowsAuthEnabled: boolean = false;
  /**
   * Microsoft auth enabled
   *
   * @type {boolean}
   * @memberof EidosLoginComponent
   */
  public microsoftAuthEnabled: boolean = false;
  /**
   * Microsoft auth enabled
   *
   * @type {string | null}
   * @memberof EidosLoginComponent
   */
  public microsoftEndpoint = '';
  /**
   * Google auth enabled
   *
   * @type {boolean}
   * @memberof EidosLoginComponent
   */
  public googleAuthEnabled: boolean = false;
  /**
   * Google auth enabled
   *
   * @type {string | null}
   * @memberof EidosLoginComponent
   */
  public googleEndpoint = '';
  /**
  * Login error by server
  *
  * @memberof EidosLoginComponent
  */
  public serverError = "";

  constructor(
    private eidosConfigService: EidosConfigService
    , private route: ActivatedRoute
    , private spinnerOverlayService: SpinnerOverlayService
    , private eidosApiService: EidosApiService
    , private router: Router
    , private coreSecurityService: EidosSecurityService
    , private dialog: MatDialog
    , private translocoService: TranslocoService
  ) {
    // Redirect to home if already logged in
    const grantAccess = !!this.coreSecurityService.amIAuthenticated();
    if (grantAccess) {
      this.loadingForGrantAccess = true;
    }

    this.logo = this.eidosConfigService.DEFAULT_CONFIG.logo;
    this.loginConfiguration = this.eidosConfigService.DEFAULT_CONFIG.loginConfiguration;
    this.setupLoginByConfig(this.eidosConfigService.DEFAULT_CONFIG);

    this.eidosConfigService.currentConfig.subscribe(config => {
      // Redirect to home if already logged in
      if (grantAccess) {
        this.router.navigate(config.landingPage ? [config.landingPage] : [CoreRoute.Home]);
      } else {
        this.setupLoginByConfig(config);
        this.loadingForGrantAccess = false;
      }
    });

    // Always hide any spinner after 10 seconds.
    setTimeout(() => {
      this.spinnerOverlayService.hide();
      this.loadingForGrantAccess = false;
    }, 10000)
  }
  /**
   * Setup login component by current config
   *
   * @private
   * @param {IEidosEnvironment} config
   * @memberof EidosLoginComponent
   */
  private setupLoginByConfig(config: IEidosEnvironment) {
    this.logo = config.logo;
    this.windowsAuthEnabled = config.windowsAuth?.enabled ?? false;
    this.microsoftAuthEnabled = (config.microsoftAuth?.enabled ?? false) && window.location.href.indexOf('https') === 0;
    this.microsoftEndpoint = config.microsoftAuth?.authorizationUrl ?? '';
    this.googleAuthEnabled = (config.googleAuth?.enabled ?? false) && window.location.href.indexOf('https') === 0;
    this.googleEndpoint = config.googleAuth?.authorizationUrl ?? '';


    if (config.loginConfiguration) {
      this.loginConfiguration = config.loginConfiguration;
      this.setupLoginAssets();
    }
  }
  /**
   * Setup custom login images
   *
   * @private
   * @memberof EidosLoginComponent
   */
  private setupLoginAssets(): void {
    if (this.loginConfiguration?.logoName?.length > 0) {
      this.logoImagePath = this.loginConfiguration.assetsPath + this.loginConfiguration.logoName;
    }
    const homeWallpaper = this.loginConfiguration.wallpaperNames;
    if (homeWallpaper) {
      if (Array.isArray(homeWallpaper)) {
        this.backgroundImagePath = this.loginConfiguration.assetsPath + homeWallpaper[Math.floor(Math.random() * homeWallpaper.length)];
      }
      switch (typeof homeWallpaper) {
        case "string":
          this.backgroundImagePath = this.loginConfiguration.assetsPath + homeWallpaper;
          break;
        default:
          break;
      }
    }
  }

  /**
   * Log into Eidos by form
   *
   * @memberof EidosLoginComponent
   */
  public formAuthentication(): void {
    if (this.loginForm.valid) {
      this.loggingIn = EidosAuthType.Form;
      this.login(EidosAuthType.Form, this.loginForm.value.username, this.loginForm.value.password);
    } else {
      this.serverError = this.translocoService.translate("login.fill_credentials");
      this.loggingIn = undefined;
    }
  }

  /**
   * Log into Eidos by Windows authentication
   *
   * @memberof EidosLoginComponent
   */
  public windowsAuthentication() {
    if (this.windowsAuthEnabled) {
      this.loggingIn = EidosAuthType.Windows;
      this.login(EidosAuthType.Windows);
    } else {
      this.loggingIn = undefined;
    }
  }
  /**
   * Login handler
   *
   * @param authType
   * @param username
   * @param password
   */
  private login(authType: EidosAuthType, username?: string, password?: string) {
    const queryString = window.location.search

    this.eidosApiService.login(authType, username, password, queryString).subscribe({
      next: response => {
        // Verbose check: subscription in API service will handle errors
        switch (authType) {
          case EidosAuthType.Microsoft:
            const rm = response as MyBizApiResponse;
            if (rm.isOk()) {
              window.open(rm.data, '_self');
            } else {
              this.loggingIn = undefined;
            }
            break;
          default:
            const r = response as LoginResponse;
            if (r.isOk()) {
              if (this.router.routerState.snapshot.url.split('/').length <= 3) {

                // Check if a route has been requested before login redirection
                const requestedRoute = this.coreSecurityService.getRequestedRoute();
                this.coreSecurityService.clearRequestedRoute();

                if (requestedRoute && requestedRoute !== '/') {

                  window.location.href = window.location.origin + requestedRoute;

                } else {
                  const redirectUrl = this.route.snapshot.queryParamMap.get('r');
                  if (redirectUrl) {
                    window.location.href = redirectUrl;
                    return;
                  }
                  const landingPage = r.landingPage ? r.landingPage : CoreRoute.Home;
                  this.router.navigate([landingPage]);
                }
              }
            } else {
              this.serverError = r.message;
              this.loggingIn = undefined;
            }
            break;
        }
      },
      error: error => {
        this.serverError = authType !== EidosAuthType.Windows ? this.translocoService.translate("login.failed") : error;
        this.loggingIn = undefined;
      }
    });
  }

  /**
   * Log into Eidos by Microsoft authentication
   *
   * @memberof EidosLoginComponent
   */
  public oAuth2Authentication(authType: EidosAuthType) {
    this.loggingIn = undefined;
    switch (authType) {
      case EidosAuthType.Microsoft:
        if (!this.microsoftAuthEnabled) return;
        break;
      case EidosAuthType.Google:
        if (!this.googleAuthEnabled) return;
        break;
    }
    this.loggingIn = authType;
    this.login(authType);
  }

  /**
   * Handles user credentials reset
   *
   * @memberof EidosLoginComponent
   */
  public resetCredentials() {
    this.dialog.open(EidosResetCredentialsDialogComponent, {
      width: '400px'
    });
  }

  public registerCredentials() {
    this.router.navigate([CoreRoute.RegisterAccount]);
  }
  /**
   * Toggle the caps lock status
   *
   * @param {boolean} active
   * @memberof EidosLoginComponent
   */
  public toggleCapsLock(active: any) {
    this.capsLockActive = active === true;
  }
}
