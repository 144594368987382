import { Component } from '@angular/core';

import { EidosSecurityService } from '@app/core/services/eidos-security.service';
import { EidosUser } from '@common/models/user-info.model';
import { EidosBaseComponent } from '../eidos-base.component';

@Component({
  selector: 'eidos-user',
  templateUrl: './eidos-user.component.html',
  styleUrls: ['./eidos-user.component.scss']
})
export class EidosUserComponent extends EidosBaseComponent {
  /**
   * Loading flag
   *
   * @protected
   * @type {boolean}
   * @memberof EidosUserComponent
   */
  protected loading: boolean = false;
  /**
   * Current user
   *
   * @protected
   * @type {BehaviorSubject<EidosUser>}
   * @memberof EidosUserComponent
   */
  protected user = new EidosUser();

  constructor(
    private coreSecurityService: EidosSecurityService,
  ) {
    super();
    this.coreSecurityService.currentLoggedUser.subscribe({
      next:(user)=>this.user = user
    })
  }

}
