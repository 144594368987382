import { BaseObject } from '@common/models/base-object.models';
import { DateTime } from 'luxon';
import { IReservationApiBookingGuest, ResGuestType } from './res-booking.model';
import { IReservationPriceType } from './res-cached-data.models';
import { ReservationApiBoolean } from './res-constant.model';

export class ReservationIndividual extends BaseObject {
  internalType='Individual';

  individualID?: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  title?: string;
  email?: string;
  phone?: string;
  phonePrefix_ISO2?: string;
  birthDate?: DateTime;
  birthPlace?: string;
  birthNation?: string;
  guestType?: ResGuestType;
  gender?: string;
  passportNumber?: string;
  passportIssuePlace?: string;
  passportIssueCountryCod?: string;
  passportIssueDate?: DateTime;
  passportExpireDate?: DateTime;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  zip1?: string;
  zip2?: string;
  city?: string;
  country?: string;
  countryCod?: string;
  countyCod?: string;
  stateCod?: string;
  nationalityISO3Cod?: string;
  nationalityName?: string;
  nationalityCod?: string;
  occupation?: string;
  language?: string;
  currencyCod?: string;
  isPlaceHolder?: boolean;
  individualType?: 'Agent' | 'Individual';
  currencies?: Array<ResCurrency>;
  priceTypes?: Array<IReservationApiPriceType>;
  cSNo?: string;
  householdId?: number;

  constructor(data: IReservationApiIndividual) {
    super(data);
  }

  public isValid() {
    return this.currencies?.length ?? 0 > 0;
  }
  override updateData(data: IReservationApiIndividual): void {
    this.addMangledProperty(data);

    // i did this because the addMangledProperty remove underscore from the property name
    delete this.phonePrefixISO2
    this.addProperty('phonePrefix_ISO2', data, 'PhonePrefix_ISO2');

    //redefine property
    this.addDateTimeProperty('birthDate', data, 'BirthDate');
    this.addDateTimeProperty('passportIssueDate', data, 'PassportIssueDate');
    this.addDateTimeProperty('passportExpireDate', data, 'PassportExpireDate');
    this.addBooleanProperty('isPlaceHolder', data, 'IsPlaceHolder');

    this.currencies = [];
    if (data.Currencies) {
      this.currencies = data.Currencies.map((item) => new ResCurrency(item));
    }
    this.currencyCod =
      this.currencies.find((c) => c.isDefault)?.CurrencyCod ??
      (this.currencies.length > 0 ? this.currencies[0].currencyCod : undefined);

    this.priceTypes = [];
    if (data.PriceTypes) {
      this.priceTypes = data.PriceTypes; //.map((item) => new ResOwnerPriceType(item));
    }
  }
  setCurrency(currencyCod: string) {
    if (!this.currencies) return;
    const c = this.currencies.find(c => c.CurrencyCod === currencyCod);
    if (c && c.currencyCod) this.currencyCod = c.currencyCod;
  }
}

export interface IReservationApiIndividual {
  IndividualID?: number;
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  Title?: string;
  Email?: string;
  Phone?: string;
  PhonePrefix_ISO2?: string;
  BirthDate?: string;
  BirthPlace?: string;
  BirthNation?: string;
  GuestType?: string;
  Gender?: string;
  PassportNumber?: string;
  PassportIssuePlace?: string;
  PassportIssueCountryCod?: string;
  PassportIssueDate?: string;
  PassportExpireDate?: string;
  Address1?: string;
  Address2?: string;
  Address3?: string;
  Address4?: string;
  Zip1?: string;
  Zip2?: string;
  City?: string;
  Country?: string;
  CountryCod?: string;
  CountyCod?: string;
  StateCod?: string;
  NationalityISO3Cod?: string;
  NationalityName?: string;
  NationalityCod?: string;
  Occupation?: string;
  Language?: string;
  CurrencyCod?: string;
  IsPlaceHolder?: string;
  Currencies?: Array<IReservationApiCurrency>;
  PriceTypes?: Array<IReservationApiPriceType>;
  IndividualType?: 'Agent' | 'Individual';
  CSNo?: string;
  household_id?: number;
}

export interface IReservationApiIndividualsParameters {
  IndividualID?: number;
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  Email?: string;
  Phone?: string;
  CountryCod?: string;
  Gender?: string;
  PageNum?: number;
  PageSize?: number;
}

export interface IResApiVoyageTable {
  VoyageID?: number,
  VoyageNumber?: string
}
export interface IResApiGuestCheckBookingExistsParams {
  IndividualID: number;
  VoyageTable: IResApiVoyageTable[];
  BkgID?: number;
}

export interface IResApiGuestCheckBookingExistsResult {
  BkgID: number;
  VoyageNumber: string;
}

export interface IReservationApiInsertGuestParameters {
  BkgID?: number;
  SectionID?: number;
  Guests?: IReservationApiBookingGuest[];
}
export interface IReservationApiUpdateGuestParameters {
  BkgID?: number;
  SectionID?: number;
  GuestID?: number;
  Guests?: IReservationApiBookingGuest;
  IsBookingCopy: ReservationApiBoolean;
}
export interface IReservationApiDeleteGuestParameters {
  BkgID?: number;
  SectionID?: number;
  GuestID?: number;
}
export interface IReservationApiReplaceGuestParameters {
  BkgID: number;
  SectionID: number;
  GuestCod: string;
  RoomID: number
  IndividualID: number;
  IndividualType: string;
}

export class ReservationAgency extends BaseObject {
  agencyID?: number;
  agencyCod?: string;
  agency?: string;
  agentID?: number;
  email?: string;
  etaAccountingEmail?: string;
  phone?: number;
  mainContact?: string;
  address?: string;
  countryCod?: string;
  countryID?: number;
  stateID?: number;
  gLAccountID?: number;
  salesRep?: string;
  currencies?: ResCurrency[];
  priceTypes?: IReservationApiPriceType[];
  isConsortium:boolean=false;
  isHeadQuarter: boolean=false;
  agencyTypology?: string;
  businessAS?: string;
  cLIA?: string;


  constructor(data: IReservationApiAgency) {
    super(data);
    if (data.Currencies)
      this.currencies = data.Currencies.map((c) => new ResCurrency(c));
    if (data.PriceTypes)
      this.priceTypes = data.PriceTypes//.map((p) => new ResOwnerPriceType(p));
  }

  override updateData(data: IReservationApiAgency): void {
    this.addMangledProperty(data);
    if(!this.agencyCod) this.agencyCod = this.agencyID?.toString();
  }
}

export interface IReservationApiAgency {
  AgencyID?: number;
  AgencyCod?: string;
  Agency?: string;
  CLIA?: string;
  BusinessAS?: string;
  Email?: string;
  EtaAccountingEmail?: string;
  Phone?: number;
  Iata?: string;
  MainContact?: string;
  Address?: string;
  CountryCod?: string;
  Country?: string;
  CountryID?: number;
  StateID?: number;
  GLAccountID?: number;
  SalesRep?: string;
  Currencies?: IReservationApiCurrency[];
  PriceTypes?: IReservationApiPriceType[];
}

export class ReservationAgent extends BaseObject {
  internalType='Agent';
  agentID?: number;
  agencyID?: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  email?: string;
  phone?: number;
  mainContact?: string;
  address?: string;
  agencyName?: string;
  currencyCod?: string;
  country?: string;
  currencies?: ResCurrency[];
  priceTypes?: IReservationApiPriceType[];

  constructor(data: IReservationApiAgent) {
    super(data);
    if (data.Currencies) {
      this.currencies = data.Currencies.map((c) => new ResCurrency(c));

      this.currencyCod =
        this.currencies.find((c) => c.isDefault)?.CurrencyCod ??
        (this.currencies.length > 0 ? this.currencies[0].currencyCod : undefined);
    }
    if (data.PriceTypes)
      this.priceTypes = data.PriceTypes//.map((p) => new ResOwnerPriceType(p));
  }

  override updateData(data: IReservationApiAgent): void {
    this.addMangledProperty(data);
  }
  setCurrency(currencyCod: string) {
    if (!this.currencies) return;
    const c = this.currencies.find(c => c.CurrencyCod === currencyCod);
    if (c && c.currencyCod) this.currencyCod = c.currencyCod;
  }
}

export interface IReservationApiAgent {
  AgentID?: number;
  AgencyID?: number;
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  Country?: string;
  Email?: string;
  Phone?: number;
  MainContact?: string;
  Address?: string;
  AgencyName?: string;
  Currencies?: IReservationApiCurrency[];
  PriceTypes?: IReservationApiPriceType[];
}
export interface IReservationApiAgentParameters {
  AgencyID?: number;
  AgentID?: number
  IsWebSiteAgent?: "Y" | "N";
}


export interface IResOwnerSelect {
  OwnerID: number,
  OwnerAgency?: number,
  CurrencyCod?: string
}
export class ResOwner extends BaseObject {
  individualID?: number;
  agencyID?: number;
  agentID?: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  title?: string;
  email?: string;
  phone?: number;
  mainContact?: string;
  agencyName?: string;
  currencies?: ResCurrency[];
  priceTypes: IReservationApiPriceType[] = [];
  countryCod?: string;
  currencyCod?: string;
  agencyContacts?: string
  ownerType?: 'DIRECT' | 'AGENCY';
  isWebSiteAgent?: boolean;
  categoryType?: string;

  constructor(data?: any) {
    super(data);
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: any): void {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.middleName = data.middleName;
    this.title = data.title;
    this.email = data.email;
    this.phone = data.phone;
    this.mainContact = data.mainContact;
    this.currencies = data.currencies;
    this.priceTypes = data.priceTypes;
    this.currencyCod = data.currencyCod;
    this.agencyID = data.agencyID;
    this.agentID = data.agentID;
    this.individualID = data.individualID;
    this.countryCod = data.countryCod;
    this.agencyName = data.agencyName;


    if (!this.currencyCod && !!this.currencies?.length) {
      let currency = this.currencies.find((c) => c.isDefault);
      if (!!currency) {
        this.currencyCod = currency.currencyCod;
      } else {
        this.currencyCod = this.currencies[0].currencyCod;
      }
    }

    if (this.individualID != undefined) {
      this.ownerType = 'DIRECT';
    }
    else if (this.agentID != undefined) {
      this.ownerType = 'AGENCY';
    }
  }

  displayData(): string {
    let string = `${this.firstName ?? ''} ${this.lastName ?? ''} ${this.isAgent() && this.agencyName ? '- ' + this.agencyName : ''} ${this.currencyCod ? '(' + this.currencyCod + ')' : ''
      }`;
    return string.trim();
  }

  displayName(): string {
    let string = `${this.firstName ?? ''} ${this.lastName ?? ''}`;
    return string.trim();
  }

  displayContacts(): string[] {
    let string = [];
    if (!!this.email) string.push(this.email);
    if (!!this.phone) string.push(this.phone.toString());
    if (!!this.mainContact) string.push(this.mainContact);
    return string;
  }

  isDirect(): boolean {
    return this.ownerType?.toUpperCase() === 'DIRECT';
  }

  isAgent(): boolean {
    return this.ownerType?.toUpperCase() === 'AGENCY';
  }

  toStorage(): string {
    let toSave: any = Object.assign({}, this);
    return JSON.stringify(toSave);
  }

  get isValid(): boolean {
    return (this.agencyID != undefined) || (this.individualID != undefined) || (!!this.isWebSiteAgent && this.agentID != undefined);
  }

  static fromStorage(data: string): ResOwner {
    let obj = JSON.parse(data);
    return new ResOwner(obj);
  }

  static EmptyOwner(): ResOwner {
    return new ResOwner({});
  }
}

export class ResCurrency extends BaseObject {
  agencyID?: number;
  ownerID?: number;
  currencyCod?: string;
  priceTypeID?: number;
  priceTypeName?: string;
  isDefault?: boolean;

  constructor(data: IReservationApiCurrency) {
    super(data);
  }

  override updateData(data: IReservationApiCurrency): void {
    this.addMangledProperty(data);
  }
}

interface IReservationApiCurrency {
  AgencyID?: number;
  OwnerID?: number;
  CurrencyCod?: string;
  PriceTypeName?: string;
  PriceTypeID?: number;
  IsDefault?: boolean;
}


export interface IReservationApiPriceType extends IReservationPriceType {
  AgencyID?: number;
  OwnerID?: number;
  isDefault?: boolean;
}

export enum ResApiAgenciesSearchType {
  Agencies='Agency',
  Direct='Direct',
  Consortium='Consortium',
  HeartQuarter='HeadQuarter'
}


export enum ResAgenciesSelectMode {
  Single,
  Multy,
  Table
}
export enum ResAgenciesLoadMode {
  ID,
  Code,
  Object
}

export interface IResAgencyOrIndividualID {
  type:ResApiAgenciesSearchType;
  id?:number;
  code?:string
}

export interface IResResAgenciesSelectConfig {
  loadMode?:ResAgenciesLoadMode;
  selectMode?:ResAgenciesSelectMode;
  enabledTypes:Array<ResApiAgenciesSearchType>;
  agenciesData?:Array<ReservationAgency|ReservationIndividual>;
  agenciesKey?:Array<IResAgencyOrIndividualID>;
  deletedAgenciesID?:Array<IResAgencyOrIndividualID>;
  addedAgenciesID?:Array<IResAgencyOrIndividualID>;
}

export interface IResApiAgenciesSearchParameters {
  SearchType:ResApiAgenciesSearchType;

  //Agency filters
  Name?: string;
  AgencyID?: number;
  AgencyCod?: string;
  EtaAccountingEmail?: string;
  CountryID?: number;
  StateID?: number;
  GLAccountID?: number;
  SalesRep?: string;
  AgenciesID_EXCL?: string;
  Iata?:string;

  //Direct filters
  IndividualID?: number;
  Gender?: string;
  IndividualsID_EXCL?: string;

  //common filters
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  Email?: string;
  Phone?: number;
  MainContact?: string;
  Address?: string;
  CountryCod?: string;
  PromoID?: number;

	PageNum?:number;
	PageSize?:number;
}

export interface IResReferralParameters {
  GuestReferralID?: number;
  BkgID?: number;
  OldDirectID?: number;
  NewDirectReferral?: {DirectID: number, Status: string}[];
  // NewDirectID?: number;
  Status?: string;
}

export class ResReferral extends BaseObject{
  guestReferralID = 0;
  bkgId = 0;
  oldDirectID = 0;
  newDirectID = 0;
  status = '';

  constructor(data: IResReferralParameters) {
    super(data);
  }
}
export interface IResPastGuestReferralParameters {
  IndividualID: number;
  IsPast: string;
}
export class ResPastGuestReferral extends BaseObject{
  individualID = 0;
  isPast = '';

  constructor(data: IResPastGuestReferralParameters) {
    super(data);
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IResPastGuestReferralParameters) {
    this.addMangledProperty(data);
  }
}
