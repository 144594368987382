import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';

import { EidosApiService } from '@common/services/eidos-api.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { CoreRoute } from '@common/models/core-constant.model';
import { SpinnerOverlayService } from '@common/services/spinner-overlay.service';
import { EidosTokenGuard } from './eidos-token.guard';
import { EidosExternalAppService } from '@common/eidos-external-app/eidos-external-app.service';

@Injectable({
  providedIn: 'root'
})
export class CoreDeveloperGuard extends EidosTokenGuard implements CanActivate {

  constructor(
    public router: Router
    , public eidosSecurityService: EidosSecurityService
    , public externalAppService: EidosExternalAppService
    , public spinnerOverlayService: SpinnerOverlayService
    , public eidosApiService: EidosApiService) {
    super(router, eidosSecurityService, externalAppService, spinnerOverlayService, eidosApiService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Promise<boolean | UrlTree> {

    return new Promise<boolean | UrlTree>(resolve => {
      super.canActivate(route).then((value: boolean | UrlTree) => {
        if (value instanceof UrlTree) {

          // Not authenticated, must resolve login path
          resolve(value);

        } else {
          // Logic of this guard
          if (this.eidosSecurityService.isDeveloperUser()) {
            return resolve(true);
          } else {
            return resolve(this.router.parseUrl(CoreRoute.Home));
          }
        }
      });
    });
  }
}
