import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import _, { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, filter, lastValueFrom, map, Observable, of, Subject } from 'rxjs';

import { EidosIconType, EidosLogSeverity, SessionStorageKeys } from '@app/core/models/core-constant.model';
import { DynamicApiResponse, DynamicApiResponsePaginatedModel, IDynamicApiResponse } from '@app/core/models/dynamic-api-response.model';
import { CoreCacheService } from '@app/core/services/core-cache.service';
import { CoreFormatService } from '@app/core/services/core-format.service';
import { CoreUtilityDialog, EidosUtilityDialogDialogType, EidosUtilityService } from '@app/core/services/eidos-utility.service';
import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosIcon, IEidosModuleConfiguration } from '@common/config/environment.interface';
import { EidosSecurityService } from '@common/services/eidos-security.service';

import { TicketingService } from '@app/standalones/ticketing/service/ticketing.service';

import { EidosModalService } from '@app/core/components/eidos-modal/eidos-modal.service';
import { EidosLogService } from '@app/core/services/eidos-log.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  IResApiAgenciesSearchParameters,
  IResOwnerSelect,
  ResCurrency,
  ReservationAgent,
  ReservationIndividual,
  ResOwner,
} from '@reservation/models/res-owner.model';
import { ReservationApiService } from '@reservation/services/reservation-api.service';
import { IResApiGeoTreeParameters } from '../models/geo-tree.model';
import { ResBookingUtility } from '../models/res-booking-utility.model';
import { BookingGuest, IReservationApiParametersBookCamp, ResBooking, ResEditGuestAction, ResTailorMadeParameters } from '../models/res-booking.model';
import { IReservationCompany, IReservationCurrency, IReservationExperienceType, IReservationLoyaltyDiscount, IReservationPackageType, IReservationPriceType, IReservationProductType, IReservationSearchableSuiteCategory, IReservationShip } from '../models/res-cached-data.models';
import { RESERVATION_ROOT, ReservationRoute } from '../models/res-constant.model';
import { IReservationCruiseCategory, IReservationCruiseSuiteCapacity } from '../models/res-cruise-availability.models';
import { IResApiVoyagesSearchParameters, IResGetCabinOccupancyParams, ReservationCabinOccupancy, ReservationCabinOccupancyByMonth, ReservationCabinOccupancyStatus, ReservationCabinOccupancyShip, ReservationCabinOccupancyVoyage } from '../models/res-cruise.models';
import { IReservationEnvironment } from '../models/res-environment.interface';
import { IReservationHomeCategory } from '../models/res-home.model';
import { ReservationServiceType } from '../models/res-option.models';
import { IResProductPropertyAvailabilityParams, ResProductPropertyAvailability, ResSearchFilterPreferences, ResSearchProduct } from '../models/res-package.model';
import { ResPreBookingUtility } from '../models/res-pre-booking-utility.model';
import { ResPromoUtility } from '../models/res-promo-utility';
import { IResPackagePricesHistoryParams, IResProductSearchParams } from '../models/res-search.models';
import { ReservationAccomodation, ResGuestGroup } from '../models/res-service.models';
import { IResTicketingSendData } from '../models/res-ticketing.model';
import { IReservationApiUserInfo } from '../models/res-user.model';
import { ReservationAction, ReservationPermission } from '../models/reservation-permission.model';
import { ReservationPaymentApiService } from './reservation-payment-api.service';
import { IReservationPromoApplyPramaters, ResPromoApply } from '../models/res-promo.model';
import { IResBkgItemApiParams } from '../models/res-bkg-item.model';

export interface IResEnvParameters {
  groupID: number,
  priceTypeID: number,
}


export interface IResTailorMadeParameters  {
  accomodations: ReservationAccomodation[]
  dateFrom:DateTime
  dateTo:DateTime
}

@Injectable({
  providedIn: 'root',
})
export class ReservationService {

  /**
   * Configurated product type codes
   *
   * @private
   * @type {string[]}
   * @memberof ReservationService
   */
  private _productTypesCodesFromConfig: string[] = [];
  public scrollable = new BehaviorSubject<boolean>(false)
  /**
   * class that implement utility methods to display: warning, request confirm or open custom dialog in
   * reservation structured style (reservation Dialog extends EidosUtilityDialog)
   *
   * @type {CoreUtilityDialog}
   * @memberof ReservationService
   */
  public utilityDialogs: CoreUtilityDialog;
  /**
   * Current user info
   *
   * @type {(BehaviorSubject<IReservationApiUserInfo | undefined>)}
   * @memberof ReservationService
   */
  public uniqueModalPanel= new BehaviorSubject<string>('')

  /**
   * Current user info
   *
   * @type {(BehaviorSubject<IReservationApiUserInfo | undefined>)}
   * @memberof ReservationService
   */
  public currentUser: BehaviorSubject<IReservationApiUserInfo | undefined> = new BehaviorSubject<
    IReservationApiUserInfo | undefined
  >(undefined);

  /**
   * Current company
   *
   * @type {(BehaviorSubject<IReservationApiUserInfo | undefined>)}
   * @memberof ReservationService
   */

  public currentCompany = new BehaviorSubject<IReservationCompany | undefined>(undefined);
  /**
   * Current filters
   *
   * @type {(BehaviorSubject<any | undefined>)}
   * @memberof ReservationService
   */
  public currentFilters: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>({});
  /**
   * Current product type
   *
   * @type {(BehaviorSubject<IReservationProductType | undefined>)}
   * @memberof ReservationService
   */
  public currentProductType: BehaviorSubject<IReservationProductType | undefined> = new BehaviorSubject<
    IReservationProductType | undefined
  >(undefined);
  /**
   * Current experience type
   *
   * @type {(BehaviorSubject<IReservationExperienceType | undefined>)}
   * @memberof ReservationService
   */
  public currentExperienceType: BehaviorSubject<IReservationExperienceType | undefined> = new BehaviorSubject<
    IReservationExperienceType | undefined
  >(undefined);
  /**
   * Current owner
   *
   * @type {(BehaviorSubject<ResOwner | undefined>)}
   * @memberof ReservationService
   */
  public currentOwner: BehaviorSubject<ResOwner> = new BehaviorSubject<ResOwner>(ResOwner.EmptyOwner());
  /**
   * Current owner
   *
   * @type {(BehaviorSubject<boolean>)}
   * @memberof ReservationService
   */
  public companyDataReload = new BehaviorSubject<boolean>(false);
  public companyDataOk = new Subject<boolean>();
  /**
   * Current owner currencies X price types
   *
   * @private
   * @type {BehaviorSubject<Array<ResCurrency>>}
   * @memberof ReservationService
   */
  public currentOwnerCurrencies: BehaviorSubject<Array<ResCurrency>> = new BehaviorSubject<Array<ResCurrency>>([]);
  allValidCurrencies: BehaviorSubject<Array<ResCurrency>> = new BehaviorSubject<Array<ResCurrency>>([]);



  /**
   * Booking utility
   *
   * @type {ResBookingUtility}
   * @memberof ReservationService
   */
  public bookingUtility: ResBookingUtility;
  /**
   * Pre booking utility
   *
   * @type {ResPreBookingUtility}
   * @memberof ReservationService
   */
  public preBookingUtility: ResPreBookingUtility;


  public promoUtility: ResPromoUtility;

  /**
   * Current booking
   *
   * @readonly
   * @type {ResBooking}
   * @memberof ReservationService
   */
  public get currentBooking(): ResBooking {
    return this.bookingUtility.current.value;
  }
  /**
   * Configurated product types
   *
   * @type {(BehaviorSubject<Array<IReservationProductType> | undefined>)}
   * @memberof ReservationService
   */
  public configuredProductTypes: BehaviorSubject<Array<IReservationProductType> | undefined> = new BehaviorSubject<
    Array<IReservationProductType> | undefined
  >(undefined);
  /**
   * Configurated Extend your Journey product types
   *
   * @type {(BehaviorSubject<Array<IReservationProductType> | undefined>)}
   * @memberof ReservationService
   */
  public configuredSuggestedItemsProductTypes: BehaviorSubject<Array<IReservationProductType> | undefined> =
    new BehaviorSubject<Array<IReservationProductType> | undefined>(undefined);
  /**
   * current user Permission
   * Permission is dependent from current user selectio or impersonation
   *
   * @type {(BehaviorSubject<ReservationPermission>)}
   * @memberof ReservationService
   */
  public currentReservationPermission: BehaviorSubject<ReservationPermission> =
    new BehaviorSubject<ReservationPermission>(ReservationPermission.emptyPermission());
  /**
   * All configured currencies
   *
   * @private
   * @type {BehaviorSubject<string[]>}
   * @memberof ReservationService
   */
  allConfiguredCurrencies: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  /**
   * All user currencies
   *
   * @type {(BehaviorSubject<Array<string> | undefined>)}
   * @memberof ReservationService
   */
  public allAvalaibleCurrencies: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  /**
   * Current selected currencies
   *
   * @type {(BehaviorSubject<string | undefined>)}
   * @memberof ReservationService
   */
  public currentCurrency: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  /**
   * All configured price types
   *
   * @type {BehaviorSubject<Array<IReservationPriceType>>}
   * @memberof ReservationService
   */
  allConfiguredPriceTypes: BehaviorSubject<Array<IReservationPriceType>> = new BehaviorSubject<
    Array<IReservationPriceType>
  >([]);
  /**
  * All user price types
  *
  * @type {(BehaviorSubject<Array<IReservationPriceType> | undefined>)}
  * @memberof ReservationService
  */
  public allAvalaiblePriceTypes: BehaviorSubject<Array<IReservationPriceType>> = new BehaviorSubject<
    Array<IReservationPriceType>
  >([]);
  /**
  * All ships based on current selected company
  *
  * @type {(BehaviorSubject<Array<IReservationPriceType> | undefined>)}
  * @memberof ReservationService
  */
  public allAvalaibleShips: BehaviorSubject<Array<IReservationShip>> = new BehaviorSubject<
    Array<IReservationShip>
  >([]);
  /**
  * All ships based on user visible copmpany
  *
  * @type {(BehaviorSubject<Array<IReservationPriceType> | undefined>)}
  * @memberof ReservationService
  */
  public allVisibleShips = new BehaviorSubject<IReservationShip[]>([]);

  allVisibleSearchableSuiteCategories = new BehaviorSubject<IReservationSearchableSuiteCategory[]>([]);
  /**
   * Current selected price type
   *
   * @type {(BehaviorSubject<IReservationPriceType | undefined>)}
   * @memberof ReservationService
   */
  public currentPriceType: BehaviorSubject<IReservationPriceType | undefined> = new BehaviorSubject<
    IReservationPriceType | undefined
  >(undefined);
  /**
    * Current selected cruise suite category
    *
    * @type {(BehaviorSubject<IReservationCruiseCategory | undefined>)}
    * @memberof ReservationService
    */
  public currentCruiseSuiteCategory: BehaviorSubject<IReservationCruiseCategory | undefined> = new BehaviorSubject<
    IReservationCruiseCategory | undefined
  >(undefined);
  /**
    * Current selected cruise suite category
    *
    * @type {(BehaviorSubject<number[]>)}
    * @memberof ReservationService
    */
  public currentRequestedPromoIds: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);
    /**
   * Current selected accomodations
   *
   * @type {(BehaviorSubject<ReservationAccomodation | undefined>)}
   * @memberof ReservationService
   */
  public currentAccomodations = new BehaviorSubject<ReservationAccomodation[]>([ReservationAccomodation.newRoom(1)]);
  resetPreBookingDefaults() {
    this.currentAccomodations.next([ReservationAccomodation.newRoom(1)])
    //@TODO set other defaults
    // ...
  }
  /**
   * Current selected cruise suite capacity
   *
   * @type {(BehaviorSubject<IReservationCruiseSuiteCapacity | undefined>)}
   * @memberof ReservationService
   */
  public currentCruiseSuiteCapacity: BehaviorSubject<IReservationCruiseSuiteCapacity | undefined> = new BehaviorSubject<
    IReservationCruiseSuiteCapacity | undefined
  >(undefined);
  /**
   * Current dashboard user subject
   *
   * @type {BehaviorSubject<IReservationHomeCategory>}
   * @memberof SecurityService
   */
  public currentDashboardUser: BehaviorSubject<IReservationHomeCategory[]>;
  /**
   * Service types
   *
   * @type {BehaviorSubject<ReservationServiceType[]>}
   * @memberof ReservationService
   */
  public serviceTypes: BehaviorSubject<ReservationServiceType[]> = new BehaviorSubject<ReservationServiceType[]>([]);
  public serviceTypesWithBkgID: BehaviorSubject<ReservationServiceType[]> = new BehaviorSubject<ReservationServiceType[]>([]);

  public retreiveServiceTypes(BkgID: number) {
    this.resApiService.getServiceTypes({ ServiceTypeName: '', BkgID: BkgID }).subscribe({
      next: (serviceTypes: Array<ReservationServiceType>) => {
        this.serviceTypesWithBkgID.next(serviceTypes);
      },
      error: () => {
        this.serviceTypesWithBkgID.next([]);
      },
      complete: () => { },
    });
  }

  /**
   * Open deposit price types configured ids
   *
   * @private
   * @type {number[]}
   * @memberof ReservationService
   */
  public configuredOpenDepositPriceTypes: number[] = [];
  /**
   * Use ReservationPermission.mockedPermission()
   * instead of ReservationPermission.emptyPermission():
   *
   * @type {boolean}
   * @memberof ReservationService
   */
  private useMockedPermissions: boolean = false;
  /**
   *
   *
   * @type {number}
   * @memberof ReservationService
   */
  public grandVoyagePriceType?: number;
  /**
   *
   *
   * @type {number}
   * @memberof ReservationService
   */
  public transportServiceTypeID: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  private touchstartX: number = 0;
  private touchendX: number = 0;
  private touchstartY: number = 0;
  private touchendY: number = 0;
  public swipeCallbacks: BehaviorSubject<any> = new BehaviorSubject<any>({});

  /**
   * Open edit guest popups
   *
   * @type {BehaviorSubject<{ guest?: BookingGuest; action?: ResEditGuestAction }>}
   * @memberof ReservationService
   */
  public editGuest: BehaviorSubject<{ guest?: BookingGuest; action?: ResEditGuestAction }> = new BehaviorSubject<{
    guest?: BookingGuest;
    action?: ResEditGuestAction;
  }>({});

  /**
   * All loyalty discounts
   *
   * @type {(BehaviorSubject<Array<IReservationLoyaltyDiscount> | undefined>)}
   * @memberof ReservationService
   */
  public allAvalaibleLoyaltyDiscounts: BehaviorSubject<Array<IReservationLoyaltyDiscount>> = new BehaviorSubject<
    Array<IReservationLoyaltyDiscount>
  >([]);

  /**
   * Current selected loyalty discount
   *
   * @type {(BehaviorSubject<IReservationLoyaltyDiscount | undefined>)}
   * @memberof ReservationService
   */
  public currentLoyaltyDiscount: BehaviorSubject<IReservationLoyaltyDiscount | undefined> = new BehaviorSubject<
    IReservationLoyaltyDiscount | undefined
  >(undefined);

  /**
   * Default AngularEditorConfig
   *
   * @type {AngularEditorConfig}
   * @memberof ReservationService
   */
  public defaultAngularEditorConfig: AngularEditorConfig = {
    editable: true,
    height: '100%',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    enableToolbar: true,
    showToolbar: true,
    toolbarHiddenButtons: [
      [
        'fontName',
      ],
      [
        'insertImage',
        'insertVideo'
      ]
    ],
    rawPaste: true,
  };

  allCompanies: IReservationCompany[] = []
  // allAvailableCompanies:IReservationCompany[]=[]
  public allAvailableCompanies: BehaviorSubject<Array<IReservationCompany>> = new BehaviorSubject<
    Array<IReservationCompany>
  >([]);

  public allAvailableSortedCompanies: BehaviorSubject<Array<IReservationCompany>> = new BehaviorSubject<
  Array<IReservationCompany>
>([]);

  public searchFilterPreferences = new BehaviorSubject<ResSearchFilterPreferences>(new ResSearchFilterPreferences())

  public geoTreeNavigate(filters: IResApiGeoTreeParameters, orderType: number = 0) {
    return this.resApiService.geoTreeNavigate(filters, orderType);
  }
  public getAvailableCompanies() {
    return this.coreUtilityService.getAvailableCompanies();
  }
  public voyagesSearch(filters: IResApiVoyagesSearchParameters) {
    return this.resApiService.getVoyagesSearch(filters);
  }
  public agenciesSearch(filters: IResApiAgenciesSearchParameters) {
    return this.resApiService.getAgenciesOrDirectsSearch(filters);
  }
  getSearchableProductService() {
    return this.resApiService.getSearchableProductService()
  }
  dynamicRouting?: { [key: string]: any };
  defaultDynamicRouting(page: string) {
    switch (page) {
      case '':
        return ReservationRoute.Home;
      default:
        return page
    }
  }
  getDynamicRouting(page: string) {
    if (this.dynamicRouting) {
      if (!!this.dynamicRouting[page]) return `${this.dynamicRouting[page]}`
    }
    return this.defaultDynamicRouting(page)
  }
  companyAlias: number[][] = []
  checkCompanyAlias(companyID1:number,companyID2:number) {
    return (companyID1===companyID2)
        || !!this.companyAlias.some(values=>values.find(id=>id===companyID1) && values.find(id=>id===companyID2))
  }

  private tailorMadeParameters:ResTailorMadeParameters = new ResTailorMadeParameters(null)

  private allPackageTypes:IReservationPackageType[]=[]

  constructor(
    private resApiService: ReservationApiService,
    private resPaymentApiService: ReservationPaymentApiService,
    public coreCacheService: CoreCacheService,
    private coreSecurityService: EidosSecurityService,
    private coreConfigService: EidosConfigService,
    private coreFormatService: CoreFormatService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private ticketingService: TicketingService,
    private eidosModalService: EidosModalService,
    private coreLogService: EidosLogService,
    private coreUtilityService: EidosUtilityService,
  ) {
    this.coreUtilityService.onPrepareEnvironment.next(true)
    this.companyDataOk.pipe(filter(v=>v)).subscribe({next:(v=>this.coreUtilityService.onPrepareEnvironment.next(!v))})
    this.currentDashboardUser = new BehaviorSubject<IReservationHomeCategory[]>([]);

    this.utilityDialogs = new CoreUtilityDialog(this.eidosModalService);
    this.bookingUtility = new ResBookingUtility(this.utilityDialogs, this);
    this.preBookingUtility = new ResPreBookingUtility(this);
    this.promoUtility = new ResPromoUtility(this, resApiService, coreConfigService);

    this.coreConfigService.currentConfig.subscribe((config) => {
      this.ticketingService.DataConfig = config;
    });
    this.resApiService.getTailorMadeParameters().subscribe({
      next: (tailorMadeParameters) => {
        this.tailorMadeParameters = tailorMadeParameters
      }
    })
    this.coreConfigService.currentModulesConfig
      .pipe(
        filter(
          (modules: Array<IEidosModuleConfiguration>) => !!modules.find((module) => module.moduleName === 'reservation')
        )
      )
      .subscribe((modules) => {
        const module = modules.find((module) => module.moduleName === 'reservation');

        if (module) {
          const reservationModule = module as IReservationEnvironment;
          if (reservationModule.companyAlias) {
            this.companyAlias = reservationModule.companyAlias
          }
          if (reservationModule.dynamicRouting) {
            this.dynamicRouting = reservationModule.dynamicRouting
          }
          if (reservationModule.openDepositPriceTypeNew) {
            this.configuredOpenDepositPriceTypes.push(reservationModule.openDepositPriceTypeNew);
          }
          if (reservationModule.openDepositPriceTypePast) {
            this.configuredOpenDepositPriceTypes.push(reservationModule.openDepositPriceTypePast);
          }
          if (reservationModule.grandVoyagePriceType) {
            this.grandVoyagePriceType = reservationModule.grandVoyagePriceType;
          }
          if (reservationModule.transportServiceTypeID) {
            this.transportServiceTypeID.next(reservationModule.transportServiceTypeID);
          }
          if (reservationModule.products) {
            this._productTypesCodesFromConfig = reservationModule.products;
          }

          this.preBookingUtility.setGuestPlaceholders(reservationModule.guestCodAdultPlaceholder, reservationModule.guestCodChildPlaceholder);

          this.ticketingService.UrlFrame = `${reservationModule.API.ticketingUrl}${reservationModule.API.frameTicketingPath}`;
          this.ticketingService.FrameOrigin = reservationModule.API.ticketingUrl;
          this.ticketingService.ModuleName = module.moduleName;

          this.useMockedPermissions = !!reservationModule.useMockedPermissions;
        }
      });

    this.coreSecurityService.currentLoggedUser.subscribe((user) => {
      if (user.isValid) {
        // If current user is valid, then retrieve user's permissions and dashboard
        this.coreLogService.logDebug(EidosLogSeverity.Log, 'Reservation service: DATA RELOAD on change user environment - START')
        this.companyDataReload.next(false)

        if (!this.currentBooking.isValid()) this.setOwner(ResOwner.EmptyOwner())

        combineLatest([this.resApiService.getUserInfo(), this.coreCacheService.getCachedData<IReservationCompany>('Companies'), this.getAvailableCompanies()])
          .subscribe({
            next: ([userInfo, companies, availableCompanies]) => {
              this.allCompanies = companies;

              const sortedCompanies = companies
                .filter(c => availableCompanies.find(ac => ac.code?.trim() === c.CompanyCode?.trim()))
                .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));

              this.allAvailableCompanies.next(sortedCompanies);

              const allSortedCompanies = companies.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
              this.allAvailableSortedCompanies.next(allSortedCompanies);

              if (!userInfo) {
                this.coreLogService.logDebug(EidosLogSeverity.Log, 'Reservation service: DATA RELOAD on change user environment - END');
                this.companyDataReload.next(true);
                this.companyDataOk.next(true);
                return;
              }
              this.currentUser.next(userInfo);
              this.resPaymentApiService.setPaymentHeaders({
                kk_company: userInfo.Company,
                // kk_companyID: userInfo.CompanyID,
              });

              let company = this.allCompanies.find(c => c.CompanyID === userInfo?.CompanyID)
              if(!company) company = this.allCompanies.find(c => this.checkCompanyAlias(c.CompanyID,userInfo?.CompanyID ?? 0))
              if (!!company) {
                this.loadCompanyData(company)
              }
            },
            error: () => {
              this.coreLogService.logDebug(EidosLogSeverity.Log, 'Reservation service: DATA RELOAD on change user environment - ERROR')
              this.companyDataReload.next(true)
            },
            complete: () => {
              this.coreLogService.logDebug(EidosLogSeverity.Log, 'Reservation service: DATA RELOAD on change user environment - COMPLITE getUserInfo')
            },
          });


        this.resApiService.getPermission().subscribe({
          next: (permission) => {
            const resPermissions = new ReservationPermission(permission, this.bookingUtility);
            this.currentReservationPermission.next(resPermissions);
          },
          error: () => {
            const permissions = this.useMockedPermissions
              ? ReservationPermission.mockedPermission()
              : ReservationPermission.emptyPermission();
            this.currentReservationPermission.next(permissions);
          },
          complete: () => { },
        });

        this.resApiService.getReservationDashboard().subscribe({
          next: (dashboard) => this.currentDashboardUser.next(dashboard),
          error: () => {
            this.currentDashboardUser.next([] as Array<IReservationHomeCategory>);
          },
          complete: () => { },
        });

        // Retrieve product types and set selected basing on config
        this.coreCacheService.getCachedData<IReservationProductType>('ProductTypes').then((productTypes) => {
          if (productTypes != undefined) {
            if (Array.isArray(productTypes) && Array.isArray(this._productTypesCodesFromConfig)) {
              const configuredProductTypes = productTypes.filter(item => this._productTypesCodesFromConfig.find((cod) => cod === item.ProductCod));
              this.configuredProductTypes.next(configuredProductTypes);
              this.configuredSuggestedItemsProductTypes.next(configuredProductTypes);
            }
          }
        });

        this.coreCacheService.getCachedData<IReservationCurrency>('Currencies').then((currencies) => {

          this.coreConfigService.currentConfig.subscribe((config) => {

            const currenciesFromConfig = config.formats.currencies ? Object.keys(config.formats.currencies) : [];
            const currenciesCodes = currencies.length > 0 ? currencies.map(currency => currency.CurrencyCod) : currenciesFromConfig;

            /**
             * Currently currencies are now set to all configured currencies
             * TODO: set currencies to user currencies
             *
             */
            this.allConfiguredCurrencies.next(currenciesCodes);
            this.setCurrencies(currenciesCodes);
          });

          if (currencies.length > 0) {
            this.coreFormatService.setCustomFormats({
              currencies: Object.fromEntries(currencies.map(currency => [currency.CurrencyCod, currency.Symbol]))
            });
          }
        });

        //this.loadCompanyData()

        // Retrieve service types and set selected basing on config
        this.resApiService.getServiceTypes().subscribe({
          next: (serviceTypes: Array<ReservationServiceType>) => {
            this.serviceTypes.next(serviceTypes);
          },
          error: () => {
            this.serviceTypes.next([]);
          },
          complete: () => { },
        });

      } else {
        // else reset user's permissions and dashboard
        this.currentUser.next(undefined);
        this.companyDataReload.next(true)
        this.companyDataOk.next(true)

        const permissions = this.useMockedPermissions
          ? ReservationPermission.mockedPermission()
          : ReservationPermission.emptyPermission();
        this.currentReservationPermission.next(permissions);
        this.currentDashboardUser.next([] as Array<IReservationHomeCategory>);

        this.currentOwner.next(ResOwner.EmptyOwner());

        this.coreCacheService.eraseCache();

        // Reset product types + experience types + service types
        this.configuredProductTypes.next([]);
        this.allAvalaiblePriceTypes.next([]);
        this.currentExperienceType.next(undefined);
        this.serviceTypes.next([]);
      }
    });

    this.allAvalaiblePriceTypes.subscribe((allAvalaiblePriceType) => {
      if (Array.isArray(allAvalaiblePriceType) && allAvalaiblePriceType.length > 0) {
        const cpt = this.currentPriceType.getValue()
        if(!allAvalaiblePriceType.find(pt=>pt.PriceTypeID===cpt?.PriceTypeID)) {
          const priceType = sessionStorage.getItem('priceType');
          if (priceType) {
            const defaultPriceType =
              allAvalaiblePriceType.find((pt) => pt.PriceTypeID === parseInt(priceType)) ?? allAvalaiblePriceType[0];
            if (defaultPriceType) {
              this.setPriceType(defaultPriceType.PriceTypeID);
              return;
            }
          }
        }

        // // TEST PURPOSES
        // this.setPriceType(allAvalaiblePriceType[0].PriceTypeID);
      }
    });

    this.allAvalaibleCurrencies.subscribe((allAvalaibleCurrencies) => {
      if (Array.isArray(allAvalaibleCurrencies) && allAvalaibleCurrencies.length > 0) {
        const ccur = this.currentCurrency.getValue()
        if(!allAvalaibleCurrencies.find(cur=>cur===ccur)) {
          const currentCurrency = sessionStorage.getItem('currency');
          if (currentCurrency) {
            this.setCurrency(currentCurrency);
            this.coreLogService.logDebug(EidosLogSeverity.Log, 'Reservation service: allAvalaibleCurrencies - set', currentCurrency)
          } else {
            this.setCurrency(allAvalaibleCurrencies.find(c => c === 'USD') ?? allAvalaibleCurrencies[0]);
            this.coreLogService.logDebug(EidosLogSeverity.Log, 'Reservation service: allAvalaibleCurrencies - set with no default', currentCurrency)
          }
        }
      }
    });

    this.currentOwner.subscribe(async (currentOwner) => {
      if (currentOwner.isValid) {
        this.currentOwnerCurrencies.next(currentOwner.currencies ?? []);
        this.allValidCurrencies.next(currentOwner.currencies ?? [])
      } else {
        this.setCurrencies(this.allConfiguredCurrencies.getValue());
        this.coreCacheService.getCachedData<IReservationCurrency>('Currencies').then(currencies=>this.allValidCurrencies.next(currencies.map(c=>new ResCurrency(c))))
      }
      this.setPriceTypes(currentOwner);
    });

    this.currentOwnerCurrencies.subscribe((currenciesXPriceTypes) => {
      const currentOwner: ResOwner = this.currentOwner.getValue();

      if (currentOwner.isValid) {
        // Set current owner available currencies
        if (Array.isArray(currenciesXPriceTypes) && currenciesXPriceTypes.length > 0) {
          const currencies = currenciesXPriceTypes.filter((c) => !!c.currencyCod).map((c) => c.currencyCod as string);
          this.setCurrencies(currencies);

          const ccur = this.currentCurrency.getValue()
          if(!currenciesXPriceTypes.find(cur=>cur.currencyCod===ccur)) {
            const defaultCurrency = currenciesXPriceTypes.find((c) => !!c.isDefault) ?? currenciesXPriceTypes[0];

            this.setCurrency(defaultCurrency?.currencyCod ?? '');
            this.coreLogService.logDebug(EidosLogSeverity.Log, 'Reservation service: currentOwnerCurrencies - set', defaultCurrency?.currencyCod ?? '')
          }
        } else {
          this.setCurrencies([]);
          this.coreLogService.logDebug(EidosLogSeverity.Log, 'Reservation service: currentOwnerCurrencies - empty currencies')
        }
      }
    });

    this.allAvalaibleLoyaltyDiscounts.subscribe((allAvalaibleLoyaltyDiscounts) => {
      if (Array.isArray(allAvalaibleLoyaltyDiscounts) && allAvalaibleLoyaltyDiscounts.length > 0) {
        const loyaltyDiscount = sessionStorage.getItem('loyaltyDiscount');
        if (loyaltyDiscount) {
          const defaultLoyaltyDiscount =
            allAvalaibleLoyaltyDiscounts.find((d) => d.LoyaltyDiscountID === parseInt(loyaltyDiscount)) ?? allAvalaibleLoyaltyDiscounts[0];
          if (defaultLoyaltyDiscount) {
            this.setLoyaltyDiscount(defaultLoyaltyDiscount.LoyaltyDiscountID);
            return;
          }
        }

        // TEST PURPOSES
        this.setLoyaltyDiscount(allAvalaibleLoyaltyDiscounts[0].LoyaltyDiscountID);
      }
    });

    this.coreCacheService.getCachedData<IReservationPackageType>('PackageTypes').then(packageTypes=>this.allPackageTypes = packageTypes);

  }

  loadCompanyData(company: IReservationCompany,forse = false) {
    if (!forse && this.checkCompanyAlias(company.CompanyID,this.currentCompany.value?.CompanyID ?? 0)) return
    this.currentCompany.next(company)
    lastValueFrom(
      combineLatest([this.setPriceTypes(ResOwner.EmptyOwner()), this.setShips(), this.coreCacheService.getCachedData<IReservationLoyaltyDiscount>('LoyaltyDiscounts'),this.resApiService.getSearchFilterPreferences()])
    ).then(v => {
      const loyaltyDiscounts = v[2]
      this.setLoyaltyDiscounts(loyaltyDiscounts, company.CompanyID ?? 0, true)
      this.coreLogService.logDebug(EidosLogSeverity.Log, 'Reservation service: DATA RELOAD on change company - END', v)
      this.companyDataReload.next(true)
      this.companyDataOk.next(true)
      this.searchFilterPreferences.next(v[3])
    }).catch(err => {
      this.coreLogService.logDebug(EidosLogSeverity.Error, 'Reservation service: ERROR DATA RELOAD on change company - END', err)
      this.companyDataOk.next(true)
    })
  }
  checkIfPackageNeedSuite(pkgTypeID: number) {
    return this.allPackageTypes.find(p=>p.PackageTypeID===pkgTypeID)?.NeedSuite === 'Y'
  }
  /**
   * Setup environment subjects from Query Params from an url.
   * QP struct:
   *  OwnerId - IndividualId for direct AgentId for agency
   *  OwnerAgency? - Agency Id if not direct
   *  OwnerCurrency? - Owner currency if different from default
   * Includes:
   * - owner setup
   * - owner currency setup
   * - owner price type setup
   *
   * @param {Params} selected
   * @memberof ReservationService
   */
  public async updateEnvFromOwner(selected: IResOwnerSelect) {
    const ownerId = selected.OwnerID;
    const isDirect = !selected.OwnerAgency;
    if (isDirect) {
      this.resApiService
        .getOwners({ IndividualID: ownerId })
        .subscribe({
          next: (directs: ReservationIndividual[]) => {
            if (Array.isArray(directs) && directs.length > 0) {
              const direct = directs[0];
              if (selected.CurrencyCod) direct.setCurrency(selected.CurrencyCod)
              this.setOwner(new ResOwner(directs[0]));
            }
          },
        });
    } else {
      this.resApiService.getAgents({ AgentID: ownerId, IsWebSiteAgent: "N", AgencyID: selected.OwnerAgency }).subscribe({
        next: (agents: ReservationAgent[]) => {
          if (Array.isArray(agents) && agents.length > 0) {
            let agent = new ResOwner(agents[0]);

            this.setOwner(agent);
            if (selected.CurrencyCod) agent.setCurrency(selected.CurrencyCod)
          }
        },
      });
    }
  }
  /**
   * Setup environment subjects to work with current booking.
   * Includes:
   * - Booking owner setup
   * - Booking owner currency setup
   * - Booking owner price type setup
   *
   * @param {ResBooking} booking
   * @memberof ReservationService
   */
  public async updateEnvWithBooking(booking: ResBooking) {
    const self = this;

    if (booking.isValid()) {

      const setBkgEnvParams = function (newOwner?: ResOwner) {
        self.setOwner(newOwner);
        self.setCurrency(booking.bkgCurrency);
        if (booking.priceTypeID !== undefined && !self.configuredOpenDepositPriceTypes.includes(booking.priceTypeID)) {
          self.setPriceType(booking.isOpenDeposit ? 3 : booking.priceTypeID, !booking.isOpenDeposit);
        }

      };

      if (booking.owner?.individualID) {

        let owner = new ResOwner();

        if (booking.owner.isDirect()) {
          this.resApiService
            .getOwners({ IndividualID: booking.owner.individualID })
            .subscribe({
              next: (directs: ReservationIndividual[]) => {

                if (Array.isArray(directs) && directs.length > 0) {
                  owner = new ResOwner(directs[0]);
                }

                setBkgEnvParams(owner);
              },
              error: (err: IDynamicApiResponse) => {
                this.utilityDialogs.error({
                  message: 'Error while retrieving booking owner',
                  error: err?.Errors?.map((e: any) => e.ErrorDesc),
                  title: `ERROR`,
                  size: '380px',
                });
                setBkgEnvParams();
              }
            });
        }
        else {
          const isWebSiteAgent = !!booking.owner.isWebSiteAgent;

          this.resApiService.getAgents({ AgentID: booking.owner.individualID, IsWebSiteAgent: isWebSiteAgent ? "Y" : "N", AgencyID: booking.owner.agencyID })
            .subscribe({
              next: (agents: ReservationAgent[]) => {

                if (Array.isArray(agents) && agents.length > 0) {
                  owner = new ResOwner(agents[0]);

                  /**
                   * RF+MO 18.10.2022
                   * Quando viene fatto un OD di agenzia da sito di Crystal, l'utente specifica un'agenzia censita ma un agente potenzialmente sconosciuto.
                   * Non avendo controllo dei dati inseriti dall'utente, l'agente viene salvato nella tabella dei direct
                   * Successivamente quindi va ricercato lì, dove però non si ha collegamento con la sua agenzia (agencyId + agencyName)
                   * Se è quindi un agent da WebSite, creiamo noi il collegamento con i dati presenti nel booking
                   */
                  if (isWebSiteAgent) {
                    owner.isWebSiteAgent = isWebSiteAgent;
                    owner.agencyName = booking.owner.agency;
                  }
                }

                setBkgEnvParams(owner);
              },
              error: (err: IDynamicApiResponse) => {
                this.utilityDialogs.error({
                  message: 'Error while retrieving booking owner',
                  error: err?.Errors?.map((e: any) => e.ErrorDesc),
                  title: `ERROR`,
                  size: '380px',
                });
                setBkgEnvParams();
              }
            });
        }
      } else {
        setBkgEnvParams();
      }
    }
  }

  /**
   * Environment parameters are global optional property selected during the use of portal
   * and recorded for subsequentil use.
   * Example of global properties is group, brand etc...
   * Any parameter is stored individualy for more easy use becouse potentialy are select, clear or monitoring separately.
   * This properties are stored in a BehaviorSubject and in a session storage for remember in case of page reload.
   * All the key used in session storege are prefixed for easy cleaning in one call without lost other keys.
   *
   * @param {IResEnvParameters} envp
   * @memberof ReservationService
   */
  public updateEnviromentParameters(envp: IResEnvParameters) {
    if (envp.groupID != this.envPrmGroup.getValue()) {
      this.envPrmGroup.next(envp.groupID);
      sessionStorage.setItem(SessionStorageKeys.ENVIRONMENT_PARAMETER.replace('{name}', this.ENVPRMS.GROUPKEY), envp.groupID.toString());
    }
    if (envp.priceTypeID != this.envPrmPriceType.getValue()) {
      this.envPrmPriceType.next(envp.priceTypeID);
      this.setPriceType(envp.priceTypeID);
      sessionStorage.setItem(SessionStorageKeys.ENVIRONMENT_PARAMETER.replace('{name}', this.ENVPRMS.PRICETYPEKEY), envp.priceTypeID.toString());
    }
  }
  public clearEnviromentParameters() {
    for (var i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key?.startsWith(SessionStorageKeys.ENVIRONMENT_PARAMETER.replace('{name}', ''))) sessionStorage.removeItem(key);
    }
  }

  public ENVPRMS = {
    GROUPKEY: 'group',
    NOGROUP: 0,
    PRICETYPEKEY: 'pricetype',
    NOPRICETYPE: 0,
  }
  private getStoredGroup(): number {
    const s = sessionStorage.getItem(SessionStorageKeys.ENVIRONMENT_PARAMETER.replace('{name}', this.ENVPRMS.GROUPKEY));
    return s === null ? this.ENVPRMS.NOGROUP : +s;
  }
  private getStoredPriceType(): number {
    const s = sessionStorage.getItem(SessionStorageKeys.ENVIRONMENT_PARAMETER.replace('{name}', this.ENVPRMS.PRICETYPEKEY));
    return s === null ? this.ENVPRMS.NOPRICETYPE : +s;
  }
  /**
   * Current group from env (zero for no group selected)
   *
   * @type {(BehaviorSubject<number>)}
   * @memberof ReservationService
   */
  public envPrmGroup: BehaviorSubject<number> = new BehaviorSubject<number>(this.getStoredGroup());
  /**
   * Current pricetype form env (zero for no group selected)
   *
   * @type {(BehaviorSubject<number>)}
   * @memberof ReservationService
   */
  private envPrmPriceType: BehaviorSubject<number> = new BehaviorSubject<number>(this.getStoredGroup());
  public environmentParameters = {
    group: {
      hasSelection: () => this.envPrmGroup.getValue() > 0,
      getValue: (def?: number): number | undefined => {
        const g = this.envPrmGroup.getValue();
        return g > 0 ? g : def;
      },
      clear: () => {
        sessionStorage.removeItem(SessionStorageKeys.ENVIRONMENT_PARAMETER.replace('{name}', this.ENVPRMS.GROUPKEY));
        sessionStorage.removeItem(SessionStorageKeys.ENVIRONMENT_PARAMETER.replace('{name}', this.ENVPRMS.PRICETYPEKEY));
        this.envPrmGroup.next(this.ENVPRMS.NOGROUP);
        this.envPrmPriceType.next(this.ENVPRMS.NOPRICETYPE);
        //clear url
        const parameters: any = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        const np: any = {};
        Object.keys(parameters).filter((p: any) => p.toLowerCase() === 'groupid' || p.toLowerCase() === 'pricetypeid').forEach((p: any) => {
          np[p] = undefined;
        });
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: np,
            queryParamsHandling: 'merge'
          }
        );
      },
      getStoredValue: () => {
        return this.getStoredGroup();
      }
    },
    priceType: {
      hasSelection: () => this.envPrmPriceType.getValue() > 0,
      getValue: (def?: number): number | undefined => {
        const g = this.envPrmPriceType.getValue();
        return g > 0 ? g : def;
      },
      clear: () => {
        sessionStorage.removeItem(SessionStorageKeys.ENVIRONMENT_PARAMETER.replace('{name}', this.ENVPRMS.PRICETYPEKEY));
        this.envPrmPriceType.next(this.ENVPRMS.NOPRICETYPE);
        //clear url
        const parameters: any = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        const np: any = {};
        Object.keys(parameters).filter((p: any) => p.toLowerCase() === 'pricetypeid').forEach((p: any) => {
          np[p] = undefined;
        });
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: np,
            queryParamsHandling: 'merge'
          }
        );
      },
      getStoredValue: () => {
        return this.getStoredPriceType();
      }
    }
  }

  goToReservationHome() {
    this.router.navigate([RESERVATION_ROOT, ReservationRoute.Home])
  }

  /**
   * Resets search filters
   *
   * @memberof ReservationService
   */
  public resetFilters() {
    this.currentFilters.next({});
  }
  /**
   * Set or clear the current owner
   *
   * @param {ResOwner} [owner]
   * @memberof ReservationService
   */
  public setOwner(owner?: ResOwner) {
    if (owner && owner.isValid) {
      sessionStorage.setItem('owner', owner.toStorage());
      //@TODO RDP change only if is different
      this.currentOwner.next(owner);
    } else {
      sessionStorage.removeItem('owner');
      this.environmentParameters.group.clear();
      this.currentOwner.next(ResOwner.EmptyOwner());
    }
  }
  /**
   * Set the current company
   *
   * @param {ResOwner} [owner]
   * @memberof ReservationService
   */
  public setCompany(company?: IReservationCompany, withMessage = false, force = false) {

    if (!company) return
    let validCompany = company

    if (!this.allAvailableCompanies.getValue().find(c=>c.CompanyID===company!.CompanyID)) {
      const c = this.allAvailableCompanies.getValue().find(c=>this.checkCompanyAlias(c.CompanyID,company!.CompanyID))
      if(c) validCompany = c
    }

    const newValue = validCompany.CompanyID
    this.companyDataReload.next(false)

    if (!this.bookingUtility.hasCurrentBooking) this.currentOwner.next(ResOwner.EmptyOwner())

    this.coreUtilityService.setCompany(validCompany.CompanyCode).subscribe({
      next: (isOk) => {
        if (!isOk) {
          this.coreUtilityService.utilityDialogs.info({
            message: 'Company not changed. There was some error saving your selection'
          });
          return
        } else if (withMessage && newValue !== this.currentCompany.value?.CompanyID ) {
          this.coreUtilityService.utilityDialogs.info({
            title: 'Company setting',
            message: 'The working company has been successfully changed'
          });
        }

        this.loadCompanyData(validCompany,force)
      },
      error: (error) => {
        this.coreUtilityService.utilityDialogs.error({
          title: 'Error while setting company',
          dialogType: EidosUtilityDialogDialogType.Error,
          message: error
        });
        this.companyDataReload.next(true)
      },
      complete: () => {
      }
    });
  }
  /**
   * Set the current company
   *
   * @param {ResOwner} [owner]
   * @memberof ReservationService
   */
  public setCompanySilent(company?: IReservationCompany | number): Promise<boolean> {
    if (!company) return lastValueFrom(of(true))

    if (!this.bookingUtility.hasCurrentBooking) this.currentOwner.next(ResOwner.EmptyOwner())

    const c = typeof company === 'number' ? this.allAvailableCompanies.getValue().find(c => c.CompanyID === company) : company
    if (!c) return lastValueFrom(of(true))

    return lastValueFrom(this.coreUtilityService.setCompany(c.CompanyCode)).then(isOk => {
      if (isOk) this.loadCompanyData(c)
      return isOk
    });
  }
  /**
   * Set avalaible currencies basing on owner or user
   *
   * @param {string[]} currencies
   * @memberof ReservationService
   */
  public setCurrencies(currencies: string[]) {
    // Remove duplicates
    let newCurrencies = currencies.filter((elem, index, self) => index === self.indexOf(elem));
    if (this.bookingUtility.hasCurrentBooking) {
      //add booking currency if need it
      const bookingCurrency = this.bookingUtility.current.getValue()?.bkgCurrency;
      if (bookingCurrency && !currencies.find(c => c === bookingCurrency)) {
        newCurrencies = newCurrencies.splice(0, 0, bookingCurrency);
      }
    }

    this.allAvalaibleCurrencies.next(newCurrencies);
  }
  /**
   * Set avalaible price types basing on owner or user
   *
   * @private
   * @param {Array<IReservationPriceType | IReservationApiPriceType>} priceTypes
   * @memberof ReservationService
   */
  private async setPriceTypes(owner: ResOwner) {

    const allPriceTypes = await this.coreCacheService.getCachedData<IReservationPriceType>('PriceTypes');

    const sortedAllPriceTypes = _.orderBy(allPriceTypes, ['PriceTypeCod'], ['asc']);
    this.allConfiguredPriceTypes.next(sortedAllPriceTypes);

    const companyID = this.currentCompany.getValue()?.CompanyID
    if (!companyID) return

    const self = this
    let acceptablePriceTypes = allPriceTypes
      .filter(pt => pt.PriceTypeCod)
      .filter(
        (priceType) => self.checkCompanyAlias(priceType.CompanyID, companyID)
      )
      .filter(
        // Remove Open Deposit PT
        (priceType) => !self.configuredOpenDepositPriceTypes.includes(priceType.PriceTypeID)
      )
      .filter(
        // Remove PT with unknown ID
        (priceType) => !owner.isValid || owner.priceTypes.find(p => p.PriceTypeID === priceType.PriceTypeID)
      )
      .filter(
        // Remove PT with permission if user don't has it
        (priceType) => priceType.PermissionCod === 'ALL' || self.currentReservationPermission.getValue().hasPermission(priceType.PermissionCod)
      );

    this.allAvalaiblePriceTypes.next(acceptablePriceTypes);

    // Eventually set default PT if current price type is not valid
    const cpt = this.currentPriceType.getValue()
    if(!acceptablePriceTypes.find(apt=>apt.PriceTypeID===cpt?.PriceTypeID)) {
      const defaultPriceType = acceptablePriceTypes.find((priceType) => !!(<any>priceType).IsDefaultFilter);
      if (defaultPriceType) {
        this.setPriceType(defaultPriceType.PriceTypeID);
      }
    }
  }
  private async setShips() {
    const allShips = await this.coreCacheService.getCachedData<IReservationShip>('Ships');
    const alSearchableSuiteCategories = await this.coreCacheService.getCachedData<IReservationSearchableSuiteCategory>('SearchableSuiteCategories');

    const allCompanies = this.allAvailableCompanies.getValue()

    const self = this
    let visibleShips = allShips
      .filter(s => s.ShipID)
      .filter(
        (s) => allCompanies.find(c => self.checkCompanyAlias(c.CompanyID, s.CompanyID))
      );


    const allVisibleSearchableSuiteCategories = alSearchableSuiteCategories
    .filter(
      (s) => allCompanies.find(c => self.checkCompanyAlias(c.CompanyID, s.CompanyID))
    );

    const visibleShipsUnique = _.uniqBy(visibleShips, 'ShipID')

    const visibleShipsOrdered = _.orderBy(visibleShipsUnique, ['IsShip', 'Ship'], ['desc', 'asc']);

    this.allVisibleShips.next(visibleShipsOrdered);
    this.allVisibleSearchableSuiteCategories.next(allVisibleSearchableSuiteCategories)

    const companyID = this.currentCompany.getValue()?.CompanyID
    if (!companyID) return


    const acceptableShips = visibleShips
      .filter(
        (s) =>  this.checkCompanyAlias(s.CompanyID, companyID)
      )
      .filter(  // Remove duplicates
        (elem, index, self) => index === self.findIndex((elem2) => elem.ShipID === elem2.ShipID)
      );

    this.allAvalaibleShips.next(acceptableShips);
  }

  /**
   * Set avalaible loyalty discounts
   *
   * @private
   * @param {Array<IReservationLoyaltyDiscount>} loyaltyDiscounts
   * @memberof ReservationService
   */
  private async setLoyaltyDiscounts(
    loyaltyDiscounts: Array<IReservationLoyaltyDiscount>,
    companyID: number,
    setAsConfigured: boolean = false
  ) {
    const configuredLoyaltyDiscounts = setAsConfigured
      ? loyaltyDiscounts
      : (await this.coreCacheService.getCachedData<IReservationLoyaltyDiscount>('LoyaltyDiscounts'));

    const applicationLoyaltyDiscounts: Array<IReservationLoyaltyDiscount> = [
      { CompanyID: companyID, LoyaltyDiscountID: 0, GuestType: 'New Guest', OptionCod: '', OptionDesc: '', DiscountValue: 0, DiscountType: '' },
      ...configuredLoyaltyDiscounts.filter(d => this.checkCompanyAlias(d.CompanyID,companyID))
    ];

    this.allAvalaibleLoyaltyDiscounts.next(applicationLoyaltyDiscounts);
  }

  /**
   * Set current currency
   *
   * @param {string} currency
   * @memberof ReservationService
   */
  public setCurrency(currency: string) {
    if (this.bookingUtility.hasCurrentBooking) {
      const bookingCurrency = this.bookingUtility.current.getValue()?.bkgCurrency;
      if (bookingCurrency) {
        if (this.currentCurrency.getValue() != bookingCurrency) {
          //Current booking must meet
          sessionStorage.setItem('currency', bookingCurrency);
          this.currentCurrency.next(bookingCurrency);

          let allCurrency = this.allAvalaibleCurrencies.getValue() ?? [];
          if (!allCurrency.find((item) => item === currency)) {
            allCurrency.push(bookingCurrency);
            this.setCurrencies(allCurrency);
          }
        }
        //prevent chage
        return
      }
    }
    const allAvalaibleCurrencies = this.allAvalaibleCurrencies.getValue() ?? [];

    let currentCurrency = allAvalaibleCurrencies.find((item) => item === currency);

    // Set the first avalaible currency if the default one is not available
    if (!currentCurrency && allAvalaibleCurrencies.length > 0) {
      currentCurrency = allAvalaibleCurrencies.find(c=>c==='USD') ?? allAvalaibleCurrencies[0];
    }

    if (currentCurrency) {
      sessionStorage.setItem('currency', currentCurrency);
      if (this.currentCurrency.getValue() !== currentCurrency) this.currentCurrency.next(currentCurrency);
    }
  }
  /**
   * Set current price type if no group or booking are set
   * If group or booking are set then set the PT only if the PT is the same
   *
   * @param {number} [priceTypeID]
   * @param {boolean} [forceSet=false] Skip current booking and current group check
   * @memberof ReservationService
   */
  public setPriceType(priceTypeID?: number, forceSet: boolean = false) {

    // If a PT is set in env, prevent different price types settings
    const envPriceType = this.environmentParameters.priceType.getValue();
    const isLockedByEnv = envPriceType && envPriceType !== this.ENVPRMS.NOPRICETYPE && envPriceType !== priceTypeID;

    // If a current booking is set, prevent different price types settings
    let isLockedByBkg = this.bookingUtility.hasCurrentBooking;
    if (isLockedByBkg) {
      const booking = this.bookingUtility.current.getValue();
      const bkgPTID = booking?.priceTypeID;
      isLockedByBkg = !booking.isOpenDeposit && !!bkgPTID && (bkgPTID !== priceTypeID);
    }

    // If forceSet = true then skip preventions
    if (forceSet || (!isLockedByEnv && !isLockedByBkg)) {
      const priceType = this.allAvalaiblePriceTypes.getValue()?.find((item) => item.PriceTypeID === priceTypeID);

      // Set current price type if found in availables
      if (priceType) {
        sessionStorage.setItem('priceType', priceType.PriceTypeID.toString());
        const v = this.currentPriceType.getValue();
        if (!v
          || v.PriceTypeID !== priceType.PriceTypeID
          || v.PriceTypeCod !== priceType.PriceTypeCod
          || v.PriceTypeName !== priceType.PriceTypeName
        ) {
          this.currentPriceType.next(priceType);
        }
      }
    }
  }

  public setCruiseSuiteCategory(suiteCategory: IReservationCruiseCategory) {
    if (suiteCategory) {
      this.currentCruiseSuiteCategory.next(suiteCategory);
    }
  }
  public setRequestedPromoIds(promoIds: number[]) {
    this.currentRequestedPromoIds.next(promoIds);
  }
  public setGuests(guests: ReservationAccomodation[]) {
    if (guests) {
      this.currentAccomodations.next(guests)
    }
  }
  public addGuests(guests: ReservationAccomodation[]) {
    const g = this.currentAccomodations.getValue()
    this.currentAccomodations.next(g.concat(guests))
  }

  private accomodationsChange(accomodations: ReservationAccomodation[]) {
    const oldAccomodations = this.currentAccomodations.getValue()
    if(oldAccomodations.length != accomodations.length) return true

    return oldAccomodations.some((a,idx)=>a.Adult != accomodations[idx].Adult || a.Child != accomodations[idx].Child || a.Infant != accomodations[idx].Infant)
  }
  public setAccomodations(accomodations: ReservationAccomodation[]) {
    const newAccomodation = accomodations.length>0 ? accomodations : [ReservationAccomodation.newRoom(1)]

    if (this.accomodationsChange(newAccomodation)) this.currentAccomodations.next(newAccomodation)
  }
  public setCruiseSuiteCapacity(suiteCapacity: IReservationCruiseSuiteCapacity) {
    if (suiteCapacity) {
      this.currentCruiseSuiteCapacity.next(suiteCapacity);
    }
  }

  public touchstart(event: any) {
    this.touchstartX = event.changedTouches[0].screenX;
    this.touchstartY = event.changedTouches[0].screenY;
  }

  public touchend(event: any) {
    this.touchendX = event.changedTouches[0].screenX;
    this.touchendY = event.changedTouches[0].screenY;
    this.handleGesture();
  }

  public handleGesture() {
    const {
      swipeLeft = () => { },
      swipeRight = () => { },
      swipeTop = () => { },
      swipeBottom = () => { },
      tap = () => { },
    } = this.swipeCallbacks.value;

    if (this.touchendX < this.touchstartX) {
      swipeLeft();
    }

    if (this.touchendX > this.touchstartX) {
      swipeRight();
    }

    if (this.touchendY < this.touchstartY) {
      swipeTop();
    }

    if (this.touchendY > this.touchstartY) {
      swipeBottom();
    }

    if (this.touchendY === this.touchstartY) {
      tap();
    }
  }

  public handleSwipe() {
    this.touchstart = this.touchstart.bind(this);
    this.touchend = this.touchend.bind(this);
    window.addEventListener('touchstart', this.touchstart, false);
    window.addEventListener('touchend', this.touchend, false);
  }

  public removeHandleSwipe() {
    window.removeEventListener('touchstart', this.touchstart, false);
    window.removeEventListener('touchend', this.touchend, false);
  }

  /**
   * Parse params to pascal case
   *
   * @param {*} params
   * @return {*}  {*}
   * @memberof ReservationService
   */
  public parseGenericParams(params: any): any {
    let parsedParams: any = {};
    for (let p in params) {
      if (params[p] !== '' && params[p] != null) {
        (parsedParams as any)[p.charAt(0).toUpperCase() + p.slice(1)] = params[p];
      }
    }
    return parsedParams;
  }

  /**
   * Build an array of dates between two dates
   *
   * @param {DateTime} d1
   * @param {DateTime} [d2]
   * @return {*}
   * @memberof ReservationService
   */
  public static buildDateRange(d1: DateTime, d2?: DateTime) {
    if (!d1 && !d2) return [];
    if (!d2) return [d1];
    let days = d2.diff(d1, 'days').days;
    let dateRange = [];
    for (let i = 0; i <= days; i++) {
      dateRange.push(d1.plus({ days: i }));
    }
    return dateRange;
  }

  /**
   * Get hours and minutes from total minutes
   *
   * @static
   * @param {number} totalMinutes
   * @return {*}  string
   * @memberof ReservationService
   */
  public static toHoursAndMinutes(totalMinutes: number | undefined): string {
    if (!totalMinutes && totalMinutes !== 0) return '';
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  }

  public static getNumberFromString(str: string): number {
    const number = Number(str.replace(/[^0-9.-]+/g, ''))
    return !isNaN(number) ? number : 0;
  }

  /**
   * Set current discount
   *
   * @param {number} discount
   * @memberof ReservationService
   */
  public setLoyaltyDiscount(loyaltyDiscountId?: number) {
    const loyaltyDiscount = this.allAvalaibleLoyaltyDiscounts.getValue()?.find((item) => item.LoyaltyDiscountID === loyaltyDiscountId);

    if (loyaltyDiscount) {
      this.currentLoyaltyDiscount.next(loyaltyDiscount);
      sessionStorage.setItem('loyaltyDiscount', loyaltyDiscount.LoyaltyDiscountID.toString());
    }
  }
  /**
   * Is ticketing button visible flag
   * (Ticketing component configured and permission allowed)
   *
   * @readonly
   * @memberof ReservationService
   */
  get isTicketingVisible() {
    const permission = this.currentReservationPermission.getValue();
    return !isEmpty(this.ticketingService.UrlFrame) && !isEmpty(this.ticketingService.FrameOrigin) && !isEmpty(this.ticketingService.ModuleName) && permission.canI(ReservationAction.CreateTicket).isAllowed;
  }
  /**
   * Opens ticketing popup
   *
   * @memberof ReservationService
   */
  openTicketing() {

    if (this.isTicketingVisible) {

      let resTicketFormData: IResTicketingSendData = {
        MoreData: {
        }
      };

      const currentBkg = this.bookingUtility.current.getValue();

      if (currentBkg && currentBkg.bkgID !== ResBooking.INVALID_BOOKING) {
        resTicketFormData.MoreData.bkgID = currentBkg.bkgID;
      }

      this.ticketingService.OpenTicket(resTicketFormData, true, true);
    }
  }
  /**
   * Close ticketing popup
   *
   * @memberof ReservationService
   */
  closeTicketing() {
    this.ticketingService.CloseTicket();
  }
  /**
   * Reset search filters and
   *
   * @memberof ReservationService
   */
  leaveCurrentBooking() {
    this.resetFilters();
    this.bookingUtility.leaveCurrentBooking();
  }

  /**
   * Get current origin url
   *
   * @readonly
   * @memberof ReservationService
   */
  get currentOriginUrl() {
    return `${window.location.origin}/${RESERVATION_ROOT}`;
  }

  /**
   * Get booking link by booking id
   *
   * @param {number} bkgID
   * @return {*}
   * @memberof ReservationService
   */
  getBookingLink(bkgID: number) {
    return `${this.currentOriginUrl}/${ReservationRoute.Booking}/${bkgID}`;
  }

  packageRefreshPdf(packageID: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {

      this.resApiService.packageRefreshPdf(packageID).subscribe({
        next: (_) => {
          resolve(true);
        },
        error: (_: IDynamicApiResponse) => {
          resolve(false);
        }
      });
    });
  }
  getServiceTypeIcon(serviceTypeName: string): IEidosIcon {
    switch ((serviceTypeName).trim().toLowerCase()) {
      case 'accommodation':
        return {
          iconType: EidosIconType.SVG,
          iconCode: 'hotel-stroke',
          iconSize: 1,
        };
      case 'transport':
        return {
          iconType: EidosIconType.Awesome,
          iconCode: 'fa-car',
          iconSize: 1,
        };
      case 'food & beverage':
        return {
          iconType: EidosIconType.Awesome,
          iconCode: 'fa-glass-cheers',
          iconSize: 1,
        };
      case 'flights':
        return {
          iconType: EidosIconType.SVG,
          iconCode: 'plane-stroke',
          iconSize: 1,
        };
      case 'discount':
        return {
          iconType: EidosIconType.Awesome,
          iconCode: 'fa-gifts',
          iconSize: 1,
        };
      case 'cruise':
        return {
          iconType: EidosIconType.Awesome,
          iconCode: 'fa-ship',
          iconSize: 1,
        };
      default:
        return {
          iconType: EidosIconType.Awesome,
          iconCode: 'fa-plus-circle',
          iconSize: 1,
        };
    }
  }
  getBookingGuestGroups(bkgID:number) {
    return this.resApiService.getBookingGuestGroups(bkgID)
  }
  getBookingPayers(bkgID:number) {
    return this.resApiService.getBookingPayers(bkgID)
  }
  getPayerDepartments(bkgID:number){
    return this.resApiService.getPayerDepartments(bkgID)
  }
  manageBookingGuestGroups(bkgID:number,guestGroups:ResGuestGroup[]) {
    return this.resApiService.manageBookingGuestGroups(bkgID,guestGroups)
  }
  getPackagePricesHistory(params: IResPackagePricesHistoryParams) {
    return this.resApiService.getPackagePricesHistory(params)
  }

  getBookingItems(bkgID:number,filters?:IResBkgItemApiParams) {
    return this.resApiService.getBookingItems(bkgID,filters)
  }

  public getApplyPromo(
    params: IReservationPromoApplyPramaters
  ): Observable<ResPromoApply[]> {
    const owner = this.currentOwner.getValue();

    if (owner.isAgent()) {
      params.AgencyID = owner.agencyID;
    } else if (owner.isDirect()) {
      params.IndividualID = owner.individualID;
    }
    return this.resApiService.getApplyPromo(params)
  }

  getCabinOccupancyByMonth(params:IResGetCabinOccupancyParams) {
    return this.resApiService.getCabinOccupancy(params).pipe(
      map<ReservationCabinOccupancy[],ReservationCabinOccupancyByMonth>((occupancies) => {
        const occupancyByMonth = new ReservationCabinOccupancyByMonth()
        occupancyByMonth.year = params.Year
        occupancyByMonth.month = params.Month
        occupancyByMonth.daysOfMonth = DateTime.fromJSDate(new Date(occupancyByMonth.year,occupancyByMonth.month-1,1)).daysInMonth
        occupancyByMonth.startDate = DateTime.fromJSDate(new Date(occupancyByMonth.year,occupancyByMonth.month-1,1))
        occupancyByMonth.endDate = DateTime.fromJSDate(new Date(occupancyByMonth.year,occupancyByMonth.month-1,occupancyByMonth.daysOfMonth))
        occupancyByMonth.viewName = occupancyByMonth.startDate.toFormat('LLL yyyy')
        const ships= new Set<number>()
        occupancies.forEach( occupancy => {
          ships.add(occupancy.shipID)
        })

        occupancyByMonth.ships = Array.from(ships).map(id=>{
          const shipName = occupancies.find(occupancy=>occupancy.shipID===id)?.ship ?? ''
          const ship = new ReservationCabinOccupancyShip(id,shipName)
          const voyageIds = new Set<number>()

          const cabins = new Set<string>()
          occupancies.filter(occupancy=>occupancy.shipID===id)
                     .forEach(occupancy=>{
                      cabins.add(occupancy.suiteNo)
                      voyageIds.add(occupancy.voyageID)
                    })

          ship.suites = Array.from(cabins).filter(c=>c!=='WL')
          ship.voyageIds = Array.from(voyageIds)

          return ship
        })

        occupancyByMonth.ships.forEach(s=>{

          let cabinStatus:ReservationCabinOccupancyStatus | null = null

          occupancies.filter(occupancy=>occupancy.shipID===s.shipID).forEach( occupancy => {
            if(!cabinStatus || cabinStatus.suiteNo !== occupancy.suiteNo || cabinStatus.voyageID !== occupancy.voyageID) {
              if(cabinStatus) s.cabinStatus.push(cabinStatus)
              cabinStatus = ReservationCabinOccupancyStatus.fromOccupancy(occupancy,occupancyByMonth.month,occupancyByMonth.daysOfMonth)
            } else {
              const guest = new BookingGuest()
              guest.firstName = occupancy.firstName
              guest.lastName = occupancy.lastName
              cabinStatus.guests.push(guest)
            }
          })

          //add last item
          if(cabinStatus) s.cabinStatus.push(cabinStatus)

          s.wlStatus = s.cabinStatus.filter(cs=>cs.suiteNo==='WL')
          s.cabinStatus = s.cabinStatus.filter(cs=>cs.suiteNo!=='WL')

          s.voyageIds.forEach(id=>{
            const voy = s.cabinStatus.find(cs=>cs.voyageID===id)
            if(voy) {
              const startDay = voy.statusFrom.month === occupancyByMonth.month ? voy.statusFrom.day : 1
              const endDay = voy.statusTo.month === occupancyByMonth.month ? voy.statusTo.day : occupancyByMonth.daysOfMonth
              const v = new ReservationCabinOccupancyVoyage()
              v.id = voy.voyageID
              v.name = voy.voyageNumber
              v.title = voy.voyageNumber + ' (' +voy.voyageName+')'
              v.starDay = startDay
              v.endDay = endDay
              v.days = endDay-startDay+1
              if(voy.isMaster) s.voyagesMaster.push(v)
              else s.voyages.push(v)
            }
          })
          s.voyagesMaster.sort((a,b)=>a.starDay-b.starDay).forEach((v,idx)=>{
            if(idx>0) v.startCollision = v.starDay === s.voyagesMaster[idx-1].endDay
            if(idx<s.voyagesMaster.length-1) v.endCollision = v.endDay === s.voyagesMaster[idx+1].starDay
            v.cols = v.days + (v.endCollision ? -1 : 0) + (v.startCollision ? -1 : 0)
          })
          s.voyages.sort((a,b)=>a.starDay-b.starDay).forEach((v,idx)=>{
            if(idx>0) v.startCollision = v.starDay === s.voyages[idx-1].endDay
            if(idx<s.voyages.length-1) v.endCollision = v.endDay === s.voyages[idx+1].starDay
            v.cols = v.days + (v.endCollision ? -1 : 0) + (v.startCollision ? -1 : 0)
          })

        })
        return occupancyByMonth;

      })
    )
  }

  //New Call
  getSearchProductPaged(
    filters: IResProductSearchParams
  ): Observable<DynamicApiResponsePaginatedModel<ResSearchProduct>> {
    const owner = this.currentOwner.getValue();

    if (owner.isAgent()) {
      filters.AgencyID = owner.agencyID;
    } else if (owner.isDirect()) {
      filters.IndividualID = owner.individualID;
    }

    const prms = Object.assign(filters ?? {}, { IsRetail: (!!filters.IsRetail ? 'Y' : 'N') })

    return this.resApiService.callDynamicAPI(
      'product/searchALLnew',
      prms,
      'POST'
    ).pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ResSearchProduct>>(
        (response) => {
          const data: any[] = response.body.ProductTypes ? response.body.ProductTypes : []
          const product: any[] = response.body.Product ? response.body.Product : []
          const priceTypes: any[] = response.body.PriceTypes ? response.body.PriceTypes : []
          const ecm: any[] = response.body.Ecm ? response.body.Ecm : []
          const prices: any[] = response.body.Prices ? response.body.Prices : []
          const companies: any[] = response.body.Companies ? response.body.Companies : []
          const categories: any[] = response.body.Categories ? response.body.Categories : []
          const combos: any[] = response.body.Combos ? response.body.Combos : []
          const promoPrice: any[] = response.body.PromoPrice ? response.body.PromoPrice : []
          const itineraries: any[] = response.body.Itineraries ? response.body.Itineraries : []
          data.forEach(pt => {
            pt.Product = product.filter(p => p.TypeID == pt.TypeID).sort((p1, p2) => (+p1.Order ?? 0) - (+p2.Order ?? 0))
              .map(p => {
                p.PriceTypes = priceTypes.filter(item => item.ProductCod == p.ProductCod && item.ProductID == p.ProductID)
                p.Ecm = ecm.filter(item => item.ProductCod == p.ProductCod && item.ProductID == p.ProductID)
                p.Prices = prices.filter(item => item.ProductCod == p.ProductCod && item.ProductID == p.ProductID)
                p.Companies = companies.filter(item => item.ProductCod == p.ProductCod && item.ProductID == p.ProductID)
                p.Categories = categories.filter(item => item.ProductCod == p.ProductCod && item.ProductID == p.ProductID)
                p.Combos = combos.filter(item => item.ProductCod == p.ProductCod && item.ProductID == p.ProductID)
                p.PromoPrice = promoPrice.filter(item => item.ProductCod == p.ProductCod && item.ProductID == p.ProductID)
                p.Itineraries = itineraries.filter(item => item.ProductCod == p.ProductCod && item.ProductID == p.ProductID)
                return p
              })
          })
          response.body = data.map((p: any) => new ResSearchProduct(p));
          return new DynamicApiResponsePaginatedModel<ResSearchProduct>(response);
        }
      )
    );
  }

  createTailorMade(params:IResTailorMadeParameters) {

    const owner = this.currentOwner.getValue();

    if (!owner || !owner.isValid) {
      this.utilityDialogs
        .alert({
          message: 'Please select an owner',
          title: `OWNER MISSING`,
        }).then(_=>{});
      return Promise.reject();
    }
    const bookCamp:IReservationApiParametersBookCamp[] = []

    params.accomodations.forEach(a=>{
      bookCamp.push({
          ServiceID:this.tailorMadeParameters.serviceID,
          OptionID:this.tailorMadeParameters.optionID,
          SuiteCategoryID:0,
          CapacityID:0,
          TravelStartDate: params.dateFrom.toJSDate(),
          TravelEndDate: params.dateTo.toJSDate(),
          Adult: a.Adult,
          Child: a.Child,
          RoomID: 0,
          OnRequest: true,
          ROE: 1,
          Markup: 0,
          SellingPrice: 0,
      })
    })

    return this.preBookingUtility.createBookingOrQuote({
      owner: owner,
      action: 'Option',
      currency: this.currentCurrency.getValue() ?? this.coreFormatService.defaultCurrency(),
      priceTypeID: this.tailorMadeParameters.priceTypeID,
      accomodations: params.accomodations,
      transfers: [],
      suites: [],
      voyages: [],
      promos: [],
      packages: [],
      bookCamp: bookCamp,
      companyID: this.tailorMadeParameters.companyID
    })
  }

  retriveProductPropertyAvailability(filters: IResProductPropertyAvailabilityParams): Observable<ResProductPropertyAvailability[]> {

    return this.resApiService.callDynamicAPI(
      'product/propertyAvailability',
      filters,
      'POST'
    ).pipe(
      map<DynamicApiResponse, ResProductPropertyAvailability[]>(
        (response) => {
          return response.body.map((p: any) => new ResProductPropertyAvailability(p));
        }
      )
    )
  }

  packAnyToHtmlArray(data:any) {
    const html:string[] = []
    if(typeof data === 'string') {
      html.push(data)
    } else if(Array.isArray(data)) {
      data.forEach(k=>{
        this.packAnyToHtmlArray(data[k]).forEach(row=>{
          html.push(`<div>${k}:${row}</div>`)
        })
      })
      return html
    } else if(typeof data === 'object') {
      Object.keys(data).forEach(k=>{
        this.packAnyToHtmlArray(data[k]).forEach(row=>{
          html.push(`<div>${k}:${row}</div>`)
        })
      })
      return html
    } else {
      html.push(`${data}`)
    }

    return html
  }
}
