import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, filter, map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import _ from 'lodash';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { EidosBaseApiService } from '@common/services/eidos-base-api.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosLogService } from '@common/services/eidos-log.service';
import { IEidosModuleConfiguration } from '@common/config/environment.interface';
import { DynamicApiResponse } from '@common/models/dynamic-api-response.model';
import { CoreFormatService } from '@common/services/core-format.service';

import { IReservationModuleAPIConfiguration } from '@reservation/models/res-environment.interface';
import {
  IReservationApiPayment,
  IResPaymentActionParams,
  IResPaymentApiRequest,
  IResPaymentApiRequestInfo,
  IResPaymentRequest,
  IResPaymentRequestConfirmation,
  IResPaymentRequestParams,
  ResPayment,
} from '../models/res-payment.model';
import { CoreBaseApiCommandParams } from '@app/core/models/core-base-api.model';

interface IReservationPaymentAPIHeaders {
  kk_username?: string;
  kk_appcode?: 'RES';
  kk_company?: string;
  kk_companyID?: number;
  kk_site?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationPaymentApiService extends EidosBaseApiService {
  /**
   * Payment API URL
   *
   * @type {string}
   * @memberof ReservationPaymentApiService
   */
  private paymentAPI?: string = '';
  /**
   * Payment callback API URL
   *
   * @type {string}
   * @memberof ReservationPaymentApiService
   */
  private paymentCallbackAPI?: string = '';
  /**
   * Payment API headers
   *
   * @private
   * @type {IReservationPaymentAPIHeaders}
   * @memberof ReservationPaymentApiService
   */
  private paymentHeaders: IReservationPaymentAPIHeaders = {};

  constructor(
    public configService: EidosConfigService,
    public eidosSecurityService: EidosSecurityService,
    public http: HttpClient,
    public eidosLogService: EidosLogService,
    public dialog: MatDialog,
    public coreFormatService: CoreFormatService,
  ) {
    super(coreFormatService, configService, eidosLogService, dialog, eidosSecurityService, http);



    this.configService.currentModulesConfig
      .pipe(
        filter(
          (modules: Array<IEidosModuleConfiguration>) => !!modules.find((module) => module.moduleName === 'reservation')
        )
      )
      .subscribe((modules) => {
        const module = modules.find((module) => module.moduleName === 'reservation');

        if (module) {
          const reservationModuleAPI = module.API as IReservationModuleAPIConfiguration;
          this.urlAPI = reservationModuleAPI.url;
          this.paymentAPI = reservationModuleAPI.paymentAPI;
          this.paymentCallbackAPI = reservationModuleAPI.paymentCallbackAPI;
          this.apiVersion = reservationModuleAPI.version;
          if (reservationModuleAPI.headers) {
            this.headers = {
              ...reservationModuleAPI.headers,
              ...this.headers
            };
          }
        }
      });
  }
  /**
   * Set payment API headers
   *
   * @param {IReservationPaymentAPIHeaders} headers
   * @memberof ReservationPaymentApiService
   */
  setPaymentHeaders(headers: IReservationPaymentAPIHeaders) {
    this.paymentHeaders = headers;
  }
  /**
   * Set payment API headers
   *
   * @param {IReservationPaymentAPIHeaders} headers
   * @memberof ReservationPaymentApiService
   */
  getPaymentHeaders(): IReservationPaymentAPIHeaders {
    return {
      ...this.paymentHeaders,
      kk_username: this.eidosSecurityService.getUser()?.username ?? '',
      kk_company: this.eidosSecurityService.getUser()?.company ?? '',
      kk_appcode: 'RES'
    }
  }
  /**
   * Create a payment request
   * Return payment sending confirmation in postponed payment
   * Return payment full request in not postponed payment
   *
   * @param {IResPaymentRequestParams} params
   * @return {*}  {Observable<IResPaymentRequest | IResPaymentRequestConfirmation>}
   * @memberof ReservationPaymentApiService
   */
  createPayment(params: IResPaymentRequestParams): Observable<IResPaymentRequest | IResPaymentRequestConfirmation> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'request',
      data: params,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, IResPaymentRequest | IResPaymentRequestConfirmation>((response: IResPaymentApiRequest) => {

        const parseResult = (response: IResPaymentApiRequest) => {
          switch (response.Info[0].Result) {
            case 'OK':
            case 'WRN':
              return response.Info[0].Result;
            default:
              return 'ERR';
          }
        }

        const isPostponed = !!response.Info[0].IsPostponed;

        if (isPostponed) {

          let req: IResPaymentRequestConfirmation = {
            requestID: response.Info[0].RequestID,
            message: response.Info[0].Message,
            bkgID: response.Info[0].BkgID,
            result: parseResult(response),
            isPostponed: true
          };

          return req;

        } else {
          const parseMode = (response: IResPaymentApiRequest) => {
            if (response.FormModel) {
              return "form";
            } else if (response.IframeModel) {
              return "iframe";
            }

            return "unknown"
          }

          let req: IResPaymentRequest = {
            requestID: response.Info[0].RequestID,
            message: response.Info[0].Message,
            bkgID: response.Info[0].BkgID,
            result: parseResult(response),
            isPostponed: false,
            amount: response.Info[0].Amount,
            currency: response.Info[0].CurrencyCod,
            url: "",
            mode: parseMode(response)
          };

          if (req.result === 'OK') {

            if (req.mode !== "unknown") {
              switch (req.mode) {
                case "form":
                  if (response.FormModel!.Fields !== undefined) {
                    req.url = response.FormModel!.Url;
                    req.method = response.FormModel!.Method;
                    req.fields = Object.entries(response.FormModel!.Fields).map((item) => {
                      return {
                        key: item[0],
                        value: item[1],
                      };
                    });
                  }
                  break;
                case "iframe":
                  if (response.IframeModel!.Url.startsWith('ERROR')) {
                    req.message = response.IframeModel!.Url;
                    req.result = 'ERR';
                    return req;
                  }
                  req.url = response.IframeModel!.Url;
                  break;
              }
            }

          } else {
            throw response.Info[0].Message;
          }

          return req;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          return response.errors;
        } else {
          return this.handleGenericError(error);
        }
      })
    );
  }
  /**
   * Retrieves a postponed payment
   *
   * @param {string} guid
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  postponedPayment(guid: string): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'postponedPayment',
      data: {
        Guid: guid,
      },
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, IResPaymentRequest>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const parseMode = (response: IResPaymentApiRequest) => {
          if (response.FormModel) {
            return "form";
          } else if (response.IframeModel) {
            return "iframe";
          }

          return "unknown"
        }

        const result = parseResult(response);

        if (result !== 'ERR') {

          let req: IResPaymentRequest = {
            requestID: response.Info[0].RequestID,
            message: response.Info[0].Message,
            bkgID: response.Info[0].BkgID,
            currency: response.Info[0].CurrencyCod,
            amount: response.Info[0].Amount,
            checkoutLayout: response.Info[0].CheckoutLayout ?? response.CheckoutLayout,
            result: result,
            isPostponed: !!response.Info[0].IsPostponed,
            url: "",
            mode: parseMode(response)
          };

          if (req.mode !== "unknown") {
            switch (req.mode) {
              case "form":
                if (response.FormModel!.Fields !== undefined) {
                  req.url = response.FormModel!.Url;
                  req.method = response.FormModel!.Method;
                  req.fields = Object.entries(response.FormModel!.Fields).map((item) => {
                    return {
                      key: item[0],
                      value: item[1],
                    };
                  });
                }
                break;
              case "iframe":
                if (response.IframeModel!.Url.startsWith('ERROR')) {
                  req.message = response.IframeModel!.Url;
                  req.result = 'ERR';
                  return req;
                }
                req.url = response.IframeModel!.Url;
                break;
            }
          }
          return req;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((_: HttpErrorResponse) =>
        of({
          IsValid: false,
          Guid: '',
          Url: '',
          RequestID: '',
        })
      )
    );
  }
  /**
   * Checks payment request status
   *
   * @param {string} paymentReqID
   * @return {*}  {Observable<IResPaymentApiRequestInfo>}
   * @memberof ReservationPaymentApiService
   */
  checkPayment(paymentReqID: string): Observable<IResPaymentApiRequestInfo> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'RequestInfo',
      data: { RequestID: paymentReqID },
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          return response.errors;
        } else {
          return this.handleGenericError(error);
        }
      })
    );
  }
  /**
   * Abort a payment request
   *
   * @param {string} paymentReqID
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  abortPayment(paymentReqID: string): Observable<IResPaymentApiRequestInfo> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'ncp/goback',
      data: { RequestID: paymentReqID },
      type: 'POST',
      customBaseUrl: this.paymentCallbackAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          return response.errors;
        } else {
          return this.handleGenericError(error);
        }
      })
    );
  }
  /**
   * Force process payment request in status PR
   *
   * @param {string} bkgID
   * @return {*}  {Observable<IResPaymentApiRequestInfo>}
   * @memberof ReservationPaymentApiService
   */
  processPayment(bkgID: number): Observable<string> {
    // return this.callDynamicAPI('account/userinfo', {}, 'GET').pipe(
    //   map<DynamicApiResponse, string>((response) =>
    //     Array.isArray(response.body) && response.body.length > 0 ? (response.body[0].Result as string) : 'KO')
    // );
    const commandParams = new CoreBaseApiCommandParams({
      url: 'RequestProcess',
      data: { BkgID: bkgID.toString() },
      type: 'GET',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<any, string>((response) => {
        if (response.FoundErrors) return 'KO';
        return response.Message as string;
      }),
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          console.log(response.errors);
        }
        return 'KO';
      })
    );
  }
  /**
   * Void a payment request
   *
   * @param {IResPaymentActionParams} voidPaymentReq
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  voidPayment(voidPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'void',
      data: voidPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }

  /**
     * Charge Back a payment request
     *
     * @param {IResPaymentActionParams} refundPaymentReq
     * @return {*}  {Observable<any>}
     * @memberof ReservationPaymentApiService
     */

  chargeBackPayment(refundPaymentReq: IResPaymentActionParams) {
    const tmpReq = {
      'ReceiptDescription': refundPaymentReq.Reason,
      'OrderID': refundPaymentReq.OrderID,
      'BkgID': refundPaymentReq.BkgId
    };
    return this.postDynamicAPI('payment/Chargeback', tmpReq ).pipe(
      map<DynamicApiResponse, any>((response) => response.body
      )
    );
  }




  /**
     * Refund a payment request
     *
     * @param {IResPaymentActionParams} refundPaymentReq
     * @return {*}  {Observable<any>}
     * @memberof ReservationPaymentApiService
     */

  refundManualPayment(refundPaymentReq: IResPaymentActionParams) {
    const tmpReq = {
      'ReceiptTotal': refundPaymentReq.Amount,
      'ReceiptDate': refundPaymentReq.Data,
      'ReceiptDescription': refundPaymentReq.Reason,
      'OrderID': refundPaymentReq.OrderID,
      'BkgID': refundPaymentReq.BkgId
    };
    return this.postDynamicAPI('payment/manualRefund', tmpReq ).pipe(
      map<DynamicApiResponse, any>((response) => response.body
      )
    );
  }

  refundManualPayment2(refundPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'payment/manualRefund',
      data: refundPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }

      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }
  /**
   * Refund a payment request
   *
   * @param {IResPaymentActionParams} refundPaymentReq
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  unrefundPayment(unrefundPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'UnrefRefund',
      data: unrefundPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }
  /**
   * Refund a payment request
   *
   * @param {IResPaymentActionParams} refundPaymentReq
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  refundPayment(refundPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'refund',
      data: refundPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }
  /**
   * Repay a payment request
   *
   * @param {IResPaymentActionParams} repayPaymentReq
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  repayPayment(repayPaymentReq: IResPaymentActionParams): Observable<any> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'repay',
      data: repayPaymentReq,
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<IResPaymentApiRequest, string>((response) => {

        const hasResponseInfo = response.Info && Array.isArray(response.Info) && response.Info.length > 0;

        const parseResult = (response: IResPaymentApiRequest) => {
          if (hasResponseInfo)
            switch (response.Info[0].Result) {
              case 'OK':
              case 'WRN':
                return response.Info[0].Result;
            }

          return 'ERR';
        }

        const result: string = parseResult(response);

        if (result === 'OK') {
          return result;
        } else {
          throw hasResponseInfo ? response.Info[0].Message : "Unknown error.";
        }
      }),
      catchError((error: HttpErrorResponse) => this.handleGenericError(error))
    );
  }
  /**
   * Gets payment history of a booking
   *
   * @param {number} bkgID
   * @return {*}  {Observable<any>}
   * @memberof ReservationPaymentApiService
   */
  getPaymentHistory(bkgID: number): Observable<any> {
    return this.getDynamicAPI('payment/history', { BkgID: bkgID }).pipe(
      map<DynamicApiResponse, [ResPayment[], ResPayment[]]>((response) => {
        const payments = response.body.Payments.map((p: IReservationApiPayment) => new ResPayment(p));
        const links = response.body.Link.map((p: IReservationApiPayment) => new ResPayment(p));
        return [payments, links];
      })
    );
  }
  /**
   * Gets payment details for OrderId
   *
   * @param {string} orderID
   * @return {*}
   * @memberof ReservationPaymentApiService
   */
  getPayment(orderID: string) {
    return this.getDynamicAPI('payment/get', { OrderID: orderID }).pipe(
      map<DynamicApiResponse, IResPaymentRequestParams | undefined>((response) => {
        if (response.body?.Payment !== undefined) {
          return response.body.Payment as IResPaymentRequestParams;
        } else {
          return undefined;
        }
      })
    );
  }

  /**
   * retrive key to cript data
   * retrive key to cript data
   *
   * @param {string} paymentReqID
   * @return {*}  {Observable<IResPaymentApiRequestInfo>}
   * @memberof ReservationPaymentApiService
   */
  acquireKey(shared:string): Observable<string[]> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'AquireKey',
      data: { Shared: shared },
      type: 'POST',
      customBaseUrl: this.paymentAPI,
      useVersion: true,
      customHeaders: this.getPaymentHeaders() as { [key: string]: string }
    });

    return this.sendCommand(commandParams).pipe(
      map<any, string[]>((response) => {
        if (response.FoundErrors) return [];
        return [response.Key as string,response.Guid as string];
      }),
      catchError((error: HttpErrorResponse) => {
        const response = new DynamicApiResponse(error);
        if (Array.isArray(response.errors) && response.errors.length > 0) {
          return response.errors;
        } else {
          return this.handleGenericError(error);
        }
      })
    );
  }


}
