import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { filter, take } from 'rxjs';
import { CoreCacheDB } from '../db/core-cache';

import { EidosApiService } from '@app/core/services/eidos-api.service';
import { EidosLogSeverity } from '@app/core/models/core-constant.model';
import { EidosLogService } from '@app/core/services/eidos-log.service';
import { EidosConfigService } from '../config/eidos-config.service';

import { CachedData } from '@app/reservation/models/res-cached-data.models';

@Injectable({
  providedIn: 'root'
})
export class CoreCacheService {
  /**
   * Test the service with a cacheExpireMinutes = 1
   *
   * @static
   * @type {boolean}
   * @memberof CoreCacheService
   */
  static TEST_THE_SERVICE: boolean = false;
  /**
   * Cache validity time in millis
   *
   * @private
   * @type {number}
   * @memberof CoreCacheService
   */
  private _cacheExpireMillis: number = CoreCacheService.TEST_THE_SERVICE ? 60000 : (1440 * 60 * 1000);
  /**
   * Cache DB
   *
   * @private
   * @type {CoreCacheDB}
   * @memberof CoreCacheService
   */
  private db?: CoreCacheDB;

  constructor(
    private coreApiService: EidosApiService,
    private eidosLogService: EidosLogService,
    private coreConfigService: EidosConfigService
  ) {

    this.coreConfigService.currentConfig.subscribe(config => {

      let cacheDisabled = true;

      // Read the cache config and set enable flag and validity time
      if (config.cache) {
        cacheDisabled = !config.cache.enabled;
        const cacheExpireMinutes = CoreCacheService.TEST_THE_SERVICE ? 0.5 : (config.cache.cacheValidityMinutes ?? 1440);
        this._cacheExpireMillis = cacheExpireMinutes * 60 * 1000;
      }

      this.db = new CoreCacheDB(config.cache);

      if (cacheDisabled) {
        this.eidosLogService.logDebug(EidosLogSeverity.Log, `CoreCache is disabled by config: cache functionalities are disabled.`);
      } else {

        if (CoreCacheService.TEST_THE_SERVICE) {
          this.eidosLogService.logDebug(EidosLogSeverity.Log, `CoreCacheService is working in test mode: cache will expire in a minute`);
        } else {
          this.eidosLogService.logDebug(EidosLogSeverity.Log, `CoreCacheService is working: cache will expire in ${config.cache.cacheValidityMinutes} minutes`);
        }

        // Browser cache can fails, eventually tofy the failure
        this.db!.cacheDisabled$
          .pipe(filter(disabled => !!disabled), take(1))
          .subscribe(() => this.eidosLogService.logDebug(EidosLogSeverity.Log, `CoreCache has failed start or is disabled by config : cache functionalities are disabled.`));
      }
    });
  }
  /**
   * Retrieves cached data by dataName
   *
   * The data are retrieved from IndexedDB if already downloaded,
   * if not, invokes the API to download the data
   * and store them in Indexed DB
   *
   * @template T Interface of the data to retrieve
   * @param {CachedData} dataName Data to retrieve
   * @param {*} [params] Params for the SP
   * @return {*}  {Promise<T[]>}
   * @memberof CoreCacheService
   */
  async getCachedData<T>(dataName: CachedData, params?: any): Promise<T[]> {
    if (dataName in this.db!.cache) {

      const cachedData = this.db!.cache[dataName];

      await this.db!.cacheReady$.pipe(filter(ready => ready === true));

      // Check if the requested data cache is disabled (timestamp < (nox + validity millis))
      let isCacheExpired = true;
      if (cachedData && !this.db!.cacheDisabled$.getValue()) {
        const tableTimestamp = await this.db!.timestamps.get(cachedData.tableName);
        if (tableTimestamp?.Timestamp) {
          isCacheExpired = DateTime.now().toMillis() > (tableTimestamp.Timestamp + this._cacheExpireMillis);
          if (isCacheExpired) {
            this.eidosLogService.logDebug(EidosLogSeverity.Log, `Res cache - Cache for ${dataName} is expired`);
          }
        }
      }

      let dataFromDB: T[] = [];

      // If browser cache is disabled or cached data are obsolete then force loading from API
      if (this.db!.cacheDisabled$.getValue() === false && !isCacheExpired) {
        dataFromDB = await (!!cachedData.orderField ? cachedData.table.orderBy(cachedData.orderField) : cachedData.table).toArray();
      }

      if (Array.isArray(dataFromDB) && dataFromDB.length > 0) {
        this.eidosLogService.logDebug(EidosLogSeverity.Log, `Res cache - Load cached Load ${dataName} from DB`, dataFromDB);
        return dataFromDB;
      } else {

        const serverMap = cachedData.serverMap;

        //@TODO RDP read from dynamoc api (extend serverMap data)

        return new Promise<T[]>((resolve) => {
          this.coreApiService.getJSON(dataName, params, serverMap.db, serverMap.schema, serverMap.sp).subscribe({
            next: (dataFromAPI) => {
              this.eidosLogService.logDebug(EidosLogSeverity.Log, `Res cache - Load ${dataName} from API`, dataFromAPI);
              if (!this.db!.cacheDisabled$.getValue()) {
                cachedData.table.clear();
                cachedData.table.bulkAdd(dataFromAPI);

                // Update the timestamp
                this.db!.timestamps.put({ TableName: cachedData.tableName, Timestamp: DateTime.now().toMillis() }, cachedData.tableName);
              }
              resolve(dataFromAPI);
            },
            error: () => {
              if (!this.db!.cacheDisabled$.getValue()) {
                cachedData.table.clear();
                this.db!.timestamps.delete(cachedData.tableName);
              }
              resolve([]);
            }
          });
        });
      }
    } else {
      return [];
    }
  }
  /**
   * Clear and redownload data of param dataName
   *
   * @param {CachedData} dataName
   * @param {*} [params]
   * @memberof CoreCacheService
   */
  async refreshCachedData(dataName: CachedData, params?: any) {
    
    await this.db!.cacheReady$.pipe(filter(ready => ready === true));

    if (!this.db!.cacheDisabled$.getValue() && dataName in this.db!.cache) {

      const cachedData = this.db!.cache[dataName];
      const serverMap = cachedData.serverMap;

      this.coreApiService.getJSON(dataName, params, serverMap.db, serverMap.schema, serverMap.sp).subscribe({
        next: (dataFromAPI) => {
          this.eidosLogService.logDebug(EidosLogSeverity.Log, `Res cache - Load ${dataName} from API`, dataFromAPI);
          cachedData.table.clear();
          cachedData.table.bulkAdd(dataFromAPI);

          // Update the timestamp
          this.db!.timestamps.put({ TableName: cachedData.tableName, Timestamp: DateTime.now().toMillis() }, cachedData.tableName);
        },
        error: () => {
          cachedData.table.clear();
        }
      });
    }
  }
  /**
   * Clear cached data of param dataName
   *
   * @param {CachedData} dataName Data to clear
   * @memberof CoreCacheService
   */
  async eraseCachedData(dataName: CachedData) {

    await this.db!.cacheReady$.pipe(filter(ready => ready === true));

    if (!this.db!.cacheDisabled$.getValue() && dataName in this.db!.cache) {
      this.db!.cache[dataName].table.clear();
      this.db!.timestamps.delete(this.db!.cache[dataName].tableName);
    }
  }
  /**
   * Completely delete the cache
   *
   * @memberof CoreCacheService
   */
  async eraseCache() {

    await this.db!.cacheReady$.pipe(filter(ready => ready === true));

    if (!this.db!.cacheDisabled$.getValue()) {
      this.db!.resetDatabase();
    }
  }
}
