import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { isEmpty } from 'lodash';
import { of } from 'rxjs';
import { DxDateBoxComponent } from 'devextreme-angular';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { IEidosInputDateConfig, IEidosInputSelectizeConfig, IEidosInputNumberPopupConfig } from '@app/core/models/eidos-inputs.models';
import { CoreCacheService } from '@app/core/services/core-cache.service';
import { CoreFormatService } from '@app/core/services/core-format.service';
import { EidosInputDateTimeComponent } from '../entities/inputs/eidos-input-datetime/eidos-input-datetime.component';
import { EidosInputSelectizeComponent } from '../entities/inputs/eidos-input-selectize/eidos-input-selectize.component';
import { ICorePopupListSelectorConfig } from '../core-popup-list-selector/core-popup-list-selector.component';

import { IReservationCountry } from '@app/reservation/models/res-cached-data.models';
import {
  IReservationApiGetCounty,
  IReservationApiGetState,
} from '@app/reservation/models/res-country-state-county.models';
import { ReservationApiService } from '@app/reservation/services/reservation-api.service';
import { ReservationService } from '@app/reservation/services/reservation.service';
import { IReservationMonthFilter } from '@app/reservation/components/entities/inputs/res-input-month/res-input-month.component';
import { DateTime } from 'luxon';


export interface IEidosLightFormField {
  field: string; // field name
  caption?: string; // field caption
  /**
   * Field HTML caption
   * N.B. Remember to add the * for required field!
   *
   * @type {string}
   * @memberof IEidosLightFormField
   */
  htmlCaption?: string | ((values:any)=>string);
  type: string; // field type
  width?: string; // field col width
  height?: string; // height for textarea field
  minHeight?: string; // min height for textarea field
  hidden?: ((...args: any[]) => boolean); // if field is hidden
  hideLabel?: boolean; // if field label is hidden
  horizontalLabel?: boolean; // if field label is horizontal
  showClearButton?: boolean; // show clear button
  showSpinButtons?: boolean; // show spin buttons when type is number
  format?: string; // format for dates and numbers
  options?: number[] | string[] | { label: string; value: any, disabled?: boolean }[]; // field options, can be number[] or string[] or {label: string, value: string}[]
  required?: boolean; // if field is required
  requiredMessage?: string; // required message
  requiredGroup?: string; // the field is required only if all fields in this group are empty
  requiredCondition?: () => boolean; // the field is required only if this condition is true
  section?: string; // form section
  sectionPos?: number; // form section position
  openedSection?: boolean; // if section accordion is opened by default
  readOnly?: ((...args: any[]) => boolean); // if field is readonly
  notDisabled?: boolean; // if field is not disabled
  min?: number; // min number for number input
  max?: number; // max number for number input
  minDate?: Date; // min date for datepicker
  maxDate?: Date; // max date for datepicker
  disabledDates?: Array<Date> | ((value: { component: DxDateBoxComponent, date: Date, view: "month" | "year" | "decade" | "century" }) => boolean) // disabled dates for datepicker
  handleValueChange?: boolean; // if field value change should be handled by component
  selectizeInputConfig?: IEidosInputSelectizeConfig; // selectize input config
  numberPopupConfig?: IEidosInputNumberPopupConfig; // number popup config
  maxLength?: number; // max length for text input
  triggerChangeOnKeyUp?: boolean;
  /**
   * Popup list selector configuration
   *
   * @type {ICorePopupListSelectorConfig}
   * @memberof IEidosLightFormField
   */
  popupListSelectorConfig?: ICorePopupListSelectorConfig;
  filters?: ((...args: any[]) => any); // filter for selectize field
  popoverMessage?: string; // popover message
  additionalConfig?: any; // additional config to use in selectize field
  customTemplate?: () => TemplateRef<any>; // custom template for field
  allowPrefix?: boolean; // Prefix allowed in phone input
  autoResizeEnabled?: boolean; // Auto resize text area
  defaultValue?: any; // Default value
  placeholder?: string;
}

export enum EidosLightFormMode {
  Default = 'default', // default mode
  Section = 'section', // section mode
  SectionAccordion = 'section-accordion', // section mode with accordions
  SectionWithSeparator = 'section-with-separator', // section mode with separator
}

/**
 * All possible eidos light form field type
 * NB: Keep in lower case because it is used in ng switch-case directive
 *
 * @export
 * @enum {number}
 */
export enum EidosLightFormFieldType {
  Text = 'text',
  Textarea = 'textarea',
  Email = 'email',
  Number = 'number',
  Percentage = 'percentage',
  Checkbox = 'checkbox',
  Select = 'select',
  Selectize = 'selectize',
  Radio = 'radio',
  Date = 'date',
  DateTime = 'datetime',
  Time = 'time',
  DateMonths = 'datemonths',
  DateRange = 'daterange',
  Country = 'country',
  State = 'state',
  County = 'county',
  ServiceType = 'servicetype',
  Service = 'service',
  Option = 'option',
  Custom = 'custom',
  Phone = 'phone',
  File = 'file',
  Html = 'html',
  PopupListSelector = 'popuplistselector',
  NumberPopup = 'numberpopup',
}

@Component({
  selector: 'eidos-light-form',
  templateUrl: './eidos-light-form.component.html',
  styleUrls: ['./eidos-light-form.component.scss'],
})
export class EidosLightFormComponent implements OnInit {
  EidosLightFormFieldType = EidosLightFormFieldType;
  /**
   * Returns true if val is empty or equal to whitespace in case of string
   *
   * @param {*} val
   * @memberof EidosLightFormComponent
   */
  isEmptyOrWhiteSpace = (val: any) =>
    isEmpty(val)
    || (typeof val === 'string' && isEmpty(val.trim()));

  private _fields: IEidosLightFormField[] = [];



  @Output()
  onFieldTouch: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  brokenRules = new EventEmitter<string[]>();

  @Input() set fields(fields: IEidosLightFormField[]) {
    if (!fields) return;
    this._fields = fields;
    this.setMode();
    this.buildFieldValidationMessage(this._fields ?? []);
    this.attachSelectizeChangeValueHandler(this._fields ?? []);
    this.checkIfSomeFieldIsCountry();
    this.buildHtmlEditorConfig();
    this.buildSections();
    this.setDefaultValues();
  }

  get fields(): IEidosLightFormField[] {
    return this._fields;
  }

  @Input() wrapperClass: string = '';

  @Input() submitButtonClass: string = '';

  @Input() submitButtonText: string = 'SEARCH';

  @Input() submitButtonIcon: string = 'search';

  @Input() submitButtonDisabled: boolean = false;

  @Input() createButtonClass: string = '';

  @Input() createButtonText: string = 'CREATE';

  @Input() createButtonIcon: string = 'plus';

  @Input() createButtonDisabled: boolean = false;

  @Input() clearButtonClass: string = '';

  @Input() title: string = '';

  @Input() formCanBeEmpty: boolean = false;

  @Input() oneLineForm: boolean = false;

  @Input() reverseButtons: boolean = false;

  @Input() scrollable: boolean = true;

  @Input() fullHeight: boolean = true;

  @Input() showScrollbar: string = 'always';

  @Input() colsClasses: string = 'row-cols-4';

  @Input() callbackBeforeSubmit?: () => Promise<any>; // do something before submit, must return a promise

  sections: string[] = [];

  openedSections: string[] = [];

  private _mode: string = EidosLightFormMode.Default;

  @Input() set mode(mode: string) {
    this._mode = mode;
  }

  get mode(): string {
    return this._mode;
  }

  /**
   * If true, will be used boostrap column classes for each field instead of row-cols-* classes
   *
   * @type {boolean}
   * @memberof EidosLightFormComponent
   */
  singleFieldWidth: boolean = false;

  private _formValues: any = {};

  @Input() set formValues(formValues: any) {
    if (formValues == null) {
      this.formValues = {};
      return;
    }

    if (Object.keys(this._formValues).every((f) => f != 'countryCod')) this.searchStateAndCounty(formValues);

    this._formValues = formValues;
    this.formValuesChange.emit(formValues);

  }

  get formValues(): any {
    return this._formValues;
  }

  @Input() validationGroupName: string = '';

  @Output() formValuesChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter<any>();

  @Output() onCreateBtnClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() onFieldValueChange: EventEmitter<any> = new EventEmitter<any>();

  countryCodInputConfig: IEidosInputSelectizeConfig = {
    type: 'selectize',
    items: [],
    asyncItems: (query?: string, cod?: number) => this.reservationApiService.getCountries(query, cod),
    searchField: ['Country'],
    displayField: 'Country',
    maxItems: 1,
    minSearchLength: 0,
    keyField: 'CountryCod',
    showClearButton: true,
    validationMessage: 'The COUNTRY field is required',
  };

  dateRangeConfigFrom: IEidosInputDateConfig = {
    type: 'date',
    displayFormat: this.coreFormatService.DateFmtWithMonthName(),
    pickerType: 'calendar',
    dateValueType: 'date',
    onValueChange: (_: EidosInputDateTimeComponent, e) => {
      if (!this.fields.find((f) => f.field == 'dateStart, dateEnd')?.handleValueChange) return;
      this.onFieldValueChange.emit({ field: 'dateStart', value: e?.value ?? e });
    },
  };

  dateRangeConfigTo: IEidosInputDateConfig = {
    type: 'date',
    displayFormat: this.coreFormatService.DateFmtWithMonthName(),
    pickerType: 'calendar',
    dateValueType: 'date',
    onValueChange: (_: EidosInputDateTimeComponent, e) => {
      if (!this.fields.find((f) => f.field == 'dateStart, dateEnd')?.handleValueChange) return;
      this.onFieldValueChange.emit({ field: 'dateEnd', value: e?.value ?? e });
    },
  };

  countries: any[] = [];
  private _states: any[] = [];
  get states(): any[] {
    return this._states;
  }
  set states(value: any[]) {
    this._states = value;

    let statesFields = this.fields.filter((f) => f.type == 'state') ?? [];

    for (let f of statesFields) {
      if (value.some((v) => v.StateISOCod == this.formValues[f.field])) continue;
      delete this.formValues[f.field];
    }
  }
  private _counties: any[] = [];
  get counties(): any[] {
    return this._counties;
  }
  set counties(value: any[]) {
    this._counties = value;

    let countiesFields = this.fields.filter((f) => f.type == 'county') ?? [];
    if (this.dontClearCounties) return;

    if (!value.length) {
      for (let f of countiesFields) {
        setTimeout(() => {
          if (this.formValues[f.field]) delete this.formValues[f.field];
        }, 0);
      }
      return;
    }

    for (let f of countiesFields) {
      if (value.some((v) => v.County == this.formValues[f.field])) continue;
      if (this.formValues[f.field]) delete this.formValues[f.field];
    }
  }

  dontClearCounties: boolean = false;

  serviceTypesSelectizeConfig: IEidosInputSelectizeConfig = {
    type: 'selectize',
    items: [],
    asyncItems: () => {
      let filters = this.fields.find((f) => f.type == 'serviceType')?.filters;
      return this.reservationApiService.getServiceTypes(filters ? filters() : {});
    },
    loadingMode: 'server',
    searchField: ['serviceTypeName'],
    displayField: 'serviceTypeName',
    maxItems: 1,
    minSearchLength: 0,
    valueField: 'serviceTypeID',
    showClearButton: true,
    validationMessage: 'The field is required',
    placeholder: 'Select...',
    onValueChange: () => {
      this.formValues.service = undefined;
      this.formValues.option = undefined;
    },
    checkUnique: true
  };

  servicesSelectizeConfig: IEidosInputSelectizeConfig = {
    type: 'selectize',
    items: [],
    asyncItems: (search, id: any) => {
      let filters = this.fields.find((f) => f.type == 'service')?.filters
      if (filters) filters = filters() ?? {};
      return this.reservationApiService.getServices({ ServiceID: id, ServiceLongName: search, ServiceTypeID: this.formValues.serviceType?.serviceTypeID, ...filters });
    },
    loadingMode: 'incremental',
    searchField: ['serviceLongName'],
    displayField: 'serviceLongName',
    maxItems: 1,
    minSearchLength: 0,
    keyField: 'serviceID',
    valueField: 'serviceID',
    showClearButton: true,
    validationMessage: 'The field is required',
    placeholder: 'Start typing to search...',
    onValueChange: (_: EidosInputSelectizeComponent, value: number) => {
      this.optionsSelectizeConfig.disabled = !value;
      this.optionsSelectizeConfig.refresh && this.optionsSelectizeConfig.refresh()
    }
  };

  optionsSelectizeConfig: IEidosInputSelectizeConfig = {
    type: 'selectize',
    items: [],
    asyncItems: (search, id: any) => {
      if (this.formValues.service?.serviceID) {
        let filters = this.fields.find((f) => f.type == 'option')?.filters
        if (filters) filters = filters() ?? {};
        return this.reservationApiService.getSetupServicesOptions({ ServiceID: this.formValues.service?.serviceID, IsIncluded: 'N', OptionID: id, OptionName: search, IsActive: 'Y', ...filters });
      } else {
        return of([]);
      }
    },
    disabled: true,
    loadingMode: 'server',
    searchField: ['optionName'],
    displayField: (item) => {
      if (!item) return '';
      let field = this.fields.find((f) => f.type == 'option')
      if (!field?.additionalConfig?.displayField) return item.optionName;
      return field.additionalConfig.displayField instanceof Function ? field.additionalConfig.displayField(item) : item[field.additionalConfig.displayField];
    },
    maxItems: 1,
    minSearchLength: 0,
    keyField: 'serviceOptionID',
    valueField: 'optionID',
    showClearButton: true,
    validationMessage: 'The field is required',
    placeholder: 'Start typing to search...',
  }

  defaultMinDate: Date = new Date(1900, 0, 1);
  defaultMaxDate: Date = new Date(2099, 11, 31);

  isFieldPopoverVisible: { [field: string]: boolean } = {};

  private _HTMLEditorConfig: AngularEditorConfig = {
    editable: true,
    height: '100%',
    minHeight: '100px',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    enableToolbar: true,
    showToolbar: true,
    toolbarHiddenButtons: [
      [
        'fontName',
        'superscript',
        'subscript',
        'insertLink',
        'unlink',
      ],
      [
        'insertImage',
        'insertVideo'
      ]
    ],
    rawPaste: true
  };

  HTMLEditorConfigs: { [field: string]: AngularEditorConfig } = {};

  constructor(
    private reservationApiService: ReservationApiService,
    private reservationService: ReservationService,
    private coreFormatService: CoreFormatService,
    private coreCacheService: CoreCacheService
  ) {
  }

  ngOnInit(): void {
    if (!this.validationGroupName) this.validationGroupName = this.calculateUniqueValidationGroupName();
    this.createMonthFrom();
  }

  monthFromList: string[] = [];
  min = DateTime.now();
  max: DateTime = this.min.set({ day: 31, month: 12, year: this.min.year + 2 });


  createMonthFrom() {
    let items: IReservationMonthFilter[] = [];

    const now = DateTime.now();

    let start = now.toMillis() > this.min.toMillis() ? now : this.min;
    const end = now.set({ day: 31, month: 12, year: now.year + 2 }).toMillis() < this.max.toMillis() ? now.set({ day: 31, month: 12, year: now.year + 2 }) : this.max;

    do {
      items.push({ displayValue: start.toFormat("LLL yyyy"), value: start.toFormat("yyyyMM") });
      start = start.set({ month: start.month + 1 });
    }
    while (start.diff(end).milliseconds <= 0);

    this.monthFromList = items.map(item => item.displayValue);

  }

  async handleFormSubmit(e?: any) {
    if (e?.preventDefault) e.preventDefault();

    if (this.callbackBeforeSubmit) await this.callbackBeforeSubmit();

    // apply form validation
    const validation = this.applyValidation();
    if (!validation) return;

    // i want to check if the form without checkboxes is empty
    let copyFormValues = Object.assign({}, this.formValues);
    for (let key in copyFormValues) {
      if (copyFormValues[key] === false || copyFormValues[key] === true) delete copyFormValues[key];
    }

    if (!this.formCanBeEmpty && !Object.entries(copyFormValues).filter(([_, v]) => v != null && v !== '').length) {
      //if formCanBeEmpty is false and form is empty, i show an error message
      this.reservationService.utilityDialogs.alert({
        message: 'Please fill at least one search filter',
        title: `Attention`,
      });
      return;
    }

    this.onFormSubmit.emit(e);
  }

  parseFieldLabel(name: string): string {
    return name.split(/(?=[A-Z])/).join(' ');
  }

  clearForm() {
    let copy = Object.assign({}, this.formValues);

    for (let key in copy) {
      if (copy[key] === false || copy[key] === true) {
        if (copy[key]) copy[key] = false;
        continue;
      }

      delete copy[key];
    }

    this.formValues = copy;
  }
  getHtmlCaption(f:any) {
    if(typeof f.htmlCaption === 'function') return f.htmlCaption(this.formValues)
    return f.htmlCaption
  }
  checkIfFieldIsRequired(f: any): boolean {
    if (!f.required) return false;

    if (!!f.requiredGroup) {
      const isNotValorized = (value: any) => value == null || value === '';
      return (
        this.fields
          ?.filter((ff) => ff.requiredGroup == f.requiredGroup)
          .every((ff) => {
            if (ff.type == EidosLightFormFieldType.DateRange) return isNotValorized(this.formValues[f.field.split(',')[0].trim()]) || isNotValorized(this.formValues[f.field.split(',')[1].trim()]);
            return isNotValorized(this.formValues[ff.field]);
          }) ?? false
      );
    }

    if (f.requiredCondition) return f.requiredCondition();

    return true;
  }

  getValidationMessage(f: any): string {
    return (f.caption || this.parseFieldLabel(f.field)).toUpperCase() + ' is required';
  }

  checkIfStructuredOptions(f: any): boolean {
    return !!f.options?.length && !!f.options[0]?.label;
  }

  setMode() {
    if (!this.fields.length) return;

    let sectionFields = this.fields.filter((f) => !!f.section);
    if (!!sectionFields.length && this.mode == EidosLightFormMode.Default) {
      //this.mode = sectionFields.every((f) => !isNaN(Number(f.section))) ? EidosLightFormMode.Section : EidosLightFormMode.SectionAccordion;
      this.mode = EidosLightFormMode.SectionAccordion;
    }

    this.singleFieldWidth = this.fields.some((f) => !!f.width);
  }

  getFieldsBySection(section: string): IEidosLightFormField[] {
    if (!this.fields.length) return [];
    return this.fields.filter((f) => f.section == section);
  }

  onValueChanged(field: IEidosLightFormField, e: any, keyField?: string) {
    if (!keyField) keyField = field.field;
    this.onFieldTouch.emit(keyField);

    field.handleValueChange && this.onFieldValueChange.emit({ field: keyField, value: e?.value ?? e, e });

    if (field.selectizeInputConfig?.dependencies) {
      field.selectizeInputConfig.dependencies.forEach(k => {
        const f = this.fields.find(f => f.field === k)
        if (f) f.selectizeInputConfig?.refresh?.()
      })
    }
    if (this.fields.find((f) => f.field == field.field)?.type == 'country') this.onCountryChange(e?.value, field.field);
    if (this.fields.find((f) => f.field == field.field)?.type == 'state') this.onStateChange(e?.value, field.field);
    if (this.fields.find((f) => f.field == field.field)?.type == 'county') this.onCountyChange(e?.value, field.field);
  }

  buildFieldValidationMessage(fields: IEidosLightFormField[]) {
    fields
      ?.filter((f) => !!f.required && !f.requiredMessage)
      .forEach((f) => {
        f.requiredMessage = this.getValidationMessage(f);
      });
  }

  resetValidation() {
    let validationGroup = validationEngine.getGroupConfig(this.validationGroupName);
    if (validationGroup) validationGroup.reset();
  }

  applyValidation(): boolean {
    let validationGroup = validationEngine.getGroupConfig(this.validationGroupName);
    if (!validationGroup) return true
    const isValid = !!validationEngine.validateGroup(this.validationGroupName).isValid;
    if (!isValid && (validationEngine.validateGroup(this.validationGroupName).brokenRules?.length ?? 0) > 0) {
      //emit validation broken rules
      this.brokenRules.emit(validationEngine.validateGroup(this.validationGroupName).brokenRules?.filter(br => br.message?.length ?? 0 > 0)
        .map(br => `${br.message} (${br.type})`))
    }
    return isValid;
  }

  calculateUniqueValidationGroupName(): string {
    let n = document.getElementsByClassName('eidos-light-form-wrapper').length;
    return 'light-form-validation' + (Math.floor(Math.random() * 1000000) + n);
  }

  buildSections() {
    if (!this.fields.some((f) => !!f.section)) return
    let f = [...(this._fields ?? [])];
    f.sort((a, b) => (a.sectionPos ?? 1000) - (b.sectionPos ?? 1000));
    this.sections = f.map((f) => f.section).filter((v, i, a) => a.indexOf(v) == i && v != null) as string[];
    this.openedSections = this.sections.filter((s) => this.getFieldsBySection(s).some((f) => !!f.openedSection));
  }

  checkIfAllButtonsAreHidden() {
    return (
      this.submitButtonClass == 'd-none' &&
      this.clearButtonClass == 'd-none' &&
      (this.createButtonClass == 'd-none' || !this.createButtonClass)
    );
  }

  checkIfSomeFieldIsCountry() {
    if (!this.countries.length && !this.fields.some((f) => f.type == 'country')) return;
    this.getCountries();
  }

  onCountryChange(country: any, field: string) {
    // i did this because i dont want to clear the county on the first time the country is selected, only when the user change the country and the county is not in the new country


    if (Object.keys(this.formValues).some((f) => f == 'countryCod')) this.dontClearCounties = false;

    if (!country) {
      if (country === null) {
        this.formValues[field] = undefined;
        return;
      }
      this.states = [];
      this.counties = [];
      return;
    }
    if (this.fields.some((f) => f.type == 'state')) {
      this.getStates({ CountryISO3Cod: country });
    }

    if (this.fields.some((f) => f.type == 'county')) {
      this.getCounties({ CountryISO3Cod: country });
    }
  }

  searchStateAndCounty(formValues: any) {
    let { countryCod, stateCod } = formValues;

    this.dontClearCounties = true;

    if (countryCod) this.onCountryChange(countryCod, 'countryCod');
    if (stateCod) this.onStateChange(stateCod, 'stateCod');
  }

  onStateChange(state: any, field: string) {
    if (state) {
      this.getCounties({ CountryISO3Cod: this.formValues.countryCod });
      return;
    }
    if (state === null) {
      this.formValues[field] = undefined;
      return;
    }
    if (this.formValues.countryCod) {
      this.getStates({ CountryISO3Cod: this.formValues.countryCod });
      this.getCounties({ CountryISO3Cod: this.formValues.countryCod });
      return;
    }
    this.states = [];
    this.counties = [];
  }

  onCountyChange(county: any, field: string) {
    this.dontClearCounties = false;
    if (county) return;
    if (county === null) {
      this.formValues[field] = undefined;
      return;
    }
    if (this.formValues.countryCod)
      this.getCounties({ CountryISO3Cod: this.formValues.countryCod });
    else this.counties = [];
  }

  async getCountries() {
    this.countries = await this.coreCacheService.getCachedData<IReservationCountry>('Countries');
  }

  getStates(filters: IReservationApiGetState) {
    if (filters.CountryISO3Cod?.toUpperCase() == 'GBR') {
      this.states = []
      return
    }
    this.reservationApiService.getStates(filters).subscribe((states) => {
      this.states = states;
    });
  }

  getCounties(filters: IReservationApiGetCounty) {
    if (!filters.CountryISO3Cod) {
      this.counties = []
      return
    }
    this.reservationApiService.getCounties(filters).subscribe((counties) => {
      this.counties = counties;
    });
  }

  attachSelectizeChangeValueHandler(fields: IEidosLightFormField[]) {
    fields
      ?.filter((f) => f.type == 'selectize')
      .forEach((f) => {
        if (!f.selectizeInputConfig) return
        const { onValueChange } = f.selectizeInputConfig;
        f.selectizeInputConfig.onValueChange = (cmp, val, prevVal) => {
          this.onValueChanged(f, val);
          if (onValueChange) onValueChange(cmp, val, prevVal);
        };
      });
  }

  getDateRangeConfig(field: IEidosLightFormField, type: 'from' | 'to') {
    if (field.type != 'daterange') return;

    const disabledDates = field.disabledDates;
    if (type == 'from') return { ...this.dateRangeConfigFrom, disabledDates };
    if (type == 'to') return { ...this.dateRangeConfigTo, disabledDates };
    return
  }

  buildHtmlEditorConfig() {
    this.fields.forEach((f) => {
      if (f.type != EidosLightFormFieldType.Html) return;
      this.HTMLEditorConfigs[f.field] = { ...this._HTMLEditorConfig, height: f.height ?? this._HTMLEditorConfig.height, minHeight: f.minHeight ?? this._HTMLEditorConfig.minHeight };
    });
  }

  setDefaultValues() {
    this.fields.forEach((f) => {
      if (f.defaultValue != undefined) this.formValues[f.field] = f.defaultValue;
    });
  }

  buildSelectedValues(elements: any[]): { [key: string]: number } {
    const selectedValues: { [key: string]: number } = {};

    elements.forEach(element => {
      selectedValues[element.property] = element.min || 0;
    });

    return selectedValues;
  }

}
