

<h1 mat-dialog-title fxLayoutAlign="center">Financial Split - {{data.section}}</h1>
<div mat-dialog-content>
    <dx-data-grid class="res-data-grid" height="100%" width="100%" [dataSource]="financialSplit"
    [showBorders]="true" [showRowLines]="true">
   
   <dxi-column alignment="left" dataField="payer" caption="Payer"></dxi-column>
   <dxi-column alignment="left" dataField="totalCharge" caption="Total Charge"></dxi-column>
   
   </dx-data-grid>
</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-flat-button type="button" color="primary" (click)="close()">close</button>
</div>