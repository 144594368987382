import 'chartjs-adapter-luxon';
import { formatNumber } from '@angular/common';
import { BubbleDataPoint, ChartConfiguration, ChartDataset, ChartType, ChartTypeRegistry, DefaultDataPoint, defaults, DoughnutControllerChartOptions, PluginOptionsByType, ScatterDataPoint, TooltipCallbacks, TooltipItem, TooltipModel } from 'chart.js';
import { cloneDeep } from 'lodash';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

import { castToDateTime, eventuallyDisplayDateAndTime } from '@common/models/core-constant.model';
import { IEidosChartJSConfiguration } from '@common/models/eidos-chart-js.interface';
import { EidosChartJSConfiguration, IEidosChartDataset } from '@common/models/eidos-chart-js.model';
import { EidosChartJSDataType } from '@common/models/eidos-object.model';
import { IEidosObjectChartDimensionValue } from '@common/models/eidos-object.interface';

/**
 *  Type of Eidos ChartJS Axes
 */
type EidosChartJSAxesDataType = 'linear' | 'category' | 'time' | 'radialLinear';

@Component({
  selector: 'eidos-chart-js',
  templateUrl: './chart-js.component.html',
  styleUrls: ['./chart-js.component.scss']
})
export class ChartJsComponent implements OnInit {
  /**
   * Chart reference
   *
   * @type {(BaseChartDirective | undefined)}
   * @memberof ChartJsComponent
   */
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  /**
   * Chart config input
   *
   * @private
   * @type {IEidosChartJSConfiguration}
   * @memberof ChartJsComponent
   */
  private _config: IEidosChartJSConfiguration = new EidosChartJSConfiguration();
  @Input()
  public set Config(config: IEidosChartJSConfiguration) {
    this._config = config;

    // Compute chart options
    this.setChartOptions();
  }
  /**
   * Chart.js type
   *
   * @readonly
   * @type {ChartType}
   * @memberof ChartJsComponent
   */
  public get Type(): ChartType {
    if (this._config.type === 'horizontalBar') {
      return "bar";
    } else {
      return this._config.type;
    }
  }
  /**
   * Chart.js options
   *
   * @type {*}
   * @memberof ChartJsComponent
   */
  public options: ChartConfiguration['options'] = {
    responsive: true
  };
  /**
  * Chart raw input data
  *
  * @private
  * @type {IEidosChartDataset}
  * @memberof ChartJsComponent
  */
  private data?: IEidosChartDataset;
  @Input()
  public set Data(data: IEidosChartDataset) {
    this.data = data;

    // Compute index Axis
    this.computeIndexAxis();

    // Set the Chart dataset on retrieving data
    this.setChartJSDataset();

    // Set the Chart axis scales options retrieving data
    this.setChartOptionsScales();
  }
  /**
   * Chart.JS series of data
   *
   * @type {ChartConfiguration['data']['datasets']}
   * @memberof ChartJsComponent
   */
  public datasets: ChartConfiguration['data']['datasets'] = [];
  /**
   * Unique values (also discretized if possibile) of X index and theirs labels
   *
   * @type {Array<{ key: any, label: string, discretized?: number }>}
   * @memberof ChartJsComponent
   */
  public indexAxisMap: Array<{ key: any, label: string, discretized?: number }> = [];
  /**
   * Chart computed normalized X axis labels
   *
   * @readonly
   * @type {Array<string>}
   * @memberof ChartJsComponent
   */
  public get labels(): ChartConfiguration['data']['labels'] {
    return this.indexAxisMap.map(item => this.Type === 'bar' ? item.label : item.key);
  }
  /**
   * Chart.JS complete dataset
   *
   * @readonly
   * @type {Array<IEidosChartJSDataset>}
   * @memberof ChartJsComponent
   */
  public get chartData(): ChartConfiguration['data'] {
    return {
      datasets: this.datasets,
      labels: this.labels
    };
  }
  /**
   * Chart error flag
   *
   * @memberof ChartJsComponent
   */
  public showError = false;
  /**
   * Chart error message
   *
   * @type {(string | undefined)}
   * @memberof ChartJsComponent
   */
  public error: string | undefined;
  /**
   * Chart X axes data type
   *
   * @private
   * @type {EidosChartJSAxesDataType}
   * @memberof ChartJsComponent
   */
  private xAxesType: EidosChartJSAxesDataType = 'category';
  /**
   * Chart Y axes data type
   *
   * @private
   * @type {EidosChartJSAxesDataType}
   * @memberof ChartJsComponent
   */
  private yAxesType: EidosChartJSAxesDataType = 'linear';
  /**
   * Edges value in series of data
   *
   * @private
   * @type {({ x: { min: number | undefined, max: number | undefined }, y: { min: number | undefined, max: number | undefined } })}
   * @memberof ChartJsComponent
   */
  private valueEdges: { x: { min: number | undefined, max: number | undefined }, y: { min: number | undefined, max: number | undefined } } = {
    x: {
      min: undefined,
      max: undefined,
    },
    y: {
      min: undefined,
      max: undefined,
    }
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  /**
   * Set Chart options on retreiving config
   *
   * @private
   * @memberof ChartJsComponent
   */
  private setChartOptions(): void {

    const self = this;

    // Get defaults plugin
    let plugins: PluginOptionsByType<typeof this.Type> = cloneDeep(defaults.plugins);

    // Rotate chart if its type is horizontal
    if (this._config.type === "horizontalBar") {
      this.options!.indexAxis = "y";
    }

    // Set the title
    if (this._config.titleResolved) {
      plugins.title.display = true;
      plugins.title.text = this._config.titleResolved;
    }

    // Adjust tooltips settings
    plugins.tooltip.mode = 'nearest';
    plugins.tooltip.intersect = false;
    plugins.tooltip.animation.delay = 200;

    const tooltipCallbacks: TooltipCallbacks<typeof this.Type> = cloneDeep(defaults.plugins.tooltip.callbacks);
    tooltipCallbacks.title = function (this: TooltipModel<typeof self.Type>, data: TooltipItem<typeof self.Type>[]): string | string[] {
      return data[0].dataset.label ?? "";
    };
    tooltipCallbacks.label = function (this: TooltipModel<typeof self.Type>, data: TooltipItem<typeof self.Type>): string | string[] {
      return data.label ?? "";
    };
    tooltipCallbacks.afterLabel = function (this: TooltipModel<typeof self.Type>, data: TooltipItem<typeof self.Type>): string | string[] {
      const t = (data.chart.config as any)?.type
      return !["doughnut", "pie"].includes(t) ? (data.formattedValue ?? "") : "";
    };
    plugins.tooltip.callbacks = tooltipCallbacks;

    this.options!.hover = defaults.hover;
    this.options!.hover.mode = 'nearest';
    this.options!.hover.intersect = false;

    if (this.Type === "pie" || this.Type === "doughnut") {
      Object.assign(this.options as DoughnutControllerChartOptions, this.setPieOrDoughnutChartOptions());
    }

    this.options!.plugins = plugins;
  }

  /**
   * Set specific Chart options for Pie or Doughnut Chart on retreiving config
   *
   * @private
   * @memberof ChartJsComponent
   */
  private setPieOrDoughnutChartOptions(): DoughnutControllerChartOptions {
    let doughnutOrPieOptions: DoughnutControllerChartOptions = {
      circumference: 360,
      cutout: 50,
      radius: '100%',
      rotation: 0,
      offset: 0,
      spacing: 0,
      animation: {
        animateRotate: true,
        animateScale: false
      }
    };

    this._config.propertyBags.forEach(p => {
      switch (p.name) {
        case "rotation":
        case "radius":
          doughnutOrPieOptions[p.name] = +p.value ?? p.value;
          break;
      }
    });

    return doughnutOrPieOptions;
  }

  /**
   * Compute index axis value-label map
   *
   * @memberof ChartJsComponent
   */
  private computeIndexAxis(): void {

    const self = this;

    const formatValueToLabel = function (value: any): string {

      const format = self._config.dimensions[0].xFieldFormat;

      if (!isNaN(+value)) {
        return formatNumber(+value, "it", format ?? '1.2');
      } else {
        value = value.toString();
        return eventuallyDisplayDateAndTime(value, format);
      }
    }

    this.indexAxisMap = [];

    if (this.data && this.data.length > 0) {

      let allXValues: Array<number | string> = this.data.map(data => data.map(point => point.x)).reduce((previous, current) => previous.concat(current));
      allXValues = allXValues.filter((item, index) => allXValues.indexOf(item) === index);
      this.indexAxisMap = allXValues.map(item => {
        return {
          key: item,
          label: formatValueToLabel(item)
        };
      });
    }
  }

  /**
   * Set ChartJS dataset basing on raw input data and config properties
   *
   * @private
   * @memberof ChartJsComponent
   */
  private setChartJSDataset(): void {

    const self = this;

    if (this.data && this.data.length > 0) {

      // Build series of data
      let datasets = this.data.map((serie) => {
        let normalizedSerie: Array<ChartTypeRegistry[typeof this.Type]['defaultDataPoint']> = [];

        switch (this._config.type) {
          case "horizontalBar":
          case "bar":
            {
              if (this._config.type === 'horizontalBar') {
                this.yAxesType = 'category';
              } else {
                this.xAxesType = 'category';
              }

              const yFieldType = this._config.dimensions[0].yFieldType ?? EidosChartJSDataType.Number;

              // We must be sure that y axis (x of horizontal chart) is a Discretizable numeric interval
              if (this.isDataTypeDiscretizable(yFieldType)) {

                if (this._config.type === 'horizontalBar') {
                  this.xAxesType = this.getAxisType(yFieldType);
                } else {
                  this.yAxesType = this.getAxisType(yFieldType);
                }

                if (this.testIntervalDiscretization(serie.map(item => item.y), yFieldType)) {
                  normalizedSerie = serie.map((item) => {

                    this.updateEdgeValues(item);

                    return this._config.type === 'horizontalBar' ?
                      { x: item.y, y: item.x } as ScatterDataPoint :
                      { x: item.x, y: item.y } as ScatterDataPoint;
                  });
                } else {
                  this.showError = true;
                  this.error = "Chart error: Unable to cast dataset y dimension to specified type!"
                }
              } else {
                this.showError = true;
                this.error = "Chart error: Dataset y dimension must be date or number."
              }
            }
            break;
          case "radar":
          case "pie":
          case "doughnut":
          case "polarArea":
            {
              const xFieldType = this._config.dimensions[0].xFieldType ?? EidosChartJSDataType.Number;

              // We must be sure that x axis is a Discretizable numeric interval
              if (this.isDataTypeDiscretizable(xFieldType)) {
                this.xAxesType = this.getAxisType(xFieldType);

                if (this.testIntervalDiscretization(serie.map(item => item.x), xFieldType)) {
                  normalizedSerie = serie.map((item) => {
                    this.updateEdgeValues(item);
                    return item.x as number;
                  });
                } else {
                  this.showError = true;
                  this.error = "Chart error: Unable to cast dataset y dimension to specified type."
                }
              } else {
                this.showError = true;
                this.error = "Chart error: Dataset y dimension must be date or number."
              }
            }
            break;
          case "line":
          case "scatter":
            {
              const xFieldType = this._config.dimensions[0].xFieldType ?? EidosChartJSDataType.Number;
              const yFieldType = this._config.dimensions[0].yFieldType ?? EidosChartJSDataType.Number;

              // We must be sure that x and y axes are Discretizable numeric intervals
              if (this.isDataTypeDiscretizable(xFieldType) && this.isDataTypeDiscretizable(yFieldType)) {
                this.xAxesType = this.getAxisType(xFieldType);
                this.yAxesType = this.getAxisType(yFieldType);

                if (this.testIntervalDiscretization(serie.map(item => item.x), xFieldType) && this.testIntervalDiscretization(serie.map(item => item.y), yFieldType)) {
                  normalizedSerie = serie.map((item) => {
                    this.updateEdgeValues(item);
                    return { x: item.x, y: item.y } as ScatterDataPoint
                  });
                } else {
                  this.showError = true;
                  this.error = "Chart error: Unable to cast dataset x and y dimension to specified type!"
                }
              }
              else {
                this.showError = true;
                this.error = "Chart error: Dataset x and y dimension must be date or number."
              }
            }
            break;
          case "bubble":
            {
              const xFieldType = this._config.dimensions[0].xFieldType ?? EidosChartJSDataType.Number;
              const yFieldType = this._config.dimensions[0].yFieldType ?? EidosChartJSDataType.Number;
              const zFieldType = this._config.dimensions[0].zFieldType ?? EidosChartJSDataType.Number;

              // We must be sure that x and y axes are Discretizable numeric intervals and z dimension is a number
              if (this.isDataTypeDiscretizable(xFieldType) && this.isDataTypeDiscretizable(yFieldType) && zFieldType === EidosChartJSDataType.Number) {
                this.xAxesType = this.getAxisType(xFieldType);
                this.yAxesType = this.getAxisType(yFieldType);

                if (this.testIntervalDiscretization(serie.map(item => item.x), xFieldType)
                  && this.testIntervalDiscretization(serie.map(item => item.y), yFieldType)
                  && this.testIntervalDiscretization(serie.map(item => item.z), EidosChartJSDataType.Number)) {
                  normalizedSerie = serie.map((item) => {
                    this.updateEdgeValues(item);
                    return {
                      x: item.x,
                      y: item.y,
                      r: item.z
                    } as BubbleDataPoint
                  });
                } else {
                  this.showError = true;
                  this.error = "Chart error: Unable to cast some dataset dimension to specified type (z dimension must be a number)!"
                }
              }
            }
            break;
        }

        return normalizedSerie;
      });

      // Fix datasets for missing data in series
      if (this.Type === 'bar') {
        datasets = datasets.map(serie => {
          return this.indexAxisMap.map(indexItem => {

            const compareItems = function (dataItem: ScatterDataPoint): boolean {
              return self._config.type === 'bar' ? dataItem.x === indexItem.key : dataItem.y === indexItem.key;
            }

            const item = serie.find(serieItem => compareItems(serieItem as ScatterDataPoint));
            if (!!item) {
              return item;
            } else {
              return self._config.type === 'bar' ? { x: indexItem.key, y: 0 } as ScatterDataPoint : { y: indexItem.key, x: 0 } as ScatterDataPoint;
            }

          });
        });
      }

      // Build dataset properties from config
      this.datasets = datasets.map((serie, index) => {

        let dataset: ChartDataset<typeof this.Type, DefaultDataPoint<typeof this.Type>> = {
          data: serie,
          label: this.getGenericValueFromProperty("label", index) ?? this._config.dimensions[index].yField,
        };

        switch (this.Type) {
          case "line":
            {
              dataset = dataset as ChartDataset<"line", DefaultDataPoint<"line">>;

              this.setLineOrRadarChartDatasetOptions(dataset, index);

              // La proprietà cubicInterpolationMode può essere una costante 'default' o una costante 'monotone'
              const cubicInterpolationMode = this.getGenericValueFromProperty('cubicInterpolationMode', index);
              if (cubicInterpolationMode === 'default' || cubicInterpolationMode === 'monotone') {
                dataset.cubicInterpolationMode = cubicInterpolationMode;
              }

              this.getBoolValueFromProperty('showLine', index) !== undefined && (dataset.showLine = this.getBoolValueFromProperty('showLine', index));

              this.getBoolValueFromProperty('showLine', index) !== undefined && (dataset.showLine = this.getBoolValueFromProperty('showLine', index));
              this.getBoolValueFromProperty('spanGaps', index) !== undefined && (dataset.spanGaps = this.getBoolValueFromProperty('spanGaps', index));
              this.getGenericValueFromProperty('pointStyle', index) !== undefined && (dataset.pointStyle = this.getGenericValueFromProperty('pointStyle', index));
              dataset.tension = this.getNumberValueFromProperty('tension', index) ?? 0.5; // Set to 0.5 for medium curves

              break;
            }
          case "bar":
            {
              dataset = dataset as ChartDataset<"bar", DefaultDataPoint<"bar">>;

              this.getNumberValueFromProperty('barPercentage', index) !== undefined && (dataset.barPercentage = this.getNumberValueFromProperty('barPercentage', index));
              this.getNumberValueFromProperty('categoryPercentage', index) !== undefined && (dataset.categoryPercentage = this.getNumberValueFromProperty('categoryPercentage', index));

              // La proprietà barThickness può essere di tipo number o una costante 'flex'
              const barThickness = this.getNumberValueFromProperty('barThickness', index);
              if (barThickness !== undefined) {
                dataset.barThickness = barThickness;
              } else {
                const barThicknessString = this.getGenericValueFromProperty('barThickness', index);
                if (barThicknessString === 'flex') {
                  dataset.barThickness = barThicknessString;
                }
              }

              this.getNumberValueFromProperty('maxBarThickness', index) !== undefined && (dataset.maxBarThickness = this.getNumberValueFromProperty('maxBarThickness', index));
              this.getNumberValueFromProperty('minBarLength', index) !== undefined && (dataset.minBarLength = this.getNumberValueFromProperty('minBarLength', index));

              break;
            }
          case "radar":
            {
              dataset = dataset as ChartDataset<"radar", DefaultDataPoint<"radar">>

              this.setLineOrRadarChartDatasetOptions(dataset, index);
              this.getGenericValueFromProperty('pointStyle', index) !== undefined && (dataset.pointStyle = this.getGenericValueFromProperty('pointStyle', index));

              break;
            }

          case "doughnut":
            {
              dataset = dataset as ChartDataset<"doughnut", DefaultDataPoint<"doughnut">>;

              this.getNumberValueFromProperty('weight', index) !== undefined && (dataset.weight = this.getNumberValueFromProperty('weight', index));

              return dataset;
            }

          case "pie":
            {
              dataset = dataset as ChartDataset<"pie", DefaultDataPoint<"pie">>;

              this.getNumberValueFromProperty('weight', index) !== undefined && (dataset.weight = this.getNumberValueFromProperty('weight', index));

              return dataset;
            }

          case "bubble":
            {
              dataset = dataset as ChartDataset<"bubble", DefaultDataPoint<"bubble">>;

              this.getGenericValueFromProperty('pointStyle', index) !== undefined && (dataset.pointStyle = this.getGenericValueFromProperty('pointStyle', index));

              break;
            }
        }

        if (["line", "radar", "bar", "doughnut", "pie", "polar", "bubble"].includes(this.Type)) {
          this.getNumberValueFromProperty('borderWidth', index) !== undefined && (dataset.borderWidth = this.getNumberValueFromProperty('borderWidth', index));
          this.getNumberValueFromProperty('hoverBorderWidth', index) !== undefined && (dataset.hoverBorderWidth = this.getNumberValueFromProperty('hoverBorderWidth', index));
          this.getGenericValueFromProperty('borderColor', index) !== undefined && (dataset.borderColor = this.getGenericValueFromProperty('borderColor', index));
          this.getGenericValueFromProperty('hoverBorderColor', index) !== undefined && (dataset.hoverBorderColor = this.getGenericValueFromProperty('hoverBorderColor', index));
        }

        this.getGenericValueFromProperty('stack', index) !== undefined && (dataset.stack = this.getGenericValueFromProperty('stack', index));
        this.getGenericValueFromProperty('backgroundColor', index) !== undefined && (dataset.backgroundColor = this.getGenericValueFromProperty('backgroundColor', index));
        this.getGenericValueFromProperty('hoverBackgroundColor', index) !== undefined && (dataset.hoverBackgroundColor = this.getGenericValueFromProperty('hoverBackgroundColor', index));

        return dataset;
      });
    }
  }

  /**
   * Checks if a data type is transformable to number
   *
   * @param dataType
   * @returns
   */
  private isDataTypeDiscretizable(dataType: EidosChartJSDataType): boolean {
    return dataType !== undefined &&
      (dataType === EidosChartJSDataType.Number || dataType === EidosChartJSDataType.DateTime);
  }

  /**
   * Get an axes type from its data type
   *
   * @param dataType
   * @returns
   */
  private getAxisType(dataType: EidosChartJSDataType): EidosChartJSAxesDataType {
    switch (dataType) {
      case EidosChartJSDataType.Number:
        return 'linear';
      case EidosChartJSDataType.DateTime:
        return 'time';
      default:
        return 'category';
    }
  }

  /**
   * Checks if an interval is transformable to a numeric interval
   *
   * @private
   * @param {Array<unknown>} interval
   * @param {EidosChartJSDataType} dataType
   * @return {*}  {boolean}
   * @memberof ChartJsComponent
   */
  private testIntervalDiscretization(interval: Array<unknown>, dataType: EidosChartJSDataType): boolean {
    return dataType === EidosChartJSDataType.Number ?
      interval.every(sample => typeof sample == 'number' || !isNaN(Number(sample)))
      : interval.every(sample => castToDateTime(sample) !== undefined);
  }

  /**
   * Update edge values of X-Y axes data intervals
   *
   * @private
   * @param {IEidosObjectChartDimensionValue} item
   * @memberof ChartJsComponent
   */
  private updateEdgeValues(item: IEidosObjectChartDimensionValue): void {

    const tryToCastValue = function (value: unknown): number | undefined {

      let valueNumber: number | undefined = undefined;

      if (value !== undefined) {
        if (typeof value === 'number' || !isNaN(Number(value))) {
          valueNumber = Number(value);
        } else {
          const valueDate = castToDateTime(value);
          if (valueDate !== undefined) {
            valueNumber = valueDate.toMillis();
          }
        }
      }

      return valueNumber;
    };

    const x = tryToCastValue(item.x);

    if (x !== undefined) {
      if (this.valueEdges.x.min === undefined || x < this.valueEdges.x.min) {
        this.valueEdges.x.min = x;
      }

      if (this.valueEdges.x.max === undefined || x > this.valueEdges.x.max) {
        this.valueEdges.x.max = x;
      }
    }

    const y = tryToCastValue(item.y);

    if (y !== undefined) {
      if (this.valueEdges.y.min === undefined || y < this.valueEdges.y.min) {
        this.valueEdges.y.min = y;
      }

      if (this.valueEdges.y.max === undefined || y > this.valueEdges.y.max) {
        this.valueEdges.y.max = y;
      }
    }
  }

  /**
   * Set options for line or radar ChartJS datasets
   *
   * @private
   * @memberof ChartJsComponent
   */
  private setLineOrRadarChartDatasetOptions(dataset: ChartDataset<"line" | "radar", DefaultDataPoint<"line" | "radar">>, index: number): void {

    // La proprietà borderCapStyle può essere una costante 'butt' o una costante 'round' o una costante 'square'
    const borderCapStyle = this.getGenericValueFromProperty('borderCapStyle', index);
    if (borderCapStyle === 'butt' || borderCapStyle === 'round' || borderCapStyle === 'square') {
      dataset.borderCapStyle = borderCapStyle;
    }

    this.getArrayValueFromProperty('borderDash', index) !== undefined && (dataset.borderDash = this.getArrayValueFromProperty('borderDash', index));
    this.getBoolValueFromProperty('fill', index) !== undefined && (dataset.fill = this.getBoolValueFromProperty('fill', index));
    this.getGenericValueFromProperty('pointBorderColor', index) !== undefined && (dataset.pointBorderColor = this.getGenericValueFromProperty('pointBorderColor', index));
    this.getGenericValueFromProperty('pointHoverBorderColor', index) !== undefined && (dataset.pointHoverBorderColor = this.getGenericValueFromProperty('pointHoverBorderColor', index));
    this.getGenericValueFromProperty('pointBackgroundColor', index) !== undefined && (dataset.pointBackgroundColor = this.getGenericValueFromProperty('pointBackgroundColor', index));
    this.getGenericValueFromProperty('pointHoverBackgroundColor', index) !== undefined && (dataset.pointHoverBackgroundColor = this.getGenericValueFromProperty('pointHoverBackgroundColor', index));
    this.getNumberValueFromProperty('pointBorderWidth', index) !== undefined && (dataset.pointBorderWidth = this.getNumberValueFromProperty('pointBorderWidth', index));
    this.getNumberValueFromProperty('pointHoverBorderWidth', index) !== undefined && (dataset.pointHoverBorderWidth = this.getNumberValueFromProperty('pointHoverBorderWidth', index));
    this.getNumberValueFromProperty('pointRadius', index) !== undefined && (dataset.pointRadius = this.getNumberValueFromProperty('pointRadius', index));
    this.getNumberValueFromProperty('pointHoverRadius', index) !== undefined && (dataset.pointHoverRadius = this.getNumberValueFromProperty('pointHoverRadius', index));
    this.getNumberValueFromProperty('pointRotation', index) !== undefined && (dataset.pointRotation = this.getNumberValueFromProperty('pointRotation', index));
  }

  /**
   * Get array value from property bag
   *
   * @private
   * @param {string} propName
   * @param {number} index
   * @return {*}
   * @memberof ChartJsComponent
   */
  private getArrayValueFromProperty(propName: string, index: number): Array<any> | undefined {
    const property = this._config.propertyBags.find(prop => prop.name === propName);
    let castedValue: Array<any> | undefined;
    if (!!property) {
      const arrayValue = property.value.split('|')[index];
      castedValue = Array.from(JSON.parse(arrayValue));
    }
    return castedValue;
  }
  /**
   * Get bool value from property bag
   *
   * @private
   * @param {string} propName
   * @param {number} index
   * @return {*} {(boolean | undefined)}
   * @memberof ChartJsComponent
   */
  private getBoolValueFromProperty(propName: string, index: number): boolean | undefined {
    const property = this._config.propertyBags.find(prop => prop.name === propName);
    let castedValue: boolean | undefined;
    if (!!property) {
      const booleanValue = property.value.split('|')[index];
      switch (booleanValue) {
        case 'true':
          castedValue = true;
          break;
        case 'false':
          castedValue = false;
          break;
      }
    }
    return castedValue;
  }
  /**
   * Get number value from property bag
   *
   * @private
   * @param {string} propName
   * @param {number} index
   * @return {*}  {(number | undefined)}
   * @memberof ChartJsComponent
   */
  private getNumberValueFromProperty(propName: string, index: number): number | undefined {
    const property = this._config.propertyBags.find(prop => prop.name === propName);
    let castedValue: number | undefined;
    if (!!property) {
      castedValue = +property.value.split('|')[index];
    }
    return castedValue;
  }
  /**
   * Get generic value from property bag
   *
   * @private
   * @param {string} propName
   * @param {number} index
   * @return {*}  {(string | undefined)}
   * @memberof ChartJsComponent
   */
  private getGenericValueFromProperty(propName: string, index: number): string | undefined {
    const property = this._config.propertyBags.find(prop => prop.name === propName);
    return !!property ? property.value.split('|')[index] : undefined;
  }

  /**
   * Set Chart scales options basing on config and data
   *
   * @private
   * @memberof ChartJsComponent
   */
  private setChartOptionsScales(): void {

    this.options!.scales = {};

    const invertAxis = this._config.type === "horizontalBar";

    switch (this.xAxesType) {
      case 'category':
      case 'linear':
        this.options!.scales['xAxes'] = {
          type: this.xAxesType === 'category' ? 'category' : 'linear',
          title: {
            display: this._config.labelAxis !== undefined,
            text: this._config.labelAxis !== undefined ? this._config.labelAxis.xAxesLabel : ''
          }
        };
        break;
      case 'time':

        const min = invertAxis ? this.valueEdges.y.min! : this.valueEdges.x.min!;
        const max = invertAxis ? this.valueEdges.y.max! : this.valueEdges.x.max!;

        this.options!.scales['xAxes'] = {
          type: 'time',
          title: {
            display: this._config.labelAxis !== undefined,
            text: this._config.labelAxis !== undefined ? this._config.labelAxis.xAxesLabel : ''
          },
          time: {
            unit: 'day',
            displayFormats: {
              day: invertAxis ? this._config.dimensions[0].yFieldFormat : this._config.dimensions[0].xFieldFormat
            }
          },
          adapters: {
            date: {
              zone: 'UTC+1'
            }
          },
          ticks: {
            source: 'data'
          },
          min: min - ((max - min) / 2),
          max: max + ((max - min) / 2)
        };
        break;
    }

    switch (this.yAxesType) {
      case 'category':
      case 'linear':
        this.options!.scales['yAxes'] = {
          type: this.yAxesType === 'category' ? 'category' : 'linear',
          title: {
            display: this._config.labelAxis !== undefined,
            text: this._config.labelAxis !== undefined ? this._config.labelAxis.xAxesLabel : ''
          }
        };
        break;
      case 'time':

        const min = invertAxis ? this.valueEdges.x.min! : this.valueEdges.y.min!;
        const max = invertAxis ? this.valueEdges.x.max! : this.valueEdges.y.max!;

        this.options!.scales['yAxes'] = {
          type: 'time',
          title: {
            display: this._config.labelAxis !== undefined,
            text: this._config.labelAxis !== undefined ? this._config.labelAxis.yAxesLabel : ''
          },
          time: {
            unit: 'day',
            displayFormats: {
              day: invertAxis ? this._config.dimensions[0].xFieldFormat : this._config.dimensions[0].yFieldFormat
            }
          },
          adapters: {
            date: {
              zone: 'UTC+1'
            }
          },
          ticks: {
            source: 'data'
          },
          min: min - ((max - min) / 2),
          max: max + ((max - min) / 2)
        };
        break;
    }
  }
}
