import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadOptions } from 'devextreme/data';
import { ResolvedData } from 'devextreme/data/custom_store';
import { Observable, filter, lastValueFrom, map, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { CoreBaseApiCommandParams } from '@app/core/models/core-base-api.model';
import { EidosIconType, LocalStorageKeys } from '@app/core/models/core-constant.model';
import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosIcon, IEidosModuleConfiguration } from '@common/config/environment.interface';
import { DynamicApiRequestPaginatedParams, DynamicApiResponse, DynamicApiResponsePaginatedModel, IDynamicApiResponse } from '@common/models/dynamic-api-response.model';
import { CoreFormatService } from '@common/services/core-format.service';
import { EidosBaseApiService } from '@common/services/eidos-base-api.service';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';

import { SafeBaseObject } from '@app/core/models/base-object.models';
import { IReservationModuleAPIConfiguration } from '@reservation/models/res-environment.interface';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { IResLinkedBooking, IReservationApiLinkedBookingManageParams, IReservationLinkedBooking } from '../components/res-booking/res-booking-link/res-booking-link.component';
import { GeoTreeDestination, GeoTreeSearch, GeoTreeTopicList, GeoTreeTopicSubject, IDynamicMapApiParams, IGeoTreeDestination, IResAPIGetGeoTreeCountryAndContinent, IResApiGeoTreeParameters, IResApiGetGeoTreeTopicItinerary, IResApiVoyageDocument, IResGeoTreeCountryAndContinent, IResVoyageDocument, ResBkgProductType, ResDynamicMapResponse, ResGeoTreeCountryAndContinent, ResGeoTreeImageCategory, ResTopicItinerary, ResVoyageDocument } from '../models/geo-tree.model';
import { BookingGuestAirData, IReservationApiAirLine, IReservationApiAirPnr, IReservationApiAirPnrHistory, IReservationApiAirResult, IReservationApiBkgAirDetail, IReservationApiBookAirResultParams, IReservationApiBookingGuestAirData, IReservationApiChangePnrAirClass, IReservationApiDeleteAirPnrParams, IReservationApiGetAirPnrHistoryParams, IReservationApiGetAirResultsParams, IReservationApiRefreshAirResultPnrsParams, IReservationApiUpdateBookingGuestAirData, ResAirData, ResBkgAirDetail, ReservationAirLine, ReservationAirPnr, ReservationAirPnrHistory, ReservationAirResult, ReservationApiRightRezParams, ReservationApiRightRezResponse } from '../models/res-air.model';
import { IResApiBookingCertificate, IResApiBookingCertificateAvailableAmount, IResApiBookingCertificateDetail, IResApiCreateBookingCertificate, IResApiGetBookingCertificateDetail, IResApiImportCertificate, IResApiSearchCertificate, IResApiUpdateBookingCertificate, ResBookingCertificate, ResBookingCertificateAvailableAmount, ResBookingCertificateDetail, ResCertificateType } from '../models/res-bkg-certificate.model';
import { IReservationApiCreateBkgDocumentParams } from '../models/res-bkg-document.model';
import { IResApiBkgItemsManage, IResBkgItemApiParams, ResBkgGroupedItems, ResBkgItem } from '../models/res-bkg-item.model';
import { IReservationApiBookingNote, IReservationApiGetBkgNotes, IReservationApiManageBkgNotes, ResBkgNote, ResBkgNoteTouch } from '../models/res-bkg-notes.model';
import {
  BookingGuest,
  BookingToRequest,
  IMarket,
  IResApiParametersQuoteCreate,
  IResBookingCreateApiResponse,
  IResBookingGroup,
  IResValueExistCheckParams,
  IReservationApiBkgWishlist,
  IReservationApiBookingActionCheckParameters,
  IReservationApiBookingActionParameters,
  IReservationApiBookingAddPackageParameters,
  IReservationApiBookingDelete,
  IReservationApiBookingFinancialRecap,
  IReservationApiBookingGetMyActivitiesUrlParameters,
  IReservationApiBookingGuest,
  IReservationApiBookingRequestParameters,
  IReservationApiBookingVersionRequestParameters,
  IReservationApiEditBookingParameters,
  IReservationApiManageBkgWishlistParams,
  IReservationApiManageReferralDiscounts,
  IReservationBookingMarginality,
  Market,
  ResBkgDmc,
  ResBkgJourneyItinerary,
  ResBkgWishlist,
  ResBooking,
  ResBookingCancelReason,
  ResBookingFinancialRecap,
  ResBookingV2VParams,
  ResFinancialSplit,
  ResTailorMadeParameters,
  ReservationBookingMarginality,
  ReservationBookingReferralGuests,
} from '../models/res-booking.model';
import { IReservationAirport, IReservationApiGeotreeType, IReservationCertificateType, IReservationLoyaltyDiscount, IReservationLoyaltyRedemptionTier, IReservationNoteType, IReservationPriceType, IReservationServiceType, IReservationSuiteAmenity } from '../models/res-cached-data.models';
import { IReservationApiCreateCashReceipt, IReservationCashReceiptCashAccount, IReservationCashReceiptCheckNO, IReservationCashReceiptPaymentReason, ResCashReceiptCashAccount } from '../models/res-cash-receipt.model';
import { IResApiCheckCommissionPayoutBatchParams, IResApiCommissionPayout, IResApiCommissionPayoutBatch, IResApiCreateCommissionPayoutBatchParams, IResApiDeleteCommissionPayoutParams, IResApiGenerateCommissionPayoutBatchSelfInvoiceParams, IResApiGetCommissionPayoutAgenciesParams, IResApiGetCommissionPayoutBatchesParams, IResApiGetCommissionPayoutBkgListParams, IResApiGetCommissionPayoutParams, IResApiManageCommissionPayoutBatchParams, IResApiOpenCommissionPayoutBatchParams, IResApiSendCommissionPayoutBatchToBcParams, IResApiSendCommissionPayoutBatchToFinanceParams, IResApiSendCommissionPayoutEmailSpecs, IResApiUnlockCommissionPayoutBkgsParams, IResApiUploadCommissionPayoutBatchPaymentParams, IReservationApiCommission, ResCommissionCompanies, ResCommissionPayout, ResCommissionPayoutBatch, ReservationCommission } from '../models/res-commission.models';
import { ReservationApiBoolean } from '../models/res-constant.model';
import { IReservationApiGetCounty, IReservationApiGetState } from '../models/res-country-state-county.models';
import {
  IResGtyUpdateParams,
  IResInventoryUpdateParams,
  IResProductsPaymentSchedulerParams,
  IReservationApiCruiseAvailability,
  IReservationApiCruiseInventoryParams,
  IReservationApiCruiseSuiteListAvailability,
  IReservationApiDeck,
  IReservationGroupVoyageList,
  ResDeck,
  ResProductsPaymentScheduler,
  ReservationCruiseAvailabilityModel,
  ReservationCruiseInventoryModel,
  ReservationCruiseSuite,
  ReservationCruiseSuiteListAvailabilityModel,
  ReservationGroupVoyageList,
  ReservationPackagePricesAndCostsHistory,
} from '../models/res-cruise-availability.models';
import {
  IResApiVoyagesSearchParameters,
  IResGetCabinOccupancyParams,
  IReservationApiCruise,
  IReservationApiCruiseItinerary,
  IReservationApiCruiseLight,
  IReservationApiCruiseSuiteProfitability,
  IReservationApiDeleteCruiseParams,
  IReservationApiUpdateSuiteData,
  IReservationSuitePriceCategory,
  ResSuitePriceCategory,
  ReservationCabinOccupancy,
  ReservationCruise,
  ReservationCruiseItinerary,
  ReservationCruiseLight,
  ReservationCruiseSuiteProfitability,
} from '../models/res-cruise.models';
import { IReservationApiSaveCustomRequest, ReservationCustomRequest } from '../models/res-custom-request.models';
import { ResDepositToBookingConversionParams } from '../models/res-deposit.model';
import { IResApiGetExchangeRateParams, IResApiManageExchangeRateTableParams, IResExchangeRates, IResExchangeTypeCurrencyRate, ResExchangeRates, ResExchangeTypeCurrencyRate } from '../models/res-exchange-rate.model';
import { IResGetGuestBookedActivitiesParams, ReservationBookingActivitiesBooked } from '../models/res-guest-my-activity';
import {
  IReservationHomeActionData,
  IReservationHomeCategory,
  IReservationHomeItem,
  IReservationHomeItemActions,
} from '../models/res-home.model';
import { IReservationApiDeclineInsurance, IReservationApiInsurance, ResInsurance } from '../models/res-insurance.model ';
import { IResApiCompareManifestParams, IResApiCompareTwoManifestsParams, IResApiDeleteFullManifestParams, IResApiDeleteManifestParams, IResApiGetManifestParams, IResApiGetSavedFullManifestsParams, IResApiGetSavedManifestsParams, IResApiManifest, IResApiManifestVoyage, IResApiOpenSavedFullManifestParams, IResApiOpenSavedManifestParams, IResApiSaveFullManifestParams, IResApiSaveManifestParams, IResApiSavedManifestHeader, IResApiUpdateFullManifestHeaderParams, IResApiUpdateFullManifestParams, IResApiUpdateManifestHeaderParams, IResApiUpdateManifestParams, ResManifest, ResSavedManifestHeader } from '../models/res-manifest.model';
import {
  IResApiGetReplaceOptionsHistoryParams,
  IResApiReplaceAvailability,
  IResApiReplaceHistory,
  IResApiReplaceOptionsParams,
  IResApiReplaceStatus,
  IReservationAddServiceOptionParams,
  IReservationApiCloneServices,
  IReservationApiGetAllocationByOption,
  IReservationApiGetOptionAllocation,
  IReservationApiGetOptionAllocationDetails,
  IReservationApiGetOptionAvailability,
  IReservationApiGetOptionCost,
  IReservationApiGetOptionPrice,
  IReservationApiGetOptions,
  IReservationApiGetServices,
  IReservationApiGetSuppliers,
  IReservationApiManageAllocationByOption,
  IReservationApiManageOptionAllocation,
  IReservationApiManageOptionAvailability,
  IReservationApiManageV2OptionCost,
  IReservationApiManageV2OptionPrice,
  IReservationApiOptionCost,
  IReservationApiOptionPrice,
  IReservationApiService,
  IReservationApiServiceOption,
  IReservationApiServiceType,
  IReservationApiSupplier,
  IReservationCheckComboAvailabilityParams,
  IReservationDeleteOptionPackageParams,
  IReservationEditServiceOptionParams,
  IReservationGetAllocations,
  IReservationGetBkgOptionsParams,
  IReservationGetBkgServicesParams,
  IReservationOptionPriceAllocation,
  IReservationOptionPriceAllocationDetail,
  IReservationSuiteType,
  IReservationUpdateMessageParams,
  ResReplaceHistory,
  ReservationGetAllocations,
  ReservationOptionCost,
  ReservationOptionPrice,
  ReservationOptionPriceAllocation,
  ReservationOptionPriceAllocationDetail,
  ReservationServiceData,
  ReservationServiceOption,
  ReservationServiceType,
  ReservationSupplier,
} from '../models/res-option.models';
import {
  IResApiAgenciesSearchParameters,
  IResApiGuestCheckBookingExistsParams,
  IResApiGuestCheckBookingExistsResult,
  IResPastGuestReferralParameters,
  IResReferralParameters,
  IReservationApiAgency,
  IReservationApiAgent,
  IReservationApiAgentParameters,
  IReservationApiDeleteGuestParameters,
  IReservationApiIndividual,
  IReservationApiIndividualsParameters,
  IReservationApiInsertGuestParameters,
  IReservationApiReplaceGuestParameters,
  IReservationApiUpdateGuestParameters,
  ResApiAgenciesSearchType,
  ResPastGuestReferral,
  ReservationAgency,
  ReservationAgent,
  ReservationIndividual,
} from '../models/res-owner.model';
import { IReservationApiPackageEcmData, IReservationApiPackageInfo, IReservationApiPackageInfoParameters } from "../models/res-package-detail.model";
import {
  BookingCreatedBy,
  HlTypes,
  IPackageHighlight,
  IResApiPackageOptionToSuiteCategory,
  IResPackageOptionToSuiteCategory,
  IResPackageSetupSingleSuppManage,
  IReservationApiBookPackageParams,
  IReservationApiDeletePackageParams,
  IReservationApiGetPackageParams,
  IReservationApiLikedPackageUpdateParams,
  IReservationApiManagePackageAvailabilityParams,
  IReservationApiManagePackageDepSchedulingParams,
  IReservationApiManagePackageItineraryDaysParams,
  IReservationApiManagePackageLinkedPortsParams,
  IReservationApiManagePackageLinkedToursParams,
  IReservationApiManagePackageLinkedVoyagesParams,
  IReservationApiManagePackageOptionsParams,
  IReservationApiManagePackageParams,
  IReservationApiManagePackageTravelDatesParams,
  IReservationApiManagePackageUpgradeParams,
  IReservationApiManangePackageCanPolicyParams,
  IReservationApiPackageBookingList,
  IReservationApiPackageConfiguratedPrice,
  IReservationApiPackageDepScheduling,
  IReservationApiPackageOptionCost,
  IReservationApiPackageOptionPriceParams,
  IReservationApiPackageOptionPriceTypeAvailibility,
  IReservationApiPackageOptionPriceTypeAvailibilityParams,
  IReservationApiPackagePrice,
  IReservationApiPackageSetup,
  IReservationApiPackageUpgrade,
  IReservationApiPackageUpgradePrice,
  IReservationApiPackageUpgradePriceParams,
  IReservationApiPricesCloneDatesParams,
  IReservationCruisePriceType,
  IReservationPackageOptionSpecialItem,
  MarketingStatus,
  PackageHighlight,
  PackageHighlightType,
  ResCruisePriceType,
  ResPackage,
  ResPackageBookingList,
  ResPackageCancPolicy,
  ResPackageDepScheduling,
  ResPackageOptionCost,
  ResPackageOptionToSuiteCategory,
  ResPackagePrice,
  ResPackageSingleSupp,
  ResPackageUpgrade,
  ResPackageUpgradePrice,
  ResSearchFilterPreferences,
  ReservationBasePackage,
  ReservationPickListPackage
} from '../models/res-package.model';
import { IResTransferCashParams } from '../models/res-payment.model';
import { ResProductService, ReservationCamp } from '../models/res-product.models';
import { IPromoCombinability, IPromoParams, IResPromoCompany, IResPromoPriceType, IResPromoShip, IResPromoSuiteCategory, IReservationApiManagePromoActionParams, IReservationApiManagePromoAgenciesParams, IReservationApiManagePromoParams, IReservationApiManagePromoVoyagesParams, IReservationApiPromoApply, IReservationApiPromoDetail, IReservationBkgPromoItemManagePramaters, IReservationPromoApplyPramaters, ResPromo, ResPromoActionItems, ResPromoApply, ResPromoCompany, ResPromoPriceType, ResPromoShip, ResPromoSuiteCategory } from '../models/res-promo.model';
import { IResCampSelectParams, IResPackagePricesHistoryParams, IResPackageSearchParams, IResPackageSelectParams, IResPackageSnglSuppParams as IResPackageSingleSuppParams, IResProductCod, IResProductServiceSelectParams, IResSuiteDeckplanParams, IResVoyageSearchRequiredParams, IReservationApiCruiseSuiteDeckplanModel, ResProductCod, ReservationCruiseSuiteDeckplanModel } from '../models/res-search.models';
import { IReservationAPIGetHotelBrand, IReservationAPIGetHotelChain, IReservationAPIGetHotelUnique, IReservationAPIGetHotelUniqueSource, IReservationAPIManageHotelBrand, IReservationAPIManageHotelChain, IReservationAPIManageHotelUnique, IReservationAPIManageHotelUniqueSource, IReservationApiCreateNewServiceOrOption, IReservationApiGeotree, IReservationApiGeotreeCitySelectParams, IReservationApiGeotreeMovement, IReservationApiSetupOption, IReservationApiSetupSequenceDiscount, IReservationApiSetupService, IReservationApiSetupServiceOption, IReservationApiSetupServiceOptionInclusiveDetail, IReservationGeotreeCityPicklist, IReservationGeotreeCitySelect, IReservationHotelBrand, IReservationHotelChain, IReservationTSHotelSetup, ResGroups, ResGuestGroup, ResPayerDepartment, ResPayerGroup, ReservationApiGeotree, ReservationApiGeotreeImage, ReservationApiGeotreeType, ReservationGeotreeCity, ReservationGeotreeCitySelect, ReservationHotelBrand, ReservationHotelChain, ReservationHotelUnique, ReservationSetupOption, ReservationSetupSequenceDiscount, ReservationSetupService, ReservationSetupServiceOption, ReservationSetupServiceOptionInclusiveDetail, ReservationTSHotelSetup } from '../models/res-service.models';
import {
  IReservationApiShorexOption,
  IReservationApiShorexPrice,
  IReservationApiShorexPriceParameters,
  ReservationShorexOption,
  ReservationShorexPrice,
} from '../models/res-shorex.model';
import { IReservationApiUserInfo } from '../models/res-user.model';
import { IReservationApiPermission, ReservationActionBooking } from '../models/reservation-permission.model';

export interface IReservationErrorApi {
  errorCod: string;
  errorDescription: string;
}
export interface IReservationApiUploadBlobParameters {
  Source: string;
  BlobCode: string;
  BlobFile: File;
}
export interface IReservationUploadBlobResponse {
  fileId?: number;
  fileGuid?: string;
  fileName?: string;
}
export class ReservationUploadBlobResponse extends SafeBaseObject {
  fileId = 0;
  fileGuid = '';
  fileName = '';

  constructor(data: IReservationUploadBlobResponse) {
    super()
    this.updateData(data);
  }
}

export interface IReservationApiTsUpserGuest {
  id: number;
  DB: string;
}
@Injectable({
  providedIn: 'root',
})
export class ReservationApiService extends EidosBaseApiService {

  readonly self = this;

  constructor(
    public configService: EidosConfigService,
    public eidosSecurityService: EidosSecurityService,
    public http: HttpClient,
    public eidosLogService: EidosLogService,
    public dialog: MatDialog,
    public domSanitizer: DomSanitizer,
    public coreFormatService: CoreFormatService,
  ) {
    super(coreFormatService, configService, eidosLogService, dialog, eidosSecurityService, http);

    this.configService.currentModulesConfig
      .pipe(
        filter(
          (modules: Array<IEidosModuleConfiguration>) => !!modules.find((module) => module.moduleName === 'reservation')
        )
      )
      .subscribe((modules) => {
        const module = modules.find((module) => module.moduleName === 'reservation');

        if (module) {
          const reservationModuleAPI = module.API as IReservationModuleAPIConfiguration;
          this.urlAPI = reservationModuleAPI.url;
          this.staticUrlAPI = reservationModuleAPI.staticUrl;
          this.pluginUrlAPI = reservationModuleAPI.pluginUrl;
          this.apiVersion = reservationModuleAPI.version;
          if (reservationModuleAPI.headers) {
            this.headers = {
              ...reservationModuleAPI.headers,
              ...this.headers
            };
          }
        }
      });
  }
  /**
   * Get current user info
   *
   * @return {*}  {Observable<IReservationApiUserInfo>}
   * @memberof ReservationApiService
   */
  public getUserInfo(): Observable<IReservationApiUserInfo | undefined> {
    return this.callDynamicAPI('account/resuserinfo', {}, 'GET').pipe(
      map<DynamicApiResponse, IReservationApiUserInfo | undefined>((response) =>
        Array.isArray(response.body) && response.body.length > 0 ? (response.body[0] as IReservationApiUserInfo) : undefined)
    );
  }

  /**
   * upload file on blob Azure
   *
   * @param {IReservationApiUploadBlobParameters} data*
   * @return {*}  {Observable<Array<ReservationShorexPrice>>}
   * @memberof ReservationApiService
   */
  public uploadBlobFile(data: IReservationApiUploadBlobParameters): Observable<ReservationUploadBlobResponse> {
    const formData = new FormData();
    formData.append("fileData", data.BlobFile);

    return this.sendFileRequest({
      type: "POST",
      url: `documents/document-blob?Source=${data.Source}&BlobCode=${data.BlobCode}`,
      data: formData,
      reportProgress: false
    })
      .pipe(
        filter((response: any) => response.type !== 0),
        map<any, ReservationUploadBlobResponse>(response => new ReservationUploadBlobResponse(response)),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }
  /**
   * upload file on blob Azure
   *
   * @param {IReservationApiUploadBlobParameters} data*
   * @return {*}  {Observable<Array<ReservationShorexPrice>>}
   * @memberof ReservationApiService
   */
  public downBlobFile(guid: string) {
    return `${this.config?.urlAPI}/documents/document-blob?FileGuid=${guid}`;
  }

  public downBlobFileGeoTree(guid: string) {
    return `${this.config?.urlAPI}/documents/document-blob-guid?FileGuid=${guid}`;
  }

  /**
   * Retrieves shorex price matching with filters specified
   *
   * @param {IReservationApiShorexPriceParameters} filter*
   * @return {*}  {Observable<Array<ReservationShorexPrice>>}
   * @memberof ReservationApiService
   */
  public getShorexPrices(filter?: IReservationApiShorexPriceParameters): Observable<Array<ReservationShorexPrice>> {
    return this.callDynamicAPI('shorex/selectPrices', filter, 'POST').pipe(
      map<DynamicApiResponse, Array<ReservationShorexPrice>>((response) =>
        response.body.map((prices: IReservationApiShorexPrice) => new ReservationShorexPrice(prices))
      )
    );
  }
  /**
   * Retrieves a package product
   *
   * @param {IResPackageSelectParams} params
   * @return {*}  {Observable<ReservationBasePackage>}
   * @memberof ReservationApiService
   */
  public getPackage(params: IResPackageSelectParams): Observable<ReservationBasePackage> {
    return this.callDynamicAPI('product/selectPKG', params, 'POST')
      .pipe(
        map<DynamicApiResponse, ReservationBasePackage>((response) => new ReservationBasePackage(response.body))
      );
  }

  public getPackageVoyagePrices(voyageID?: number): Observable<ResCruisePriceType[]> {
    return this.callDynamicAPI('package/selectPackageVoyagePrices', { VoyageID: voyageID }).pipe(
      map<DynamicApiResponse, ResCruisePriceType[]>((response) => {
        return response.body.map((c: IReservationCruisePriceType) => new ResCruisePriceType(c));
      })
    )
  }

  public getPackageOptionToSuiteCategoryList(packageID?: number | string): Observable<ResPackageOptionToSuiteCategory[]> {
    return this.callDynamicAPI('package/getOptionSuiteCategory', { PackageID: packageID }).pipe(
      map<DynamicApiResponse, ResPackageOptionToSuiteCategory[]>((response) => {
        return response.body.map((c: IResPackageOptionToSuiteCategory) => new ResPackageOptionToSuiteCategory(c));
      })
    )
  }

  public managePackageOptionToSuiteCategoryList(params: IResApiPackageOptionToSuiteCategory) {
    const packageID = params?.PackageID;
    params.PackageID = undefined;
    return this.callDynamicAPI('package/manageOptionSuiteCategory', { PackageOption2SuiteCategory: params, PackageID: packageID }, 'POST').pipe(
      map<DynamicApiResponse, any>((response) => response.body)
    );
  }


  /**
   * Retrieves a camp product
   *
   * @param {IResCampSelectParams} params
   * @return {*}  {Observable<ReservationCamp>}
   * @memberof ReservationApiService
   */
  public getCamp(params: IResCampSelectParams): Observable<ReservationCamp> {
    return this.callDynamicAPI('product/selectCMP', params, 'POST')
      .pipe(
        map<DynamicApiResponse, ReservationCamp>((response) => new ReservationCamp(response.body))
      );
  }
  /**
   * Retrieves a service product
   *
   * @param {IResProductServiceSelectParams} params
   * @return {*}  {Observable<ResProductService>}
   * @memberof ReservationApiService
   */
  public getProductService(params: IResProductServiceSelectParams): Observable<ResProductService> {
    return this.callDynamicAPI('product/selectSRV', params, 'POST')
      .pipe(
        map<DynamicApiResponse, ResProductService>((response) => new ResProductService(response.body))
      );
  }
  /**
   * Retrieves a service product
   *
   * @param {IResProductServiceSelectParams} params
   * @return {*}  {Observable<ReservationServiceType>}
   * @memberof ReservationApiService
   */
  getSearchableProductService(): Observable<ReservationServiceType[]> {
    return this.callDynamicAPI('search/allProductService', {}, 'GET').pipe(
        map<DynamicApiResponse, ReservationServiceType[]>((response) =>
          response.body.map((s: IReservationServiceType) => new ReservationServiceType(s))
        )
      )
  }
  /**
   *
   * Retrieves a package product
   *
   * @param {IResPackageSearchParams} params
   * @return {*}  {Observable<BookingPackage>}
   * @memberof ReservationApiService
   */
  public getPaginatedPackage(params: IResPackageSearchParams): Observable<DynamicApiResponsePaginatedModel<ResPackage>> {
    (params.IsRetail = params?.IsRetail ? 'Y' : 'N');

    if (params?.MonthList) {
      if (params?.MonthList?.length === 0) {
        params.MonthList = '';
      } else if (params?.MonthList?.length === 1) {
        params.MonthList = params.MonthList[0];
      } else {
        params.MonthList = params.MonthList.toString();
      }
    }

    return this.callDynamicAPI(
      'product/searchLightPKG',
      params,
      'POST'
    ).pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ResPackage>>(
        (response) => {
          response.body = response.body.map(
            (pkg: IReservationApiPackageSetup) => new ResPackage(pkg)
          );
          return new DynamicApiResponsePaginatedModel<ResPackage>(response);
        }
      )
    );
  }

  public getPackageList(params:IResPackageSearchParams): Observable<ResPackage[]> {

    return this.callDynamicAPI(
      'product/searchLightPKG',
      params,
      'POST'
    ).pipe(
      map<DynamicApiResponse, ResPackage[]>(
        (response) => response.body = response.body.map((pkg: IReservationApiPackageSetup) => new ResPackage(pkg))
      )
    );
  }

  public getPackagePickList(search?: string, packageID?: number, packageTypeID?: number): Observable<ReservationPickListPackage[]> {
    return this.callDynamicAPI('package/search', { Search: search, PackageID: packageID , PackageTypeID: packageTypeID}, 'POST').pipe(
      map<DynamicApiResponse, ReservationPickListPackage[]>((response) => {
        return response.body.map((data: any) => new ReservationPickListPackage(data));
      })
    )
  }
  /**
   *
   * Retrieves a package single supplement cost
   *
   * @param {IResPackageSearchParams} params
   * @return {*}  {Observable<BookingPackage>}
   * @memberof ReservationApiService
   */
  public getPackageSingleSupp(params: IResPackageSingleSuppParams): Observable<ResPackageSingleSupp[]> {

    return this.callDynamicAPI(
      'package/selectSingleSupplement',
      params,
      'GET'
    ).pipe(
      map<DynamicApiResponse, ResPackageSingleSupp[]>(
        (response) => response.body.map((pkg: any) => new ResPackageSingleSupp(pkg))
      )
    );
  }
  /*

    getPackagesPaged(
      filters?: IResPackageSearchParams
    ): Observable<DynamicApiResponsePaginatedModel<ResPackage>> {
      if (!isUndefined(filters)) {
        const owner = this.reservationService.currentOwner.getValue();
        if (owner.isAgent()) {
          filters.AgencyID = owner.agencyID;
        }

        // Remap isRetail boolean filter to ReservationApiBoolean
        !isUndefined(filters.IsRetail) &&
          (filters.IsRetail = filters!.IsRetail ? 'Y' : 'N');
      }

      return this.callDynamicAPI(
        'product/searchPKG',
        this.remapCruiseSelectFilters(filters),
        'POST'
      ).pipe(
        map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ResPackage>>(
          (response) => {
            response.body = response.body.map(
              (pkg: IReservationApiPackageSetup) => new ResPackage(pkg)
            );
            return new DynamicApiResponsePaginatedModel<ResPackage>(response);
          }
        )
      );
    }
  */

  /**
   * Retrieves shorex price matching with filters specified
   *
   * @param {*} filter*
   * @return {*}  {Observable<Array<ReservationShorexPrice>>}
   * @memberof ReservationApiService
   */
  public getShorexOptions(filter?: any): Observable<Array<ReservationShorexOption>> {
    return this.callDynamicAPI('shorex/selectOptions', filter, 'POST').pipe(
      map<DynamicApiResponse, Array<ReservationShorexOption>>((response) =>
        response.body.map((option: IReservationApiShorexOption) => new ReservationShorexOption(option))
      )
    );
  }

  // public getSuggestedItems(suggestedItemsReq: IResSuggestedItemsRequest, currentCategory: string) {
  //   return this.callDynamicAPI('booking/suggestedItems' + currentCategory, suggestedItemsReq, 'POST').pipe(
  //     map<DynamicApiResponse, Array<ResBookingSuggestedItem>>((response) =>
  //       response.body.map((product: any) => {
  //         switch (currentCategory) {
  //           case 'CRU':
  //             return new ReservationCruise(product);
  //           case 'SHX':
  //             return new ReservationShorex(product);
  //           default:
  //             return product;
  //         }
  //       })
  //     )
  //   );
  // }
  /**
   * Retrieves user's permissions
   *
   * @return {*}  {Observable<IReservationApiPermission>}
   * @memberof ReservationApiService
   */
  public getPermission(): Observable<IReservationApiPermission> {
    return this.callDynamicAPI('reservation/permission', {}, 'POST').pipe(
      map<DynamicApiResponse, IReservationApiPermission>((response) => response.body)
    );
  }

  public getReservationDashboard() {
    const self = this;
    function createMenuCategory(item: any): IReservationHomeCategory {
      const menuCategory = {
        icon: {
          iconCode: item.ImageId,
          iconType: item.IconType || EidosIconType.SVG,
          iconSize: item.IconSize || 2,
        } as IEidosIcon,
        title: item.Label,
        description: item.Description,
        children: [],
        isFavourites: false,
      } as IReservationHomeCategory;
      return menuCategory;
    }
    function createMenuItem(item: any, items: Array<any>): IReservationHomeItem {
      const menuItem: IReservationHomeItem = {
        id: item.MenuId,
        icon: {
          iconCode: item.IconId,
          iconType: item.IconType || EidosIconType.SVG,
          iconSize: item.IconSize || 1,
        } as IEidosIcon,
        title: item.Label,
        description: item.Description,
        action: !!item.Path ? IReservationHomeItemActions.URL : undefined,
        actionData: {
          target: item.NewWindow === 'Y' ? '_blank' : '_self',
          url: item.Path,
          safeUrl: self.domSanitizer.bypassSecurityTrustUrl(item.Path),
          urlRelative: item.PathType ? item.PathType === 'R' : false,
          module: item.Module
        } as IReservationHomeActionData,
        children: [],
      };

      //add children
      items
        .filter((child) => child.ParentId === item.MenuId)
        .sort((a, b) => a.Pos - b.Pos)
        .forEach((child) => menuItem.children!.push(createMenuItem(child, items)));

      if (items.length > 0 && item.ImageId) {
        menuItem.icon.iconCode = item.ImageId;
        menuItem.icon.iconSize = item.IconSize || 2;
      }
      return menuItem;
    }
    function _prepareDashboard(items: Array<any>): Array<IReservationHomeCategory> {
      const homeMenu: Array<IReservationHomeCategory> = [];

      items
        .filter((item) => item.ParentId === 0)
        .sort((a, b) => a.Pos - b.Pos)
        .forEach((item) => {
          const menuItem = createMenuCategory(item);

          //add children
          items
            .filter((child) => child.ParentId === item.MenuId)
            .sort((a, b) => a.Pos - b.Pos)
            .forEach((child) => menuItem.children.push(createMenuItem(child, items)));

          homeMenu.push(menuItem);
        });

      return homeMenu;
    }
    return this.callDynamicAPI('oneis/home', {}, 'GET').pipe(
      map<DynamicApiResponse, Array<IReservationHomeCategory>>((response) =>
        response.body.HomeList ? _prepareDashboard(response.body.HomeList) : _prepareDashboard([])
      )
    );
  }


  /**
   * Search voyages with filters
   *
   * @param {IResApiVoyagesSearchParameters} [filters]
   * @return {DynamicApiResponsePaginatedModel<ReservationCruise>}
   * @memberof ReservationApiService
   */
  public getVoyagesSearch(filters: IResApiVoyagesSearchParameters) {
    return this.callDynamicAPI(
      'voyages/search',
      filters,
      'POST'
    ).pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ReservationCruise>>(
        (response) => {
          const paginatedModel = new DynamicApiResponsePaginatedModel<ReservationCruise>(response);
          paginatedModel.content = response.body.map(
            (cruise: IReservationApiCruise) => new ReservationCruise(cruise)
          );
          return paginatedModel;
        })
    );
  }

  getPromotionShips(promoID?: number) {
    return this.callDynamicAPI('promoVoyages/getShips', { PromoID: promoID }).pipe(
      map<DynamicApiResponse, Array<ResPromoShip>>((response) =>
        response.body.map((p: IResPromoShip) => new ResPromoShip(p))
      )
    );
  }

  getPromotionPriceTypes(promoID?: number, companyPromoID?: number) {
    return this.callDynamicAPI('promoVoyages/getPriceTypes', { PromoID: promoID, CompanyPromoID: companyPromoID }).pipe(
      map<DynamicApiResponse, Array<ResPromoPriceType>>((response) =>
        response.body.map((p: IResPromoPriceType) => new ResPromoPriceType(p))
      )
    );
  }

  packageRefreshPdf(packageID: number) {
    return this.callDynamicAPI(
      'package/PackagePdfRefresh',
      { PackageID: packageID },
      'GET'
    ).pipe(
      map<DynamicApiResponse, boolean>(
        (response) => response.errors.length == 0
      )
    );
  }
  /**
   * Search voyages with filters
   *
   * @param {IResApiVoyagesSearchParameters} [filters]
   * @return {DynamicApiResponsePaginatedModel<ReservationCruise>}
   * @memberof ReservationApiService
   */
  public getAgenciesOrDirectsSearch(filters: IResApiAgenciesSearchParameters) {

    switch (filters.SearchType) {

      case ResApiAgenciesSearchType.Agencies:
        return this.callDynamicAPI(
          'agency/select',
          Object.assign({ Typology: 'AGN' }, filters),
          'GET'
        ).pipe(
          map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ReservationAgency | ReservationIndividual>>(
            (response) => {
              const paginatedModel = new DynamicApiResponsePaginatedModel<ReservationAgency>(response);
              paginatedModel.content = response.body.map(
                (agency: IReservationApiAgency) => new ReservationAgency(agency)
              );
              return paginatedModel;
            })
        );

      case ResApiAgenciesSearchType.Direct:
        return this.callDynamicAPI(
          'individual/select',
          filters,
          'GET'
        ).pipe(
          map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ReservationIndividual>>(
            (response) => {
              const paginatedModel = new DynamicApiResponsePaginatedModel<ReservationIndividual>(response);
              paginatedModel.content = response.body.map(
                (individual: IReservationApiIndividual) => new ReservationIndividual(individual)
              );
              return paginatedModel;
            })
        );

      case ResApiAgenciesSearchType.Consortium:
        return this.callDynamicAPI(
          'agency/select',
          Object.assign({ Typology: 'CNS' }, filters),
          'GET'
        ).pipe(
          map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ReservationAgency>>(
            (response) => {
              const paginatedModel = new DynamicApiResponsePaginatedModel<ReservationAgency>(response);
              paginatedModel.content = response.body.map(
                (agency: IReservationApiAgency) => new ReservationAgency(agency)
              );
              return paginatedModel;
            })
        );

      case ResApiAgenciesSearchType.HeartQuarter:
        return this.callDynamicAPI(
          'agency/select',
          Object.assign({ Typology: 'AHQ' }, filters),
          'GET'
        ).pipe(
          map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ReservationAgency>>(
            (response) => {
              const paginatedModel = new DynamicApiResponsePaginatedModel<ReservationAgency>(response);
              paginatedModel.content = response.body.map(
                (agency: IReservationApiAgency) => new ReservationAgency(agency)
              );
              return paginatedModel;
            })
        );
    }
  }

  getIndividualGuests(filters: IReservationApiIndividualsParameters) {
    return this.callDynamicAPI(
      'individual/select',
      filters,
      'GET'
    ).pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ReservationIndividual>>(
        (response) => {
          const paginatedModel = new DynamicApiResponsePaginatedModel<ReservationIndividual>(response);
          paginatedModel.content = response.body.map(
            (individual: IReservationApiIndividual) => new ReservationIndividual(individual)
          );
          return paginatedModel;
        })
    );
  }

  checkReferralPastGuest(bkgID: number, params: { IndividualID: number }[]) {
    return this.callDynamicAPI('referral/CheckPastGuest', { BkgID: bkgID, Guests: params }, 'POST').pipe(
      map<DynamicApiResponse, ResPastGuestReferral[]>((response) => {
        return response.body.map((r: IResPastGuestReferralParameters) => new ResPastGuestReferral(r));
      })
    );
  }

  getReferralIndividualID(params: IResReferralParameters) {
    return this.callDynamicAPI('booking/GetGuestReferral', params, 'GET').pipe(
      map<DynamicApiResponse, { GuestReferralID: number, OldDirectID: number }>((response) => response.body[0])
    );
  }

  manageReferral(params: IResReferralParameters) {
    if(this.callMillisec>0 && DateTime.now().toMillis()-this.callMillisec<1000) return of(0)
    return this.callDynamicAPI('booking/ManageGuestReferral', params, 'POST').pipe(
      map<DynamicApiResponse, number>((response) => response.body)
    );
  }

  mailGuestChecker(individualID: number) {
    return this.callDynamicAPI('booking/checkMailGuestReferral', { DirectID: individualID }, 'GET').pipe(
      map<DynamicApiResponse, { DirectID: number, Email: string }>((response) => response.body[0])
    );
  }

  getPortfolioGuests(individualID: number[], bkgID: number) {
    return this.callDynamicAPI('booking/getPortfolioGuests', { DirectID: individualID, BkgID: bkgID }, 'GET').pipe(
      map<DynamicApiResponse, ReservationBookingReferralGuests[]>((response) => response.body.map((g: any) => new ReservationBookingReferralGuests(g))
      )
    );
  }

  managePortfolioDiscounts(bkgID: number, tblPortfolio: IReservationApiManageReferralDiscounts[]) {
    return this.callDynamicAPI('booking/managePortfolioDiscountReferral', { BkgID: bkgID, TblPortfolio: tblPortfolio }, 'POST').pipe(
      map<DynamicApiResponse, any>((response) => response.body)
    );
  }

  checkReferralVerification(bkgID: number) {
    return this.callDynamicAPI('booking/getReferralIndividualVerification', { BkgID: bkgID }, 'GET').pipe(
      map<DynamicApiResponse, { IsVerified: string, Verified: string }>((response) => response.body[0])
    );
  }

  /**
   * Select inventory capacity of a cruise
   *
   * @return {*}
   * @memberof ReservationApiService
   */
  getInventorySelectCapacity(payload: IResVoyageSearchRequiredParams) {
    return this.callDynamicAPI('inventory/selectcapacity', payload, 'GET').pipe(
      map<DynamicApiResponse, ReservationCruiseInventoryModel>((response) => new ReservationCruiseInventoryModel(response.body))
    );
  }

  /**
   * Select inventory suites of a cruise
   *
   * @param {IResVoyageSearchRequiredParams} payload
   * @param {IReservationSuiteAmenity[]} [amenities=[]]
   * @return {*}
   * @memberof ReservationApiService
   */
  getInventorySuiteList(payload: IResVoyageSearchRequiredParams, amenities: IReservationSuiteAmenity[] = []) {
    return this.callDynamicAPI('inventory/suiteList', payload, 'GET').pipe(
      map<DynamicApiResponse, Array<ReservationCruiseSuiteListAvailabilityModel>>((response) =>
        response.body.map(
          (reservationCruiseAvail: IReservationApiCruiseSuiteListAvailability) =>
            new ReservationCruiseSuiteListAvailabilityModel(reservationCruiseAvail, amenities)
        )
      )
    );
  }


  getPackagePricesHistory(payload: IResPackagePricesHistoryParams) {
    return this.callDynamicAPI('package/pricesHistory', payload, 'GET').pipe(
      map<DynamicApiResponse, ReservationPackagePricesAndCostsHistory>((response) =>
        new ReservationPackagePricesAndCostsHistory(response.body)
      )
    );
  }


  /**
   * update inventory suites of a cruise
   *
   * @param {IResInventoryUpdateParams} payload
   * @return {*}
   * @memberof ReservationApiService
   */
  inventoryUpdate(payload: IResInventoryUpdateParams) {
    return this.callDynamicAPI('inventory/update', payload, 'POST').pipe(
      map<DynamicApiResponse, object>((response) => response.body)
    );
  }

  gtyUpdate(payload: IResGtyUpdateParams) {
    return this.callDynamicAPI('gty/update', payload, 'POST').pipe(
      map<DynamicApiResponse, any>((response) => response.body)
    );
  }
  /**
   * Retrieves cruise availability
   *
   * @return {*}
   * @memberof ReservationApiService
   */
  getCruiseAvailabilityNew(payload: IReservationApiCruiseInventoryParams, showAllSuites: boolean = false) {
    const url = showAllSuites ? 'inventory/selectAll' : 'inventory/select';
    return this.callDynamicAPI(url, payload, 'GET').pipe(
      map<DynamicApiResponse, Array<ReservationCruiseAvailabilityModel>>((response) =>
        response.body.map((aval: IReservationApiCruiseAvailability) => new ReservationCruiseAvailabilityModel(aval))
      )
    );
  }
  /**
   * Get current selected products
   *
   * @param {ResProductType} productCode
   * @param {number[]} [productIDs=[]]
   * @param {*} [filters]
   * @return {*}  {Promise<any[]>}
   * @memberof ResPreBookingUtility
   */
  getProductsPaymentScheduler(params: IResProductsPaymentSchedulerParams) {
    return this.callDynamicAPI('inventory/pyamentScheduler', params, 'GET').pipe(
      map<DynamicApiResponse, Array<ResProductsPaymentScheduler>>((response) =>
        response.body.map((data: any) => new ResProductsPaymentScheduler(data))
      )
    );
  }
  /**
   * Get suites deckplan of a cruise
   *
   * @param {IResSuiteDeckplanParams} payload
   * @return {*}
   * @memberof ReservationApiService
   */
  getSuiteDeckplan(payload: IResSuiteDeckplanParams) {
    //TODO cache data
    return this.callDynamicAPI('suite/deckplan', payload, 'GET').pipe(
      map<DynamicApiResponse, ReservationCruiseSuiteDeckplanModel>(
        (response) => new ReservationCruiseSuiteDeckplanModel(response.body as IReservationApiCruiseSuiteDeckplanModel)
      )
    );
  }

  //#region Booking

  /**
   * Create booking with specified params
   *
   * @param {IResApiParametersQuoteCreate} params
   * @return {*}  {Observable<IResBookingCreateApiResponse>}
   * @memberof ReservationApiService
   */
  createBooking(params: IResApiParametersQuoteCreate): Observable<IResBookingCreateApiResponse> {
    return this.postDynamicAPI('quote/create', params).pipe(
      map<DynamicApiResponse, IResBookingCreateApiResponse>((apiResponse) => {
        let response: IResBookingCreateApiResponse = {
          BkgID: ResBooking.INVALID_BOOKING
        };

        if (!!apiResponse && Array.isArray(apiResponse.body) && apiResponse.body.length > 0) {
          response.BkgID = apiResponse.body[0].BkgID ?? ResBooking.INVALID_BOOKING;
          response.ErrorMessage = apiResponse.body[0].ErrorMessage;
        }

        return response;
      })
    );
  }

  getTailorMadeParameters() {
    return this.callDynamicAPI('tailorMade/parameters', {}, 'GET').pipe(
      map<DynamicApiResponse, ResTailorMadeParameters>((response) => new ResTailorMadeParameters(response.body))
    );
  }
  /**
   * Retrieves booking items list
   *
   * @return {*}  {Observable<ResBkgItem[]>}
   * @memberof ReservationApiService
   */
  getBookingItems(bkgID:number, filters?: IResBkgItemApiParams): Observable<ResBkgGroupedItems> {
    return this.callDynamicAPI('booking/items', {BkgID:bkgID, ...filters}, 'POST').pipe(
      map<DynamicApiResponse, ResBkgGroupedItems>((response) => {

        const content = new ResBkgGroupedItems()

        const items = new DynamicApiResponsePaginatedModel<ResBkgItem>(response);
        items.content = response.body.items.map((item: any) => new ResBkgItem(item))
        content.itemsPaginated = items

        let aggregationField = ''

        content.groups = response.body.groups.map((item: any, index:number) => {
          const g = new ResGroups()
          if(item.GuestCod) {
            aggregationField='guestCod'
            g.code = `${item.GuestCod}`
            g.description = item.GuestName
            g.id = item.GuestCod
          } else if(item.RoomConfHdrID) {
            aggregationField='roomConfHdrID'
            g.code = `${item.RoomConfHdrID}`
            g.description = `G${index+1}`
            g.id = item.RoomConfHdrID
          } else if(item.ProductCod) {
            aggregationField='productCod'
            g.code = item.ProductCod
            g.description = item.ProductName
            g.id = index+1
            g.attributes = [
              {productName:item.ProductName},
              {itemName:item.ItemName},
              {suiteCategoryID:item.SuiteCategoryID},
              {suiteCategory:item.SuiteCategory},
              {priceCategoryID:item.PriceCategoryID},
              {priceCategory:item.PriceCategory},
              {suiteNo:item.SuiteNo},
            ]
          }

          return g
        })

        let row=0, lastCodes=''
        items.content.forEach(item => {
          item.aggregationDescription = ''
          item.aggregationField = aggregationField
          const codes = `${(item as any)[aggregationField]}`;

          if(lastCodes!==codes) {
            row = 0
            lastCodes=codes
          }
          item.aggregationGroupRow = ++row

          codes.split(',').forEach(code=>{
              const group = content.groups.find(g=>g.code==code)
              if(group && !item.aggregations.find(ag=>ag.code===code)) {
                item.aggregations.push(group)
                item.aggregationDescription += ', ' + group.description
              }
          })

          item.aggregationDescription = item.aggregationDescription.replace(/^\, /,'')
        })

        return content
      }),
      catchError((_response: IDynamicApiResponse) => {
        return [];
      })
    );
  }

  manageMultiItems(bkgID: number, items: IResApiBkgItemsManage[]) {
    return this.callDynamicAPI('booking/manageMultiItems', {BkgID: bkgID, EditItem: items}, 'POST');
  }


  /**
   * Retrieves booking cancel reasons list
   *
   * @return {*}  {Observable<ResBookingCancelReason>}
   * @memberof ReservationApiService
   */
  getBookingCancelReason(bkgID?:number): Observable<Array<ResBookingCancelReason>> {
    return this.callDynamicAPI('booking/cancelReason', {BkgID: bkgID}, 'GET').pipe(
      map<DynamicApiResponse, Array<ResBookingCancelReason>>((response) => response.body.map((r: any) => new ResBookingCancelReason(r))),
      catchError((_response: IDynamicApiResponse) => {
        return [];
      })
    );
  }

  normalizeBookingGet(data: any) {

    const bkg = Object.assign({}, data?.DT0[0] ?? {})
    bkg.Owner = data?.DT1[0] ?? {}
    bkg.FinacialRecap = data.DT2 ?? []
    bkg.Voyages = data?.DT3 ?? []
    bkg.Voyages.forEach((v: any) => {
      v.Itineraries = data?.DT9.filter((i: any) => i.VoyageID == v.VoyageID)
      v.Features = data?.DT10.filter((f: any) => f.VoyageID == v.VoyageID)
    })

    bkg.Supplements = data.DT4
    bkg.Supplements.forEach((s: any) => {
      s.Promo = data?.DT8.find((p: any) => p.PromoID == s.PromoID) ?? {}
    })

    bkg.Pax = data?.DT5
    bkg.Pax.forEach((s: any) => {
      s.GuestOwnArrangments = data?.DT16.filter((d: any) => d.DetailID == s.DetailID)
    })

    bkg.Deposit = data?.DT6
    bkg.Penalties = data?.DT7

    bkg.CustomPackages = data?.DT11
    bkg.CustomPackages.forEach((s: any) => {
      s.CustomPackagesDetail = data?.DT12.filter((d: any) => d.DetailID == s.DetailID)
    })

    bkg.Packages = data?.DT13
    bkg.PriceJourney = data?.DT14
    bkg.JourneySupplements = data?.DT15

    bkg.Links = data?.DT17
    bkg.EmbarkDebarkInfo = data?.DT18[0] ?? {}
    bkg.EmbarkDebarkDetails = data?.DT19
    bkg.RoomsConfig = data?.DT20

    return bkg
  }




  /**
   * Retrieves booking matching with filters specified
   *
   * @param {IReservationApiBookingRequestParameters} [filter]
   * @param {boolean} [lightMode=false] Specify to retrieve booking in a minimal structure
   * @return {*}  {Observable<ResBooking>}
   * @memberof ReservationApiService
   */
  getBooking(
    filter?: IReservationApiBookingRequestParameters,
    lightMode: boolean = false
  ): Observable<ResBooking> {
    let url = lightMode ? 'lightBooking/select' : 'booking/selectOptimized';

    return this.callDynamicAPI(url, filter, 'GET').pipe(
      map<DynamicApiResponse, ResBooking>((response) => {
        const data = lightMode ? response.body : this.normalizeBookingGet(response.body)
        return new ResBooking(data)
      }),
      catchError((response: IDynamicApiResponse) => {
        return of(ResBooking.ErrorResponse(filter?.BkgID ?? 0, response.Errors ?? [], response));
      })
    );
  }
  /**
   * Post booking edit params to edit a booking
   *
   * @param {IReservationApiEditBookingParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  editBooking(params: IReservationApiEditBookingParameters) {
    return this.callDynamicAPI('booking/edit', params, 'POST');
  }
  /**
   * Post booking version request params to recalculate a booking
   *
   * @param {IReservationApiBookingVersionRequestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  recalculateBooking(params: IReservationApiBookingRequestParameters) {
    return this.callDynamicAPI('booking/recalculate', Object.assign({ Action: 'RePrice' }, params), 'POST');
  }
  /**
   * Performs booking version confirmation
   *
   * @param {IReservationApiBookingVersionRequestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  confirmBooking(params: IReservationApiBookingVersionRequestParameters) {
    return this.callDynamicAPI('booking/confirm', params, 'POST');
  }
  /**
   * Perform booking version deletion
   *
   * @param {IReservationApiBookingVersionRequestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  deleteBooking(params: IReservationApiBookingDelete) {
    return this.callDynamicAPI('booking/delete', params, 'POST');
  }
  /**
   * Perform booking version cloning
   *
   * @param {IReservationApiBookingVersionRequestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  cloneBooking(params: IReservationApiBookingVersionRequestParameters) {
    return this.callDynamicAPI('booking/addversion', params, 'POST');
  }
  /**
   * Request autheticated url to open myactivities on web site
   *
   * @param {IReservationApiBookingVersionRequestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  getMyActivitiesUrl(params: IReservationApiBookingGetMyActivitiesUrlParameters) {
    return this.getExternalAPI('oem/crystalws/Agent/OnBehalfOf', 'POST', params).pipe(
      map<any, string>(res => {
        const url = res.Body.url ?? '';
        return url;
      })
    );
  }
  getSpResult(spCode: string) {
    return this.getExternalAPI('oem/dd/listdata', 'POST', {name:spCode}).pipe(
      map<any, any[]>(res => {
        if(res.HasError) return of([])
        return res.listdata;
      })
    );
  }


  getDynamicMap(prms:IDynamicMapApiParams){
    return this.getExternalAPI('oem/keytechmaps/itinerary', 'POST', prms).pipe(
      map<any, ResDynamicMapResponse>(res => new ResDynamicMapResponse(res))
    );
  }

  /**
   * Request upsert guest on Travel studio
   *
   * @param {IReservationApiBookingVersionRequestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  upsertGuestOnTS(params: IReservationApiTsUpserGuest) {
    const payload = {
      id: (params.id ?? '').toString(),
      DB: params.DB
    }
    return this.callDynamicAPI('oem/travelstudio/sendGuest2TS', payload, 'POST').pipe(
      map<any, any>(res => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Request autheticated url to open myactivities on web site
   *
   * @param {IReservationApiBookingVersionRequestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  bookingAddPackage(params: IReservationApiBookingAddPackageParameters) {
    return this.callDynamicAPI('booking/addpackage', params, 'POST').pipe(
      map<IDynamicApiResponse, IDynamicApiResponse>(res => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Check if can perform booking action
   *
   * @param {IReservationApiBookingActionCheckParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  checkBookingAction(params: IReservationApiBookingActionCheckParameters) {

    const { Action, ...apiParams } = params;
    let apiAction = '';

    switch (Action) {
      case ReservationActionBooking.ChangeVoyageBookingVersion:
        apiAction = 'ChangeVoyage';
        break;
      default:
        throw {
          name: 'Unhandled booking action',
          message: 'Not implemented a check handling for this booking action.'
        };
    }

    return this.postDynamicAPI('booking/checkAction', {
      Action: apiAction,
      ...apiParams
    });
  }
  /**
   * Performs a booking action
   *
   * @param {IReservationApiBookingActionParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  executeBookingAction(params: IReservationApiBookingActionParameters, options?: any) {
    let route: string = '';
    switch (params.Action) {
      case ReservationActionBooking.ChangeSuiteBookingVersion:
        route = 'booking/changesuite';
        break;
      case ReservationActionBooking.PromoteBooking:
        route = 'booking/promote';
        break;
    }

    const { Action, ...routeParams } = params;

    if (options?.keepPrice) routeParams.KeepPrice = 'Y'

    return this.callDynamicAPI(route, routeParams, 'POST');
  }
  /**
   * Retrieves booking guests
   *
   * @param {number} bkgID
   * @param {number} sectionID
   * @return {*}
   * @memberof ReservationApiService
   */
  public getBookingGuests(bkgID: number, sectionID: number) {
    return this.postDynamicAPI('booking/guests', { BkgID: bkgID, SectionID: sectionID }).pipe(
      map<DynamicApiResponse, Array<BookingGuest>>((response) => {
        return response.body.map((guest: IReservationApiBookingGuest) => new BookingGuest(guest));
      })
    );
  }
  /**
   * Retrieves booking itinerary
   *
   * @param {number} bkgID
   * @return {*}
   * @memberof ReservationApiService
   */
  public getBookingItinerary(bkgID: number) {
    return this.postDynamicAPI('booking/itinerary', { BkgID: bkgID }).pipe(
      map<DynamicApiResponse, Array<ReservationCruiseItinerary>>((response) => {
        return response.body.map((i: IReservationApiCruiseItinerary) => new ReservationCruiseItinerary(i));
      })
    );
  }
  /**
   * Retrieves booking financials
   *
   * @param {number} bkgID
   * @return {*}
   * @memberof ReservationApiService
   */
  public getBookingFinancials(bkgID: number) {
    return this.getDynamicAPI('booking/financial', { BkgID: bkgID }).pipe(
      map<DynamicApiResponse, Array<ResBookingFinancialRecap>>((response) => {
        return response.body.Financials.map((i: IReservationApiBookingFinancialRecap) => new ResBookingFinancialRecap(i));
      })
    );
  }
  /**
   * Convert an OD to a booking
   *
   * @param {ResDepositToBookingConversionParams} params
   * @return {*}
   * @memberof ReservationApiService
   */
  convertODToBkg(params: ResDepositToBookingConversionParams) {
    return this.postDynamicAPI('booking/voyage2voyage', params)
  }
  /**
   * Perform booking V2V
   *
   * @param {ResBookingV2VParams} params
   * @return {*}
   * @memberof ReservationApiService
   */
  bookingV2V(params: ResBookingV2VParams) {
    return this.postDynamicAPI('booking/v2v', params);
  }
  /**
   * Get a booking guest booked activities
   *
   * @param {IResGetGuestBookedActivitiesParams} params
   * @return {Observable<ReservationBookingActivitiesBooked>}
   * @memberof ReservationApiService
   */
  getGuestBookedActivities(params: IResGetGuestBookedActivitiesParams): Observable<ReservationBookingActivitiesBooked> {
    return this.getDynamicAPI('booking/guestBookedActivities', params).pipe(
      map<DynamicApiResponse, ReservationBookingActivitiesBooked>((response) => {
        return new ReservationBookingActivitiesBooked(response.body);
      })
    );
  }

  getGuestTypesForBkg(params: { BkgID: number }): Observable<IReservationLoyaltyDiscount[]> {
    return this.callDynamicAPI('booking/guestTypes', params, 'GET').pipe(
      map<DynamicApiResponse, IReservationLoyaltyDiscount[]>((response) => response.body)
    );
  }

  getAllLinkedBkgs(params: { BkgID: number }): Observable<{ prev: IResLinkedBooking[], next: IResLinkedBooking[], linked: IResLinkedBooking[] }> {
    return this.getDynamicAPI('booking/checkBookingLink', params).pipe(
      map<DynamicApiResponse, { prev: IResLinkedBooking[], next: IResLinkedBooking[], linked: IResLinkedBooking[] }>((response) => {
        return { prev: response.body.linkedPrev, next: response.body.linkedNext, linked: response.body.linkedBooking };
      }
      ))
  }

  /**
   * Get links to other bkgs of current bkg
   *
   * @param {({ BkgID: number } | { Sequence: number })} params
   * @return {*}  {Observable<IReservationLinkedBooking[]>}
   * @memberof ReservationApiService
   */
  getLinkedBkgsOfBkg(params: { BkgID: number } | { Sequence: number }): Observable<IReservationLinkedBooking[]> {
    return this.callDynamicAPI('booking/getLinks', params, 'GET').pipe(
      map<DynamicApiResponse, IReservationLinkedBooking[]>((response) => response.body.map((i: any) => ({ ...i, Status: 'A' })))
    );
  }
  /**
   * Checks is if can be linked to bkgidprv as IsSameVyg
   *
   * @param {{ BkgID: number, BkgIDPrv: number, IsSameVyg: ReservationApiBoolean }} params
   * @return {*}  {(Observable<(any[] | {})>)}
   * @memberof ReservationApiService
   */
  checkBkgLink(params: { BkgID: number, BkgIDPrv: number, IsSameVyg: ReservationApiBoolean }): Observable<(any[] | {})> {
    return this.callDynamicAPI('booking/checkLink', params, 'GET').pipe(
      map<DynamicApiResponse, any[] | {}>((response) => response.body)
    );
  }
  /**
   * Upsert booking links
   *
   * @param {{ LinkedBkg: IReservationApiLinkedBookingManageParams[], IsSameVyg: ReservationApiBoolean }} params
   * @return {*}  {(Observable<(any[] | {})>)}
   * @memberof ReservationApiService
   */
  manageBkgLinks(params: { LinkedBkg: IReservationApiLinkedBookingManageParams[], IsSameVyg: ReservationApiBoolean }): Observable<(any[] | {})> {
    return this.callDynamicAPI('booking/upsertLinks', params, 'POST').pipe(
      map<DynamicApiResponse, any[] | {}>((response) => response.body)
    );
  }

  /**
   * Save OD notes
   *
   * @param {number} bkgID
   * @param {string} newNote
   * @return {*}
   * @return {*}
   * @memberof ReservationApiService
   */
  saveBookingNotes(bkgID: number, note: string) {
    return this.postDynamicAPI('booking/upsertHeaderNote', { bkgID, note });
  }

  //#endregion Booking

  /**
   * Retrieves cruise availability
   *
   * @return {*}  {Observable<ReservationAvailabilityModel>}
   * @memberof ReservationApiService
   */
  public getCruiseAvailabilityWithDetails(voyageId: number, route: string = 'cruise/availability') {
    return this.callDynamicAPI(route, { voyageId }, 'GET').pipe(
      map<DynamicApiResponse, ReservationCruiseAvailabilityModel>((response) => {
        const reservationCruiseAvailability = new ReservationCruiseAvailabilityModel(response.body);
        return reservationCruiseAvailability;
      })
    );
  }

  /**
   *  Get Individuals, used in bookin guests search
   *
   * @param {IReservationApiIndividualsParameters} [filters]
   * @return {*}
   * @memberof ReservationApiService
   */
  public getIndividuals(filters?: IReservationApiIndividualsParameters) {
    return this.callDynamicAPI('individual/select', filters, 'GET').pipe(
      map<DynamicApiResponse, ReservationIndividual[]>((response) => {
        return response.body.map((r: IReservationApiIndividual) => new ReservationIndividual(r));
      })
    );
  }

  public getPaginatedIndividuals(filters?: IReservationApiIndividualsParameters, loadOptions?: any) {
    filters = this.fillPaginatedParams(filters, loadOptions);

    return this.callDynamicAPI('individual/select', filters, 'GET').pipe(
      map<DynamicApiResponse, { data: any[]; totalCount: number }>((response) => {
        return {
          data: response.body.map((r: IReservationApiIndividual) => new ReservationIndividual(r)),
          totalCount: response.recordsCount,
        };
      })
    );
  }



  /**
   * Get Owners, used in owners search
   *
   * @param {IReservationApiIndividualsParameters} [filters]
   * @param {*} [loadOptions]
   * @return {*}
   * @memberof ReservationApiService
   */
  public getOwners(filters?: IReservationApiIndividualsParameters) {
    return this.callDynamicAPI('individualOwners/select', filters, 'GET').pipe(
      map<DynamicApiResponse, ReservationIndividual[]>((response) => {
        return response.body.map((r: IReservationApiIndividual) => new ReservationIndividual(r));
      })
    );
  }

  public getPaginatedOwners(filters?: IReservationApiIndividualsParameters, loadOptions?: any) {
    filters = this.fillPaginatedParams(filters, loadOptions);

    return this.callDynamicAPI('individualOwners/select', filters, 'GET').pipe(
      map<DynamicApiResponse, { data: any[]; totalCount: number }>((response) => {
        return {
          data: response.body.map((r: IReservationApiIndividual) => new ReservationIndividual(r)),
          totalCount: response.recordsCount,
        };
      })
    );
  }

  /**
   * Get individual household
   *
   * @param {IReservationApiIndividualsParameters} [filters]
   * @return {*}
   * @memberof ReservationApiService
   */
  public getHousehold(household_id: number) {
    return this.callDynamicAPI('individual/select', { household_id }, 'GET').pipe(
      map<DynamicApiResponse, ReservationIndividual[]>((response) => {
        return response.body.map((r: IReservationApiIndividual) => new ReservationIndividual(r));
      })
    );
  }


  public guestCheckBookingExists(params: IResApiGuestCheckBookingExistsParams): Observable<{ current: IResApiGuestCheckBookingExistsResult[], next: IResApiGuestCheckBookingExistsResult[], previous: IResApiGuestCheckBookingExistsResult[] }> {
    return this.callDynamicAPI('individual/checkBookingExists', params, 'POST').pipe(
      map<DynamicApiResponse, { current: IResApiGuestCheckBookingExistsResult[], next: IResApiGuestCheckBookingExistsResult[], previous: IResApiGuestCheckBookingExistsResult[] }>((response) => {
        return { current: response.body.Current, next: response.body.Next, previous: response.body.Previous };
      }
      ))
  }

  /**
   * Manage Individuals
   *
   * @param {IReservationApiIndividual} params
   * @return {*}
   * @memberof ReservationApiService
   */
  public manageIndividuals(params: IReservationApiIndividual) {
    return this.callDynamicAPI('individual/manage', params, 'POST');
  }

  /**
   * Get Agencies
   *
   * @param {IReservationApiAgency} [filters]
   * @return {*}
   * @memberof ReservationApiService
   */
  public getAgencies(filters?: IReservationApiAgency) {
    return this.callDynamicAPI('agency/select', filters, 'GET').pipe(
      map<DynamicApiResponse, ReservationAgency[]>((response) => {
        return response.body.map((r: IReservationApiAgency) => new ReservationAgency(r));
      })
    );
  }

  public getPaginatedAgencies(filters?: IReservationApiAgency, loadOptions?: any) {
    filters = this.fillPaginatedParams(filters, loadOptions);

    return this.callDynamicAPI('agency/select', filters, 'GET').pipe(
      map<DynamicApiResponse, { data: any[]; totalCount: number }>((response) => {
        return {
          data: response.body.map((r: IReservationApiAgency) => new ReservationAgency(r)),
          totalCount: response.recordsCount,
        };
      })
    );
  }

  /**
   * Get Agents by Agency
   *
   * @param {IReservationApiAgentParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  public getAgents(params: IReservationApiAgentParameters) {
    return this.callDynamicAPI('agent/agency', params, 'GET').pipe(
      map<DynamicApiResponse, ReservationAgent[]>((response) => {
        return response.body.map((r: IReservationApiAgent) => new ReservationAgent(r));
      })
    );
  }

  public getPaginatedAgents(filters: IReservationApiAgentParameters, loadOptions?: any) {
    filters = this.fillPaginatedParams(filters, loadOptions);

    return this.callDynamicAPI('agent/agency', filters, 'GET').pipe(
      map<DynamicApiResponse, { data: any[]; totalCount: number }>((response) => {
        return {
          data: response.body.map((r: IReservationApiAgent) => new ReservationAgent(r)),
          totalCount: response.recordsCount,
        };
      })
    );
  }

  /**
   * Manage Agencies
   *
   * @param {IReservationApiAgency} params
   * @return {*}
   * @memberof ReservationApiService
   */
  public manageAgencies(params: IReservationApiAgency) {
    return this.callDynamicAPI('agency/manage', params, 'POST');
  }

  /**
   * Manage Agents
   *
   * @param {IReservationApiAgent} params
   * @return {*}
   * @memberof ReservationApiService
   */
  public manageAgents(params: IReservationApiAgent) {
    return this.callDynamicAPI('agent/manage', params, 'POST');
  }

  /**
   * Create Guest
   *
   * @param {IReservationApiInsertGuestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  public insertGuest(params: IReservationApiInsertGuestParameters) {
    return this.callDynamicAPI('guest/insert', params, 'POST');
  }

  /**
   * Update Guest
   *
   * @param {IReservationApiUpdateGuestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  public updateGuest(params: IReservationApiUpdateGuestParameters) {
    return this.callDynamicAPI('guest/update', params, 'POST');
  }

  /**
   * Delete Guest
   *
   * @param {IReservationApiDeleteGuestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  public deleteGuest(params: IReservationApiDeleteGuestParameters) {
    return this.callDynamicAPI('guest/delete', params, 'POST');
  }

  /**
   * Associate Directs to Guest
   *
   * @param {IReservationApiReplaceGuestParameters} params
   * @return {*}
   * @memberof ReservationApiService
   */
  public replaceGuest(params: IReservationApiReplaceGuestParameters) {
    return this.postDynamicAPI('guest/replace', params);
  }

  public getCountries(search?: string, cod?: number): Observable<Array<any>> {
    let params: any = {};
    if (!!search) {
      params.Country = search;
    }
    if (!!cod) {
      params.CountryCod = cod;
    }

    return this.callDynamicAPI('country/select', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body;
      })
    );
  }

  public getStates(params: IReservationApiGetState): Observable<Array<any>> {
    return this.callDynamicAPI('state/select', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body;
      })
    );
  }

  public getCounties(params: IReservationApiGetCounty): Observable<Array<any>> {
    return this.callDynamicAPI('county/select', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body;
      })
    );
  }

  public getOccupations(Occupation?: string): Observable<Array<any>> {
    return this.callDynamicAPI('occupation/select', { Occupation }).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body;
      })
    );
  }

  public getCashAccounts(params: { CurrencyCod: string, ReceiptTypeID: number }): Observable<Array<ResCashReceiptCashAccount>> {
    return this.callDynamicAPI('cashAccounts/select', params).pipe(
      map<DynamicApiResponse, Array<ResCashReceiptCashAccount>>((response) => {
        return response.body.map((r: IReservationCashReceiptCashAccount) => new ResCashReceiptCashAccount(r));
      })
    );
  }

  public getCheckNo(): Observable<Array<IReservationCashReceiptCheckNO>> {
    return this.callDynamicAPI('checkNO/select').pipe(
      map<DynamicApiResponse, Array<IReservationCashReceiptCheckNO>>((response) => {
        return response.body;
      })
    );
  }

  public getPaymentReasons(): Observable<Array<IReservationCashReceiptPaymentReason>> {
    return this.callDynamicAPI('paymentReasons/select').pipe(
      map<DynamicApiResponse, Array<IReservationCashReceiptPaymentReason>>((response) => {
        return response.body;
      })
    );
  }
  /**
   * Retrieves all possible booking groups
   *
   * @param {{ BkgID: number, GrpID?: number }} params
   * @return {*}  {Observable<Array<ResGuestGroup>>}
   * @memberof ReservationApiService
   */
  public getBookingGuestGroups(bkgID: number): Observable<ResGuestGroup[]> {
    return this.callDynamicAPI('booking/guestgroups', {BkgID: bkgID}).pipe(
      map<DynamicApiResponse,ResGuestGroup[]>((response) => {
        return response.body.map((data:any)=>new ResGuestGroup(data))
      })
    );
  }
  /**
   * Retrieves all possible booking payers
   *
   * @param {{ BkgID: number, GrpID?: number }} params
   * @return {*}  {Observable<Array<ResPayerGroup>>}
   * @memberof ReservationApiService
   */
  public getBookingPayers(bkgID: number): Observable<ResPayerGroup[]> {
    return this.callDynamicAPI('booking/payers', {BkgID: bkgID}).pipe(
      map<DynamicApiResponse, ResPayerGroup[]>((response) => {
        return response.body.map((data:any)=>new ResPayerGroup(data))
      })
    );
  }
  /**
   * Retrieves all possible booking payers
   *
   * @param {{ BkgID: number, GrpID?: number }} params
   * @return {*}  {Observable<Array<ResPayerGroup>>}
   * @memberof ReservationApiService
   */
  public getPayerDepartments(bkgID: number): Observable<ResPayerDepartment[]> {
    return this.callDynamicAPI('payer/departments', {BkgID: bkgID}).pipe(
      map<DynamicApiResponse, ResPayerDepartment[]>((response) => {
        return response.body.map((data:any)=>new ResPayerDepartment(data))
      })
    );
  }
  manageBookingGuestGroups(bkgID:number,guestGroups:ResGuestGroup[]) {
    return this.postDynamicAPI('booking/managegroups', {BkgID: bkgID, GroupGuest:guestGroups}).pipe(
      map<DynamicApiResponse, boolean>((response) => {
        return response.errors.length===0
      })
    );
  }
  /**
   * Retrieves all booking possible groups
   *
   * @param {{ BkgID: number, GrpID?: number }} params
   * @return {*}  {Observable<Array<IResBookingGroup>>}
   * @memberof ReservationApiService
   */
  public getBookingGroups(params: { BkgID: number, GrpID?: number }): Observable<Array<IResBookingGroup>> {
    return this.callDynamicAPI('booking/groups', params).pipe(
      map<DynamicApiResponse, Array<IResBookingGroup>>((response) => {
        return response.body as IResBookingGroup[];
      })
    );
  }
  /**
   * Retrieves all booking agent owners
   *
   * @return {*}  {Observable<Array<any>>}
   * @memberof ReservationApiService
   */
  public getBookingAgentOwners(bkgID: number, search?: string, id?: string): Observable<Array<any>> {
    let params: any = { BkgID: bkgID };
    if (!!search) {
      params.SearchText = search;
    }
    if (!!id) {
      params.Bkg_Agent_Owner = id;
    }
    return this.callDynamicAPI('booking/agentowners', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body;
      })
    );
  }
  /**
   * Retrieves booking custom request
   *
   * @param {number} bkgID
   * @param {number} sectionID
   * @return {*}
   * @memberof ReservationApiService
   */
  public getBookingCustomRequest(bkgID: number, sectionID: number) {
    return this.postDynamicAPI('customRequest/get', { BkgID: bkgID, SectionID: sectionID }).pipe(
      map<DynamicApiResponse, ReservationCustomRequest | null>((response) => {
        return !!response.body && !_.isEmpty(response.body) ? new ReservationCustomRequest(response.body) : null;
      })
    );
  }

  public saveBookingCustomRequest(req: IReservationApiSaveCustomRequest) {
    return this.postDynamicAPI('customRequest/save', req).pipe(
      map<DynamicApiResponse, number>((response) =>
        !!response.body && !_.isEmpty(response.body) ? response.body.RequestID : null
      )
    );
  }

  public getDeposit(params: any): Observable<any> {
    return this.callDynamicAPI('OpenDeposit/get', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body;
      })
    );
  }

  public getServiceTypes(filters?: IReservationApiServiceType): Observable<ReservationServiceType[]> {
    return this.callDynamicAPI('serviceType/select', filters).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body.map((c: IReservationServiceType) => new ReservationServiceType(c));
      })
    );
  }

  public getBkgServices(params: IReservationGetBkgServicesParams, loadOptions?: any): Observable<any> {
    if (params.DateTo && !params.DateTo.isValid) params.DateTo = undefined;
    if (params.DateFrom && !params.DateFrom.isValid) params.DateFrom = undefined;
    params = this.fillPaginatedParams(params, loadOptions);

    return this.callDynamicAPI('bkgService/select', params).pipe(
      map<DynamicApiResponse, { data: any[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IReservationApiService) => new ReservationServiceData(c, this.coreFormatService)),
          totalCount: response.recordsCount,
        };
      })
    );
  }

  public getBkgOptions(params: IReservationGetBkgOptionsParams, loadOptions?: any): Observable<any> {
    if (params.DateTo && !params.DateTo.isValid) params.DateTo = undefined;
    if (params.DateFrom && !params.DateFrom.isValid) params.DateFrom = undefined;
    params = this.fillPaginatedParams(params, loadOptions);

    return this.callDynamicAPI('bkgOption/select', params).pipe(
      map<DynamicApiResponse, { data: any[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IReservationApiServiceOption) => new ReservationServiceOption(c, this.coreFormatService)),
          totalCount: response.recordsCount,
        };
      })
    );
  }

  public addServiceOptions(params: IReservationAddServiceOptionParams) {
    return this.postDynamicAPI('serviceOption/add', params);
  }

  public replaceServiceOptions(params: IResApiReplaceOptionsParams): Observable<IResApiReplaceStatus[]> {
    return this.postDynamicAPI('option/replace', params).pipe(
      map<DynamicApiResponse, Array<IResApiReplaceStatus>>((response) => {
        const { body } = response;
        return !body || Array.isArray(body) ? body : body[Object.keys(body).at(-1) ?? -1] ?? [];
      })
    );
  }

  public checkReplaceServiceOptionsAvl(params: IResApiReplaceOptionsParams): Observable<IResApiReplaceAvailability[]> {
    return this.postDynamicAPI('option/replaceCheckAvl', params).pipe(
      map<DynamicApiResponse, Array<IResApiReplaceAvailability>>((response) => {
        return response.body;
      })
    );
  }

  public getReplaceServiceOptionsHistory(params: IResApiGetReplaceOptionsHistoryParams, loadOptions: any): Observable<{ data: ResReplaceHistory[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    return this.callDynamicAPI('option/replaceHistory', params, 'GET').pipe(
      map<DynamicApiResponse, { data: ResReplaceHistory[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IResApiReplaceHistory) => new ResReplaceHistory(c)),
          totalCount: response.recordsCount
        }
      })
    );
  }

  public editServiceOption(params: IReservationEditServiceOptionParams) {
    return this.postDynamicAPI('serviceOption/update', params);
  }

  public deleteOptionPackage(params: IReservationDeleteOptionPackageParams) {
    return this.postDynamicAPI('serviceOption/delete', params);
  }

  public getCommission(bkgID: number): Observable<any> {
    return this.callDynamicAPI('commission/get', { BkgID: bkgID, Detail: 'Y' }).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body.map((c: IReservationApiCommission) => new ReservationCommission(c));
      })
    );
  }

  public updateCommissionAmount(params: { BkgID?: number; SectionID?: number; CommissionTypeID?: number; CommissionValue?: number }) {
    return this.postDynamicAPI('commissionAmount/update', params);
  }
  /**
   * Does a transfer cash between bookings or groups
   *
   * @param {IResTransferCashParams} params
   * @return {Observable<{result: boolean, message: string}>}
   * @memberof ReservationApiService
   */
  public transferCash(params: IResTransferCashParams): Observable<{ result: boolean; message: string }> {
    return this.postDynamicAPI('booking/transferCash', { Transfer: [params] }).pipe(
      map<DynamicApiResponse, { result: boolean; message: string }>((response) => {
        let retValue: { result: boolean; message: string } = { result: false, message: '' };

        if (Array.isArray(response.body) && response.body.length > 0) {
          const responseValue: { Result: string; Message: string } = response.body[0];
          retValue.result = responseValue.Result === 'OK';
          retValue.message = responseValue.Message;
        }

        return retValue;
      })
    );
  }

  public createCashReceipt(params: { CashReceipt: IReservationApiCreateCashReceipt[] }): Observable<any> {
    return this.postDynamicAPI('cashReceipt/create', params);
  }

  public checkComboAvailability(params: IReservationCheckComboAvailabilityParams) {
    return this.postDynamicAPI('serviceOption/checkComboAvailability', params).pipe(
      map<DynamicApiResponse, IReservationSuiteType[]>((response) => response.body.map((p: IReservationSuiteType) => p))
    );
  }

  public updateMessage(params: IReservationUpdateMessageParams) {
    return this.postDynamicAPI('message/update', params);
  }

  public createInvoiceForBkg(params: IReservationApiCreateBkgDocumentParams): Observable<any> {
    return this.postDynamicAPI('invoiceForBkg/create', params);
  }

  public getInvoiceTypes(BkgID?: number) {
    return this.callDynamicAPI('invoiceType/select', { BkgID }, 'GET').pipe(
      map<DynamicApiResponse, { value: string; label: string }[]>((response) => {
        return response.body.map((t: { Key: string; Label: string }) => ({ value: t.Key, label: t.Label }));
      })
    );
  }

  public getInvoiceSendingTypes() {
    return this.callDynamicAPI('invoiceSendingType/select', undefined, 'GET').pipe(
      map<DynamicApiResponse, { value: string; label: string }[]>((response) => {
        return response.body.map((t: { Key: string; Label: string }) => ({ value: t.Key, label: t.Label }));
      })
    );
  }

  public deleteVoyage(params: IReservationApiDeleteCruiseParams): Observable<any> {
    return this.postDynamicAPI('cruise/delete', params);
  }

  public linkedPackageUpdate(params: IReservationApiLikedPackageUpdateParams): Observable<any> {
    return this.postDynamicAPI('package/linkedPackage', params);
  }

  public deletePackage(params: IReservationApiDeletePackageParams): Observable<any> {
    return this.postDynamicAPI('package/delete', params);
  }

  public getServices(params?: IReservationApiGetServices): Observable<ReservationServiceData[]> {
    return this.callDynamicAPI('service/select', params).pipe(
      map<DynamicApiResponse, Array<ReservationServiceData>>((response) => {
        return response.body.map((s: IReservationApiService) => new ReservationServiceData(s));
      })
    );
  }
  public cloneServices(params?: IReservationApiCloneServices): Observable<ReservationServiceData> {
    return this.postDynamicAPI('service/clone', params).pipe(
      map<DynamicApiResponse, ReservationServiceData>((response) => {
        return new ReservationServiceData(response.body.Service);
      })
    );
  }
  public getService(serviceID: number, companyOvewrite?:number): Observable<ReservationSetupService> {
    return this.callDynamicAPI('service/select', { serviceID: serviceID, companyID_OVERWRITE: companyOvewrite }).pipe(
      map<DynamicApiResponse, ReservationSetupService>((response) => {
        return new ReservationSetupService(response.body[0]);
      })
    );
  }

  private fillPaginatedParams(params?: any, loadOptions?: LoadOptions) {
    if (!params) params = {};

    if (!loadOptions) return params;

    const skip = loadOptions.skip ?? 0;
    const take = loadOptions.take ?? 20;

    params.PageNum = Math.floor(skip / take) + 1;
    params.PageSize = take;

    if (!!loadOptions.filter) {
      if (Array.isArray(loadOptions.filter[0])) {
        loadOptions.filter.forEach((f: any) => {
          if (Array.isArray(f)) {
            let field: string = f[0];
            let cmp: string = f[2];
            params[field] = cmp;
          }
        });
      } else {
        let field: string = loadOptions.filter[0];
        let cmp: string = loadOptions.filter[2];
        params[field] = cmp;
      }
    }

    if (!!loadOptions.sort) {
      const sort = loadOptions.sort as { selector: string; desc: boolean }[];

      params.OrderField = sort[0]?.selector;
      params.OrderDirect = sort[0]?.desc ? 1 : 0;
    }

    return params;
  }

  private titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substring(1);
  }

  private localSort(items: [], loadOptions: any): [] {
    return items.sort((i1, i2) => {
      let field = loadOptions.sort[0].selector;
      let desc = loadOptions.sort[0].desc;
      let item1 = i1[field];
      if (!item1) {
        item1 = i1[this.titleCaseWord(field)];
      }
      let item2 = i2[field];
      if (!item2) {
        item2 = i2[this.titleCaseWord(field)];
      }
      if (item1 > item2) {
        return desc ? -1 : 1;
      }
      if (item1 < item2) {
        return desc ? 1 : -1;
      }
      return 0;
    });
  }

  public getServicesPaginated(params?: any, loadOptions?: any): Promise<ResolvedData<ReservationSetupService>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(this.callDynamicAPI('service/select', params)).then((response: any) => {
      return {
        data: this.localSort(response.body, loadOptions).map((o: IReservationApiSetupService) => new ReservationSetupService(o)),
        totalCount: response.recordsCount
      };
    }).catch(() => {
      throw 'Data loading error'
    });
  }

  public getServicesOptionsPaginated(params?: any, loadOptions?: any): Promise<ResolvedData<ReservationSetupServiceOption>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(
      this.callDynamicAPI('option/select', params).pipe(
        map<DynamicApiResponse, ResolvedData<ReservationServiceOption>>((response: DynamicApiResponse) => {
          return {
            data: this.localSort(response.body, loadOptions).map((o: IReservationApiSetupServiceOption) => new ReservationSetupServiceOption(o)),
            totalCount: response.recordsCount,
          };
        })
      )
    ).catch(() => {
      throw 'Data loading error';
    });
  }

  public getSetupServicesOptions(params: { ServiceIDOption?: number, ServiceID?: number, OptionID?: number, OptionName?: string, IsIncluded: 'Y' | 'N', IsActive?: 'Y', PriceSetup?: string }): Observable<ReservationSetupServiceOption[]> {
    return this.callDynamicAPI('option/select', params).pipe(
      map<DynamicApiResponse, Array<ReservationSetupServiceOption>>((response) => {
        return response.body.map((o: IReservationApiSetupServiceOption) => {
          let item = new ReservationSetupServiceOption(o);
          return item;
        });
      })
    );
  }

  public getServicesOptionsIncludedDetail(serviceID: number, optionID: number): Observable<ReservationSetupServiceOptionInclusiveDetail[]> {
    return this.callDynamicAPI('option/selectDetail', { serviceID: serviceID, optionID: optionID }).pipe(
      map<DynamicApiResponse, Array<ReservationSetupServiceOptionInclusiveDetail>>((response) => {
        response.body.forEach((o: IReservationApiSetupServiceOptionInclusiveDetail) => {
          if (!o.Status) o.Status = 'A';
        });
        return response.body.map((o: IReservationApiSetupServiceOptionInclusiveDetail) => new ReservationSetupServiceOptionInclusiveDetail(o));
      })
    );
  }

  public getServicesOptions(params?: IReservationApiGetOptions): Observable<ReservationServiceOption[]> {
    return this.callDynamicAPI('option/select', params).pipe(
      map<DynamicApiResponse, Array<ReservationServiceOption>>((response) => {
        return response.body.map((o: IReservationApiServiceOption) => {
          let item = new ReservationServiceOption(o);
          return item;
        });
      })
    );
  }

  public getSetupOptionsPaginated(params?: any, loadOptions?: any): Promise<ResolvedData<ReservationSetupOption>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(
      this.callDynamicAPI('option/get', params).pipe(
        map<DynamicApiResponse, ResolvedData<ReservationSetupOption>>((response: DynamicApiResponse) => {
          return {
            data: this.localSort(response.body, loadOptions).map((o: IReservationApiSetupOption) => new ReservationSetupOption(o)),
            totalCount: response.recordsCount,
          };
        })
      )
    ).catch(() => {
      throw 'Data loading error';
    });
  }

  public getSetupSequenceDiscountsPaginated(params?: any, loadOptions?: any): Promise<ResolvedData<ReservationSetupOption>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(
      this.callDynamicAPI('option/get', params).pipe(
        map<DynamicApiResponse, ResolvedData<ReservationSetupOption>>((response: DynamicApiResponse) => {
          return {
            data: response.body.map((o: IReservationApiSetupSequenceDiscount) => new ReservationSetupSequenceDiscount(o)),
            totalCount: response.recordsCount,
          };
        })
      )
    ).catch(() => {
      throw 'Data loading error';
    });
  }


  public getSequentialDiscountCods(companyId: number): Observable<string[]> {
    const params = {CompanyID: companyId}
    return this.callDynamicAPI('sequentialDiscount/getOptionCod', params).pipe(
      map<DynamicApiResponse, Array<string>>((response) => {
        return response.body;
      })
    );
  }



  public getSetupOptions(params?: IReservationApiGetOptions): Observable<ReservationSetupOption[]> {
    return this.callDynamicAPI('option/get', params).pipe(
      map<DynamicApiResponse, Array<ReservationSetupOption>>((response) => {
        return response.body.map((o: IReservationApiSetupOption) => new ReservationSetupOption(o));
      })
    );
  }

  public getOptions(params?: IReservationApiGetOptions): Observable<ReservationServiceOption[]> {
    return this.callDynamicAPI('option/select', params).pipe(
      map<DynamicApiResponse, Array<ReservationServiceOption>>((response) => {
        return response.body.map((o: IReservationApiServiceOption) => new ReservationServiceOption(o));
      })
    );
  }

  getSuppliers(params?: IReservationApiGetSuppliers ): Observable<ReservationSupplier[]> {
    return this.callDynamicAPI('serviceSupplier/select', params).pipe(
      map<DynamicApiResponse, ReservationSupplier[]>((response) => response.body.map((s: IReservationApiSupplier) => new ReservationSupplier(s)))
    );
  }
  getSuppliersPaginated(params: {
    SupplierID?: number,
    SupplierName?: string,
    companyID_OVERWRITE?: number,
    PageSize?: number,
    ServiceTypeID?: number
  },loadOptions:any): Promise<ResolvedData<ReservationSupplier>> {

    params = this.fillPaginatedParams(params, loadOptions);
    return lastValueFrom(this.callDynamicAPI('serviceSupplier/select', params)).then((response: any) => {
      return {
        data: response.body.map((s: IReservationApiSupplier) => new ReservationSupplier(s)),
        totalCount: response.recordsCount
      };
    }).catch(() => {
      throw 'Data loading error'
    });
  }

  public getOptionCost(params?: IReservationApiGetOptionCost): Observable<any[]> {
    return this.callDynamicAPI('optionCost/select', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body.map((c: IReservationApiOptionCost) => new ReservationOptionCost(c));
      })
    );
  }

  public getOptionPrice(params?: IReservationApiGetOptionPrice): Observable<any[]> {
    return this.callDynamicAPI('optionPrice/select', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body.map((p: IReservationApiOptionPrice) => new ReservationOptionPrice(p));
      })
    );
  }

  public getOptionAvailability(params?: IReservationApiGetOptionAvailability): Observable<any[]> {
    return this.callDynamicAPI('optionAvailability/select', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body.map((p: IReservationApiOptionPrice) => new ReservationOptionPrice(p));
      })
    );
  }

  public getAllocations(params?: IReservationApiGetOptionAllocation): Observable<ReservationGetAllocations[]> {
    return this.callDynamicAPI('optionAllocation/select', params).pipe(
      map<DynamicApiResponse, Array<ReservationGetAllocations>>((response) => {
        return response.body.map((p: IReservationGetAllocations) => new ReservationGetAllocations(p));
      })
    );
  }

  public getAllocationByOption(params?: IReservationApiGetAllocationByOption): Observable<ReservationOptionPriceAllocation[]> {
    return this.callDynamicAPI('optionAllocation/getAllocationByOption', params).pipe(
      map<DynamicApiResponse, Array<ReservationOptionPriceAllocation>>((response) => {
        return response.body.map((p: IReservationOptionPriceAllocation) => new ReservationOptionPriceAllocation(p));
      })
    );
  }

  public getDetailsAllocationByOption(params?: IReservationApiGetOptionAllocationDetails): Observable<ReservationOptionPriceAllocationDetail[]> {
    return this.callDynamicAPI('optionAllocation/getAllocationByOptionDetail', params).pipe(
      map<DynamicApiResponse, Array<ReservationOptionPriceAllocationDetail>>((response) => {
        return response.body.map((p: IReservationOptionPriceAllocationDetail) => new ReservationOptionPriceAllocationDetail(p));
      })
    );
  }

  public manageDetailsAllocationByOption(params: IReservationApiManageAllocationByOption): Observable<any> {
    return this.postDynamicAPI('optionAllocation/manageAllocationByOption', params);
  }

  public manageOptionCost(params: IReservationApiManageV2OptionCost): Observable<any> {
    return this.postDynamicAPI('optionCost/manageV2', params);
  }

  public manageOptionPrice(params: IReservationApiManageV2OptionPrice): Observable<any> {
    return this.postDynamicAPI('optionPrice/manageV2', params);
  }

  public manageOptionPriceAllPt(params: IReservationApiManageV2OptionPrice): Observable<any> {
    return this.postDynamicAPI('optionPrice/manageAllPt', params);
  }

  public manageOptionAvailability(params: IReservationApiManageOptionAvailability): Observable<any> {
    return this.postDynamicAPI('optionAvailability/manage', params);
  }

  public manageOptionAllocation(params: IReservationApiManageOptionAllocation): Observable<any> {
    return this.postDynamicAPI('optionAllocation/manage', params);
  }

  public manageOptionAvailabilityAllPt(params: IReservationApiManageOptionAvailability): Observable<any> {
    return this.postDynamicAPI('optionAvailability/manageAllPt', params);
  }

  public manageService(params?: ReservationSetupService): Observable<any> {
    return this.postDynamicAPI('service/manage', params);
  }

  public manageOption(params?: any): Observable<any> {
    params.refundable = !!params.refundable ? 'Y' : 'N';
    params.commissionable = !!params.commissionable ? 'Y' : 'N';
    params.isSBC = !!params.isSBC ? 'Y' : 'N';
    return this.postDynamicAPI('setupServiceOption/manage', params);
  }

  public manageSequentialDiscounts(params?: any) {
    params.refundable = !!params.refundable ? 'Y' : 'N';
    params.commissionable = !!params.commissionable ? 'Y' : 'N';
    params.isSBC = !!params.isSBC ? 'Y' : 'N';
    return this.postDynamicAPI('setupServiceOption/manage', params);
  }

  public manageServiceOption(params?: any): Observable<any> {
    return this.postDynamicAPI('setupServiceOptionLink/manage', params);
  }

  public manageServiceOptionInclusive(params?: any): Observable<any> {
    return this.postDynamicAPI('setupServiceOptionInclusive/manage', params);
  }

  public manageSupplier(params?: ReservationServiceData): Observable<any> {
    return this.postDynamicAPI('serviceSupplier/manage', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body
      })
    )
  }
  /**
   * Checks is params.Value is a valid and existing entity
   * The entity depends on params.Type:
   * BkgID -> booking and GroupCod -> Group
   *
   * @param {IResValueExistCheckParams} params
   * @return {Observable<boolean>}
   * @memberof ReservationApiService
   */
  public existsValue(params: IResValueExistCheckParams): Observable<boolean> {
    return this.getDynamicAPI('utility/exists', params).pipe(
      map<DynamicApiResponse, boolean>((response) => {
        let check = false;

        if (Array.isArray(response.body) && response.body.length > 0) {
          const retValue: { Valid: string } = response.body[0];
          check = retValue.Valid === 'Y';
        }

        return check;
      })
    );
  }

  public getDocument(url: string) {
    let headers = this.getHeaders(null);
    let token = localStorage.getItem(LocalStorageKeys.TOKEN);
    if (token) {
      headers = headers.set(LocalStorageKeys.TOKEN, token);
    }

    let options = {
      withCredentials: true,
      headers,
      observe: 'response' as 'body',
      responseType: 'blob' as 'blob',
    };

    let baseUrl = this.urlAPI.substr(0, this.urlAPI.length - 2);
    let apiUrl = baseUrl + '/documents/document?fullPath=' + url;
    return this.http.get(apiUrl, options as any);
  }

  public getInsurances(params: { bkgID: number }): Observable<any[]> {
    return this.callDynamicAPI('insurance/select', { BkgID: params.bkgID, Action: 'Get' }).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body.map((i: IReservationApiInsurance) => new ResInsurance(i));
      })
    );
  }

  public declineInsurance(params?: IReservationApiDeclineInsurance): Observable<any> {
    return this.postDynamicAPI('insurance/decline', params);
  }
  /**
   * Gets ECM data details for a voyage
   *
   * @param {number} voyageID
   * @return {*}  {Observable<Array<IReservationApiPackageEcmData>>}
   * @memberof ReservationApiService
   */
  getVoyageEcmData(voyageID: number): Observable<Array<IReservationApiPackageEcmData>> {
    return this.getDynamicAPI('voyageecmdata/get', { VoyageID: voyageID }).pipe(
      map<DynamicApiResponse, Array<IReservationApiPackageEcmData>>((response) => {
        return response.body as Array<IReservationApiPackageEcmData>;
      }),
      catchError<any, Array<IReservationApiPackageEcmData>>((_: any) => new Array<IReservationApiPackageEcmData>())
    );
  }
  /**
   * Gets ECM data details for a package
   *
   * @param {number} packageID
   * @return {*}  {Observable<Array<IReservationApiPackageEcmData>>}
   * @memberof ReservationApiService
   */
  getPackageEcmData(packageID: number): Observable<Array<IReservationApiPackageEcmData>> {
    return this.getDynamicAPI('packageecmdata/get', { PackageID: packageID }).pipe(
      map<DynamicApiResponse, Array<IReservationApiPackageEcmData>>((response) => {
        return response.body as Array<IReservationApiPackageEcmData>;
      }),
      catchError<any, Array<IReservationApiPackageEcmData>>((_: any) => new Array<IReservationApiPackageEcmData>())
    );
  }
  /**
   * Gets data details for a package: ECM, Itineraries and traveldate
   *
   * @param {number} packageID
   * @return {*}  {Observable<IReservationApiPackageInfo>}
   * @memberof ReservationApiService
   */
  getPackageInfo(parameters: IReservationApiPackageInfoParameters): Observable<IReservationApiPackageInfo> {
    return this.getDynamicAPI('packageinfo/get', parameters).pipe(
      map<DynamicApiResponse, IReservationApiPackageInfo>((response) => {
        return response.body as IReservationApiPackageInfo;
      }),
      catchError<any, Observable<IReservationApiPackageInfo>>((_: any) => new Observable<IReservationApiPackageInfo>())
    );
  }
  /**
   * Get itinerary of a voyage
   *
   * @param {number} voyageID
   * @return {*}  {Observable<ReservationCruiseItinerary[]>}
   * @memberof ReservationApiService
   */
  getVoyageItinerary(voyageID: number): Observable<ReservationCruiseItinerary[]> {
    return this.getDynamicAPI('cruise/itinerary', { VoyageID: voyageID }).pipe(
      map<DynamicApiResponse, ReservationCruiseItinerary[]>((response) => response.body.map((i: IReservationApiCruiseItinerary) => new ReservationCruiseItinerary(i))),
      catchError<any, ReservationCruiseItinerary[]>((_: any) => [])
    );
  }
  /**
   * Get airports
   *
   * @param {{ AirportID?: number, AirName?: string }} params
   * @return {*}  {Observable<Array<IReservationAirport>>}
   * @memberof ReservationApiService
   */
  public getAirports(params: { AirportID?: number, AirName?: string }): Observable<Array<IReservationAirport>> {
    return this.callDynamicAPI('airport/select', params).pipe(
      map<DynamicApiResponse, Array<IReservationAirport>>((response) => {
        return response.body;
      })
    );
  }

  //#region Package

  public getPackages(params: IReservationApiGetPackageParams): Observable<ResPackage[]> {
    return this.callDynamicAPI('package/select', params).pipe(
      map<DynamicApiResponse, Array<ResPackage>>((response) => {
        return response.body.map((p: IReservationApiPackageSetup) => new ResPackage(p));
      })
    );
  }

  getPackageOptionCostsAndPrices(params: {
    PriceTypeID: number,
    CompanyID: number,
    AgencyID: number | undefined,
    PackageTravelDateID: number,
    StartDate: string,
    EndDate: string,
    PackageID: number,
    ForceReload: 'Y' | 'N'
  }): Observable<[Array<ResPackageOptionCost>, Array<ResPackagePrice>, Array<ResPackageUpgradePrice>]> {
    return this.callDynamicAPI('package/selectOptionCostsAndPrices', params).pipe(
      map<DynamicApiResponse, [Array<ResPackageOptionCost>, Array<ResPackagePrice>, Array<ResPackageUpgradePrice>]>((response) => {
        return [
          response.body.Costs.map((c: IReservationApiPackageOptionCost) => new ResPackageOptionCost(c)),
          response.body.Prices.map((c: IReservationApiPackagePrice) => new ResPackagePrice(c)),
          response.body.UpgradesPrices.map((c: IReservationApiPackageUpgradePrice) => new ResPackageUpgradePrice(c))
        ];
      })
    );
  }

  public getPackageSpecialOptions(): Observable<Array<IReservationPackageOptionSpecialItem>> {
    return this.callDynamicAPI('package/selectSpecialOptions').pipe(
      map<DynamicApiResponse, Array<IReservationPackageOptionSpecialItem>>((response) => {
        return response.body as IReservationPackageOptionSpecialItem[];
      })
    );
  }

  public managePackage(params: IReservationApiManagePackageParams): Observable<number> {
    return this.postDynamicAPI('package/manage', params).pipe(
      map<DynamicApiResponse, number>((response) => {
        if (Array.isArray(response.body)) {
          return response.body.length > 0 && response.body[0].PackageID ? response.body[0].PackageID : undefined;
        } else {
          return response.body.DT0 && response.body.DT0.length > 0 && response.body.DT0[0].PackageID ? response.body.DT0[0].PackageID : undefined;
        }
      })
    );
  }

  public managePackageTravelDates(params: IReservationApiManagePackageTravelDatesParams): Observable<any> {
    return this.postDynamicAPI('packageTravelDate/manage', params);
  }

  public managePackageElements(params: { PackageID: number, PackageOption: IReservationApiManagePackageOptionsParams[] }): Observable<any> {
    return this.postDynamicAPI('package/manageOptions', params);
  }

  managePackagePrices(params: {
    PackageTravelDateID: number,
    PackageOptionCost: IReservationApiPackageOptionCost[],
    PackagePrice: IReservationApiPackageOptionPriceParams[],
    PackageOptionUpgrade: IReservationApiPackageUpgradePriceParams[],
  }): Observable<any> {
    return this.postDynamicAPI('package/managePrices', params);
  }
  packageManageSingleSupp(params: IResPackageSetupSingleSuppManage): Observable<any> {
    return this.postDynamicAPI('package/manageSingleSupplement', params);
  }
  public checkPackageOptionPrices(params: { PackageID: number, PackageTravelDateID?: number }): Observable<IReservationApiPackageConfiguratedPrice[]> {
    return this.getDynamicAPI('package/checkPrices', params).pipe(
      map<DynamicApiResponse, IReservationApiPackageConfiguratedPrice[]>((response) => response.body)
    );
  }

  public packagePricesCloneDates(params: IReservationApiPricesCloneDatesParams): Observable<any> {
    return this.postDynamicAPI('package/CostPriceClone', params);
  }
  public managePackageItineraryDays(params: IReservationApiManagePackageItineraryDaysParams): Observable<any> {
    return this.postDynamicAPI('packageItineraryDay/manage', params);
  }

  public managePackageLinkedPorts(params: IReservationApiManagePackageLinkedPortsParams): Observable<any> {
    return this.postDynamicAPI('package/managePorts', params);
  }

  public managePackageLinkedVoyages(params: IReservationApiManagePackageLinkedVoyagesParams): Observable<any> {
    return this.postDynamicAPI('package/manageLinkedVoyages', params);
  }
  public managePackageLinkedTours(params: IReservationApiManagePackageLinkedToursParams): Observable<any> {
    return this.postDynamicAPI('package/manageLinkedTours', params);
  }
  public getPackageDepScheduling(packageID: number): Observable<ResPackageDepScheduling[]> {
    return this.callDynamicAPI('package/selectDepScheduling', { packageID }).pipe(
      map<DynamicApiResponse, Array<ResPackageDepScheduling>>((response) => {
        return response.body.map((p: IReservationApiPackageDepScheduling) => new ResPackageDepScheduling(p));
      })
    );
  }

  public managePackageDepScheduling(params: IReservationApiManagePackageDepSchedulingParams): Observable<any> {
    return this.postDynamicAPI('package/manageDepScheduling', params);
  }

  public getPackageCancPolicy(packageID: number): Observable<ResPackageCancPolicy> {
    return this.callDynamicAPI('package/selectCancPolicy', { packageID }).pipe(
      map<DynamicApiResponse, ResPackageCancPolicy>((response) => {
        return new ResPackageCancPolicy(response.body)
      })
    );
  }

  public managePackageCancPolicy(params: IReservationApiManangePackageCanPolicyParams): Observable<any> {
    return this.postDynamicAPI('package/manageCancPolicy', params);
  }
  public getPackageBookingList(params: { PackageID: number, PackageTravelDateID?: number | null }): Observable<ResPackageBookingList[]> {
    return this.callDynamicAPI('package/selectBookingList', params).pipe(
      map<DynamicApiResponse, Array<ResPackageBookingList>>((response) => {
        return response.body.map((p: IReservationApiPackageBookingList) => new ResPackageBookingList(p));
      })
    );
  }

  public getGuestPreferences(bkgID: number, Id: number): Observable<any> {
    let params: any = { Id: Id, BkgID: bkgID, Take: "1000", EntityType: "customer" }
    return this.postDynamicAPI('customer/getpreferences', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body;
      })
    );
  }

  public managePackageAvailability(params: IReservationApiManagePackageAvailabilityParams): Observable<any> {
    return this.postDynamicAPI('package/manageAvailability', params);
  }
  /**
   * Calls API to clone the package with specified package ID
   *
   * @param {{ PackageID: number, ClonePrice: ReservationApiBoolean }} params
   * @return {*}  {Observable<number>}
   * @memberof ReservationApiService
   */
  clonePackage(params: { PackageID: number, ClonePrice: ReservationApiBoolean }): Observable<number> {
    return this.postDynamicAPI('package/clone', params).pipe(
      map<DynamicApiResponse, number>((response) => Array.isArray(response.body) && response.body.length > 0 && response.body[0].PackageID ? response.body[0].PackageID : ResPackage.INVALID_PACKAGE_ID)
    );
  }

  public getPackageOptionsAvailabilityElements(params: { AvByOption: IReservationApiPackageOptionPriceTypeAvailibilityParams[], AllPriceType: 'Y' | 'N' }): Observable<IReservationApiPackageOptionPriceTypeAvailibility[]> {
    return this.postDynamicAPI('package/availabilityOptions', params).pipe(
      map<DynamicApiResponse, IReservationApiPackageOptionPriceTypeAvailibility[]>((response) => response.body));
  }

  public bookPackage(params: IReservationApiBookPackageParams): Observable<any> {
    return this.postDynamicAPI('package/book', params);
  }

  getPackageUpgrades(params: { BkgID: number }): Observable<ResPackageUpgrade[]> {
    return this.callDynamicAPI('packageUpgrade/select', params).pipe(
      map<DynamicApiResponse, Array<ResPackageUpgrade>>((response) => {
        return response.body.map((p: IReservationApiPackageUpgrade) => new ResPackageUpgrade(p));
      })
    );
  }

  public managePackageUpgrades(params: IReservationApiManagePackageUpgradeParams): Observable<any> {
    return this.postDynamicAPI('packageUpgrade/manage', params);
  }

  //#endregion Package


  public getPriceTypes(params: { Type?: 'OD' | 'ChangePriceType', BkgID?: number }): Observable<Array<IReservationPriceType>> {
    return this.callDynamicAPI('products/pricetypes', params).pipe(
      map<DynamicApiResponse, Array<IReservationPriceType>>((response) => {
        return response.body?.PriceTypes;
      })
    );
  }

  public changeODFare(params: { BkgID: number, NEWPriceTypeID: number }): Observable<any> {
    return this.postDynamicAPI('openDeposit/changeFare', params);
  }

  public getSuiteStatus(params: { VoyageID: number, priceTypeID: number }): Observable<Array<any>> {
    return this.callDynamicAPI('inventory/selectStatus', params).pipe(
      map<DynamicApiResponse, Array<any>>((response) => {
        return response.body;
      })
    )
  }
  public getBookingToRequests(bkgId: number): Observable<BookingToRequest[]> {
    return this.callDynamicAPI('booking/toRequest', { BkgID: bkgId }).pipe(
      map<DynamicApiResponse, BookingToRequest[]>((response) => {
        return response.body.map((data: any) => new BookingToRequest(data));
      })
    )
  }
  public saveSuiteStatus(params: { VoyageID: number, PriceTypeID: number, SuiteCategoryCod: string, Status: string }) {
    return this.postDynamicAPI('inventory/manageStatus', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body;
      })
    )
  }

  public getAssignedTo(params: { EntityType?: string, Entity?: number, TouchId?: number }): Observable<Array<{ Cod: string, Desc: string }>> {
    return this.callDynamicAPI('touch/assignedto', params).pipe(
      map<DynamicApiResponse, Array<{ Cod: string, Desc: string }>>((response) => {
        return response.body;
      })
    );
  }

  public getAirData(): Observable<ResAirData> {
    return this.getDynamicAPI('air/get', {}).pipe(
      map<DynamicApiResponse, ResAirData>((response) => {
        return new ResAirData(response.body);
      })
    );
  }

  public getBkgNotes(params: IReservationApiGetBkgNotes): Observable<Array<ResBkgNote>> {
    return this.callDynamicAPI('bkgNotes/select', params).pipe(
      map<DynamicApiResponse, Array<ResBkgNote>>((response) => {
        return response.body.map((b: IReservationApiBookingNote) => new ResBkgNote(b));
      })
    );
  }

  public manageBkgNotes(params: IReservationApiManageBkgNotes): Observable<any> {
    return this.postDynamicAPI('bkgNotes/manage', params);
  }

  public getBkgTouch(bkgID?: number): Observable<Array<ResBkgNoteTouch>> {
    return this.callDynamicAPI('bkgNotes/getNoteTouch', {KeyCod: bkgID}).pipe(
      map<DynamicApiResponse, Array<ResBkgNoteTouch>>((response) => {
        return response.body.map((b: any) => new ResBkgNoteTouch(b));
      })
    );
  }

  public getGuestAirData(params: { BkgID: number }): Observable<Array<BookingGuestAirData>> {
    return this.callDynamicAPI('guestGTW/select', params).pipe(
      map<DynamicApiResponse, Array<BookingGuestAirData>>((response) => {
        return response.body.map((d: IReservationApiBookingGuestAirData) => new BookingGuestAirData(d));
      })
    );
  }

  public updateGuestAirData(params: IReservationApiUpdateBookingGuestAirData): Observable<any> {
    return this.postDynamicAPI('guestGTW/update', params);
  }

  public getSuitePriceCategories(params: { BkgID: number, SectionID: number }): Observable<Array<ResSuitePriceCategory>> {
    return this.callDynamicAPI('suitePriceCategory/select', params).pipe(
      map<DynamicApiResponse, Array<ResSuitePriceCategory>>((response) => {
        return response.body.map((d: IReservationSuitePriceCategory) => new ResSuitePriceCategory(d));
      })
    );
  }

  public updateSuitePriceCategory(params: IReservationApiUpdateSuiteData): Observable<any> {
    return this.postDynamicAPI('suitePriceCategory/update', params);
  }

  getPowerBIConfig(workspaceId: string, reportId: string): Observable<any> {
    return this.sendCommand(new CoreBaseApiCommandParams({
      url: 'pbi/params',
      data: { workspaceId, reportId },
      type: 'GET',
      customBaseUrl: this.pluginUrlAPI
    }));
  }

  getDocumentList(containerName: string, prefix: string) {
    return this.sendCommand(new CoreBaseApiCommandParams({
      url: 'documents/document-list',
      data: { containerName, prefix },
      type: 'GET',
      customBaseUrl: this.pluginUrlAPI
    }));
  }
  /**
   * Get light details of all voyages
   * Used in:
   * - Suite setup page
   *
   * @param {{ VoyageID?: number, MonthList?: string[], ShipID?: number[] }} filters
   * @param {DynamicApiRequestPaginatedParams} [params=new DynamicApiRequestPaginatedParams(1, 150)]
   * @return {*}  {Observable<DynamicApiResponsePaginatedModel<ReservationCruiseLight>>}
   * @memberof ReservationApiService
   */
  getPagedLightVoyages(
    filters: { VoyageID?: number, MonthList?: string[], ShipID?: number[] },
    params: DynamicApiRequestPaginatedParams = new DynamicApiRequestPaginatedParams(1, 150)
  ): Observable<DynamicApiResponsePaginatedModel<ReservationCruiseLight>> {
    return super.getDynamicAPI('cruise/selectDetail', { ...filters, ...params.serialize() }).pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ReservationCruiseLight>>((response) => {
        const paginatedModel = new DynamicApiResponsePaginatedModel<ReservationCruiseLight>(response);
        paginatedModel.content = response.body.map((cruise: IReservationApiCruiseLight) => new ReservationCruiseLight(cruise));
        return paginatedModel;
      })
    );
  }

  /**
   * Get booked suite of a voyages and their bkgID
   *
   * @param {number} voyageID
   * @return {*}  {Observable<ReservationApiBookedSuite[]>}
   * @memberof ReservationApiService
   */
  getVoyageSuiteAndBookingList(voyageID: number, amenities: IReservationSuiteAmenity[] = [], includeWL: boolean = true): Observable<ReservationCruiseSuite[]> {
    return this.callDynamicAPI('inventory/suiteBookedNew', { VoyageID: voyageID, IncludeWL: includeWL ? 'Y' : 'N' }, 'GET').pipe(
      map<DynamicApiResponse, ReservationCruiseSuite[]>(response => {

        const suites: any[] = response.body.Suites ? response.body.Suites : []
        const bookingInfo: any[] = response.body.BookingInfo ? response.body.BookingInfo : []
        const guestBooking: any[] = response.body.GuestBooking ? response.body.GuestBooking : []
        const connectedSuite: any[] = response.body.ConnectedSuite ? response.body.ConnectedSuite : []

        return suites.map(s => {
          s.BookingInfo = bookingInfo.filter(bi => bi.SuiteID == s.SuiteID)
            .map(p => {
              p.GuestBooking = guestBooking.filter(item => item.BkgID == p.BkgID)
              return p
            })

          s.ConnectedSuite = connectedSuite.find(item => item.SuiteNo == s.SuiteNo)

          return new ReservationCruiseSuite(s, amenities);
        })

        //response.body.Suites.map((sl: IReservationApiCruiseSuite) => new ReservationCruiseSuite(sl, amenities))
      })
    )
  }

  getVoyageGroupList(voyageID?: number): Observable<any[]> {
    return this.callDynamicAPI('inventory/getSetupGroups', { VoyageID: voyageID }).pipe(
      map<DynamicApiResponse, any[]>((response) =>
        response.body.map((g: IReservationGroupVoyageList) => new ReservationGroupVoyageList(g))
      )
    );
  }


  getAirLines(): Observable<IReservationApiAirLine[]> {
    return this.callDynamicAPI('airlines/select', 'GET').pipe(
      map<DynamicApiResponse, IReservationApiAirLine[]>((response) =>
        response.body.map((al: IReservationApiAirLine) => new ReservationAirLine(al))
      )
    );
  }
  /**
   * Get voyage suite profitability
   *
   * @param {number} voyageID
   * @return {*}  {Observable<ReservationCruiseSuiteProfitability[]>}
   * @memberof ReservationApiService
   */
  getVoyageSuiteProfitability(voyageID: number, priceTypeID: number): Observable<ReservationCruiseSuiteProfitability[]> {
    return this.callDynamicAPI('voyage/suiteProfitability', { VoyageID: voyageID, PriceTypeID: priceTypeID }, 'GET').pipe(
      map<DynamicApiResponse, ReservationCruiseSuiteProfitability[]>((response) =>
        response.body.map((sl: IReservationApiCruiseSuiteProfitability) => new ReservationCruiseSuiteProfitability(sl))
      )
    );
  }

  getRightRezUrl(params: ReservationApiRightRezParams): Observable<ReservationApiRightRezResponse> {
    console.log(params)
    const commandParams = new CoreBaseApiCommandParams({
      url: 'oem/rightrez/book',
      data: params,
      type: 'POST',
      customBaseUrl: this.pluginUrlAPI
    });

    return this.sendCommand(commandParams).pipe(
      tap(val => console.log(`BEFORE MAP: ${val}`)),
      map<DynamicApiResponse, ReservationApiRightRezResponse>((response) => new ReservationApiRightRezResponse(response)),
      catchError(err => {
        return of(new ReservationApiRightRezResponse(err))
      })
    );
  }
  getAirResults(params: IReservationApiGetAirResultsParams): Observable<{ itineraries: ReservationAirResult[], Error?: string }> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'gmt/searchavailability',
      data: params,
      type: 'POST',
      customBaseUrl: this.pluginUrlAPI
    });

    return this.sendCommand(commandParams).pipe(
      map<{ itineraries?: IReservationApiAirResult[], Error?: string }, { itineraries: ReservationAirResult[], Error?: string }>((response) => {
        return {
          itineraries: response.itineraries?.map((itinerary: IReservationApiAirResult) => new ReservationAirResult(itinerary)) || [],
          Error: response.Error
        };
      }),
      catchError(({ error: { Error } }) => {
        return of({ itineraries: [], Error })
      })
    );
  }

  bookAirResult(params: IReservationApiBookAirResultParams): Observable<{ pnr: IReservationApiAirPnr, Error?: string }> {
    return this.sendCommand(new CoreBaseApiCommandParams({
      url: 'gmt/bookpnr',
      data: params,
      type: 'POST',
      customBaseUrl: this.pluginUrlAPI
    })).pipe(
      catchError(({ error: { Error } }) => {
        return of({ pnr: {} as IReservationApiAirPnr, Error })
      })
    )
  }

  getAirPnrs(bookingId: number): Observable<ReservationAirPnr[]> {
    return this.sendCommand(new CoreBaseApiCommandParams({
      url: 'gmt/pnr',
      data: { bookingId },
      type: 'GET',
      customBaseUrl: this.pluginUrlAPI
    })).pipe(
      map<IReservationApiAirPnr[], Array<ReservationAirPnr>>((response) => {
        return response.map((pnr: IReservationApiAirPnr) => new ReservationAirPnr(pnr));
      })
    );
  }
  deletePnrDocument(flightHeaderId: number) {
    return this.sendCommand(new CoreBaseApiCommandParams({
      url: 'air/registerPnrDoc',
      data: {
        FlightHeaderId: flightHeaderId
      },
      type: 'POST',
      useVersion: true
    })).pipe(
      map<IDynamicApiResponse, DynamicApiResponse>((response) => new DynamicApiResponse(response)),
      map<DynamicApiResponse, boolean>((response) => response.errors.length === 0)
    );
  }
  uploadPnrDocument(params: ReservationUploadBlobResponse, flightHeaderId: number) {
    return this.sendCommand(new CoreBaseApiCommandParams({
      url: 'air/registerPnrDoc',
      data: {
        FlightHeaderId: flightHeaderId,
        FileID: params.fileId,
        FileGUID: params.fileGuid,
        FileName: params.fileName
      },
      type: 'POST',
      useVersion: true
    })).pipe(
      map<IDynamicApiResponse, DynamicApiResponse>((response) => new DynamicApiResponse(response)),
      map<DynamicApiResponse, boolean>((response) => response.errors.length === 0)
    );
  }
  getAirPnrHistory(params: IReservationApiGetAirPnrHistoryParams): Observable<ReservationAirPnrHistory[]> {
    return this.sendCommand(new CoreBaseApiCommandParams({
      url: 'gmt/pnrhistory',
      data: params,
      type: 'GET',
      customBaseUrl: this.pluginUrlAPI
    })).pipe(
      map<IReservationApiAirPnrHistory[], Array<ReservationAirPnrHistory>>((response) => {
        return response.map((pnr: IReservationApiAirPnrHistory) => new ReservationAirPnrHistory(pnr));
      })
    );
  }
  /**
   * Import external A&K TravelStudio airs in OneIS
   *
   * @param {number} bkgID
   * @param {{ BkgID: string, SiteID: number }[]} params
   * @return {*}  {Observable<any>}
   * @memberof ReservationApiService
   */
  importExternalAirs(bkgID: number, params: { BkgID: string, SiteID: number }[]): Observable<any> {
    return this.postDynamicAPI('booking/importExternalAirs', { BkgID: bkgID, BookingList: params });
  }

  deleteAirRRPnr(params: IReservationApiDeleteAirPnrParams, isTicketed: boolean): Observable<string> {

    return (isTicketed ? this.postDynamicAPI('airPnr/delete', params) : this.sendCommand(new CoreBaseApiCommandParams({
      url: 'oem/rightrez/Cancel',
      data: params,
      type: 'POST',
      customBaseUrl: this.pluginUrlAPI
    }))).pipe(map<{ Error?: string }, string>((response) => response?.Error || ''))
  }

  deleteAirPnr(params: IReservationApiDeleteAirPnrParams, isTicketed: boolean): Observable<string> {

    return (isTicketed ? this.postDynamicAPI('airPnr/delete', params) : this.sendCommand(new CoreBaseApiCommandParams({
      url: 'gmt/pnr',
      data: params,
      type: 'DELETE',
      customBaseUrl: this.pluginUrlAPI
    }))).pipe(map<{ Error?: string }, string>((response) => response?.Error || ''))
  }

  refreshAirPnrs(params: IReservationApiRefreshAirResultPnrsParams): Observable<{ pnrs: ReservationAirPnr[], Error?: string }> {

    const commandParams = new CoreBaseApiCommandParams({
      url: 'gmt/summarypnr',
      data: params,
      type: 'POST',
      customBaseUrl: this.pluginUrlAPI
    });

    return this.sendCommand(commandParams).pipe(
      map<{ pnrs: IReservationApiAirPnr[], Error?: string }, { pnrs: ReservationAirPnr[], Error?: string }>((response) => {
        return {
          pnrs: response.pnrs?.map((pnr: IReservationApiAirPnr) => new ReservationAirPnr(pnr)),
          Error: response.Error
        }
      }),
      catchError(({ error: { Error } }) => {
        return of({ pnrs: [], Error })
      })
    );
  }

  changePnrAirClass(params: IReservationApiChangePnrAirClass): Observable<any> {
    return this.postDynamicAPI('pnrAirClass/update', params);
  }

  getLoyaltyRedemptionTiers(): Observable<IReservationLoyaltyRedemptionTier[]> {
    return this.callDynamicAPI('redemptionTiers/select', undefined, 'GET').pipe(
      map<DynamicApiResponse, IReservationLoyaltyRedemptionTier[]>((response) =>
        response.body
      )
    );
  }

  searchCertificates(params: IResApiSearchCertificate): Observable<ResBookingCertificate[]> {
    return this.callDynamicAPI('certificate/search', params, 'GET').pipe(
      map<DynamicApiResponse, ResBookingCertificate[]>((response) =>
        response.body.map((c: IResApiBookingCertificate) => new ResBookingCertificate(c))
      )
    );
  }

  importCertificates(params: IResApiImportCertificate): Observable<any> {
    return this.postDynamicAPI('certificate/import', params);
  }

  getBkgCertificates(bkgID: number): Observable<{ certificates: ResBookingCertificate[], availableAmounts: ResBookingCertificateAvailableAmount[] }> {
    return this.callDynamicAPI('bkgCertificate/select', { bkgID }, 'GET').pipe(
      map<DynamicApiResponse, { certificates: ResBookingCertificate[], availableAmounts: ResBookingCertificateAvailableAmount[] }>((response) => {
        return {
          certificates: response.body.Certificates?.map((c: IResApiBookingCertificate) => new ResBookingCertificate(c)),
          availableAmounts: response.body.AvailableAmounts?.map((c: IResApiBookingCertificateAvailableAmount) => new ResBookingCertificateAvailableAmount(c))
        }
      }
      )
    );
  }

  getBkgCertificateDetail(params: IResApiGetBookingCertificateDetail): Observable<ResBookingCertificateDetail[]> {
    return this.callDynamicAPI('bkgCertificateDetail/select', params, 'GET').pipe(
      map<DynamicApiResponse, ResBookingCertificateDetail[]>((response) =>
        response.body.map((c: IResApiBookingCertificateDetail) => new ResBookingCertificateDetail(c))
      )
    );
  }

  getBkgCertificateTypes(): Observable<ResCertificateType[]> {
    return this.callDynamicAPI('bkgCertificateTypes/select', undefined, 'GET').pipe(
      map<DynamicApiResponse, ResCertificateType[]>((response) =>
        response.body.map((c: IReservationCertificateType) => new ResCertificateType(c))
      )
    );
  }

  createBkgCertificate(params: IResApiCreateBookingCertificate): Observable<any> {
    return this.postDynamicAPI('bkgCertificate/create', params);
  }

  updateBkgCertificate(params: IResApiUpdateBookingCertificate): Observable<any> {
    return this.postDynamicAPI('bkgCertificate/update', params);
  }

  getManifestVoyages({ VoyageNumber }: { VoyageNumber: string }): Observable<IResApiManifestVoyage[]> {
    return this.callDynamicAPI('manifestVoyage/select', { VoyageNumber }, 'GET').pipe(
      map<DynamicApiResponse, IResApiManifestVoyage[]>((response) =>
        response.body
      )
    );
  }

  getManifest(params: IResApiGetManifestParams, loadOptions: any): Observable<{ data: ResManifest[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    return this.callDynamicAPI('manifest/select', params, 'GET').pipe(
      map<DynamicApiResponse, { data: ResManifest[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IResApiManifest) => new ResManifest(c)),
          totalCount: response.recordsCount
        }
      })
    );
  }

  getManifestGroupBy(params: IResApiGetManifestParams, loadOptions: any): Observable<{ data: ResManifest[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    return this.callDynamicAPI('manifestGroupBy/select', params, 'GET').pipe(
      map<DynamicApiResponse, { data: ResManifest[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IResApiManifest) => new ResManifest(c)),
          totalCount: response.recordsCount
        }
      })
    );
  }

  getSavedManifest(params: IResApiGetSavedManifestsParams | IResApiGetSavedFullManifestsParams, loadOptions: any, isFullManifest: boolean = false): Observable<{ data: ResSavedManifestHeader[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    const url = isFullManifest ? 'fullManifestSaved/select' : 'manifestSaved/select';

    return this.callDynamicAPI(url, params, 'GET').pipe(
      map<DynamicApiResponse, { data: ResSavedManifestHeader[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IResApiSavedManifestHeader) => new ResSavedManifestHeader(c)),
          totalCount: response.recordsCount
        }
      })
    );
  }

  openSavedManifest(params: IResApiOpenSavedManifestParams | IResApiOpenSavedFullManifestParams, loadOptions: any, isFullManifest: boolean = false): Observable<{ data: ResManifest[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    const url = isFullManifest ? 'fullManifestSaved/open' : 'manifestSaved/open';

    return this.callDynamicAPI(url, params, 'GET').pipe(
      map<DynamicApiResponse, { data: ResManifest[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IResApiManifest) => new ResManifest(c)),
          totalCount: response.recordsCount
        }
      })
    );
  }

  saveManifest(params: IResApiSaveManifestParams | IResApiSaveFullManifestParams, isFullManifest: boolean = false): Observable<any> {
    const url = isFullManifest ? 'fullManifest/save' : 'manifest/save';
    return this.postDynamicAPI(url, params);
  }

  updateManifest(params: IResApiUpdateManifestParams | IResApiUpdateFullManifestParams, isFullManifest: boolean = false): Observable<any> {
    const url = isFullManifest ? 'fullManifest/update' : 'manifest/update';
    return this.postDynamicAPI(url, params);
  }

  updateManifestHeader(params: IResApiUpdateManifestHeaderParams | IResApiUpdateFullManifestHeaderParams, isFullManifest: boolean = false): Observable<any> {
    const url = isFullManifest ? 'fullManifestHdr/update' : 'manifestHdr/update';
    return this.postDynamicAPI(url, params);
  }

  deleteManifest(params: IResApiDeleteManifestParams | IResApiDeleteFullManifestParams, isFullManifest: boolean = false): Observable<any> {
    const url = isFullManifest ? 'fullManifest/delete' : 'manifest/delete';
    return this.postDynamicAPI(url, params);
  }

  compareManifest(params: IResApiCompareManifestParams, loadOptions: any): Observable<{ data: ResManifest[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    return this.callDynamicAPI('manifest/compare', params, 'GET').pipe(
      map<DynamicApiResponse, { data: ResManifest[], totalCount: number }>((response) => {
        return {
          data: response.body?.map?.((c: IResApiManifest) => new ResManifest(c)) ?? [],
          totalCount: response.recordsCount
        }
      })
    );
  }

  compareTwoManifest(params: IResApiCompareTwoManifestsParams, loadOptions: any): Observable<{ data: ResManifest[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    return this.callDynamicAPI('manifest/compareTwo', params, 'GET').pipe(
      map<DynamicApiResponse, { data: ResManifest[], totalCount: number }>((response) => {
        return {
          data: response.body?.map?.((c: IResApiManifest) => new ResManifest(c)) ?? [],
          totalCount: response.recordsCount
        }
      })
    );
  }

  getBkgAirDetails(params: { BkgID: number }): Observable<ResBkgAirDetail[]> {
    return this.callDynamicAPI('bkgAirDetails/select', params, 'GET').pipe(
      map<DynamicApiResponse, ResBkgAirDetail[]>((response) =>
        response.body.map((c: IReservationApiBkgAirDetail) => new ResBkgAirDetail(c))
      )
    );
  }

  getBkgWishlist(params: { BkgID: number }): Observable<ResBkgWishlist[]> {
    return this.callDynamicAPI('bkgWishlist/select', params, 'GET').pipe(
      map<DynamicApiResponse, ResBkgWishlist[]>((response) =>
        response.body.map((c: IReservationApiBkgWishlist) => new ResBkgWishlist(c))
      )
    );
  }

  manageBkgWishlist(params: IReservationApiManageBkgWishlistParams): Observable<any> {
    return this.postDynamicAPI('bkgWishlist/manage', params);
  }

  getDecks(params: { Wishlist: ReservationApiBoolean }): Observable<ResDeck[]> {
    return this.callDynamicAPI('deck/select', params, 'GET').pipe(
      map<DynamicApiResponse, ResDeck[]>((response) =>
        response.body.map((c: IReservationApiDeck) => new ResDeck(c))
      )
    );
  }

  getCommissionPayout(params: IResApiGetCommissionPayoutParams, loadOptions: any): Observable<{ data: ResCommissionPayout[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    return this.callDynamicAPI('commissionPayout/select', params, 'GET').pipe(
      map<DynamicApiResponse, { data: ResCommissionPayout[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IResApiCommissionPayout) => new ResCommissionPayout(c)),
          totalCount: response.recordsCount
        }
      })
    );
  }

  createCommissionPayoutBatch(params: IResApiCreateCommissionPayoutBatchParams): Observable<any> {
    return this.postDynamicAPI('commissionPayout/createBatch', params);
  }

  getCommissionPayoutBatches(params: IResApiGetCommissionPayoutBatchesParams, loadOptions: any): Observable<{ data: ResCommissionPayoutBatch[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    return this.callDynamicAPI('commissionPayout/selectBatches', params, 'GET').pipe(
      map<DynamicApiResponse, { data: ResCommissionPayoutBatch[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IResApiCommissionPayoutBatch) => new ResCommissionPayoutBatch(c)),
          totalCount: response.recordsCount
        }
      })
    );
  }

  openCommissionPayoutBatch(params: IResApiOpenCommissionPayoutBatchParams, loadOptions: any): Observable<{ data: ResCommissionPayout[], totalCount: number }> {
    params = this.fillPaginatedParams(params, loadOptions);

    return this.postDynamicAPI('commissionPayout/selectBatch', params).pipe(
      map<DynamicApiResponse, { data: ResCommissionPayout[], totalCount: number }>((response) => {
        return {
          data: response.body.Bookings.map((c: IResApiCommissionPayout) => new ResCommissionPayout(c, response.body.DisplayFlags)),
          totalCount: response.recordsCount
        }
      })
    );
  }

  manageCommissionPayoutBatch(params: IResApiManageCommissionPayoutBatchParams): Observable<any> {
    return this.postDynamicAPI('commissionPayout/manageBatch', params);
  }

  deleteCommissionPayout(params: IResApiDeleteCommissionPayoutParams): Observable<any> {
    return this.postDynamicAPI('commissionPayout/delete', params);
  }

  checkCommissionPayoutBatch(params: IResApiCheckCommissionPayoutBatchParams): Observable<{ BkgID: number, CommissionPayoutDetID: number }[]> {
    return this.postDynamicAPI('commissionPayout/checkBatch', params).pipe(
      map<DynamicApiResponse, { BkgID: number, CommissionPayoutDetID: number }[]>((response) => response.body)
    )
  }

  sendCommissionPayoutBatchToFinance(params: IResApiSendCommissionPayoutBatchToFinanceParams): Observable<IResApiSendCommissionPayoutEmailSpecs | undefined> {
    return this.postDynamicAPI('commissionPayout/sendBatchToFinance', params).pipe(
      map<DynamicApiResponse, IResApiSendCommissionPayoutEmailSpecs | undefined>((response) => response.body?.[0])
    )
  }

  uploadCommissionPayoutBatchPayment(params: IResApiUploadCommissionPayoutBatchPaymentParams): Observable<any> {
    return this.postDynamicAPI('commissionPayout/uploadBatchPayment', params)
  }

  generateCommissionPayoutBatchSelfInvoice(params: IResApiGenerateCommissionPayoutBatchSelfInvoiceParams): Observable<any> {
    return this.postDynamicAPI('commissionPayout/generateBatchSelfInvoice', params)
  }

  sendCommissionPayoutBatchToBc(params: IResApiSendCommissionPayoutBatchToBcParams): Observable<any> {
    return this.postDynamicAPI('commissionPayout/sendBatchToBc', params)
  }

  unlockComissionPayoutBkgs(params: IResApiUnlockCommissionPayoutBkgsParams): Observable<any> {
    return this.postDynamicAPI('commissionPayout/unlockBkgs', params)
  }

  completeCommissionPayoutBatch(params: { batchID: number }): Observable<any> {
    return this.postDynamicAPI('commissionPayout/completeBatch', params)
  }

  getCommissionPayoutBatchAgencies(params: IResApiGetCommissionPayoutAgenciesParams): Observable<ReservationAgency[]> {
    return this.callDynamicAPI('commissionPayout/selectBatchAgencies', params, 'GET').pipe(
      map<DynamicApiResponse, ReservationAgency[]>((response) =>
        response.body.map((c: IReservationApiAgency) => new ReservationAgency(c))
      )
    );
  }


  getCommissionPayoutBkgList(params: IResApiGetCommissionPayoutBkgListParams, loadOptions?: any): Observable<{ data: ResCommissionPayout[], totalCount: number } | ResCommissionPayout[]> {
    if (!loadOptions) {
      return this.postDynamicAPI('commissionPayout/selectBkgList', params).pipe(
        map<DynamicApiResponse, ResCommissionPayout[]>((response) =>
          response.body.map((c: IResApiCommissionPayout) => new ResCommissionPayout(c))
        )
      );
    }

    params = this.fillPaginatedParams(params, loadOptions);

    return this.postDynamicAPI('commissionPayout/selectBkgList', params).pipe(
      map<DynamicApiResponse, { data: ResCommissionPayout[], totalCount: number }>((response) => {
        return {
          data: response.body.map((c: IResApiCommissionPayout) => new ResCommissionPayout(c)),
          totalCount: response.recordsCount
        }
      })
    );
  }

  getCommissionPayoutCompanies(): Observable<ResCommissionCompanies[]> {
    return this.callDynamicAPI('commissionPayout/getCompany', 'GET').pipe(
      map<DynamicApiResponse, ResCommissionCompanies[]>((response) =>
        response.body.map((c: any) => new ResCommissionCompanies(c))
      )
    );
  }



  // #region Promo Setup

  public deletePromo(params?: { PromoID: number }) {
    return this.callDynamicAPI('/promo/delete', params).pipe(
      map<DynamicApiResponse, void>((response) => {
        console.log(response);
      })
    );
  }

  public getCombinability(): Observable<{ Value: number, DisplayValue: string }[]> {
    return this.callDynamicAPI('promo/combinability').pipe(
      map<DynamicApiResponse, Array<{ Value: number, DisplayValue: string }>>((response) => {
        return response.body.map(
          (combinability: IPromoCombinability) => {
            return { Value: combinability.PromoCombinabilityID, DisplayValue: combinability.PromoCombinability }
          }
        );
      })
    );
  }

  public getPromos(
    params: { PromoID: number }
  ): Observable<ResPromo[]> {
    return this.callDynamicAPI('promo/get', params).pipe(
      map<DynamicApiResponse, Array<ResPromo>>((response) => {
        return response.body.map(
          (p: IReservationApiPromoDetail) => new ResPromo(p)
        );
      })
    );
  }


  public getApplyPromo(
    params: IReservationPromoApplyPramaters
  ): Observable<ResPromoApply[]> {

    return this.callDynamicAPI('promo-apply/get', params).pipe(
      map<DynamicApiResponse, Array<ResPromoApply>>((response) => {
        if (!response.body.Promos) return []; //@TODO display error configuration
        return response.body.Promos.map(
          (p: IReservationApiPromoApply) => new ResPromoApply(p)
        );
      })
    );
  }

  public bkgPromoItemsManage(
    params: IReservationBkgPromoItemManagePramaters
  ): Observable<boolean> {
    return this.postDynamicAPI('promo-apply/manage', params).pipe(
      map<DynamicApiResponse, boolean>((response) => {
        return (response?.errors?.length ?? 0) === 0
      })
    );
  }
  public managePromo(params: IReservationApiManagePromoParams): Observable<number> {
    return this.postDynamicAPI('promo/upsert', params).pipe(
      map<DynamicApiResponse, number>((response) => {
        return Array.isArray(response.body) && response.body.length > 0 && response.body[0].PromoID ? response.body[0].PromoID : undefined;
      })
    );
  }

  public getPromoItems(params: { PromoID?: number }
  ): Observable<ResPromoActionItems> {
    return this.callDynamicAPI('promo/getItems', params).pipe(
      map<DynamicApiResponse, ResPromoActionItems>((response) => {
        return new ResPromoActionItems(response.body)
      })
    );
  }

  public savePromoAction(params: IReservationApiManagePromoActionParams): Observable<void> {
    return this.postDynamicAPI('promo/itemsUpsert', params).pipe(
      map<DynamicApiResponse, void>((response) => {
        console.log(response);
      })
    );
  }

  public getPromoVoyages(params: { PromoID?: number }
  ): Observable<Array<ReservationCruise>> {
    return this.callDynamicAPI('promo/getVoyages', params).pipe(
      map<DynamicApiResponse, Array<ReservationCruise>>((response) => {
        return response.body.map((r: IReservationApiCruise) => new ReservationCruise(r))
      })
    );
  }

  public getPromoCompanies(params: { Promo?: string }
  ): Observable<ResPromoCompany[]> {
    return this.callDynamicAPI('promo/companies', params).pipe(
      map<DynamicApiResponse, Array<ResPromoCompany>>((response) => {
        const promoCompanies = response.body.map((r: IResPromoCompany) => new ResPromoCompany(r));
        // const allCompanies = { companyID: 0, companyCode: '*', reportingCompanyName: 'All Companies' }
        // promoCompanies.splice(0, 0, allCompanies)
        return promoCompanies;
      })
    );
  }

  public getPromoSuiteCategories(params: { promoId?: number }
  ): Observable<ResPromoSuiteCategory[]> {
    return this.callDynamicAPI('promo/suitecategories', params).pipe(
      map<DynamicApiResponse, Array<ResPromoSuiteCategory>>((response) => {
        const promoSuiteCategories = response.body.map((r: IResPromoSuiteCategory) => new ResPromoSuiteCategory(r));
        const allSuiteCategories = new ResPromoSuiteCategory({ SuiteCategoryID: 0, SuiteCategory: 'ALL' })
        promoSuiteCategories.splice(0, 0, allSuiteCategories)
        return promoSuiteCategories;
      })
    );
  }

  public savePromoVoyages(params: IReservationApiManagePromoVoyagesParams): Observable<void> {
    return this.postDynamicAPI('/promo/voyagesUpsert', params).pipe(
      map<DynamicApiResponse, void>(() => { })
    )
  }

  public getPromoAgencies(params: { PromoID?: number }
  ): Observable<Array<ReservationAgency | ReservationIndividual>> {
    return this.callDynamicAPI('promo/getAgencies', params).pipe(
      map<DynamicApiResponse, Array<ReservationAgency | ReservationIndividual>>((response) => {
        return response.body.Agencies.map(
          (agency: IReservationApiAgency) => {
            const a = new ReservationAgency(agency);
            a.agencyTypology = "AGN";
            return a;
          }
        ).concat(response.body.Consortiums.map(
          (consortium: IReservationApiAgency) => {
            const c = new ReservationAgency(consortium);
            c.isConsortium = true;
            c.agencyTypology = "CNS";
            return c;
          }
        )).concat(response.body.HeadQuarters.map(
          (headQuarter: IReservationApiAgency) => {
            const hq = new ReservationAgency(headQuarter);
            hq.isHeadQuarter = true;
            hq.agencyTypology = "AHQ";
            return hq;
          }
        ));
      })
    );
  }

  public savePromoAgencies(params: IReservationApiManagePromoAgenciesParams): Observable<void> {
    return this.postDynamicAPI('promo/agenciesUpsert', params).pipe(
      map<DynamicApiResponse, void>((response) => {
        console.log(response);
      })
    )
  }
  // #endregion Promo Setup

  // Get All Countries
  public getGeoTreeCities(params: IReservationApiGeotreeCitySelectParams, loadOptions?: any): Promise<ResolvedData<ReservationGeotreeCitySelect>> {
    params.PageNum = (loadOptions?.skip ?? 0) / (loadOptions?.take ?? 0) + 1;
    if (loadOptions?.filter?.length > 0) {
      let map = params as any;
      if (Array.isArray(loadOptions.filter[0])) {
        loadOptions?.filter?.forEach((item: any) => {
          if (Array.isArray(item) && item.length > 2) {
            map[item[0]] = item[2]
          }
        })
      }
      else if (loadOptions.filter.length > 2) {
        map[loadOptions.filter[0]] = loadOptions.filter[2]
      }
    }
    return lastValueFrom(this.callDynamicAPI('geotree/citySelect', this.pascalCaseObject(params), 'POST')).then((response: any) => {
      //console.log(response);
      return {
        data: this.localSort(response.body, loadOptions).map((o: IReservationGeotreeCitySelect) => new ReservationGeotreeCitySelect(o)),
        totalCount: response.recordsCount
      };
    }).catch(() => {
      throw 'Data loading error'
    });
  }

    public getGeoCountryAndContinent(params?: IResAPIGetGeoTreeCountryAndContinent): Observable<ResGeoTreeCountryAndContinent[]> {
      return this.callDynamicAPI('geotree/getCountryAndContinent', params).pipe(
        map<DynamicApiResponse, ResGeoTreeCountryAndContinent[]>((response) => {
          return response.body.map((data: IResGeoTreeCountryAndContinent) => new ResGeoTreeCountryAndContinent(data));
        })
      )
    }

  public getGeoTreeCitiesObservable(params: IReservationApiGeotreeCitySelectParams, loadOptions?: any): Observable<ReservationGeotreeCitySelect[]> {
    params.PageNum = (loadOptions?.skip ?? 0) / (loadOptions?.take ?? 0) + 1;
    if (loadOptions?.filter?.length > 0) {
      let map = params as any;
      if (Array.isArray(loadOptions.filter[0])) {
        loadOptions?.filter?.forEach((item: any) => {
          if (Array.isArray(item) && item.length > 2) {
            map[item[0]] = item[2]
          }
        })
      }
      else if (loadOptions.filter.length > 2) {
        map[loadOptions.filter[0]] = loadOptions.filter[2]
      }
    }
    return this.callDynamicAPI('geotree/citySelect', this.pascalCaseObject(params), 'POST');
  }

  // Get Specific Country
  public getGeoTreeCity(cityID: number): Observable<ReservationGeotreeCity> {
    return this.callDynamicAPI('geotree/cityGet', { CityID: cityID }).pipe(
      map<DynamicApiResponse, ReservationGeotreeCity>((response) => {
        // console.log("response", response);
        return new ReservationGeotreeCity(response.body);
      })
    );
  }

  // Manage a Country
  public manageGeoTreeCity(params: ReservationGeotreeCity, deleteAction: boolean = false): Observable<boolean> {
    const geoTree2City: { GeoTreeID: number, DepartmentID: number }[] = []

    const properties = params as any
    Object.keys(properties).filter(name => name.toLowerCase().startsWith('geotreeid')).forEach(name => {
      const departmentId = +(name.split('_')[1])
      geoTree2City.push({
        GeoTreeID: properties[name],
        DepartmentID: departmentId
      })
    })

    const city = this.pascalCaseObject(params);
    city['Status'] = deleteAction ? 'D' : 'A';
    //console.log(city);
    return this.postDynamicAPI('geotree/cityManage', { City: city, GeoTree2City: geoTree2City }).pipe(
      map<DynamicApiResponse, boolean>((response) => {
        return !(response.errors.length > 0)
      })
    )
  }

  // Get cities in picklist for GeoCity modal
  public getPicklistCities(city: string, departmentId = 1): Observable<IReservationGeotreeCityPicklist[]> {
    return this.callDynamicAPI('geotree/picklist', { GeoTreeName: city, DepartmentID: departmentId }).pipe(
      map<DynamicApiResponse, IReservationGeotreeCityPicklist[]>((response) => {
        // console.log("pickList",response);
        return response.body
      })
    )
  }

  // Create or Modify a city inside Destination Geo Tree Tab
  public manageGeoTreeDestination(params: IReservationApiGeotree): Observable<any> {
    return this.callDynamicAPI('geotree/treeManage', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        // console.log(response);
        return response.body
      })
    )
  }

  // Create or Modify images inside Destination Geo Tree Tab
  public manageGeoTreeImageDestination(geoTree: ReservationApiGeotree, geoTreeIMG: ReservationApiGeotreeImage[], deleteNode = false): Observable<any> {
    geoTree.status = deleteNode ? 'D' : 'A';
    // console.log("geoTree", geoTree);
    // console.log("geoTreeIMG", geoTreeIMG);
    const data = Object.assign({}, geoTree) as any
    data.Level_1_ID = data.level1ID
    data.Level_2_ID = data.level2ID
    data.Level_3_ID = data.level3ID
    data.Level_4_ID = data.level4ID
    data.Level_5_ID = data.level5ID
    data.bestTimeToGo = data.bestTimeToGo?.toString();
    data.timeZone = data.timeZone?.toString();

    return this.sendCommand(new CoreBaseApiCommandParams({
      url: 'geotree/treeManage',
      data: {
        GeoTree: [data],
        GeoTreeIMG: geoTreeIMG
      },
      type: 'POST',
      useVersion: true
    })).pipe(
      map<IDynamicApiResponse, DynamicApiResponse>((response) => new DynamicApiResponse(response)),
      map<DynamicApiResponse, boolean>((response) => response.errors.length === 0)
    );
  }

  //Get images inside Destination Geo Tree Tab
  public getGeoTreeItem(geoTreeID: number): Observable<ReservationApiGeotree> {
    return this.callDynamicAPI('geotree/itemGet', { GeoTreeID: geoTreeID }).pipe(
      map<DynamicApiResponse, ReservationApiGeotree>((response) => {
        // console.log("full response", response);
        return new ReservationApiGeotree(response.body)
      })
    )
  }
  public getGeoTreeClone(source: number, destination: number): Observable<boolean> {
    return this.callDynamicAPI('geotree/clone', { DptSourceID: source, DptDestID: destination }).pipe(
      map<DynamicApiResponse, boolean>((response) => response.errors.length === 0)
    )
  }
  // Get Geo Tree Type List - Geo Tree Tab
  public getGeoTreeTypeDestination(geoTreeID: number): Observable<ReservationApiGeotreeType[]> {
    return this.callDynamicAPI('geotree/destinationType', { GeoTreeID: geoTreeID }).pipe(
      map<DynamicApiResponse, ReservationApiGeotreeType[]>((response) => {
        return response.body.map((data: IReservationApiGeotreeType) => new ReservationApiGeotreeType(data));
      })
    )
  }
  public getPicklistCitiesByID(id: number, departmentId = 1): Observable<IReservationGeotreeCityPicklist[]> {
    return this.callDynamicAPI('geotree/picklist', { GeoTreeID: id, departmentId: departmentId }).pipe(
      map<DynamicApiResponse, IReservationGeotreeCityPicklist[]>((response) => {
        // console.log("pickList", response);
        return response.body
      })
    )
  }

  //Save a movement inside Geo Tree Tab
  public saveMovementGeoTree(params: IReservationApiGeotreeMovement): Observable<any> {
    return this.postDynamicAPI('geotree/GeoTreeOrder', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        // console.log("movement", response);
        return response.body
      })
    )
  }

  //Geo Tree main call
  public geoTreeNavigate(filters: IResApiGeoTreeParameters, orderType: number = 0) {

    //SetUp to add
    //0 vedi tutti e 1 non vedi i nascosti
    // @SetUp, se 0 vedi tutto, se 1 vedi solo i SalesPriority o i Destination Priority che non sono null,
    // sulla base del parametro @OrderType, che in questo caso avrà o valore 1 o valore 2

    return this.callDynamicAPI(
      'geotree/navigate',
      { ...filters, OrderType: orderType },
      'POST'
    ).pipe(
      map<DynamicApiResponse, Array<GeoTreeDestination>>(
        (response) => {
          // console.log(response);
          return response.body.map(
            (geo: IGeoTreeDestination) => new GeoTreeDestination(geo)
          );
        })
    );
  }

  //Search an item inside Geo Tree
  public geoTreeSearchNavigate(searched: string, orderType: number = 0, departmentID = 1) {
    return this.callDynamicAPI(
      'geotree/search',
      { Search: searched, OrderType: orderType, departmentID: departmentID },
      'POST'
    ).pipe(
      map<DynamicApiResponse, Array<GeoTreeDestination>>(
        (response) => {
          // console.log("geo search", response);
          return response.body.map(
            (geo: IGeoTreeDestination) => {
              const g = new GeoTreeDestination(geo)
              g.hightlightSearchText(searched)
              if (geo.Search === 1) {
                return g
              } else {
                g.expanded = true;
                return g
              }
            }
          );
        })
    );
  }

  //Define visibility of a Geo Tree (Sales Priority / Destination Priority) - automatically last position
  public orderSetGeoTree(typeOrder?: number, geoTreeID?: number, isVisible?: number): Observable<any> {
    // @TypeOrder Int NULL,--0 Sales, 1 Destination
    // @GeoTreeID int NULL,--IDGeotree Source
    // @IsVisible = 0 per visibile e 1 per non visibile
    return this.postDynamicAPI('geotree/OrderSet', { TypeOrder: typeOrder, GeoTreeID: geoTreeID, IsVisible: isVisible }).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body
      })
    )
  }

  // Check if an external city is already in use in Geo Tree Tab
  public checkExternalCityAlredyInUse(cityID?: number): Observable<any> {
    return this.postDynamicAPI('geotree/getCityValue', { CityID: cityID }).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body
      })
    )
  }


  public loadDestinationPopup(orderType?: number, geoTreeID?: number): Observable<GeoTreeSearch[]> {
    return this.callDynamicAPI('geotree/FilterGet', { orderType: orderType, GeoTreeId: geoTreeID }).pipe(
      map<DynamicApiResponse, GeoTreeSearch[]>((response) => {
        return response.body.map((r: any) => new GeoTreeSearch(r))
      })
    )
  }
  public getBookingMarginality(bkgID?: number): Observable<ReservationBookingMarginality> {
    return this.callDynamicAPI('booking/marginality', { BkgID: bkgID }).pipe(
      map<DynamicApiResponse, ReservationBookingMarginality>((response) => {
        return response.body.map((data: IReservationBookingMarginality) => new ReservationBookingMarginality(data));
      })
    )
  }

  public getMarkets(): Observable<Array<Market>> {
    return this.callDynamicAPI('market/list').pipe(
      map<DynamicApiResponse, Array<Market>>((response) => {
        return response.body.map((data: IMarket) => new Market(data));
      })
    )
  }

  createNewServiceOptionPackage(params: IReservationApiCreateNewServiceOrOption) {
    return this.postDynamicAPI('service/manageServiceOption', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body;
      })
    )
  }

  public getSearchProducts(): Observable<ResProductCod[]> {
    return this.callDynamicAPI('productCod/get').pipe(
      map<DynamicApiResponse, ResProductCod[]>((response) => {
        return response.body.map((data: IResProductCod) => new ResProductCod(data));
      })
    )
  }

  // topic
  public getMainTopicList(search?: string, topicID?: number): Observable<Array<GeoTreeTopicList>> {
    return this.callDynamicAPI('topic/getMainTopic', { Search: search, TopicID: topicID }).pipe(
      map<DynamicApiResponse, Array<GeoTreeTopicList>>((response) => {
        return response.body.map((data: any) => new GeoTreeTopicList(data));
      })
    )
  }

  public getTopicSubjectList(search?: string, topicID?: number, subjectID?: number): Observable<Array<GeoTreeTopicSubject>> {
    return this.callDynamicAPI('topic/subjectGet', { Search: search, TopicID: topicID, SubjectID: subjectID }).pipe(
      map<DynamicApiResponse, Array<GeoTreeTopicSubject>>((response) => {
        return response.body.map((data: any) => new GeoTreeTopicSubject(data));
      })
    )
  }

  public getCategoryImageGeoTree(): Observable<Array<ResGeoTreeImageCategory>> {
    return this.callDynamicAPI('geoTree/imageCategory').pipe(
      map<DynamicApiResponse, Array<ResGeoTreeImageCategory>>((response) => {
        return response.body.map((data: any) => new ResGeoTreeImageCategory(data));
      })
    )
  }

  public getVoyageDocument(params?: IResApiVoyageDocument): Observable<Array<ResVoyageDocument>> {
    return this.callDynamicAPI('topic/getVoyageDocument', params, 'POST').pipe(
      map<DynamicApiResponse, Array<ResVoyageDocument>>((response) => {
        return response.body.map((data: IResVoyageDocument) => new ResVoyageDocument(data));
      })
    )
  }

  public manageVoyageDocument(params: IResApiVoyageDocument): Observable<any> {
    return this.postDynamicAPI('topic/manageVoyageDocument', { VoyageDocument: params }).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body
      })
    )
  }

  getProductTypes(companiesID?: string): Observable<Array<ResBkgProductType>> {
    return this.callDynamicAPI('topic/getProductTypes', {CompaniesID: companiesID}).pipe(
      map<DynamicApiResponse, Array<ResBkgProductType>>((response) => {
        return response.body.map((data: any) => new ResBkgProductType(data));
      })
    )
  }

  //  Exchange Rate Page

  getCurrenciesList(): Observable<Array<ResExchangeRates>> {
    return this.callDynamicAPI('exchange/getCurrencies').pipe(
      map<DynamicApiResponse, Array<ResExchangeRates>>((response) => {
        return response.body.map((data: IResExchangeRates) => new ResExchangeRates (data));
      })
    )
  }

  getTypePriceCurrency(): Observable<Array<ResExchangeTypeCurrencyRate>> {
    return this.callDynamicAPI('exchange/getTypeCurrencyRate').pipe(
      map<DynamicApiResponse, Array<ResExchangeTypeCurrencyRate>>((response) => {
        return response.body.map((data: IResExchangeTypeCurrencyRate) => new ResExchangeTypeCurrencyRate(data));
      })
    )
  }

  getExchangeRates(params?: IResApiGetExchangeRateParams): Observable<Array<ResExchangeRates>> {
    return this.callDynamicAPI('exchange/getExchangeRates', params).pipe(
      map<DynamicApiResponse, Array<ResExchangeRates>>((response) => {
        return response.body.map((data: IResExchangeRates) => new ResExchangeRates(data));
      })
    )
  }

  manageExchangeRate(params: IResApiManageExchangeRateTableParams) {
    const dateFrom = params.DateFrom
    const dateTo = params.DateTo
    const currencyTable = params.CurrencyTable
    const typeID = params.TypeID
    return this.postDynamicAPI('exchange/manageExchangeRate', {DateFrom: dateFrom, DateTo: dateTo, TypeID: typeID, FXTablesAll: currencyTable}).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body;
      })
    )
  }

  ///// hotels

  //Brand
  getHotelBrandsPaginated(params?: IReservationAPIGetHotelBrand, loadOptions?: any): Promise<ResolvedData<ReservationHotelBrand>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(
      this.callDynamicAPI('hotel/getBrands', params).pipe(
        map<DynamicApiResponse, ResolvedData<ReservationHotelBrand>>((response: DynamicApiResponse) => {
          return {
            data: response.body.map((o: IReservationHotelBrand) => new ReservationHotelBrand(o)),
            totalCount: response.recordsCount,
          };
        })
      )
    ).catch(() => {
      throw 'Data loading errors';
    });
  }

  getHotelBrands(params?: IReservationAPIGetHotelBrand): Observable<Array<ReservationHotelBrand>> {
    return this.callDynamicAPI('hotel/getBrands', params).pipe(
      map<DynamicApiResponse, Array<ReservationHotelBrand>>((response) => {
        return response.body.map((data: IReservationHotelBrand) => new ReservationHotelBrand(data));
      })
    )
  }

  manageHotelBrands(params: IReservationAPIManageHotelBrand) {
    return this.postDynamicAPI('hotel/manageBrand', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body;
      })
    )
  }

  //Chain
  getHotelChainPaginated(params?: IReservationAPIGetHotelChain, loadOptions?: any): Promise<ResolvedData<ReservationHotelChain>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(
      this.callDynamicAPI('hotel/getChain', params).pipe(
        map<DynamicApiResponse, ResolvedData<ReservationHotelChain>>((response: DynamicApiResponse) => {
          return {
            data: response.body.map((o: IReservationHotelChain) => new ReservationHotelChain(o)),
            totalCount: response.recordsCount,
          };
        })
      )
    ).catch(() => {
      throw 'Data loading errors';
    });
  }

  getHotelChain(params?: IReservationAPIGetHotelChain): Observable<Array<ReservationHotelChain>> {
    return this.callDynamicAPI('hotel/getChain', params).pipe(
      map<DynamicApiResponse, Array<ReservationHotelChain>>((response) => {
        return response.body.map((data: IReservationHotelChain) => new ReservationHotelChain(data));
      })
    )
  }

  manageHotelChain(params: IReservationAPIManageHotelChain) {
    return this.postDynamicAPI('hotel/manageChain', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body;
      })
    )
  }

  //Unique
  getHotelUniquePaginated(params?: IReservationAPIGetHotelUnique, loadOptions?: any): Promise<ResolvedData<ReservationHotelUnique>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(
      this.callDynamicAPI('hotel/getUnique', params).pipe(
        map<DynamicApiResponse, ResolvedData<ReservationHotelUnique>>((response: DynamicApiResponse) => {
          return {
            data: response.body.map((o: any) => new ReservationHotelUnique(o)),
            totalCount: response.recordsCount,
          };
        })
      )
    ).catch(() => {
      throw 'Data loading errors';
    });
  }

  getHotelUnique(params?: IReservationAPIGetHotelUnique): Observable<Array<ReservationHotelUnique>> {
    return this.callDynamicAPI('hotel/getUnique', params).pipe(
      map<DynamicApiResponse, Array<ReservationHotelUnique>>((response) => {
        return response.body.map((data: any) => new ReservationHotelUnique(data));
      })
    )
  }

  manageHotelUnique(params: IReservationAPIManageHotelUnique) {
    return this.postDynamicAPI('hotel/manageUnique', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body;
      })
    )
  }

  //TS Hotel Setup
  getTSHotelSetupPaginated(params?: IReservationAPIGetHotelUniqueSource, loadOptions?: any): Promise<ResolvedData<ReservationTSHotelSetup>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(
      this.callDynamicAPI('hotel/getHotelUniqueSource', params).pipe(
        map<DynamicApiResponse, ResolvedData<ReservationTSHotelSetup>>((response: DynamicApiResponse) => {
          return {
            data: response.body.map((o: IReservationTSHotelSetup) => new ReservationTSHotelSetup (o)),
            totalCount: response.recordsCount,
          };
        })
      )
    ).catch(() => {
      throw 'Data loading errors';
    });
  }

  getTSHotelSetup(params?: IReservationAPIGetHotelUniqueSource): Observable<Array<ReservationTSHotelSetup>> {
    return this.callDynamicAPI('hotel/getHotelUniqueSource', params).pipe(
      map<DynamicApiResponse, Array<ReservationTSHotelSetup>>((response) => {
        return response.body.map((data: IReservationTSHotelSetup) => new ReservationTSHotelSetup(data));
      })
    )
  }

  manageHotelUniqueSource(params: IReservationAPIManageHotelUniqueSource) {
    return this.postDynamicAPI('hotel/manageHotelUniqueSource', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body;
      })
    )
  }

  public getPromoPaginated(params?: IPromoParams, loadOptions?: any): Promise<ResolvedData<ResPromo>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(this.postDynamicAPI('promo/search', params)).then((response: any) => {
      return {
        data: response.body.map((promo: any) => new ResPromo(promo)),
        totalCount: response.recordsCount
      };
    }).catch(() => {
      throw 'Data loading error'
    });
  }
  getSearchFilterPreferences(companyID?: number): Observable<ResSearchFilterPreferences> {
    return this.callDynamicAPI('search/getFilterPreferences', {CompanyID: companyID}).pipe(
      map<DynamicApiResponse, ResSearchFilterPreferences>((response) => {
        return new ResSearchFilterPreferences(response.body);
      })
    )
  }

  public getPromo(params?: IPromoParams) {
    return this.postDynamicAPI('promo/search', params).pipe(
      map<DynamicApiResponse, Array<ResPromo>>((response) => {
        return response.body.map((promo: any) => new ResPromo(promo));
      })
    );
  }

  getTopicItinerary(params?: IResApiGetGeoTreeTopicItinerary ): Observable<Array<ResTopicItinerary>> {
    return this.callDynamicAPI('topic/getTopicItinerary', params).pipe(
      map<DynamicApiResponse, Array<ResTopicItinerary>>((response) => {
        return response.body.map((data: any) => new ResTopicItinerary(data));
      })
    )
  }

  //Booking Journey Summary Itinerary
  getBkgJourneyItinerary(bkgID: number): Observable<Array<ResBkgJourneyItinerary>> {
    return this.callDynamicAPI('booking/getBkgJourneyItinerary', {BkgID: bkgID}).pipe(
      map<DynamicApiResponse, Array<ResBkgJourneyItinerary>>((response) => {
        return response.body.map((data: any) => new ResBkgJourneyItinerary(data));
      })
    )
  }

  getNoteTypes(bkgID?: number): Observable<IReservationNoteType[]> {
    return this.callDynamicAPI('noteTypes/select', {BkgID: bkgID}).pipe(
      map<DynamicApiResponse, IReservationNoteType[]>((response) => {
        return response.body as IReservationNoteType[];
      })
    )
  }
  getCabinOccupancy(params: IResGetCabinOccupancyParams): Observable<ReservationCabinOccupancy[]> {
    return this.getDynamicAPI('voyage/cabinOccupancy', params).pipe(
      map<DynamicApiResponse, ReservationCabinOccupancy[]>((response) => {
        return response.body.map((data: any) => new ReservationCabinOccupancy(data))
      })
    );
  }

  manageBkgJourneyItinerary(params: any) {
    return this.postDynamicAPI('booking/manageBkgJourneyItinerary', params).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body;
      })
    )
  }

  public getPackagesPaginated(params?: IReservationApiGetPackageParams, loadOptions?: any): Promise<ResolvedData<ResPackage>> {
    params = this.fillPaginatedParams(params, loadOptions);

    return lastValueFrom(this.callDynamicAPI('packageHeader/select', params)).then((response: any) => {
      return {
        data: response.body.map((pkg: any) => new ResPackage(pkg)),
        totalCount: response.recordsCount
      };
    }).catch(() => {
      throw 'Data loading error'
    });
  }

  getBkgDmc(bkgID: number): Observable<Array<ResBkgDmc>> {
    return this.callDynamicAPI('booking/dmcList', {BkgID: bkgID}).pipe(
      map<DynamicApiResponse, Array<ResBkgDmc>>((response) => {
        return response.body.map((data: any) => new ResBkgDmc(data));
      })
    )
  }

  getFinancialSplit(bkgID: number, section: string): Observable<Array<ResFinancialSplit>> {
    return this.callDynamicAPI('financial/split', {BkgID: bkgID, Section: section}).pipe(
      map<DynamicApiResponse, Array<ResFinancialSplit>>((response) => {
        return response.body.map((data: any) => new ResFinancialSplit(data));
      })
    )
  }

  getMarketingStatus(): Observable<MarketingStatus[]> {
    return this.getDynamicAPI('package/marketingStatus').pipe(
      map<DynamicApiResponse, MarketingStatus[]>((response) => {
        return response.body.map((data: any) => new MarketingStatus(data))
      })
    );
  }

  getHlTypes(): Observable<HlTypes[]> {
    return this.getDynamicAPI('package/hlTypes').pipe(
      map<DynamicApiResponse, HlTypes[]>((response) => {
        return response.body.map((data: any) => new HlTypes(data))
      })
    );
  }

  getPackageHighlightsType(): Observable<PackageHighlightType[]> {
    return this.getDynamicAPI('package/highlightsType').pipe(
      map<DynamicApiResponse, PackageHighlightType[]>((response) => {
        return response.body.map((data: any) => new PackageHighlightType(data))
      })
    );
  }

  getPackageHighlightsList(packageID: number): Observable<PackageHighlight[]> {
    return this.getDynamicAPI('package/highlightsList', {PackageID: packageID}).pipe(
      map<DynamicApiResponse, PackageHighlight[]>((response) => {
        return response.body.map((data: any) => new PackageHighlight(data))
      })
    );
  }

  getBookingCreatedBy(bookingID: number): Observable<BookingCreatedBy[]> {
    return this.getDynamicAPI('booking/createdByModifyUsers', {BkgID: bookingID}).pipe(
      map<DynamicApiResponse, BookingCreatedBy[]>((response) => {
        return response.body.map((data: any) => new BookingCreatedBy(data))
      })
    );
  }

  managePackageHighlights(packageID: number, packageHighlights: IPackageHighlight[]) {
    return this.postDynamicAPI('package/highlightsManage', {PackageID: packageID, PackageHighlights: packageHighlights}).pipe(
      map<DynamicApiResponse, any>((response) => {
        return response.body
      })
    )
  }
}

