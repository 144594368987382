import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { lastValueFrom, Observable, of } from 'rxjs';

import { EidosApiService } from '@common/services/eidos-api.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { SpinnerOverlayService } from '@common/services/spinner-overlay.service';
import { CoreRoute } from '@common/models/core-constant.model';
import { EidosExternalAppService } from '@common/eidos-external-app/eidos-external-app.service';

@Injectable({
  providedIn: 'root'
})
export class EidosTokenGuard implements CanActivate {

  constructor(
    public router: Router
    , public eidosSecurityService: EidosSecurityService
    , public externalAppService: EidosExternalAppService
    , public spinnerOverlayService: SpinnerOverlayService
    , public eidosApiService: EidosApiService) { }

  protected amIAuthenticated(): Observable<boolean | UrlTree> {
    const grantAccess = this.eidosSecurityService.amIAuthenticated();
    if (!grantAccess) {
      return this.eidosApiService.amIAuthenticated();
    } else {
      return of(true);
    }
  }

  // @ts-ignore
  canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {

    return new Promise<boolean | UrlTree>(async resolve => {
      lastValueFrom(this.amIAuthenticated()).then(authenticated => {
        if (authenticated === true) {
          resolve(authenticated);
        } else {

          if (authenticated !== false) {
            console.log('EidosTokenGuard ERROR: ', authenticated);
          }

          this.eidosSecurityService.clearAuthentication();
          this.eidosSecurityService.setRequestedRoute(window.location.href.replace(window.location.origin, ''));

          window.location.href = window.location.origin + '/' + CoreRoute.Login;
        }
      });
    });
  }
}