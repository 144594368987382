import { SafeBaseObject } from "@common/models/base-object.models";
import { ReservationPackageEcmData } from "./res-package-detail.model";
import { DateTime } from "luxon";
import { AppModule } from "@app/app.module";
import { DomSanitizer, SafeHtml, SafeUrl } from "@angular/platform-browser";
import { ResPackage, ResProductPropertyAvailability, ResProductType, ReservationBasePackage } from "./res-package.model";
import { ReservationCruise, ReservationCruiseLight } from "./res-cruise.models";
import { BookingPackage } from "./res-booking.model";

interface ReservationProductComboInfo {
  ComboID: number;
}

export type ResGenericProduct = ReservationCruise | ReservationBasePackage | ReservationCruiseLight | ResPackage | ReservationCamp | BookingPackage | ResProductService
export class ReservationProduct extends SafeBaseObject {

  public comboID: number | undefined;
  public comboModel: string = '';

  constructor(data?: IReservationApiProduct) {
    super();
    if(data) this.updateData(data);
  }

  override updateData(data?: IReservationApiProduct): void {
    if(data) {
      super.updateData(data);
      this.comboModel = (data as any).ShipCod ?? '';
      if (Array.isArray(data.Combos) && data.Combos.length > 0 && data.Combos[0].ComboID != undefined) {
        this.comboID = data.Combos[0].ComboID;
      }
    }
  }
}

export interface IReservationApiProduct<T extends ReservationProductComboInfo = ReservationProductComboInfo> {
  Combos: T[];
}

export class ReservationCampSuites extends SafeBaseObject {
  shipID = 0
  suiteID = 0
  suiteNo = ''
  suiteCategoryID = 0
  suiteCategoryCod = ''
  deckID = 0
  suiteCapacityID = 0
  suiteCapacity = ''
  suiteCategoryKey = ''

  constructor(data?:any) {
    super();
    if (!data) return

    super.updateData(data)
    this.suiteCategoryKey = `${this.suiteCategoryID}-${this.suiteCapacityID}`
    this.updateData(data)
  }

  override updateData(_data?:any) {
  }
}
export class ReservationCampPrices extends SafeBaseObject {
  shipID = 0
  propertyID = 0
  propertyPriceID	= 0
  campPriceID = 0
  priceTypeID = 0
  priceTypeName = ''
  suiteCategoryKey = ''
  suiteCategoryID = 0
  suiteCapacityID = 0
  startDate = DateTime.invalid('empty')
  endDate = DateTime.invalid('empty')
  travelStartDate = DateTime.invalid('empty')
  travelEndDate = DateTime.invalid('empty')

  //by night form
  nightFrom = 0
  nightTo = 0
  priceSingle = 0
  priceDouble = 0
  priceChild = 0
  priceExtraChild = 0
  currency = 'USD'

  //by day mode
  byDay = DateTime.invalid('empty')
  cost = 0
  costCurrency = ''
  markup = 0
  sellingPrice = 0
  sellingPriceCurrency = ''
  roe = 1
  priceSetupID = 0
  priceSetup = ''
  availability = 0
  onRequest = false
  onRequestMsg = ''

  constructor(data?:any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(_data?:any) {
    this.suiteCategoryKey = `${this.suiteCategoryID}-${this.suiteCapacityID}`
  }
}
export class ReservationCampMedia extends SafeBaseObject {
  dayID = 0
  shipID = 0
  fldValue = ''
  entID = 0
  fldCod = ''
  context = ''



  constructor(data?:any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(_data?:any) {
  }
}
export class ReservationCampSuiteCategory extends SafeBaseObject {
  shipID = 0
  suiteCategoryKey = ''
  suiteCategoryID = 0
  suiteCategory = ''
  suiteCategoryCod = ''
  suiteCategoryDesc = ''
  displaySequence = 0
  sqm = ''
  sizeBalconySqm = ''
  sizeSuiteFt = ''
  sizeBalconyFT = ''
  suiteCapacity = ''
  suiteCapacityID = 0
  serviceTypeID = 0

  suites:ReservationCampSuites[] = []
  prices:ReservationCampPrices[] = []


  constructor(data?:any) {
    super();
    if (!data) return

    super.updateData(data)
    this.suiteCategoryKey=`${this.suiteCategoryID}-${this.suiteCapacityID}`
    this.updateData(data)
  }

  override updateData(data?:any) {
    if(data.Suites) this.suites = data.Suites.map((s:any)=>new ReservationCampSuites(s))
    if(data.Prices) this.prices = data.Prices.map((p:any)=>new ReservationCampPrices(p))

  }
}
export class ReservationCamp extends SafeBaseObject {
  internalType = 'camp'

  shipID = 0
  propertyID = 0
  serviceID = 0
  optionID = 0

  shipCod = ''
  ship = ''
  description = ''
  cityID = 0
  geoTreeName = ''
  minPax = 0
  maxPax = 0
  currency = ''
  priceTypeID = 0
  availabilityMessage = ''
  availabilityBackgroundColor = ''
  onHoldQty = 0
  isTM = false
  isAK = false
  cruiseLineID = 0
  cruiseLine = ''
  deckPlanPath = ''
  deckPlanOn = false
  gridOn = false
  deck = '1'
  isOverNight = false
  isAnchor = false
  markup = 0

  media:ReservationCampMedia[] = []

  images:string[] = []
  imagesSafeUrl:SafeUrl[] = []
  titles:string[] = []
  descriptionsHtml:SafeHtml[] = []

  mainImage = ''
  mainImageSafeUrl:SafeUrl = ''
  mainTitle = ''
  mainDescriptionsHtml:SafeHtml = ''
  suiteCategories:ReservationCampSuiteCategory[]=[]
  ecmData = new ReservationPackageEcmData();
  duration = 0;
  travelStartDate = DateTime.invalid('empty')
  travelEndDate = DateTime.invalid('empty')
  propertyGroupID = 0
  selectable = false
  dateFrom = DateTime.invalid('empty')
  dateTo = DateTime.invalid('empty')

  get isCamp() {
    return true
  }
  get isService() {
    return false
  }
  get productCod() {
    return ResProductType.SANCTUARY
  }
  get hasMediaData() {
    return this.images.length>0
  }
  get hasImages() {
    return this.images.length>0
  }
  get hasMap() {
    return false
  }
  get mapUrl() {
    return this.mainImageSafeUrl
  }
  get imagesUrl() {
    return this.imagesSafeUrl
  }
  get hasHtmlDescription() {
    return !!this.mainDescriptionsHtml
  }
  get descriptionHtml() {
    return this.mainDescriptionsHtml
  }
  constructor(data?:any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(data?:any) {
    const sanitaizer = AppModule.injector.get(DomSanitizer);
    this.propertyID = this.shipID
    if(data.SuiteCategories) this.suiteCategories = data.SuiteCategories.map((sc:any)=>new ReservationCampSuiteCategory(sc))
    if(data.Media) this.media = data.Media.map((m:any)=>new ReservationCampMedia(m))

    this.images = this.media.filter(m=>m.fldCod.toLowerCase().includes('image')).map(m=>m.fldValue)
    this.mainImage =  this.images.length>0 ? this.images[0] : `/assets/images/camp-placeholder.jpg`
    this.mainImageSafeUrl = sanitaizer.bypassSecurityTrustResourceUrl(this.mainImage)
    this.imagesSafeUrl = this.images
                            //.filter((_:string,idx:number)=>idx>0)
                            .map(img=>sanitaizer.bypassSecurityTrustResourceUrl(img))
    this.descriptionsHtml = this.media.filter(m=>m.fldCod.toLowerCase().includes('description')).map(m=>sanitaizer.bypassSecurityTrustHtml(m.fldValue))
    this.mainDescriptionsHtml =  this.descriptionsHtml.length>0 ? this.descriptionsHtml[0] : ''

    this.titles = this.media.filter(m=>m.fldCod.toLowerCase().includes('name')).map(m=>m.fldValue)

    this.mainTitle = this.titles.length>0 ? this.titles[0] : ''

  }

  displayName() {
    if(this.description=='') return this.mainTitle
    return this.description
  }
  getStartDate(): DateTime {
    return this.travelStartDate;
  }
  getEndDate(): DateTime {
    return this.travelEndDate;
  }
}

export class ReservationOptionPrices extends SafeBaseObject {
  serviceID =	0
  optionID = 0
  date = DateTime.invalid('empty')
  cost = 0
  costCurrency = ''
  markup = 0
  sellingPrice = 0
  sellingPriceCurrency = ''
  priceTypeID = 0
  priceTypeName = ''
  roe = 1
  priceSetupID = 0
  priceSetup = ''
  availability = 0
  onRequest = false
  onRequestMsg = ''


  constructor(data?:any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(_data?:any) {

  }
}
export class ReservationServiceOption extends SafeBaseObject {
  serviceID = 0
  optionID = 0
  optionName = ''
  minPax = 0
  maxPax = 0

  prices:ReservationOptionPrices[] = []


  constructor(data?:any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(data?:any) {
    if(data.Prices) this.prices = data.Prices.map((p:any)=>new ReservationOptionPrices(p))

  }
}
export class ResProductService extends SafeBaseObject {
  internalType = 'service'

  shipID = 0;
  shipCod = '';
  ship = '';
  propertyID = 0

  serviceTypeID = 0
  serviceTypeName = ''
  serviceID = 0
  service = ''
  optionID = 0
  option = ''
  description = ''
  cityID = 0
  geoTreeName = ''
  minPax = 0
  maxPax = 0
  currency = ''
  priceTypeID = 0
  priceTypeName = ''
  availabilityMessage = ''
  availabilityBackgroundColor = ''
  deckPlanPath = ''
  deckPlanOn = false
  gridOn = false
  deck = '1'
  isOverNight = false
  isAnchor = false
  markup = 0

  media:ReservationCampMedia[] = []

  images:string[] = []
  imagesSafeUrl:SafeUrl[] = []
  titles:string[] = []
  descriptionsHtml:SafeHtml[] = []

  mainImage = ''
  mainImageSafeUrl:SafeUrl = ''
  mainTitle = ''
  mainDescriptionsHtml:SafeHtml = ''
  options:ReservationServiceOption[]=[]
  ecmData = new ReservationPackageEcmData();
  duration = 0;
  travelStartDate = DateTime.invalid('empty')
  travelEndDate = DateTime.invalid('empty')
  propertyGroupID = 0
  selectable = false

  suiteCategories:ReservationCampSuiteCategory[] = []
  propertyAvailability: ResProductPropertyAvailability[] = []
  get isCamp() {
    return false
  }
  get isService() {
    return true
  }
  get productCod() {
    return ResProductType.SERVICE
  }

  get hasMediaData() {
    return this.images.length>0
  }
  get hasImages() {
    return this.images.length>0
  }
  get hasMap() {
    return false
  }
  get mapUrl() {
    return this.mainImageSafeUrl
  }
  get imagesUrl() {
    return this.imagesSafeUrl
  }
  get hasHtmlDescription() {
    return !!this.mainDescriptionsHtml
  }
  get descriptionHtml() {
    return this.mainDescriptionsHtml
  }

  constructor(data?:any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  get placegholderPrefix() {
    switch(this.serviceTypeID) {
      case 500:
        return 'guide'
      case 1600:
        return 'transport'
      case 1400:
        return 'tour'
      case 500:
        return 'gift'
      case 300:
        return 'hotel'
      case 1000:
        return 'shorex'
      case 1100:
        return 'flight'
      case 1100:
        return 'food'
      default:
        return 'srv'
      }
  }

  override updateData(data?:any) {
    const sanitaizer = AppModule.injector.get(DomSanitizer);
    this.propertyID = this.shipID
    if(data.Options) this.options = data.Options.map((o:any)=>new ReservationServiceOption(o))
    if(data.Media) this.media = data.Media.map((m:any)=>new ReservationCampMedia(m))

    this.images = this.media.filter(m=>m.fldCod.toLowerCase().includes('image')).map(m=>m.fldValue)
    this.mainImage =  this.images.length>0 ? this.images[0] : `/assets/images/service/${this.placegholderPrefix}-placeholder.jpg`
    this.mainImageSafeUrl = sanitaizer.bypassSecurityTrustResourceUrl(this.mainImage)
    this.imagesSafeUrl = this.images
                            //.filter((_:string,idx:number)=>idx>0)
                            .map(img=>sanitaizer.bypassSecurityTrustResourceUrl(img))
    this.descriptionsHtml = this.media.filter(m=>m.fldCod.toLowerCase().includes('description')).map(m=>sanitaizer.bypassSecurityTrustHtml(m.fldValue))
    this.mainDescriptionsHtml =  this.descriptionsHtml.length>0 ? this.descriptionsHtml[0] : ''

    this.titles = this.media.filter(m=>m.fldCod.toLowerCase().includes('name')).map(m=>m.fldValue)

    this.mainTitle = this.titles.length>0 ? this.titles[0] : ''

    this.options.forEach(o=>{

      const category = new ReservationCampSuiteCategory()

      category.shipID = this.serviceID

      category.suiteCategoryID = o.optionID
      category.suiteCategory = o.optionName
      category.suiteCategoryKey = `${this.serviceID}-${o.optionID}`

      o.prices.forEach(p=>{
        const price = new ReservationCampPrices(null)
        const availability = new ResProductPropertyAvailability(null)

        price.suiteCategoryKey = category.suiteCategoryKey
        availability.suiteCategoryKey = category.suiteCategoryKey

        price.startDate = p.date
        price.endDate = p.date
        price.travelStartDate = p.date
        price.travelEndDate = p.date
        price.byDay = p.date
        price.cost = p.cost
        price.costCurrency = p.costCurrency
        price.markup = p.markup
        price.sellingPrice = p.sellingPrice
        price.sellingPriceCurrency = p.sellingPriceCurrency
        price.roe = p.roe
        price.priceSetupID = p.priceSetupID
        price.priceSetup = p.priceSetup
        price.onRequest = p.onRequest
        price.onRequestMsg = p.onRequestMsg
        price.priceDouble = p.sellingPrice
        price.currency = p.sellingPriceCurrency
        price.priceTypeID = p.priceTypeID
        price.priceTypeName = p.priceTypeName


        price.suiteCategoryID = o.optionID
        price.suiteCapacityID = o.maxPax
        category.prices.push(price)


        availability.date = p.date
        availability.cnt = p.availability
        availability.maxCapacity = o.maxPax
        availability.suiteCategoryID = o.optionID
        availability.suiteCategory = o.optionName
        availability.suiteCapacityID = 0

        this.propertyAvailability.push(availability)

      })

      this.suiteCategories.push(category)

      this.currency = category.prices[0]?.sellingPriceCurrency ?? this.currency
      this.priceTypeID = category.prices[0]?.priceTypeID ?? this.priceTypeID
      this.priceTypeName = category.prices[0]?.priceTypeName ?? this.priceTypeName
    })
  }

  displayName() {
    if(this.description=='') return this.mainTitle
    return this.description
  }
  getStartDate(): DateTime {
    return this.travelStartDate;
  }
  getEndDate(): DateTime {
    return this.travelEndDate;
  }
}
