import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';

import { IEidosMenuAction } from '@common/config/environment.interface';
import { EidosLogSeverity, CoreRoute, MenuActionType, LocalStorageKeys, SecurityCheckRequestType, EidosAuthType } from '@common/models/core-constant.model';
import { IEidosDashboardConfig } from '@common/models/eidos-dashboard.interface';
import { EidosLanguage } from '@common/models/eidos-language.model';
import { EidosMenu } from '@common/models/eidos-menu.model';
import { IEidosDataRequestOptions } from '@common/models/eidos-object.interface';
import { EidosDataRequest, EidosObjectApiResponse, EidosObjectDataApiResponse, EidosDataObjectPagination, EidosDataObjectFieldSort, EidosSortType } from '@common/models/eidos-object.model';
import { IEidosResetCredentialsRequest } from '@common/models/mybiz.interface';
import { MyBizApiResponse, MyBizLabels, MyBizVersionApiResponse } from '@common/models/mybiz.model';
import { EidosUser } from '@common/models/user-info.model';
import { EidosApiService } from './eidos-api.service';
import { EidosLogService } from './eidos-log.service';
import { EidosSecurityService } from './eidos-security.service';
import { CoreFormatService } from './core-format.service';
import { CoreCacheService } from './core-cache.service';
import { EidosCompany } from '../models/eidos-company.model';
import { EidosOrganization } from '../models/eidos-organization.model';
import { IEidosModalInfoSendEmail } from '../components/eidos-modal/eidos-modal.component';
import { EidosModalService } from '../components/eidos-modal/eidos-modal.service';
import { IDynamicApiResponse } from '../models/dynamic-api-response.model';

export enum EidosUtilityDialogDialogType {
  Generic,
  Warning,
  Info,
  Debug,
  Alert,
  Fault,
  Error,
  Ask
}
export interface IEidosUtilityDialogOptions {
  title?: string;
  dialogType?: EidosUtilityDialogDialogType;
  message: string;
  info?: string;
  iconSize?: number;
  error?: Array<string>;
  size?: string;
}
export interface IEidosUtilityDialogEmailOptions extends IEidosModalInfoSendEmail {
}

export class CoreUtilityDialog {

  private eidosModalService: EidosModalService;

  constructor(eidosModalService: EidosModalService) {
    this.eidosModalService = eidosModalService;
  }

  public async error(data: IEidosUtilityDialogOptions, err?: IDynamicApiResponse): Promise<void> {
    const msg= data.message + '<br />' + (data.error ? data.error.join('<br />') : '') + (err && err.Errors ? '<br />' + err.Errors.map((e: any) => e.ErrorDesc) : '' );
    await this.eidosModalService.errorMessage({
      message: msg,
      title: data.title ?? '',
      width: data.size ?? 'auto',
      height: 'auto'
    });
    // alert(this.getMessageErrorTemplate(data), data.title ?? '');
  }
  public async info(data: IEidosUtilityDialogOptions): Promise<void> {
    await this.eidosModalService.infoMessage({
      message: data.message,
      title: data.title ?? '',
      width: data.size ?? 'auto',
      height: 'auto'
    });
    //alert(this.getMessageAlertTemplate(data), data.title ?? '');
  }
  public async alert(data: IEidosUtilityDialogOptions): Promise<void> {
    await this.eidosModalService.warningMessage({
      message: data.message,
      title: data.title ?? '',
      width: data.size ?? 'auto',
      height: 'auto'
    });
    //alert(this.getMessageAlertTemplate(data), data.title ?? '');
  }
  public confirm(data: IEidosUtilityDialogOptions): Promise<boolean> {
    return this.eidosModalService.confirmMessage({
      message: data.message,
      title: data.title ?? '',
      width: data.size ?? 'auto',
      height: 'auto',
    });
    //return confirm(this.getMessageConfirmTemplate(data), data.title ?? '');
  }
  public async sendEmail(data: IEidosUtilityDialogEmailOptions): Promise<boolean> {
    const emailData = await this.eidosModalService.sendEmail(data)
    // call api to send email
    return emailData;
    //return confirm(this.getMessageConfirmTemplate(data), data.title ?? '');
  }
}

@Injectable({
  providedIn: 'root'
})
export class EidosUtilityService {
  /**
   * Only admin actions
   *
   * @readonly
   * @private
   * @memberof EidosUtilityService
   */
  private readonly DEVELOPER_ACTIONS = [MenuActionType.Debug, MenuActionType.Readme];

  /**
   * Eidos labels from MyBiz
   *
   * @private
   * @type {MyBizLabels}
   * @memberof EidosUtilityService
   */
  private labels: MyBizLabels = new MyBizLabels();

  /**
   * Current debug mode
   *
   * @memberof EidosUtilityService
   */
  public inDebugMode = new BehaviorSubject<boolean>(false);


  private _skipNextError = false
  get skipNextError(): boolean {
    const value = this._skipNextError;
    this._skipNextError = false;
    return value
  }
  set skipNextError(value:boolean) {
    this._skipNextError = value
  }
  /**
   * Current menu open/closed status
   *
   * @memberof EidosUtilityService
   */
  public isMenuClosed = new BehaviorSubject<boolean>(true);

  /**
   * Current menu pin/unpin status
   *
   * @memberof EidosUtilityService
   */
  public isMenuPinned = new BehaviorSubject<boolean>(false);
  /**
   * Hide sidebar flag
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof EidosUtilityService
   */
  public hideSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onPrepareEnvironment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Hide topbar flag
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof EidosUtilityService
   */
  public hideTopbar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Current menu
   *
   * @memberof EidosUtilityService
   */
  public currentMenu: BehaviorSubject<EidosMenu>;

  /**
   * Current dashboard
   *
   * @memberof EidosUtilityService
   */
  public currentDashboard: BehaviorSubject<IEidosDashboardConfig | undefined>;

  /**
   * Current user actions
   *
   * @memberof EidosUtilityService
   */
  public currentActions: BehaviorSubject<Array<IEidosMenuAction>>;

  /**
   * Current user info
   *
   * @private
   * @type {(EidosUser | null)}
   * @memberof EidosUtilityService
   */
  private currentUser: EidosUser | null = null;
  /**
   * Default actions in profile menu
   *
   * @private
   * @type {Array<IEidosMenuAction>}
   * @memberof EidosUtilityService
   */
  private defaultMenuActions: Array<IEidosMenuAction> = [
    {
      id: MenuActionType.Readme,
      icon: 'perm_data_setting',
      labelCod: 'readme',
      order: 100
    },
    {
      id: MenuActionType.Debug,
      icon: 'adb',
      labelCod: 'debug_mode',
      order: 100
    },
    {
      id: MenuActionType.Settings,
      icon: 'settings',
      labelCod: 'settings',
      order: 50
    },
    {
      id: MenuActionType.ChangePassword,
      icon: 'lock',
      labelCod: 'change_password',
      order: 50
    },
    {
      id: MenuActionType.Refresh,
      icon: 'cached',
      labelCod: 'cache',
      order: 10
    },
    {
      id: MenuActionType.Impersonate,
      icon: 'people',
      labelCod: 'impersonate_user',
      isDisabled: () => !!this.currentUser?.isImpersonated,
      order: 100
    },
    {
      id: MenuActionType.StopImpersonate,
      icon: 'person',
      labelCod: 'user_impersonated',
      isDisabled: () => !this.currentUser?.isImpersonated,
      order: 100
    },
    {
      id: MenuActionType.Logout,
      icon: 'power_settings_new',
      labelCod: 'logout',
      order: -100
    }
  ];

  utilityDialogs: CoreUtilityDialog;

  constructor(
    private eidosApi: EidosApiService
    , private eidosModalService: EidosModalService
    , private router: Router
    , private eidosSecurityService: EidosSecurityService
    , private eidosLogService: EidosLogService
    , private coreCacheService: CoreCacheService
  ) {
    this.utilityDialogs = new CoreUtilityDialog(this.eidosModalService);

    this.currentMenu = new BehaviorSubject<EidosMenu>(new EidosMenu());
    this.currentDashboard = new BehaviorSubject<IEidosDashboardConfig | undefined>(undefined);
    this.currentActions = new BehaviorSubject<Array<IEidosMenuAction>>([]);

    this.eidosSecurityService.currentLoggedUser
      .subscribe(user => {
        // Current user can be invalid
        this.currentUser = user;
        this.setupUtilities(user);
      });

    this.eidosSecurityService.authenticated
      .pipe(filter(isAuthenticated => isAuthenticated !== undefined))
      .subscribe(isAuthenticated => {
        if (!isAuthenticated && this.router.url !== CoreRoute.Login && !this.router.url.includes('checkout') ) {
          sessionStorage.clear();
          window.location.href = window.location.origin + '/' + CoreRoute.Login;
          // this.router.navigate([CoreRoute.Login]);
        }
      });
  }
  /**
   * Setup utilities of service
   *
   * @private
   * @param {EidosUser} user
   * @memberof EidosUtilityService
   */
  private setupUtilities(user: EidosUser) {
    // If valid, setup utilities
    if (user.isValid) {

      this.setDebugMode(localStorage.getItem(LocalStorageKeys.DEBUG_MODE) === "true");

      this.setConfigActions();

      this.loadLabels();

      this.currentDashboard.next(user.dashboard);

      this.eidosApi.loadMenu().subscribe({
        next: (menu: EidosMenu) => {
          // this._currentMenu = menu;
          this.currentMenu.next(menu);
          this.setupMenuClosing(user);
        },
        error: (error) => {
          this.eidosLogService.logDebug(EidosLogSeverity.Error, "EidosUtilityService::setupUtilities - eidosApi.loadMenu", error);
        },
        complete: () => { }
      });

    }
    else {
      // If user is invalid, set default utilities
      this.labels = new MyBizLabels();
      this.currentActions.next([]);
      this.currentDashboard.next({
        enabled: false,
        columns: 10,
        rows: 10,
        columnsGap: 0,
        rowsGap: 0,
        eidosObject: []
      });
      this.currentMenu.next(new EidosMenu());
      if (localStorage.getItem(LocalStorageKeys.DEBUG_MODE) === null) {
        this.setDebugMode(false);
      }
      this.setupMenuClosing(user);
    }
  }

  /**
   * Load labels from APIs
   *
   * @memberof EidosUtilityService
   */
  public loadLabels(): void {
    this.eidosApi.getMyBizLabels().subscribe({
      next: (labels: MyBizLabels) => {
        this.labels = labels;
      },
      error: (error) => {
        this.labels = new MyBizLabels();
        this.eidosLogService.logDebug(EidosLogSeverity.Error, "EidosUtilityService::setupUtilities - eidosApi.loadLabels", error);
      },
      complete: () => { }
    });
  }

  /**
   * Setup the menu closing after its loading.
   * Set the menu as closed and unpinned if user is invalid
   *
   * @private
   * @param {EidosUser} user
   * @memberof EidosUtilityService
   */
  private setupMenuClosing(user: EidosUser): void {
    if (user.isValid) {
      let pinMenu = user.menuPinned;
      const cachedLocked = localStorage.getItem(LocalStorageKeys.PINNED_MENU);
      if (cachedLocked) {
        pinMenu = cachedLocked === "true";
      }
      this.setMenuStatus(!pinMenu);
      this.setMenuPin(pinMenu);
    } else {
      this.setMenuPin(false);
      localStorage.removeItem(LocalStorageKeys.PINNED_MENU);
    }
  }

  /**
   * Filters and sets avalaible user actions basing on its permissions
   *
   * @private
   * @memberof EidosUtilityService
   */
  private setConfigActions(): void {

    let userActions = this.defaultMenuActions
      // If user authentication type is not Form, discard change password action
      .filter(action => this.currentUser?.authType === EidosAuthType.Form || (action.id !== MenuActionType.ChangePassword))
      // If user can not impersonate or is not impersonated, discard impersonate actions
      .filter(action => !!this.currentUser?.canImpersonate || !!this.currentUser?.isImpersonated || (action.id !== MenuActionType.Impersonate && action.id !== MenuActionType.StopImpersonate))
      // If user is not developer, discard developer actions
      .filter(action => !!this.currentUser?.isDeveloper || this.DEVELOPER_ACTIONS.every(actionId => actionId !== action.id));

    // userActions = userActions.map(action => {

    //   switch (action.id) {
    //     case MenuActionType.Impersonate:
    //       action.enabled =  !this.currentUser?.isImpersonated;
    //       break;
    //     case MenuActionType.StopImpersonate:
    //       action.enabled = !!this.currentUser?.isImpersonated;
    //       break;
    //     default:
    //       break;
    //   }
    //   return action;
    // })

    this.currentActions.next(userActions);
  }

  /**
   * Retrive label specified by key, or its default
   *
   * @param {string} key
   * @param {string} [defaultText]
   * @param {...Array<string>} placeholders
   * @return {*}  {string}
   * @memberof EidosUtilityService
   */
  public getLabel(key: string, defaultText?: string, ...placeholders: Array<string>): string {

    let result: string;
    defaultText = defaultText ?? '*default';

    if (key) {
      result = this.labels.get(key) ?? defaultText;

      if (result && placeholders && placeholders.length > 0) {
        placeholders.forEach((item, i) => {
          result = result.replace(`${(i + 1)}`, item);
        });
      }
    } else {
      result = defaultText;
    }

    return result;
  }

  /**
   * Gets MyBiz app version
   *
   * @return {*}  {Observable<EidosMyBizVersionApiResponse>}
   * @memberof EidosUtilityService
   */
  getMyBizVersion(): Observable<MyBizVersionApiResponse> {
    return this.eidosApi.getMyBizVersion();
  }
  /**
   * Gets a generic MyBiz object
   *
   * @param {number} objectId
   * @return {*}  {Observable<EidosObjectApiResponse>}
   * @memberof EidosUtilityService
   */
  getObject(objectId: number): Observable<EidosObjectApiResponse> {
    return this.eidosApi.getObject(objectId);
  }

  /**
   * Gets data of a generic MyBiz object
   *
   * @param {number} objectId
   * @param {number} page
   * @param {IEidosDataRequestOptions} [options]
   * @return {*}  {Observable<EidosObjectDataApiResponse>}
   * @memberof EidosUtilityService
   */
  getDataObject(objectId: number, page: number, options?: IEidosDataRequestOptions): Observable<EidosObjectDataApiResponse> {

    let requestOptions: EidosDataRequest = new EidosDataRequest();
    requestOptions.page = page;
    if (options) {
      if (options.pagination) {
        requestOptions.pageSize = (options?.pagination as EidosDataObjectPagination).pageSize
      }
      if (options.sort && options.sort.length > 0) {
        requestOptions.orderby = options.sort!.map((s: EidosDataObjectFieldSort) => {
          return {
            fieldName: s.fieldName,
            priority: s.priority,
            isAscending: s.type === EidosSortType.Ascending
          }
        });
      }
      if (options.filters && options.filters.length > 0) {
        requestOptions.filters = options.filters!;
      }
    }
    this.eidosLogService.logDebug(EidosLogSeverity.Log, 'getDataObject', objectId, requestOptions);
    return this.eidosApi.getDataObject(objectId, requestOptions);
  }

  /**
   * Set or toggle debug mode, if user is admin
   *
   * @param {boolean} [enable] Mode setting
   * @memberof EidosUtilityService
   */
  public setDebugMode(enable?: boolean): void {
    const canDebugMode = this.eidosSecurityService.isDeveloperUser();
    let nextState = enable !== undefined ? enable : (canDebugMode && !this.inDebugMode.value);
    if (isDevMode()) {
      nextState = true;
    }
    const storageState = nextState ? "true" : "false";
    localStorage.setItem(LocalStorageKeys.DEBUG_MODE, storageState);
    this.eidosLogService.inDebugMode = nextState;
    this.inDebugMode.next(nextState);
  }

  /**
   * Toggle the menu status
   *
   * @param {boolean} [closed]
   * @memberof EidosUtilityService
   */
  public toggleMenuStatus(closed?: boolean): void {
    const nextState = closed !== undefined ? closed : !this.isMenuClosed.value;
    this.setMenuStatus(nextState);
  }

  /**
   * Toggle the menu status, if not pinned
   *
   * @param {boolean} [closed]
   * @memberof EidosUtilityService
   */
  public toggleMenuStatusIfNotPinned(closed?: boolean): void {
    if (!this.isMenuPinned.value) {
      this.toggleMenuStatus(closed);
    }
  }

  /**
   * Change the menu state
   *
   * @param {boolean} close Next menu state
   * @memberof EidosUtilityService
   */
  private setMenuStatus(close: boolean): void {
    this.isMenuClosed.next(close);
  }

  /**
   * Pin/Unpin the menu
   * @param {boolean} pin
   * @memberof EidosUtilityService
   */
  public setMenuPin(pin: boolean): void {
    this.isMenuPinned.next(pin);
    const storageState = pin ? "true" : "false";
    localStorage.setItem(LocalStorageKeys.PINNED_MENU, storageState);
    // Force the menu to close while unpinning it
    if (pin === false) {
      this.setMenuStatus(true);
    }
  }

  /**
   * Logout user from Eidos and redirect him to login
   *
   * @memberof EidosUtilityService
   */
  public logout(): void {
    this.eidosApi.logout()
      .subscribe({
        next: async (response) => {
          if (response.isOk()) {
            this.eidosSecurityService.clearAuthentication();
            this.eidosSecurityService.clearRequestedRoute();
            sessionStorage.clear();
            await this.coreCacheService.eraseCache();
            window.location.href = window.location.origin + '/' + CoreRoute.Login;
            return true;
          } else {
            return false;
          }
        },
        error: () => false,
        complete: () => { },
      });
  }

  public getMyBizImageUrl(imageCod: string): Observable<string> {
    return this.eidosApi.getMyBizImageUrl(imageCod);
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<string> {
    const response = this.eidosApi
      .changePassword(oldPassword, newPassword)
      .pipe(
        map<MyBizApiResponse, string>(response => {
          if (response.isOk()) {
            if (this.currentUser?.mustChangePassword) {
              this.updateUser({ must_change_password: false });
            }
            return "";
          } else {
            return response.error ?? "Error while changing password.";
          }
        }),
        catchError(async (err: string) => err)
      );
    return response;
  }

  /**
   * Requests a user credentials reset
   *
   * @param {string} username
   * @return {*}  {Observable<string>}
   * @memberof EidosUtilityService
   */
  public resetCredentials(username: string): Observable<string> {
    const payload: IEidosResetCredentialsRequest = CoreFormatService.IsEmail(username) ? { email: username } : { username: username };

    const response = this.eidosApi
      .resetCredentials(payload)
      .pipe(
        map<MyBizApiResponse, string>(response => {
          return response.isOk() ? response.data : "";
        }),
        catchError(async () => "")
      );
    return response;
  }
  /**
   * Confirms a user credentials reset
   *
   * @param {string} resetId
   * @return {*}  {Observable<string>}
   * @memberof EidosUtilityService
   */
  public confirmResetCredentials(resetId: string): Observable<string> {
    const response = this.eidosApi
      .confirmResetCredentials(resetId)
      .pipe(
        map<MyBizApiResponse, string>(response => {
          return response.isOk() ? response.data : "";
        }),
        catchError(async () => "")
      );
    return response;
  }

  private updateUser(propertyToUpdate: any) {
    // this.currentUser = this.securityService.UpdateUserProperty(propertyToUpdate);
    this.eidosSecurityService.updateUserProperty(propertyToUpdate);
  }

  impersonateStart(user: string): Observable<string> {
    return this.eidosApi.impersonate(user).pipe(
      map<MyBizApiResponse, string>(response => {
        if (response.isOk()) {
          this.reloadUser();
          return '';
        } else {
          return response.error != null ? response.error : 'Error while impersonating user';
        }
      }),
      catchError(error => { throw typeof error === 'string' ? error : 'Error while impersonating user' })
    );
  }

  impersonateEnd(): Observable<boolean> {
    const response = this.eidosApi
      .endImpersonate()
      .pipe(
        map<MyBizApiResponse, boolean>(response => {
          if (response.isOk()) {
            this.reloadUser();
            return true;
          } else {
            return false;
          }
        }),
        catchError(async (err) => {
          this.eidosLogService.logDebug(EidosLogSeverity.Log, err);
          return false;
        })
      );
    return response;
  }
  public getAvailableLanguages(): Observable<Array<EidosLanguage>> {
    return this.eidosApi.getLanguages();
  }
  public getAvailableCompanies(): Observable<Array<EidosCompany>> {
    return this.eidosApi.getCompanies();
  }
  public getAvailableOrganizations(): Observable<Array<EidosOrganization>> {
    return this.eidosApi.getOrganizations();
  }
  public setLanguage(code: string): Observable<boolean> {
    const response = this.eidosApi
      .setCurrentLanguage(code)
      .pipe(
        map<MyBizApiResponse, boolean>(response => response.isOk()),
        catchError(async () => false)
      );
    return response;
  }
  public setCompany(code: string): Observable<boolean> {
    const response = this.eidosApi
      .setCurrentCompany(code)
      .pipe(
        map<MyBizApiResponse, boolean>(response => response.isOk())
      );
    return response;
  }
  public setOrganization(code?: string): Observable<boolean> {
    const response = this.eidosApi
      .setCurrentOrganization(code)
      .pipe(
        map<MyBizApiResponse, boolean>(response => response.isOk()),
        catchError(async () => false)
      );
    return response;
  }
  //TODO modellizare setting
  public updateCurrentSetting(setting: any): Observable<boolean> {
    const response = forkJoin([
      this.setCompany(setting.companyCode),
      this.setLanguage(setting.languageCode),
      this.setOrganization(setting.organizationCode)
    ]).pipe(
      map<Array<boolean>, boolean>(([company, language]) => {
        this.loadLabels();
        this.reloadUser();
        return company && language;
      }),
      catchError(async () => false)
    );
    return response;
  }

  updateCurrentCompany(companyCode: string): Observable<boolean> {
    const response = this.setCompany(companyCode).pipe(
      map<boolean, boolean>(result => {
        this.loadLabels();
        this.reloadUser();
        return result;
      }),
      catchError(async (err) => {
        console.log(err)
        return false
      })
    );
    return response;
  }

  public getCurrentUser(): EidosUser | null {
    return this.currentUser;
  }

  /**
   * Force reload user
   *
   * @memberof EidosUtilityService
   */
  private reloadUser(): void {
    this.eidosSecurityService.checkRequest.emit(SecurityCheckRequestType.User);
  }

}
