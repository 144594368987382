import { SafeBaseObject } from '@app/core/models/base-object.models';
import { DateTime } from 'luxon';
import { ReservationApiBoolean } from './res-constant.model';

export class ResBkgNote extends SafeBaseObject {
  bkgID: number = 0;
  bookingNotesID: number = 0;
  noteTypeCod: string = '';
  noteTypeName: string = '';
  guestID: number = 0;
  bookingNote: string = '';
  created: DateTime = DateTime.min();
  formattedCreated: string = '';
  createdBy: string = '';
  locked: boolean = false;
  canBeLocked: boolean = true;
  canEditLocked: boolean = true;
  pickListID: number = 0;
  pickListName: string = '';

  constructor(data?: IReservationApiBookingNote) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiBookingNote): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('created', data, 'Created');
    this.addBooleanProperty('locked', data, 'Locked');
    this.addBooleanProperty('canBeLocked', data, 'CanBeLocked');
    this.canEditLocked = this.locked || this.canBeLocked;

    if (this.created?.isValid) this.formattedCreated = this.created.toFormat('dd LLL yyyy HH:mm');
  }
}

export interface IReservationApiBookingNote {
  BkgID?: number;
  BookingNotesID?: number;
  NoteTypeCod?: string;
  NoteTypeName?: string;
  GuestID?: number;
  BookingNote?: string;
  Created?: string;
  CreatedBy?: string;
  Locked?: ReservationApiBoolean;
  CanBeLocked?: ReservationApiBoolean;
  PickListID?: number;
  PickListName?: string;
}

export interface IReservationApiGetBkgNotes {
  BkgID?: number;
  NoteTypeCod?: string;
  GuestID?: number;
}

export type IReservationApiManageBkgNotes = {
  BkgID: number;
  NoteTypeCod: string;
  GuestID?: number;
  BookingNote: string;
  Locked: ReservationApiBoolean;
  BookingNotesID?: number;
  PickListID?: number;
} | {
  BkgID: number;
  BookingNotesID: number;
  Status: 'D';
};

export class ResBkgHistory extends SafeBaseObject {
  public bkgID: number = 0;
  public historyType: string = '';
  public historyDesc: string = '';
  public historyDate: DateTime = DateTime.min();
  public userName: string = '';

  constructor(data?: IReservationApiBkgHistory) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiBkgHistory): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('historyDate', data, 'HistoryDate');
  }
}

export interface IReservationApiBkgHistory {
  BkgID?: number;
  HistoryType?: string;
  HistoryDesc?: string;
  HistoryDate?: string;
  UserName?: string;
}

export class ResBkgNoteTouch extends SafeBaseObject {
  touchPointID = 0
  keyCod = 0
  touchDate: DateTime = DateTime.invalid('empty');
  scheduleDate: DateTime = DateTime.invalid('empty');
  assignedTo = ''
  originalQualification = ''
  qualification = ''
  outcome = ''
  type = ''
  serviceTypeTypology = ''
  createdBy = ''
  created: DateTime = DateTime.invalid('empty');
  canEdit = false;
  canView = false;

  constructor(data?: any) {
    super();
    if (data) this.updateData(data);
    this.addBooleanProperty('canEdit', data, 'CanEdit');
    this.addBooleanProperty('canView', data, 'CanView');
  }
}




