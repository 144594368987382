<content *ngFor="let btn of buttonsConfig">
	<dx-button [text]="btnEvaluateText(btn)"
    [icon]="btn.icon"
    [hint]="btn.tooltip"
    [stylingMode]="btn.stylingMode ?? 'contained'"
    [visible]="isVisible(btn.visible)"
		[disabled]="isDisabled(btn.disabled)"
    (onClick)="_onButtonClick($event, btn);">
	</dx-button>
</content>
