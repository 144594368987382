import { BaseObject, SafeBaseObject } from "@common/models/base-object.models";

import { ReservationCruise } from "./res-cruise.models";
import { ReservationApiBoolean } from "./res-constant.model";
import { IReservationSuiteAmenity } from "./res-cached-data.models";
import { DateTime } from "luxon";
import { IReservationApiPromoDetail, ResPromo } from "./res-promo.model";
import { ResProductType } from "./res-package.model";

export class ReservationCruiseAvailabilityModel extends SafeBaseObject {
  public currency: string = '';
  public diff: number = 0;
  public singleSuite: number = 0;
  public singleSuiteGty: number = 0;
  public discountedPriceChild: number = 0;
  public discountedPriceDouble: number = 0;
  public discountedPriceExtraGuest: number = 0;
  public discountedPriceSingle: number = 0;
  public discountedTotalPrice: number = 0;
  public doubleSuite: number = 0;
  public doubleSuiteGty: number = 0;
  public fare: number = 0;
  public ncf: number = 0;
  public portCharges: number = 0;
  public priceCategory: string = '';
  public priceCategoryCod: string = '';
  public priceCategoryID: number = 0;
  public priceChild: number = 0;
  public priceDouble: number = 0;
  public priceExtraGuest: number = 0;
  public priceSingle: number = 0;
  public priceType: string = '';
  public quadrupleSuite: number = 0;
  public quadrupleSuiteGty: number = 0;
  public quintupleSuite: number = 0;
  public quintupleSuiteGty: number = 0;
  public sixtupleSuite: number = 0;
  public sixtupleSuiteGty: number = 0;
  public sortSequence: number = 0;
  public suiteCategory: string = '';
  public suiteCategoryCode: string = '';
  public suiteCategoryID: number = 0;
  public suiteCategoryText: string = '';
  public tax: number = 0;
  public totalPrice: number = 0;
  public totAvailable: number = 0;
  public totGuarantee: number = 0;
  public tripleSuite: number = 0;
  public tripleSuiteGty: number = 0;
  public voyageId: number = 0;

  public get totAvailableText(): string {
    return this.totAvailable?.toString() ?? '-';
  }

  public get totGuaranteeText(): string {
    return this.totGuarantee?.toString() ?? '-';
  }

  public get singleSuiteText(): string {
    return this.singleSuite?.toString() ?? '-';
  }

  public get singleSuiteGtyText(): string {
    return this.singleSuiteGty?.toString() ?? '-';
  }

  public get doubleSuiteText(): string {
    return this.doubleSuite?.toString() ?? '-';
  }

  public get doubleSuiteGtyText(): string {
    return this.doubleSuiteGty?.toString() ?? '-';
  }

  public get tripleSuiteText(): string {
    return this.tripleSuite?.toString() ?? '-';
  }

  public get tripleSuiteGtyText(): string {
    return this.tripleSuiteGty?.toString() ?? '-';
  }

  public get quadrupleSuiteText(): string {
    return this.quadrupleSuite?.toString() ?? '-';
  }

  public get quadrupleSuiteGtyText(): string {
    return this.quadrupleSuiteGty?.toString() ?? '-';
  }

  public get quintupleSuiteText(): string {
    return this.quintupleSuite?.toString() ?? '-';
  }

  public get quintupleSuiteGtyText(): string {
    return this.quintupleSuiteGty?.toString() ?? '-';
  }

  public get sixtupleSuiteText(): string {
    return this.sixtupleSuite?.toString() ?? '-';
  }

  public get sixtupleSuiteGtyText(): string {
    return this.sixtupleSuiteGty?.toString() ?? '-';
  }

  constructor(data: IReservationApiCruiseAvailability) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data)
    }
  }

  override updateData(data: IReservationApiCruiseAvailability) {
    //@TODO START this property is safe to drop becouse is in autobinding afrte release of Thursday (9 Feb 2023) we drop it
    // this.addProperty("currency", data, "Currency");
    // this.addProperty("discountedPriceChild", data, "DiscountedPriceChild");
    // this.addProperty("discountedPriceDouble", data, "DiscountedPriceDouble");
    // this.addProperty("discountedPriceExtraGuest", data, "DiscountedPriceExtraGuest");
    // this.addProperty("discountedPriceSingle", data, "DiscountedPriceSingle");
    // this.addProperty("discountedTotalPrice", data, "DiscountedTotalPrice");
    // this.addProperty("portCharges", data, "PortCharge");
    // this.addProperty("priceCategory", data, "PriceCategory");
    // this.addProperty("priceCategoryCod", data, "PriceCategoryCod");
    // this.addProperty("priceCategoryID", data, "PriceCategoryID");
    // this.addProperty("priceChild", data, "PriceChild");
    // this.addProperty("priceDouble", data, "PriceDouble");
    // this.addProperty("priceExtraGuest", data, "PriceExtraGuest");
    // this.addProperty("priceSingle", data, "PriceSingle");
    // this.addProperty("sortSequence", data, "SortSequence");
    // this.addProperty("suiteCategoryID", data, "SuiteCategoryID");
    // this.addProperty("tax", data, "Tax");
    // this.addProperty("totalPrice", data, "TotalPrice");
    //@TODO END

    //@TODO refactoring property name (for convention and consistency we have to preserve BackEnd assigned name)
    this.addProperty("singleSuite", data, "Sgl");
    this.addProperty("singleSuiteGty", data, "SglGTY");
    this.addProperty("doubleSuite", data, "Dbl");
    this.addProperty("doubleSuiteGty", data, "DblGTY");
    this.addProperty("ncf", data, "NCF");
    this.addProperty("quadrupleSuite", data, "Quad");
    this.addProperty("quadrupleSuiteGty", data, "QuadGTY");
    this.addProperty("quintupleSuite", data, "Quin");
    this.addProperty("quintupleSuiteGty", data, "QuinGTY");
    this.addProperty("singleSuite", data, "Sgl");
    this.addProperty("singleSuiteGty", data, "SglGTY");
    this.addProperty("sixtupleSuite", data, "Six");
    this.addProperty("sixtupleSuiteGty", data, "SixGTY");
    this.addProperty("suiteCategoryText", data, "CategoryName");
    this.addProperty("suiteCategoryCode", data, "Category");
    this.addProperty("totAvailable", data, "TotAV");
    this.addProperty("totGuarantee", data, "TotGTY");
    this.addProperty("tripleSuite", data, "Trip");
    this.addProperty("tripleSuiteGty", data, "TripGTY");
    this.addProperty("voyageId", data, "VoyageID");
  }
}
export interface IReservationApiCruiseAvailability {
  Category: string;
  Currency: string;
  Dbl: number;
  DblGTY: number;
  DiscountedPriceChild: number;
  DiscountedPriceDouble: number;
  DiscountedPriceExtraGuest: number;
  DiscountedPriceSingle: number;
  DiscountedTotalPrice: number;
  NCF: number;
  PortCharge: number;
  PriceCategory: string;
  PriceCategoryCod: string;
  PriceCategoryID: number;
  PriceChild: number;
  PriceDouble: number;
  PriceExtraGuest: number;
  PriceSingle: number;
  Quad: number;
  QuadGTY: number;
  Quin: number;
  QuinGTY: number;
  Six: number;
  SixGTY: number;
  Sgl: number;
  SglGTY: number;
  SortSequence: number;
  SuiteCategoryID: number;
  Tax: number;
  TotalPrice: number;
  TotAV: number;
  TotGTY: number;
  Trip: number;
  TripGTY: number;
  VoyageID: number;

}

export enum ReservationCruiseSuiteAvailabilityStatus {
  Available = 'Available',
  ContractGroup = 'Contract Group',
  Booked = 'Booked',
  BookedOnSegment = 'Booked on Segment',
  StandardHold = 'Standard Hold',
  Staff = 'Staff'
}

export type ReservationCruiseSuiteAvailabilityStatusCode =
  '' // ReservationCruiseSuiteAvailabilityStatus.Available
  | '+' // ReservationCruiseSuiteAvailabilityStatus.ContractGroup
  | 'B' // ReservationCruiseSuiteAvailabilityStatus.Booked
  | 'H' // ReservationCruiseSuiteAvailabilityStatus.StandardHold
  | 'S'; // ReservationCruiseSuiteAvailabilityStatus.Staff

export enum ReservationCruiseSuiteAvailabilityEnumStatusCode {
  Available = '',
  ContractGroup = '+',
  Booked = 'B',
  StandardHold = 'H',
  Staff = 'S'
}
export class ReservationPackageCostHistory extends SafeBaseObject {
  packageOptionCostID				      = 0
  packageID                       = 0
  packageTravelDateID             = 0
  itineraryDate                   = 0
  serviceID                       = 0
  optionID                        = 0
  optionName                      = ''
  priceTypeID                     = 0
  priceTypeName                   = ''
  costCurrency                    = 0
  cost                            = 0
  costTax                         = 0
  rOE                             = 0
  margin                          = 0
  guideCost                       = 0
  escortCost                      = 0
  driverCost                      = 0
  validityStartDate               = DateTime.invalid('empty')
  validityEndDate                 = DateTime.invalid('empty')
  travelStartDate                 = DateTime.invalid('empty')
  travelEndDate                   = DateTime.invalid('empty')
  priceSetupID                    = 0
  forcedCost                      = false
  status                          = ''
  quantity                        = 0
  agencyID                        = 0
  sellingPrice                    = 0
  priceSingle                     = 0
  priceDouble                     = 0
  priceExtraGuest                 = 0
  priceChild                      = 0
  supplierID                      = 0
  supplierName                    = ''
  serviceTypeID                   = 0
  serviceTypeName                 = ''

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
  override updateData(data: any) {

    super.updateData(data);

  }

}
export class ReservationPackagePriceHistory extends SafeBaseObject {
  packagePriceID                 =  0
  packageID                      =  0
  packageTravelDateID            =  0
  priceTypeID                    =  0
  priceTypeName                   = ''
  guestFrom                      =  0
  guestTo                        =  0
  margin                         =  0
  sellingPriceCurrency           =  0
  sellingPrice                   =  0
  childSellingPrice              =  0
  childReduction                 =  0
  validityStartDate              =  DateTime.invalid('empty')
  validityEndDate                =  DateTime.invalid('empty')
  travelStartDate                = DateTime.invalid('empty')
  travelEndDate                  = DateTime.invalid('empty')
  agencyID                       =  0
  forcedPrice                    =  false
  priceSingle                    =  0
  priceDouble                    =  0
  priceExtraGuest                =  0
  priceChild                     =  0
  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
  override updateData(data: any) {

    super.updateData(data);

  }
}
export class ReservationPackageUpgradeHistory extends SafeBaseObject {
  packageOptionUpgradeID               =  0
  packageID                            =  0
  packageTravelDateID                  =  0
  packageOptionID                      =  0
  priceTypeID                          =  0
  agencyID                             =  0
  validityStartDate                    =  DateTime.invalid('empty')
  validityEndDate                      =  DateTime.invalid('empty')
  travelStartDate                      = DateTime.invalid('empty')
  travelEndDate                        = DateTime.invalid('empty')
  sellingPriceCurrency                 =  0
  day                                  =  0
  serviceID                            =  0
  optionID                             =  0
  upgradeAmount                        =  0
  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
  override updateData(data: any) {

    super.updateData(data);

  }
}
export class ReservationPackageSingleSupplementHistory extends SafeBaseObject {
  packageID                    =  0
  packageTravelDateID          =  0
  priceTypeID                  =  0
  currency                     =  ''
  agencyID                     =  0
  serviceID                    =  0
  optionID                     =  0
  suiteCategoryID              =  0
  promoID                      =  0
  defaultFormula               =  false
  snglSuppType                 =  ''
  snglSuppValue                =  0
  snglSuppCommPerc             =  0
  discountType                 =  ''
  discountValue                =  0
  startDate                    =  DateTime.invalid('empty')
  endDate                      =  DateTime.invalid('empty')
  created                      =  DateTime.invalid('empty')
  createdBy                    =  0
  updated                      =  DateTime.invalid('empty')
  utatus                       =  ''
  travelStartDate              = DateTime.invalid('empty')
  travelEndDate                = DateTime.invalid('empty')

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
  override updateData(data: any) {

    super.updateData(data);

  }
}

export class ReservationPackageHistoryGeneralData extends SafeBaseObject {
  packageID = 0
  packageName = ''
  packageTravelDateID = 0
  priceTypeID = 0
  priceTypeName = ''
  travelStartDate = DateTime.invalid('empty')
  travelEndDate = DateTime.invalid('empty')

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
  override updateData(data: any) {

    super.updateData(data);

  }
}
export class ReservationPackagePricesAndCostsHistory extends SafeBaseObject {
  costsHistory:ReservationPackageCostHistory[]=[]
  pricesHistory:ReservationPackagePriceHistory[]=[]
  upgradesHistory:ReservationPackageUpgradeHistory[]=[]
  singleSupplementsHistory:ReservationPackageSingleSupplementHistory[]=[]
  generalDataHistory:ReservationPackageHistoryGeneralData = new ReservationPackageHistoryGeneralData()

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: any) {

    super.updateData(data);

    if (data.Costs) {
      this.costsHistory = data.Costs.map((d: any)=>new ReservationPackageCostHistory(d))
    }
    if (data.Prices) {
      this.pricesHistory = data.Prices.map((d: any)=>new ReservationPackagePriceHistory(d))
    }
    if (data.Upgrade) {
      this.upgradesHistory = data.Upgrade.map((d: any)=>new ReservationPackageUpgradeHistory(d))
    }
    if (data.SingleSUpp) {
      this.singleSupplementsHistory = data.SingleSUpp.map((d: any)=>new ReservationPackageSingleSupplementHistory(d))
    }
    if (data.General) {
      this.generalDataHistory = new ReservationPackageHistoryGeneralData(data.General)
    }
  }
}
export class ReservationCruiseSuiteListAvailabilityModel extends SafeBaseObject {
  deckID = 0;
  deckName = '';
  deckNumber = 0;
  isGty = false;
  isSelected = false;
  statusDesc = '';
  suiteCapacity = '';
  suiteCapacityID = 0;
  suiteCategory = '';
  suiteCategoryCode = '';
  suiteCategoryID = 0;
  shipID: number = -1;
  suiteID = 0;
  suiteNo = '';
  suiteStatus = '';
  suiteDesc = '';
  suiteCapacityMax: number = 1;
  suiteNumber = 0;
  voyageID = 0;
  suiteFeatureID = 0;
  suiteFeatureDesc = '';
  refurbStatus = '';
  obstruction = '';
  obsPercentage = 0;
  obsReason = '';
  keySuiteID = 0;

  amenities: Array<IReservationSuiteAmenity> = [];
  connectedSuite = new ReservationConnectedSuite()
  suiteCategoryColor = ''

  constructor(data?: IReservationApiCruiseSuiteListAvailability, amenities: IReservationSuiteAmenity[] = []) {
    super();
    if (data) {
      this.updateData(data, amenities);
    }
  }

  getSuiteInfoTooltip() {
    let info = '';
    if (!!this.refurbStatus) info += `Refurb: ${this.refurbStatus}`;
    if (!!this.obstruction) {
      if (info.length > 0) info += ' - ';
      info += `Obstruction view: ${this.obstruction}`
      if (!!this.obsPercentage) {
        info += ` ${this.obsPercentage}%`
      };
      if (!!this.obsReason) {
        info += ` ${this.obsReason}`
      };
    };
    return info;
  }
  getSuiteInfoTooltipHtml() {
    let info = '<div>';
    if (!!this.refurbStatus) info += `<div>Refurb: ${this.refurbStatus}</div>`;
    if (!!this.obstruction) {
      info += `<div class="text-start">Obstruction view: ${this.obstruction}`
      if (!!this.obsPercentage) {
        info += ` ${this.obsPercentage}%`
      };
      if (!!this.obsReason) {
        info += `<br />${this.obsReason}`
      };
      info += '</div>';
    };
    info += '</div>';
    return info;
  }

  override async updateData(data: IReservationApiCruiseSuiteListAvailability, amenities: IReservationSuiteAmenity[] = []) {

    super.updateData(data);

    //Property out of name convetion START (@TODO when there will be time)
    this.addProperty("suiteCategoryCode", data, "SuiteCategoryCod");
    this.addProperty("suiteNo", data, "SuiteNO");
    this.addBooleanProperty("isGty", data, "IsGTY");
    //Property out of name convetion END

    if (data.AmenitiesID) {
      data.AmenitiesID.split(',').forEach(aID => {
        const am = amenities.find(a => a.AmenitiesID === +aID);
        if (am) {
          this.amenities.push(am);
        }
      });
    }
    if (data.ConnectedSuite) this.connectedSuite = new ReservationConnectedSuite(data.ConnectedSuite)

  }

  public get suiteNoText(): string {
    return this.suiteNo ?? '-';
  }
}

class ReservationCruiseAvailabilityDetailedModel extends BaseObject {
  public availability: Array<ReservationCruiseAvailability> | undefined;
  public availabilityDetails: Array<ReservationCruiseAvailabilityDetail> | undefined;
  public voyageID: number | undefined;

  constructor(data: IReservationApiAvailabilityModel) {
    super(data);
  }

  override updateData(data: IReservationApiAvailabilityModel): void {
    this.availability = data.Availability.map(availability => new ReservationCruiseAvailability(availability));
    this.availabilityDetails = data.AvailabilityDetails.map(availabilityDetail => new ReservationCruiseAvailabilityDetail(availabilityDetail));
    if (this.availabilityDetails.length > 0) {
      this.voyageID = this.availabilityDetails[0].voyageID;
    }
  }
}

class ReservationCruiseAvailabilityDetail extends BaseObject {
  public suiteCategory: string | undefined;
  public suiteCategoryCode: string | undefined;
  public suiteNo: number | undefined;
  public voyageID: number | undefined;

  constructor(data: IReservationApiAvailabilityDetail) {
    super(data);
  }

  override updateData(data: IReservationApiAvailabilityDetail): void {
    this.addProperty('suiteCategory', data, 'SuiteCategory');
    this.addProperty('suiteCategoryCode', data, 'SuiteCategoryCod');
    this.addProperty('suiteNo', data, 'SuiteNO');
    this.addProperty('voyageID', data, 'VoyageID');
  }
}

class ReservationCruiseAvailability extends BaseObject {
  public availableSuites: number | undefined;
  public currency: string | undefined;
  public doublePrice: number | undefined;
  public fareCode: string | undefined;
  public suiteCategory: string | undefined;
  public suiteCategoryCode: string | undefined;

  constructor(data: IReservationApiAvailabilityDetailed) {
    super(data);
  }

  override updateData(data: IReservationApiAvailabilityDetailed): void {
    this.addProperty('availableSuites', data, 'TotAV');
    this.addProperty('currency', data, 'Currency');
    this.addProperty('doublePrice', data, 'PriceDouble');
    this.addProperty('suiteCategoryCode', data, 'SuiteCategoryCod');
  }
}

export interface IReservationCruiseCompareItem {
  availability: ReservationCruiseAvailabilityDetailedModel | undefined;
  categoryList: IReservationCruiseCategory[] | undefined;
  cruise: ReservationCruise | undefined;
  voyageId: number | undefined;
  voyageName: string | undefined;
}

export interface IReservationCruiseCategory {
  code: string | undefined;
  description: string | undefined;
  id: number | undefined;
}

interface IReservationApiAvailabilityModel {
  AvailabilityDetails: IReservationApiAvailabilityDetail[];
  Availability: IReservationApiAvailabilityDetailed[];
}

interface IReservationApiAvailabilityDetail {
  SuiteCategory: string;
  SuiteCategoryCod: string;
  SuiteNO: number;
  VoyageId: number;
}

interface IReservationApiAvailabilityDetailed {
  Currency: string;
  PriceDouble: number;
  SuiteCategoryCod: string;
  TotAV: number;
}

export interface IReservationCruiseSuiteCapacity {
  id: number;
  description: string;
  adultNumber?: number;
  childNumber?: number;
}

export class ReservationCruiseInventoryModel extends SafeBaseObject {
  public capacity: ReservationCruiseInventoryCapacityModel[] = [];
  public voyage: ReservationCruiseInventoryVoyageModel[] = [];
  public pastGuestDiscount: ReservationCruiseInventoryPastGuestDiscountModel[] = [];
  public paymentScheduler: ReservationCruiseInventoryPaymentScheduler[] = [];
  public promo: ResPromo[]= [];

  constructor(data: IReservationApiCruiseInventory) {
    super();
    if(data) this.updateData(data)
  }

  override updateData(data: IReservationApiCruiseInventory): void {
    this.capacity = data.Capacity.map(capacity => new ReservationCruiseInventoryCapacityModel(capacity));
    this.voyage = data.Voyage.map(voyage => new ReservationCruiseInventoryVoyageModel(voyage));
    if (data.PastGuestDiscount) {
      this.pastGuestDiscount = data.PastGuestDiscount.map(discount => new ReservationCruiseInventoryPastGuestDiscountModel(discount));
    }
    if (data.PaymentScheduler) {
      this.paymentScheduler = data.PaymentScheduler.map(ps => new ReservationCruiseInventoryPaymentScheduler(ps));
    }
    if (data.Promo) {
      this.promo = data.Promo.map(promo => new ResPromo(promo));
    }
  }
}

export class ReservationCruiseInventoryCapacityModel extends BaseObject {
  public adultNumber: number | undefined;
  public childNumber: number | undefined;
  public suiteCapacity: string | undefined;
  public suiteCapacityID: number | undefined;

  constructor(data: IReservationApiCruiseInventoryCapacity) {
    super(data);
  }

  override updateData(data: IReservationApiCruiseInventoryCapacity) {
    this.addProperty("adultNumber", data, "AdultNumber");
    this.addProperty("childNumber", data, "ChildNumber");
    this.addProperty("suiteCapacity", data, "SuiteCapacity");
    this.addProperty("suiteCapacityID", data, "SuiteCapacityID");
  }
}

class ReservationCruiseInventoryVoyageModel extends BaseObject {
  public bw: string | undefined;
  public shipCod: string | undefined;
  public category: string | undefined;
  public currency: string | undefined;
  public diff: number | undefined;
  public discountedPriceChild: number | undefined;
  public discountedPriceDouble: number | undefined;
  public discountedPriceExtraGuest: number | undefined;
  public discountedPriceSingle: number | undefined;
  public discountedTotalPrice: number | undefined;
  public doubleSuite: number | undefined;
  public doubleSuiteGty: number | undefined;
  public fare: number | undefined;
  public groupColor: string | undefined;
  public ncf: number | undefined;
  public portCharges: number | undefined;
  public airPrice: number | undefined;
  public ada: number | undefined;
  public priceChild: number | undefined;
  public priceDouble: number | undefined;
  public priceExtraGuest: number | undefined;
  public priceSingle: number | undefined;
  public priceType: string | undefined;
  public quadrupleSuite: number | undefined;
  public quadrupleSuiteGty: number | undefined;
  public quintupleSuite: number | undefined;
  public quintupleSuiteGty: number | undefined;
  public singleSuite: number | undefined;
  public singleSuiteGty: number | undefined;
  public sixtupleSuite: number | undefined;
  public sixtupleSuiteGty: number | undefined;
  public sortSequence: number | undefined;
  public sqm: string | undefined;
  public suiteCategory: string | undefined;
  public suiteCategoryCode: string | undefined;
  public suiteCategoryGroup: string | undefined;
  public suiteCategoryID: number | undefined;
  public suiteCategoryName: string | undefined;
  //public suiteCategoryText: string | undefined;
  public tax: number | undefined;
  public totalPrice: number | undefined;
  public totAvailable: number | undefined;
  public totGuarantee: number | undefined;
  public tripleSuite: number | undefined;
  public tripleSuiteGty: number | undefined;
  public voyageId: number | undefined;

  public get totAvailableText(): string {
    return this.totAvailable?.toString() ?? '-';
  }

  public get totGuaranteeText(): string {
    return this.totGuarantee?.toString() ?? '-';
  }

  public get singleSuiteText(): string {
    return this.singleSuite?.toString() ?? '-';
  }

  public get singleSuiteGtyText(): string {
    return this.singleSuiteGty?.toString() ?? '-';
  }

  public get doubleSuiteText(): string {
    return this.doubleSuite?.toString() ?? '-';
  }

  public get doubleSuiteGtyText(): string {
    return this.doubleSuiteGty?.toString() ?? '-';
  }

  public get tripleSuiteText(): string {
    return this.tripleSuite?.toString() ?? '-';
  }

  public get tripleSuiteGtyText(): string {
    return this.tripleSuiteGty?.toString() ?? '-';
  }

  public get quadrupleSuiteText(): string {
    return this.quadrupleSuite?.toString() ?? '-';
  }

  public get quadrupleSuiteGtyText(): string {
    return this.quadrupleSuiteGty?.toString() ?? '-';
  }

  public get quintupleSuiteText(): string {
    return this.quintupleSuite?.toString() ?? '-';
  }

  public get quintupleSuiteGtyText(): string {
    return this.quintupleSuiteGty?.toString() ?? '-';
  }

  public get sixtupleSuiteText(): string {
    return this.sixtupleSuite?.toString() ?? '-';
  }

  public get sixtupleSuiteGtyText(): string {
    return this.sixtupleSuiteGty?.toString() ?? '-';
  }

  public get adaText(): string {
    return this.ada?.toString() ?? '-';
  }

  constructor(data: IReservationApiCruiseInventoryVoyage) {
    super(data);
  }

  override updateData(data: IReservationApiCruiseInventoryVoyage) {
    this.addProperty("bw", data, "B_W");
    this.addProperty("shipCod", data, "ShipCod");
    this.addProperty("currency", data, "Currency");
    this.addProperty("discountedPriceChild", data, "DiscountedPriceChild");
    this.addProperty("discountedPriceDouble", data, "DiscountedPriceDouble");
    this.addProperty("discountedPriceExtraGuest", data, "DiscountedPriceExtraGuest");
    this.addProperty("discountedPriceSingle", data, "DiscountedPriceSingle");
    this.addProperty("discountedTotalPrice", data, "DiscountedTotalPrice");
    this.addProperty("doubleSuite", data, "Dbl");
    this.addProperty("doubleSuiteGty", data, "DblGTY");
    this.addProperty("groupColor", data, "GroupColor");
    this.addProperty("ncf", data, "NCF");
    this.addProperty("portCharges", data, "PortCharge");
    this.addProperty("priceChild", data, "PriceChild");
    this.addProperty("priceDouble", data, "PriceDouble");
    this.addProperty("priceExtraGuest", data, "PriceExtraGuest");
    this.addProperty("priceSingle", data, "PriceSingle");
    this.addProperty("quadrupleSuite", data, "Quad");
    this.addProperty("quadrupleSuiteGty", data, "QuadGTY");
    this.addProperty("quintupleSuite", data, "Quin");
    this.addProperty("quintupleSuiteGty", data, "QuinGTY");
    this.addProperty("singleSuite", data, "Sgl");
    this.addProperty("singleSuiteGty", data, "SglGTY");
    this.addProperty("sixtupleSuite", data, "Six");
    this.addProperty("sixtupleSuiteGty", data, "SixGTY");
    this.addProperty("sortSequence", data, "SortSequence");
    this.addProperty("sqm", data, "Sqm");
    this.addProperty("suiteCategoryCode", data, "Category");
    this.addProperty("suiteCategoryName", data, "CategoryName");
    this.addProperty("suiteCategoryGroup", data, "SuiteCategoryGroup");
    this.addProperty("suiteCategoryID", data, "SuiteCategoryID");
    this.addProperty("tax", data, "Tax");
    this.addProperty("totalPrice", data, "TotalPrice");
    this.addProperty("totAvailable", data, "TotAV");
    this.addProperty("totGuarantee", data, "TotGTY");
    this.addProperty("tripleSuite", data, "Trip");
    this.addProperty("tripleSuiteGty", data, "TripGTY");
    this.addProperty("voyageId", data, "VoyageID");
    this.addProperty("airPrice", data, "AirPrice");
    this.addProperty("ada", data, "ADA");
  }
}

class ReservationCruiseInventoryPastGuestDiscountModel extends BaseObject {
  public discountType: string | undefined;
  public discountValue: number | undefined;

  constructor(data: IReservationApiCruiseInventoryPastGuestDiscount) {
    super(data);
  }

  override updateData(data: IReservationApiCruiseInventoryPastGuestDiscount) {
    this.addDateTimeProperty("discountType", data, "DiscountType");
    this.addDateTimeProperty("discountValue", data, "DiscountValue");
  }
}
class ReservationCruiseInventoryPaymentScheduler extends BaseObject {
  public depositPaymentDate: DateTime | undefined;
  public finalPaymentDate: DateTime | undefined;

  constructor(data: IReservationApiCruiseInventoryPaymentScheduler) {
    super(data);
  }

  override updateData(data: IReservationApiCruiseInventoryPaymentScheduler) {
    this.addDateTimeProperty("depositPaymentDate", data, "DepositPaymentDate");
    this.addDateTimeProperty("finalPaymentDate", data, "FinalPaymentDate");
  }
}

interface IReservationApiCruiseInventory {
  Capacity: IReservationApiCruiseInventoryCapacity[];
  Voyage: IReservationApiCruiseInventoryVoyage[];
  PastGuestDiscount: IReservationApiCruiseInventoryPastGuestDiscount[];
  PaymentScheduler: IReservationApiCruiseInventoryPaymentScheduler[];
  Promo: IReservationApiPromoDetail[];
}

interface IReservationApiCruiseInventoryCapacity {
  AdultNumber: number;
  ChildNumber: number;
  LowBed: string;
  LowNumGuest: number;
  LowUpperNumGuest: number;
  MaxLowNumGuest: number;
  MaxUpperNumGuest: number;
  OptionCrib: string;
  OptionID: number;
  SuiteCapacity: string;
  SuiteCapacityID: number;
  UpperBed: string;
}

interface IReservationApiCruiseInventoryVoyage {
  B_W: string;
  ShipCod: string;
  Category: string;
  CategoryName: string;
  Currency: string;
  Dbl: number;
  DblGTY: number;
  DiscountedPriceChild: number;
  DiscountedPriceDouble: number;
  DiscountedPriceExtraGuest: number;
  DiscountedPriceSingle: number;
  DiscountedTotalPrice: number;
  DisplayPrice: number;
  GroupColor: string;
  NCF: number;
  PortCharge: number;
  PriceChild: number;
  PriceDouble: number;
  PriceExtraGuest: number;
  PriceSingle: number;
  PriceTypeName: string;
  Quad: number;
  QuadGTY: number;
  Quin: number;
  QuinGTY: number;
  Sgl: number;
  SglGTY: number;
  ShipID: number;
  Six: number;
  SixGTY: number;
  SortSequence: number;
  Sqm: string;
  SuiteCategoryGroup: string;
  SuiteCategoryID: number;
  Tax: number;
  TotalPrice: number;
  TotAV: number;
  TotGTY: number;
  Trip: number;
  TripGTY: number;
  VoyageID: number;
}

interface IReservationApiCruiseInventoryPastGuestDiscount {
  DepositPaymentDate: DateTime;
  FinalPaymentDate: DateTime;
}

interface IReservationApiCruiseInventoryPaymentScheduler {

}
export interface IReservationApiCruiseInventoryParams {
  Id: string;
  Currency: string;
  PriceTypeId: number;
  GrpID?: number;
  PackageId?: number;
  OnlyADA?: ReservationApiBoolean;
  /**
   * Y if retrieving inventory for a combo
   *
   * @type {ReservationApiBoolean}
   * @memberof IReservationApiCruiseInventoryParams
   */
  BkgCombo?: ReservationApiBoolean;
  /**
   * BkgID to get prices of the booking creation day
   * Needed in V2V / OD conversion / Copy booking
   * In general, when retrieving inventory inside a booking
   *
   * @type {number}
   * @memberof IReservationApiCruiseInventoryParams
   */
  BkgID?: number;
  /**
   * Y if today price forcing is needed
   *
   * @type {ReservationApiBoolean}
   * @memberof IReservationApiCruiseInventoryParams
   */
  ShowTodayPrice?: ReservationApiBoolean;
}
export interface IResProductsPaymentSchedulerParams {
  ProductCode: ResProductType,
  ID: Array<number>,
  PriceTypeID: number
}
export class ResProductsPaymentScheduler extends SafeBaseObject {
  [key: string]: any;
  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
  override updateData(data: any) {

    Object.keys(data).forEach(k => {
      this[this.camelCase(k)] = data[k];
    })

  }

  mangleColumnName(s: string): string {
    var cn = '';
    for (const c of s) {
      switch (c === c.toUpperCase()) {
        case true:
          cn += ' ' + c
          break;
        default:
          cn += c
          break;
      }
    }
    return cn.trim();
  }
  public getColumsName(): string[] {
    return Object.keys(this).map(k => this.pascalCase(this.mangleColumnName(k)));
  }
  public getColums(): string[] {
    return Object.keys(this);
  }
}

export interface IReservationApiCruiseSuiteListAvailability {
  DeckID?: number;
  DeckName?: string;
  DeckNumber?: number;
  IsGTY?: string;
  SuiteCapacityMax?: number;
  StatusDesc?: string;
  SuiteCapacity?: string;
  SuiteCapacityID?: number;
  SuiteCategory?: string;
  SuiteCategoryCod?: string;
  SuiteCategoryID?: number;
  ShipID: number;
  SuiteID?: number;
  SuiteNO?: string;
  SuiteNumber?: number;
  VoyageID?: number;
  SuiteFeatureID?: number;
  SuiteFeatureDesc?: string;
  Refurb_Status?: string;
  Obstruction?: string;
  ObsPercentage?: number;
  ObsReason?: string;
  AmenitiesID?: string;
  ConnectedSuite?:IReservationApiCruiseSuite
}

export class ReservationApiCruiseBooking extends SafeBaseObject {
  bkgID: number = 0;
  bkgStatus: string = '';
  ownerType: string = '';
  ownerDesc: string = '';
  priceTypeID: number = 0;
  priceTypeName: string = '';
  suiteCategoryID: number = 0;
  suiteCategory: string = '';
  suiteCategoryCode: string = '';
  agency?: string;
  firstName?: string;
  lastName?: string;
  groupID?: number;
  groupName?: string;
  displayAssignSuite: boolean = false;
  guestBooking: Array<ReservationApiCruiseSuiteGuest> = [];
  constructor(data?: IReservationApiCruiseBooking) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
  override updateData(data?: IReservationApiCruiseBooking) {
    if (data) {
      super.updateData(data);
      if (data.GuestBooking) this.guestBooking = data.GuestBooking.map(g => new ReservationApiCruiseSuiteGuest(g));
      if (this.ownerType) {
        this.ownerDesc = this.ownerType === 'DIRECT' ? `${this.firstName} ${this.lastName}` : `${this.agency} - agent: ${this.firstName} ${this.lastName}`;
      }

    }
  }

}
export class ReservationApiCruiseSuiteGuest extends SafeBaseObject {
  bkgID: number = 0;
  lastName: string = '';
  firstName: string = '';
  guestType: string = '';

  constructor(data?: IReservationApiCruiseSuiteGuest) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}
export class ReservationConnectedSuite extends SafeBaseObject {
  shipID = 0
  suiteNo = ''
  suiteCategoryCod = ''
  suiteNoConnected = ''
  suiteCategoryCodConnected = ''

  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  override updateData(_data: any) {
    if(this.suiteNo==='') this.suiteNo='none'
  }
}
export class ReservationCruiseSuite extends SafeBaseObject {
  voyageID: number = 0;
  suiteCategoryID: number = 0;
  suiteCategory: string = '';
  suiteCategoryCode: string = '';
  displaySequence?: number;
  suiteID: number = 0;
  suiteNo: string = '';
  suiteNoConnected = ''
  suiteStatus: ReservationCruiseSuiteAvailabilityStatusCode = '';
  suiteStatusDesc: string = '';
  suiteCapacityID: number = 0;
  suiteCapacity?: string = '';
  suiteCapacityNum: number = 0;
  deckID: number = 0;
  deckNumber: number = 0;
  deckName?: string;
  suiteFeatureDesc?: string;
  bkgID: number = 0;
  bkgStatus: string = '';
  created?: DateTime;
  ownerType?: string;
  ownerDesc?: string;
  agency?: string;
  firstName?: string;
  lastName?: string;
  groupID?: number;
  groupName?: string;
  gty: 'Y' | 'N' | 'W' = 'N';
  suiteFeatureID = 0;
  refurbStatus = '';
  obstruction = '';
  obsPercentage = 0;
  obsReason = '';
  reason = '';
  allotmentExpireDate?: DateTime;
  updatedBy = '';
  agencyGroupID = 0;

  connectedSuite = new ReservationConnectedSuite()
  suiteCategoryColor = ''

  bookingInfo: Array<ReservationApiCruiseBooking> = []

  amenities: Array<IReservationSuiteAmenity> = [];

  get isAda(): boolean {
    let items = this.amenities.filter(item => item.Amenity === 'ADA');
    return items.length > 0;
  }

  get noAndStatusDescription(): string {
    return `${this.number} - ${this.statusDescription}`
  }

  get number(): string {
    return this.suiteNo;
  }

  get statusDescription(): string {
    return this.suiteStatusDesc ?? '?';
  }

  constructor(data?: IReservationApiCruiseSuite, amenities: IReservationSuiteAmenity[] = []) {
    super();
    if (data) {
      this.updateData(data, amenities);
    }
  }

  override updateData(data: IReservationApiCruiseSuite, amenities: IReservationSuiteAmenity[] = []) {

    super.updateData(data);

    this.addDateTimeProperty("created", data, "Created");
    this.addDateTimeProperty("allotmentExpireDate", data, "AllotmentExpireDate");

    //Property out of name convetion START (@TODO when there will be time)
    this.addProperty("gty", data, "GTY");

    if (data.BookingInfo) this.bookingInfo = data.BookingInfo.map(g => new ReservationApiCruiseBooking(g));
    this.bkgID = this.getSuiteBookingID();
    this.bkgStatus = this.getSuiteBookingStatus();
    this.ownerType = this.getSuiteBooking()?.ownerType;
    this.ownerDesc = this.getSuiteBooking()?.ownerDesc;
    this.agency = this.getSuiteBooking()?.agency;
    this.firstName = this.getSuiteBooking()?.firstName;
    this.lastName = this.getSuiteBooking()?.lastName;
    this.groupID = this.getSuiteBooking()?.groupID;
    this.groupName = this.getSuiteBooking()?.groupName;

    //Property out of name convetion END
    if (data.AmenitiesID) {
      data.AmenitiesID.split(';').forEach(aID => {
        const am = amenities.find(a => a.AmenitiesID === +aID);
        if (am) {
          this.amenities.push(am);
        }
      });
    }
    if (data.ConnectedSuite) this.connectedSuite = new ReservationConnectedSuite(data.ConnectedSuite)
  }

  get hasGtyBooking(): boolean {
    return this.gty === 'Y' && this.hasAssignableBooking();
  }

  get hasWlBooking(): boolean {
    return this.gty === 'W' && this.hasAssignableBooking();
  }

  hasAssignableBooking(): boolean {
    return this.bookingInfo.filter(b => b.displayAssignSuite).length > 0;
  }
  hasBooking(): boolean {
    return this.bookingInfo.length > 0;
  }
  hasSingleBooking(): boolean {
    return this.bookingInfo.length === 1;
  }
  hasMoreBooking(): boolean {
    return this.bookingInfo.length > 1;
  }
  existBooking(bkgIDs: Array<number>) {
    return this.bookingInfo.find(b => bkgIDs.includes(b.bkgID));
  }
  getSuiteBookingID(): number {
    if (this.bookingInfo.length === 0) return 0;
    const b = this.bookingInfo.find(b => b.bkgStatus === 'Booked') ?? this.bookingInfo[0];
    return b.bkgID;
  }
  getSuiteBooking(): ReservationApiCruiseBooking | undefined {
    if (this.bookingInfo.length === 0) return;
    return this.bookingInfo.find(b => b.bkgStatus === 'Booked') ?? this.bookingInfo[0];
  }
  getSuiteBookingStatus(): string {
    if (this.bookingInfo.length === 0) return '';
    const b = this.bookingInfo.find(b => b.bkgStatus === 'Booked') ?? this.bookingInfo[0];
    return b.bkgStatus;
  }
  hasGroup(): boolean {
    return this.bookingInfo.filter(b => b.groupID).length > 0;
  }
  GetVisibleBooking(onlyAssignable: boolean): Array<ReservationApiCruiseBooking> {
    if (!onlyAssignable) return this.bookingInfo;

    return this.bookingInfo.filter(b => b.displayAssignSuite);
  }
  getSuiteGroupDescription(): string {
    const b = this.bookingInfo.find(b => b.groupID);
    return b ? (b.groupName ?? ' ') + (b.groupID ?? '') : '';
  }
  getSuiteInfoTooltip() {
    let info = '';
    if (!!this.refurbStatus) info += `Refurb: ${this.refurbStatus}`;
    if (!!this.obstruction) {
      if (info.length > 0) info += ' - ';
      info += `Obstruction view: ${this.obstruction}`
      if (!!this.obsPercentage) {
        info += ` ${this.obsPercentage}%`
      };
      if (!!this.obsReason) {
        info += ` ${this.obsReason}`
      };
    };
    return info;
  }
  getSuiteInfoTooltipHtml() {
    let info = '<div>';
    if (!!this.refurbStatus) info += `<div>Refurb: ${this.refurbStatus}</div>`;
    if (!!this.obstruction) {
      info += `<div class="text-start">Obstruction view: ${this.obstruction}`
      if (!!this.obsPercentage) {
        info += ` ${this.obsPercentage}%`
      };
      if (!!this.obsReason) {
        info += `<br />${this.obsReason}`
      };
      info += '</div>';
    };
    info += '</div>';
    return info;
  }
  get isBooked() {
    return this.suiteStatus === ReservationCruiseSuiteAvailabilityEnumStatusCode.Booked;
  }
  get isStdHold() {
    return this.suiteStatus === ReservationCruiseSuiteAvailabilityEnumStatusCode.StandardHold;
  }
}

export interface IResInventoryUpdateParams {
  ShipID: number,
  TravelStartDate?: string
  TravelEndDate?: string
  SuiteID: number
  SuiteStatus: string
  Reason?: string;
  AllotmentExpireDate?: string
  SuiteCapacityID?: number,
  SuiteCategoryCod?: string
  SuiteNoConnected?: string
  GroupID?: number
}

export interface IResGtyUpdateParams {
  VoyID: number;
  SuiteCategoryID: number;
  SuiteCapacityID: number;
  NGty: number;
}

export interface IReservationApiCruiseSuite {
  VoyageID?: number;
  SuiteCategoryID?: number;
  SuiteCategory?: string;
  SuiteCategoryCod?: string;
  DisplaySequence?: number;
  SuiteID?: number;
  SuiteNo?: string;
  SuiteStatus?: ReservationCruiseSuiteAvailabilityStatusCode;
  SuiteStatusDesc?: string;
  SuiteCapacityID?: number;
  SuiteCapacity?: string;
  SuiteCapacityNum?: number;
  DeckID?: number;
  DeckNumber?: number;
  DeckName?: string;
  SuiteFeatureDesc?: string;
  // BkgID?: number;
  // BkgStatus?: string;
  Created?: string;
  // OwnerType?:string;
  // Agency?:string;
  // FirstName?:string;
  // LastName?:string;
  // GroupID?: number;
  // GroupName?: string;
  GTY?: 'Y' | 'N' | 'W';
  SuiteFeatureID?: number;
  Refurb_Status?: string;
  Obstruction?: string;
  ObsPercentage?: number;
  ObsReason?: string;
  AmenitiesID?: string;
  Reason?: string;
  AllotmentExpireDate?: DateTime;
  UpdatedBy?: string;
  BookingInfo: Array<IReservationApiCruiseBooking>;
  ConnectedSuite: IReservationApiCruiseSuite
  SuiteCategoryColor?: ''
  AgencyGroupID?: number
  KeySuiteID:number;
}

export interface IReservationApiCruiseBooking {
  BkgID: number;
  BkgStatus: string;
  OwnerType: string;
  ownerDesc: string;
  PriceTypeID: number;
  PriceTypeName: string;
  SuiteCategoryID: number;
  SuiteCategory: string;
  SuiteCategoryCode: string;
  Agency?: string;
  FirstName?: string;
  LastName?: string;
  GroupID?: number;
  GroupName?: string;
  DisplayAssignSuite: string;
  GuestBooking: Array<IReservationApiCruiseSuiteGuest>;
}
export interface IReservationApiCruiseSuiteGuest {
  BkgID: number;
  LastName: string;
  FirstName: string;
  GuestType: string;
}

export class ResDeck extends SafeBaseObject {
  deckID: number = 0;
  deckNumber: number = 0;
  deckName: string = '';
  shipID: number = 0;
  suiteCategoryID: number = 0;

  constructor(data?: IReservationApiDeck) {
    super();
    if(data) this.updateData(data);
  }
}

export interface IReservationApiDeck {
  DeckID: number;
  DeckNumber: number;
  DeckName: string;
  ShipID: number;
  SuiteCategoryID: number;
}

export interface IReservationGroupVoyageList {
  GroupID: number;
  GroupName: string;
}

export class ReservationGroupVoyageList extends SafeBaseObject {
  groupID: number = 0;
  groupName: string = '';

  constructor(data?: IReservationGroupVoyageList) {
    super();
    if(data) this.updateData(data);
  }
}
