import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

import {
  EidosExternalAppAction,
  EidosLogSeverity,
  LocalStorageKeys,
} from '@common/models/core-constant.model';
import { EidosUser } from '@common/models/user-info.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosChangePasswordDialogComponent } from '@common/components/eidos-dialogs/eidos-change-password-dialog/eidos-change-password-dialog.component';
import { EidosConfigService } from '@common/config/eidos-config.service';
import {
  EidosMenu,
  EidosMenuItem,
  EidosMenuItemType,
} from '@common/models/eidos-menu.model';
import { FormControl } from '@angular/forms';
import { EidosExternalAppService } from '@common/eidos-external-app/eidos-external-app.service';
import { filter, take } from 'rxjs';
import { EidosExternalApp } from '@common/models/eidos-external-app.model';

@Component({
  selector: 'eidos-main-page',
  templateUrl: './eidos-main-page.component.html',
  styleUrls: ['./eidos-main-page.component.scss'],
})
export class EidosMainPageComponent implements OnInit {
  /**
   * Authenticated flag
   *
   * @memberof EidosMainPageComponent
   */
  public isAuthenticated = false;
  /**
   * CSS class of main page
   *
   * @type {string}
   * @memberof EidosMainPageComponent
   */
  public mainClass: string = 'eidos-theme';
  /**
   * Menu closed flag
   *
   * @type {boolean}
   * @memberof EidosMainPageComponent
   */
  public isMenuClosed: boolean = true;
  /**
   * External app mode flag
   *
   * @memberof EidosMainPageComponent
   */
  public extAppMode = false;
  /**
   * Hide topbar flag
   *
   * @type {boolean}
   * @memberof EidosMainPageComponent
   */
  public hideTopbar: boolean = false;
  /**
   * Hide sidebar flag
   *
   * @type {boolean}
   * @memberof EidosMainPageComponent
   */
  public hideSidebar: boolean = false;
  /**
   *
   *
   * @type {boolean}
   * @memberof EidosMainPageComponent
   */
  public isDrawerOpen: boolean = false;
  /**
   *
   *
   * @type {(EidosMenu | null)}
   * @memberof EidosMainPageComponent
   */
  public menu: EidosMenu | null = null;
  /**
   *
   *
   * @type {FormControl}
   * @memberof EidosMainPageComponent
   */
  public sideMenuSearch: FormControl = new FormControl();
  /**
   *
   *
   * @type {Array<EidosMenuItem>}
   * @memberof EidosMainPageComponent
   */
  public displayedMenuItems: Array<EidosMenuItem> = [];
  /**
   *
   *
   * @type {Array<EidosMenuItem>}
   * @memberof EidosMainPageComponent
   */
  public orderedDisplayedMenuItems: Array<EidosMenuItem> = [];
  /**
   *
   *
   * @type {number}
   * @memberof EidosMainPageComponent
   */
  public selectedId: number = 0;
  /**
   *
   *
   * @private
   * @type {string}
   * @memberof EidosMainPageComponent
   */
  private externalAppsRoot: string;
  /**
   *
   *
   * @type {boolean}
   * @memberof EidosMainPageComponent
   */
  public isLocked: boolean = false;
  /**
   *
   *
   * @type {string[]}
   * @memberof EidosMainPageComponent
   */
  public menuOrders: string[] = ['none', 'az', 'za'];
  /**
   *
   *
   * @type {string}
   * @memberof EidosMainPageComponent
   */
  public menuOrder: string = this.menuOrders[0];
  /**
   *
   *
   * @type {boolean}
   * @memberof EidosMainPageComponent
   */
  public isSearchBarVisible: boolean = false;
  /**
   *
   *
   * @type {string}
   * @memberof EidosMainPageComponent
   */
  public searchBarMenuValue: string = '';

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  mobileToolbarQuery: MediaQueryList;
  private _mobileToolbarQueryListener: () => void;

  protected onPrepareEnvironment = false
  constructor(
    private securityService: EidosSecurityService,
    private router: Router,
    private eidosLogService: EidosLogService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private translocoService: TranslocoService,
    private eidosUtilityService: EidosUtilityService,
    private externalAppEventService: EidosExternalAppService,
    private eidosConfigService: EidosConfigService,
    private externalAppService: EidosExternalAppService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 1024px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.mobileToolbarQuery = this.media.matchMedia('(max-width: 599px)');
    this._mobileToolbarQueryListener = () =>
      this.changeDetectorRef.detectChanges();
    this.mobileToolbarQuery.addListener(this._mobileToolbarQueryListener);

    this.externalAppsRoot =
      this.eidosConfigService.DEFAULT_CONFIG.externalAppsRoot;

    this.eidosUtilityService.hideTopbar.next(false);
    this.eidosUtilityService.hideSidebar.next(false);
    this.eidosUtilityService.onPrepareEnvironment.subscribe({next:status=>this.onPrepareEnvironment=status})
  }

  ngOnInit(): void {
    const cachedLocked = localStorage.getItem(LocalStorageKeys.PINNED_MENU);
    this.isLocked = cachedLocked === 'true' ? true : false;

    this.eidosConfigService.currentConfig.subscribe(
      (config) => (this.externalAppsRoot = config.externalAppsRoot)
    );

    this.eidosUtilityService.currentMenu.subscribe((menu) => {
      this.menu = menu;
      this.buildDisplayMenuItem();
    });

    this.eidosUtilityService.hideSidebar.subscribe((hideSidebar) => {
      this.hideSidebar = hideSidebar;
    });

    this.eidosUtilityService.hideTopbar.subscribe((hideTopbar) => {
      this.hideTopbar = hideTopbar;
    });

    this.securityService.currentLoggedUser.subscribe((user) => {
      this.eidosLogService.logDebug(
        EidosLogSeverity.Log,
        `User change: ${user.username}`
      );
      this.loadNewUser(user);
    });

    this.eidosUtilityService.isMenuClosed.subscribe(
      (closed) => (this.isMenuClosed = closed)
    );

    this.extAppMode = this.router.url.indexOf('app') >= 0;
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.extAppMode = val.url.indexOf('app') >= 0;
      }
    });
  }
  /**
   * Load a new user in Eidos
   *
   * @private
   * @param {EidosUser} user
   * @memberof EidosMainPageComponent
   */
  private loadNewUser(user: EidosUser) {
    // Set current language if defined
    if (user.language) {
      this.translocoService.setActiveLang(user.language);
    }

    this.isAuthenticated = user.isValid;

    if (user.useCustomTheme) {
      this.mainClass = 'custom-theme';
    }

    // If user must change password, open the change pwd modal and force to use it
    if (user.mustChangePassword) {
      this.dialog.open(EidosChangePasswordDialogComponent, {
        width: '480px',
        disableClose: true,
      });
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }


  /**
   *
   *
   * @memberof EidosMainPageComponent
   */
  public toggleSidebar(): void {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  /**
   *
   *
   * @private
   * @memberof EidosMainPageComponent
   */
  private buildDisplayMenuItem(): void {
    let items: Array<EidosMenuItem> = [];

    if (this.menu) {
      const menuItems = this.menu.getItems(this.sideMenuSearch.value);
      items = [...menuItems];

      // Preserve menu ordering after search
      //this.onSort(items);

      this.externalAppEventService.apps
        .pipe(
          filter(
            (apps: EidosExternalApp[] | undefined) => !!apps && apps.length > 0
          ),
          take(1)
        )
        .subscribe((apps) => {
          if (apps) {
            for (let menuItem of apps
              .filter((app) => app.addToMenu)
              .map((app) => new EidosMenuItem(app))) {
              menuItem.label = menuItem.label.toUpperCase();
              const indexOfApp = items.findIndex((x) => x.id === menuItem.id);
              if (indexOfApp !== -1) {
                items[indexOfApp] = menuItem;
              } else {
                items.unshift(menuItem);
              }
            }
          }
        });
    }

    items = items.filter(
      (i, index, self) => index === self.findIndex((t) => t.id === i.id)
    );

    this.displayedMenuItems = items;
    this.orderedDisplayedMenuItems = items;

    if (!!this.orderedDisplayedMenuItems?.length && !this.hideSidebar)
      this.isDrawerOpen = this.isLocked;
  }

  /**
   *
   *
   * @param {*} e
   * @return {*}  {void}
   * @memberof EidosMainPageComponent
   */
  public selectItem(e: any): void {
    let item = e.itemData;

    this.selectedId = item.id;

    if (!!item.children?.length) {
      e.node.expanded
        ? e.component.collapseItem(e.node.key)
        : e.component.expandItem(e.node.key);
      return;
    }

    if (!item.disabled) {
      switch (item.type) {
        case EidosMenuItemType.url:
          if (item.isEidosObject) {
            const urlPart = (item.url + '').split('?');
            var qp: NavigationExtras = {};
            if (urlPart.length > 1) {
              qp.queryParams = {};
              urlPart[1].split('&').forEach(kv => {
                const kvp = kv.split('=');
                qp.queryParams![kvp[0]] = kvp.length > 0 ? kvp[1] : undefined;
              });
            }
            this.router.navigate([urlPart[0]], qp);
          } else {
            this.externalAppService.setAppEidosEmbeddedUrl(item.url ?? '');
            this.router.navigate([
              this.externalAppsRoot,
              EidosExternalAppAction.EmbeddedUrl,
            ]);
          }
          break;
        default:
          if (!!item.url) {
            this.router.navigate([item.url]);
          } else if (item.action) {
            this.router.navigate([this.externalAppsRoot, item.action]);
          } else if (item.isEidosObject) {
            this.router.navigate([EidosExternalAppAction.Eidos, item.objectId]);
          } else {
            this.router.navigate([
              this.externalAppsRoot,
              EidosExternalAppAction.MyBiz,
              item.objectId,
            ]);
          }
          break;
      }
      this.eidosUtilityService.toggleMenuStatusIfNotPinned(true);
    }
  }

  /**
   *
   *
   * @memberof EidosMainPageComponent
   */
  public menuOrderChange(): void {
    let orderIndex = this.menuOrders.findIndex(o => o == this.menuOrder)
    if (orderIndex == this.menuOrders.length - 1) orderIndex = 0
    else orderIndex++
    this.menuOrder = this.menuOrders[orderIndex]
    this.orderedDisplayedMenuItems = this.onSort(this.displayedMenuItems);
  }

  /**
   *
   *
   * @private
   * @param {Array<EidosMenuItem>} items
   * @return {*}  {Array<EidosMenuItem>}
   * @memberof EidosMainPageComponent
   */
  private onSort(items: Array<EidosMenuItem>): Array<EidosMenuItem> {
    let orderedItems: Array<EidosMenuItem> = [...items];

    switch (this.menuOrder) {
      case 'az':
        orderedItems.sort((a, b) => {
          return b.label > a.label ? -1 : 1;
        });
        break;
      case 'za':
        orderedItems.sort((a, b) => {
          return a.label > b.label ? -1 : 1;
        });
        break;
      case 'none':
        return orderedItems;
    }
    orderedItems.forEach((item) => {
      if (item.children.length !== 0) {
        this.onSort(item.children);
      }
    });

    return orderedItems;
  }

  /**
   *
   *
   * @param {*} e
   * @memberof EidosMainPageComponent
   */
  public optionChanged(e: any): void {
    if (e.name == 'searchValue' && !e.value) {
      //TODO invastigate how to collapse
      e.component.collapseAll();
    }
  }

  /**
   *
   *
   * @param {*} e
   * @memberof EidosMainPageComponent
   */
  public onContentReady(e: any): void {
    const searchBar: any = document.querySelector('.dx-treeview-search input')
    if (!searchBar?.value) {
      const selectedNode = document.querySelector('.dx-treeview-node.dx-state-focused')
      if (!selectedNode) e.component.collapseAll();
    }
  }

  /**
   *
   *
   * @param {*} e
   * @memberof EidosMainPageComponent
   */
  public toggleLock(): void {
    this.isLocked = !this.isLocked;
    this.eidosUtilityService.setMenuPin(this.isLocked);
  }

  /**
   *
   *
   * @memberof EidosMainPageComponent
   */
  public toggleSearchBar(): void {
    this.isSearchBarVisible = !this.isSearchBarVisible;
    if (!this.isSearchBarVisible) this.searchBarMenuValue = '';
  }
}
